import type { Accept as AcceptOptions } from "react-dropzone"
import {
  BankAccountTransferType,
  BuyerGroupPersonnelType,
  CalcAgentReviewStatus,
  Option,
  ProductType,
} from "types/server/main"

export const MAX_CONCENTRATION_LIMITS = 300

export const stateOptions: Option[] = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Maryland", value: "MD" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
]

export const buyerPersonnelTypeLabels = {
  [BuyerGroupPersonnelType.AGENT]: "Agent",
  [BuyerGroupPersonnelType.LOAN_OFFICER]: "Loan Officer",
  [BuyerGroupPersonnelType.OTHER]: "Other",
}

export const productTypeLabelMapper = {
  [ProductType.AIRPLANE_LEASE]: "Airplane Lease",
  [ProductType.AUTO]: "Auto",
  [ProductType.BBYS]: "Buy Before You Sell",
  [ProductType.BRIDGE_LOAN]: "Bridge Loan",
  [ProductType.CASH_OFFER]: "Cash Offer",
  [ProductType.EGG_FREEZING]: "Egg Freezing",
  [ProductType.EQUITY_ADVANCE]: "Equity Advance",
  [ProductType.TRADE_UP]: "Trade Up",
  [ProductType.FIX_AND_FLIP]: "Fix & Flip",
  [ProductType.FUND_TO_BUILD]: "Fund to Build",
  [ProductType.HW_BBYS_AGED]: "BBYS Aged",
  [ProductType.HW_CASH_OFFER_AGED]: "Cash Offer Aged",
  [ProductType.HW_FLOOR_PRICE_ACTIVATION]: "Floor Price Activation",
  [ProductType.HW_BACKED_BY_CASH]: "Backed By Cash",
  [ProductType.IBUYER]: "iBuyer",
  [ProductType.INSURANCE]: "Insurance",
  [ProductType.IVF]: "IVF",
  [ProductType.METRO_TRAILING]: "Metro Trailing",
  [ProductType.ORCHARD_AGED_GOLDMAN_SACHS]: "Aged",
  [ProductType.ORCHARD_OFFERBOOST]: "Offerboost",
  [ProductType.RECEIVABLE]: "Receivable",
  [ProductType.RTL]: "RTL",
  [ProductType.RENT_TO_OWN]: "Rent to Own",
  [ProductType.SFR]: "SFR",
  [ProductType.SBYL]: "SBYL",
  [ProductType.COMMERCIAL]: "Commercial",
}

export const bankAccountTypes: Option[] = [
  { label: "Disbursement", value: BankAccountTransferType.DISBURSEMENT },
  { label: "Collection", value: BankAccountTransferType.COLLECTION },
  { label: "Reserve", value: BankAccountTransferType.RESERVE },
]

export const infiniteMoney = 1000000000000

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const MONTHS_ABBREV = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

export const MONTH_OPTIONS: Option[] = MONTHS.map((month, idx) => ({
  label: month,
  value: String(idx + 1),
}))

const year = new Date().getFullYear()
const prevYear = String(year - 1)
const currentYear = String(year)
const nextYear = String(year + 1)
export const YEAR_OPTIONS = [
  { label: prevYear, value: prevYear },
  { label: currentYear, value: currentYear },
  { label: nextYear, value: nextYear },
]

export const FUNDING_REQUIREMENT_FILE_TYPES: AcceptOptions = {
  "application/pdf": [".pdf"],
  "image/*": [".jpg", ".jpeg", ".png", ".gif"],
  "text/csv": [".csv"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-excel*": [".xls", ".csv", ".xlsx", ".xlsb", ".xlsm"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.ms-outlook": [".msg"],
}

export const SPREADSHEET_FILE_TYPES: AcceptOptions = {
  "text/csv": [".csv"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-excel*": [".xls", ".csv", ".xlsx", ".xlsb", ".xlsm"],
}

export const DK_AIRLINES = [
  { label: "Aer Lingus", value: "Aer Lingus" },
  { label: "Air Canada", value: "Air Canada" },
  { label: "Air China", value: "Air China" },
  { label: "Air France-KLM", value: "Air France-KLM" },
  { label: "Air Macau", value: "Air Macau" },
  { label: "Air New Zealand", value: "Air New Zealand" },
  { label: "Alaska Airlines", value: "Alaska Airlines" },
  { label: "Allegiant Air", value: "Allegiant Air" },
  { label: "Amazon", value: "Amazon" },
  { label: "American Airlines", value: "American Airlines" },
  { label: "ANA", value: "ANA" },
  { label: "Asiana", value: "Asiana" },
  { label: "Atlas Air", value: "Atlas Air" },
  { label: "Austrian Airlines", value: "Austrian Airlines" },
  { label: "British Airways", value: "British Airways" },
  { label: "Brussels", value: "Brussels" },
  { label: "Cargolux", value: "Cargolux" },
  { label: "Cathay Pacific", value: "Cathay Pacific" },
  { label: "Cebu Pacific", value: "Cebu Pacific" },
  { label: "China Airlines", value: "China Airlines" },
  { label: "China Eastern Airlines", value: "China Eastern Airlines" },
  { label: "China Postal Airlines", value: "China Postal Airlines" },
  { label: "China Southern Airlines", value: "China Southern Airlines" },
  { label: "Copa Airlines", value: "Copa Airlines" },
  { label: "Delta Air Lines", value: "Delta Air Lines" },
  { label: "DHL", value: "DHL" },
  { label: "EasyJet", value: "EasyJet" },
  { label: "El Al", value: "El Al" },
  { label: "Emirates Airline", value: "Emirates Airline" },
  { label: "Etihad Airways", value: "Etihad Airways" },
  { label: "FedEx/TNT Group", value: "FedEx/TNT Group" },
  { label: "Flydubai", value: "Flydubai" },
  { label: "Frontier Airlines", value: "Frontier Airlines" },
  { label: "Hawaiian", value: "Hawaiian" },
  { label: "IAG Group", value: "IAG Group" },
  { label: "Iberia", value: "Iberia" },
  { label: "Icelandair", value: "Icelandair" },
  { label: "IndiGo", value: "IndiGo" },
  { label: "Japan Airlines", value: "Japan Airlines" },
  { label: "JetBlue Airways", value: "JetBlue Airways" },
  { label: "Jetstar Group", value: "Jetstar Group" },
  { label: "Korean Air", value: "Korean Air" },
  { label: "Laudamotion", value: "Laudamotion" },
  { label: "Lufthansa", value: "Lufthansa" },
  { label: "Luxair", value: "Luxair" },
  { label: "Nippon Cargo Airlines", value: "Nippon Cargo Airlines" },
  { label: "Peach Aviation", value: "Peach Aviation" },
  { label: "Pegasus", value: "Pegasus" },
  { label: "Qantas", value: "Qantas" },
  { label: "Qatar Airways", value: "Qatar Airways" },
  { label: "Ryanair", value: "Ryanair" },
  { label: "Scoot", value: "Scoot" },
  { label: "SF Express Group", value: "SF Express Group" },
  { label: "Shanghai Airlines", value: "Shanghai Airlines" },
  { label: "Shenzhen Airlines", value: "Shenzhen Airlines" },
  { label: "Singapore Airline Group", value: "Singapore Airline Group" },
  { label: "SkyWest Airlines", value: "SkyWest Airlines" },
  { label: "Southwest Airlines", value: "Southwest Airlines" },
  { label: "Spirit Airlines", value: "Spirit Airlines" },
  { label: "SunExpress", value: "SunExpress" },
  { label: "Sunwing", value: "Sunwing" },
  { label: "Swiss", value: "Swiss" },
  { label: "Transavia", value: "Transavia" },
  { label: "AF-KLM/Transavia", value: "AF-KLM/Transavia" },
  { label: "Turkish Airlines", value: "Turkish Airlines" },
  { label: "United Airlines", value: "United Airlines" },
  { label: "UPS", value: "UPS" },
  { label: "Volaris", value: "Volaris" },
  { label: "Vueling Airlines", value: "Vueling Airlines" },
  { label: "WestJet", value: "WestJet" },
  { label: "Wizz Air", value: "Wizz Air" },
  { label: "Aegean Airlines", value: "Aegean Airlines" },
  { label: "Aeromexico", value: "Aeromexico" },
  { label: "Air Arabia", value: "Air Arabia" },
  { label: "Air Astana", value: "Air Astana" },
  { label: "Air Baltic", value: "Air Baltic" },
  { label: "Air Europa", value: "Air Europa" },
  { label: "Air India", value: "Air India" },
  { label: "Air Transat", value: "Air Transat" },
  { label: "ASL Airlines", value: "ASL Airlines" },
  { label: "Avianca", value: "Avianca" },
  { label: "Azul", value: "Azul" },
  { label: "Bangkok Airways", value: "Bangkok Airways" },
  { label: "Breeze Airways", value: "Breeze Airways" },
  { label: "Cargojet", value: "Cargojet" },
  { label: "Condor", value: "Condor" },
  { label: "Egypt air", value: "Egypt air" },
  { label: "Ethiopian Airlines", value: "Ethiopian Airlines" },
  { label: "EVA Air", value: "EVA Air" },
  { label: "Fiji Airways", value: "Fiji Airways" },
  { label: "Finnair", value: "Finnair" },
  { label: "Flair Airlines", value: "Flair Airlines" },
  { label: "GOL", value: "GOL" },
  { label: "Gulf Air", value: "Gulf Air" },
  { label: "ITA Airways", value: "ITA Airways" },
  { label: "Jeju Air", value: "Jeju Air" },
  { label: "Jet2.com", value: "Jet2.com" },
  { label: "JetSMART", value: "JetSMART" },
  { label: "Jin Air", value: "Jin Air" },
  { label: "Juneyao", value: "Juneyao" },
  { label: "Kenya Airways", value: "Kenya Airways" },
  { label: "Kuwait Airways", value: "Kuwait Airways" },
  { label: "LatAm", value: "LatAm" },
  { label: "LOT", value: "LOT" },
  { label: "Mesa", value: "Mesa" },
  { label: "Oman Air", value: "Oman Air" },
  { label: "PAL", value: "PAL" },
  { label: "Republic Airways", value: "Republic Airways" },
  { label: "REX", value: "REX" },
  { label: "Royal Air Maroc", value: "Royal Air Maroc" },
  { label: "Royal Jordanian Airlines", value: "Royal Jordanian Airlines" },
  { label: "SAS", value: "SAS" },
  { label: "Saudia", value: "Saudia" },
  { label: "Shandong Airlines", value: "Shandong Airlines" },
  { label: "Sichuan Airlines", value: "Sichuan Airlines" },
  { label: "Sky Airline", value: "Sky Airline" },
  { label: "Skymark", value: "Skymark" },
  { label: "Solaseed", value: "Solaseed" },
  { label: "Spring", value: "Spring" },
  { label: "Starflyer", value: "Starflyer" },
  { label: "Sun Country", value: "Sun Country" },
  { label: "TAP Air Portugal", value: "TAP Air Portugal" },
  { label: "TUI Group", value: "TUI Group" },
  { label: "Vietjet", value: "Vietjet" },
  { label: "Vietnam Airlines", value: "Vietnam Airlines" },
  { label: "Virgin Atlantic", value: "Virgin Atlantic" },
  { label: "Virgin Australia", value: "Virgin Australia" },
  { label: "Vistara", value: "Vistara" },
  { label: "Vivaaerobus", value: "Vivaaerobus" },
  { label: "Viva Colombia", value: "Viva Colombia" },
  { label: "Xiamen Airlines", value: "Xiamen Airlines" },
  { label: "YTO Express", value: "YTO Express" },
  { label: "Other - Tier 3", value: "Other" },
]

export const DK_AIRCRAFTS = [
  { label: "A220-100", value: "A220-100" },
  { label: "A220-300", value: "A220-300" },
  { label: "A320NEO", value: "A320NEO" },
  { label: "A321NEO", value: "A321NEO" },
  { label: "A321LR", value: "A321LR" },
  { label: "A321XLR", value: "A321XLR" },
  { label: "737 MAX 8", value: "737 MAX 8" },
  { label: "737 MAX 9", value: "737 MAX 9" },
  { label: "737 MAX 10", value: "737 MAX 10" },
  { label: "A319-100", value: "A319-100" },
  { label: "A320-200", value: "A320-200" },
  { label: "A321-200", value: "A321-200" },
  { label: "737-700", value: "737-700" },
  { label: "737-800", value: "737-800" },
  { label: "737-900ER", value: "737-900ER" },
  { label: "A350-1000", value: "A350-1000" },
  { label: "A350-900", value: "A350-900" },
  { label: "A330-900NEO", value: "A330-900NEO" },
  { label: "787-10", value: "787-10" },
  { label: "787-8", value: "787-8" },
  { label: "787-9", value: "787-9" },
  { label: "777-8", value: "777-8" },
  { label: "777-9", value: "777-9" },
  { label: "E190-E2", value: "E190-E2" },
  { label: "E195-E2", value: "E195-E2" },
  { label: "A330F", value: "A330F" },
  { label: "777-200F", value: "777-200F" },
  { label: "747-400F", value: "747-400F" },
  { label: "747-8F", value: "747-8F" },
  { label: "737-800F", value: "737-800F" },
  { label: "737-800SF", value: "737-800SF" },
  { label: "A321F", value: "A321F" },
  { label: "767-300ERF", value: "767-300ERF" },
  { label: "757-200F", value: "757-200F" },
  { label: "A330-200", value: "A330-200" },
  { label: "A330-300", value: "A330-300" },
  { label: "777-300ER", value: "777-300ER" },
  { label: "767-300ER", value: "767-300ER" },
  { label: "ATR42", value: "ATR42" },
  { label: "ATR72", value: "ATR72" },
  { label: "757-200", value: "757-200" },
  { label: "CRJ-900", value: "CRJ-900" },
  { label: "E170", value: "E170" },
  { label: "E175", value: "E175" },
  { label: "E190", value: "E190" },
  { label: "Q400", value: "Q400" },
  { label: "E195", value: "E195" },
  { label: "PW1500G", value: "PW1500G" },
  { label: "PW1100G", value: "PW1100G" },
  { label: "LEAP 1-A", value: "LEAP 1-A" },
  { label: "LEAP 1-B", value: "LEAP 1-B" },
  { label: "CFM56-5B", value: "CFM56-5B" },
  { label: "V2500", value: "V2500" },
  { label: "CFM56-7B", value: "CFM56-7B" },
  { label: "GEnx-1B", value: "GEnx-1B" },
  { label: "Trent 1000", value: "Trent 1000" },
  { label: "Trent 7000", value: "Trent 7000" },
  { label: "Trent XWB", value: "Trent XWB" },
  { label: "GE9X", value: "GE9X" },
]

export const DK_COUNTRIES = [
  { label: "Algeria", value: "Algeria" },
  { label: "Egypt", value: "Egypt" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Kenya", value: "Kenya" },
  { label: "Morocco", value: "Morocco" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "South Africa", value: "South Africa" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Namibia", value: "Namibia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Iran", value: "Iran" },
  { label: "Israel", value: "Israel" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Oman", value: "Oman" },
  { label: "Qatar", value: "Qatar" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Syria", value: "Syria" },
  { label: "Turkey", value: "Turkey" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "Australia", value: "Australia" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Brunei", value: "Brunei" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "China", value: "China" },
  { label: "Fiji", value: "Fiji" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Japan", value: "Japan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Macau", value: "Macau" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Philippines", value: "Philippines" },
  { label: "Singapore", value: "Singapore" },
  { label: "South Korea", value: "South Korea" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Taiwan", value: "Taiwan" },
  { label: "Thailand", value: "Thailand" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Nepal", value: "Nepal" },
  { label: "North Korea", value: "North Korea" },
  { label: "Albania", value: "Albania" },
  { label: "Andorra", value: "Andorra" },
  { label: "Armenia", value: "Armenia" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Belarus", value: "Belarus" },
  { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: "Georgia", value: "Georgia" },
  { label: "Iceland", value: "Iceland" },
  { label: "Kosovo", value: "Kosovo" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Moldova", value: "Moldova" },
  { label: "Monaco", value: "Monaco" },
  { label: "Montenegro", value: "Montenegro" },
  { label: "North Macedonia", value: "North Macedonia" },
  { label: "Norway", value: "Norway" },
  { label: "Russia", value: "Russia" },
  { label: "San Marino", value: "San Marino" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "Vatican City", value: "Vatican City" },
  { label: "United States of America", value: "United States of America" },
  { label: "Canada", value: "Canada" },
  { label: "Austria", value: "Austria" },
  { label: "Belgium", value: "Belgium" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czech Republic", value: "Czech Republic" },
  { label: "Denmark", value: "Denmark" },
  { label: "Estonia", value: "Estonia" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "Germany", value: "Germany" },
  { label: "Greece", value: "Greece" },
  { label: "Hungary", value: "Hungary" },
  { label: "Ireland", value: "Ireland" },
  { label: "Italy", value: "Italy" },
  { label: "Latvia", value: "Latvia" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Malta", value: "Malta" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Romania", value: "Romania" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Spain", value: "Spain" },
  { label: "Sweden", value: "Sweden" },
  { label: "Brazil", value: "Brazil" },
  { label: "Colombia", value: "Colombia" },
  { label: "Argentina", value: "Argentina" },
  { label: "Peru", value: "Peru" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Chile", value: "Chile" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Guyana", value: "Guyana" },
  { label: "Suriname", value: "Suriname" },
  { label: "French Guiana", value: "French Guiana" },
  { label: "Falkland Islands", value: "Falkland Islands" },
  { label: "Mexico", value: "Mexico" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Honduras", value: "Honduras" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Panama", value: "Panama" },
  { label: "Belize", value: "Belize" },
  { label: "Cuba", value: "Cuba" },
  { label: "Haiti", value: "Haiti" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Martinique", value: "Martinique" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Barbados", value: "Barbados" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  { label: "Curaçao", value: "Curaçao" },
  { label: "Saint Vincent - Grenadines", value: "Saint Vincent - Grenadines" },
  { label: "Grenada", value: "Grenada" },
  { label: "United States Virgin Islands", value: "United States Virgin Islands" },
  { label: "Aruba", value: "Aruba" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Dominica", value: "Dominica" },
  { label: "Cayman Islands", value: "Cayman Islands" },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Sint Maarten", value: "Sint Maarten" },
  { label: "Saint Martin", value: "Saint Martin" },
  { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: "British Virgin Islands", value: "British Virgin Islands" },
  { label: "Caribbean Netherlands", value: "Caribbean Netherlands" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Saint-Barthélemy", value: "Saint-Barthélemy" },
]

export const DK_EQUIPMENT = [
  { label: "Aircraft", value: "Aircraft" },
  { label: "Engine", value: "Engine" },
]

export const DK_BORROWER_TYPE = [
  { label: "Sponsor/SPV", value: "Sponsor/SPV" },
  { label: "Direct Airline", value: "Direct Airline" },
]

export const DK_PIPELINE_STATUS = [
  { label: "Pricing", value: "pricing" },
  { label: "Awarded", value: "awarded" },
  { label: "Funded", value: "funded" },
]

export const CALC_AGENT_BORROWER_REPORT_REVIEW_STATUS_OPTIONS = [
  { label: "All", value: "" },
  {
    label: "Ready For Borrowing Request",
    value: CalcAgentReviewStatus.READY_FOR_BORROWING_REQUEST,
  },
  {
    label: "Borrowing Request Added",
    value: CalcAgentReviewStatus.BORROWING_REQUEST_ADDED,
  },
  { label: "Approved", value: CalcAgentReviewStatus.APPROVED },
  { label: "Funded", value: CalcAgentReviewStatus.FUNDED },
] as Option[]

export const FILE_EXTENSION_REGEX = /\.([0-9a-z]+)(?:[?#]|$)/i
