import { type GetServerSidePropsContext } from "next"
import { Permission } from "types/server/auth"
import { checkAuth, getSession, redirect } from "utility/auth"

export const BORROWER_REPORTS_PAGE_PATHNAME =
  "/borrower-reports/report/[calcAgentReportGlobalId]/[tab]"

export const BORROWER_REPORTS_TABS = [
  {
    label: "Reports",
    value: "reports",
    href: "/borrower-reports/summary",
  },
  {
    label: "Waivers",
    value: "waivers",
    href: "/borrower-reports/waivers",
  },
  {
    label: "Settings",
    value: "settings",
    href: "/borrower-reports/settings",
  },
  {
    label: "Audit Log",
    value: "audit",
    href: "/borrower-reports/audit",
  },
]
export const BORROWER_REPORTS_SUMMARY_PATHNAME = BORROWER_REPORTS_TABS[0].href

/** Requires the `Permission.BORROWER_REPORTS_PAGE` to be set. */
export async function redirectUnauthorizedUsers(context: GetServerSidePropsContext) {
  const session = await getSession(context)
  if (!session) return redirect("/")

  const { permissions } = session
  if (!permissions.includes(Permission.BORROWER_REPORTS_PAGE)) return redirect("/")

  return checkAuth(context, Permission.BORROWER_REPORTS_PAGE)
}
