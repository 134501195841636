import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import "styles/globals.css"
import "styles/other-overrides.css"
import "styles/pdf-viewer-style-override.css"
import "styles/react-datepicker-style-override.css"
import "styles/react-select-style-override.css"
import "styles/blueprintjs-style-override.css"
import "tailwindcss/tailwind.css"

import { QueryClient, QueryClientProvider } from "react-query"
import { SegmentScript, trackPageView } from "utility/analytics"

import {reactQueryDevToolsEnabled} from "config"
import { useProfileQuery } from "hooks/auth"
import { AppProps } from "next/app"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { ReactQueryDevtools } from "react-query/devtools"
import { IntercomProvider } from "react-use-intercom"
import { ProfileResponse } from "types/server/auth"
import { logOut } from "utility/auth"
import { getSubdomain, isClient } from "utility/utils"
import {DatadogScript} from "utility/datadog";

const INTERCOM_APP_ID = "ketmhk46"
const DEFAULT_SUBDOMAINS = ["app", "local"]

const canAccessSubdomain = (
  pageSubdomain: string,
  whitelabelSubdomain: string | undefined,
): boolean => {
  if (whitelabelSubdomain && pageSubdomain !== whitelabelSubdomain) {
    return false
  }

  if (!whitelabelSubdomain && !DEFAULT_SUBDOMAINS.includes(pageSubdomain)) {
    return false
  }

  return true
}

type SetpointAppProps = AppProps<Partial<Record<"session", ProfileResponse>>>

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 15000,
    },
  },
})

const ComponentWrapper = ({ Component, pageProps }: SetpointAppProps) => {
  const router = useRouter()
  const { data: profileData, isSuccess } = useProfileQuery()
  const _isClient = isClient()

  useEffect(() => {
    if (_isClient && isSuccess) {
      const subdomain = getSubdomain()
      const whitelabelSubdomain = profileData?.accountSettings?.whitelabelSubdomain
      if (!canAccessSubdomain(subdomain, whitelabelSubdomain) && !profileData?.user?.isSetpointEmail) {
        logOut().then(() => router.push("/"))
      }
    }
  }, [isSuccess, _isClient, profileData, router])

  return <Component {...pageProps} />
}

const SetpointApp = (props: SetpointAppProps) => {
  const router = useRouter()

  useEffect(() => {
    const isRouteLoaded = router?.isReady ?? false
    if (!isRouteLoaded) {
      return
    }

    trackPageView(router.asPath, router.pathname, router.query)
  }, [router?.asPath, router?.isReady, router?.pathname, router?.query])

  return (
    <>
      <DatadogScript/>
      <SegmentScript />
      <QueryClientProvider client={queryClient}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <ComponentWrapper {...props} />
        </IntercomProvider>
        {reactQueryDevToolsEnabled ? (
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        ) : null}
      </QueryClientProvider>
    </>
  )
}

export default SetpointApp
