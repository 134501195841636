/** @type {import('tailwindcss').Config} */

import colors from "tailwindcss/colors"
import defaultTheme from "tailwindcss/defaultTheme"

const setpointColors = {
  // Primary
  "sp-forest": {
    50: "#e8edee",
    100: "#d0dbdc",
    200: "#a2b6b8",
    300: "#739297",
    400: "#456d74",
    DEFAULT: "#164951",
    500: "#164951",
    600: "#123a41",
    700: "#0d2c31",
    800: "#091d20",
    900: "#040f10",
  },
  "sp-taupe": {
    50: "#fffefe",
    100: "#fefdfd",
    200: "#fdfcfb",
    300: "#fcfaf8",
    400: "#fbf9f6",
    DEFAULT: "#faf7f4",
    500: "#faf7f4",
    600: "#c8c6c3",
    700: "#969492",
    800: "#646362",
    900: "#323131",
  },
  "sp-dark-taupe": {
    50: "#fefdfd",
    100: "#fcfbfb",
    200: "#f9f8f7",
    300: "#f6f4f2",
    400: "#f3f1ee",
    DEFAULT: "#f0edea",
    500: "#f0edea",
    600: "#c0bebb",
    700: "#908e8c",
    800: "#605f5e",
    900: "#302f2f",
  },
  "sp-plum": {
    50: "#ebe6ea",
    100: "#d8cdd5",
    200: "#b09aab",
    300: "#896881",
    400: "#613557",
    DEFAULT: "#3a032d",
    500: "#3a032d",
    600: "#2e0224",
    700: "#23021b",
    800: "#170112",
    900: "#0c0109",
  },
  "sp-teal": {
    50: "#e6ecee",
    100: "#ced9de",
    200: "#9db4bc",
    300: "#6b8e9b",
    400: "#3a6979",
    DEFAULT: "#094358",
    500: "#094358",
    600: "#073646",
    700: "#052835",
    800: "#041b23",
    900: "#020d12",
  },
  "sp-green": {
    50: "#eaf5f3",
    100: "#d5ebe7",
    200: "#abd7cf",
    300: "#80c4b8",
    400: "#56b0a0",
    DEFAULT: "#2c9c88",
    500: "#2c9c88",
    600: "#237d6d",
    700: "#1a5e52",
    800: "#123e36",
    900: "#091f1b",
  },

  // Secondary
  "sp-blue": {
    50: "#f5fcff",
    100: "#eaf9fe",
    200: "#d5f3fe",
    300: "#c1edfd",
    400: "#ace7fd",
    DEFAULT: "#97e1fc",
    500: "#97e1fc",
    600: "#79b4ca",
    700: "#5b8797",
    800: "#3c5a65",
    900: "#1e2d32",
  },
  "sp-coral": {
    50: "#fff4f3",
    100: "#fee9e6",
    200: "#fed3cd",
    300: "#fdbcb4",
    400: "#fda69b",
    DEFAULT: "#fc9082",
    500: "#fc9082",
    600: "#ca7368",
    700: "#97564e",
    800: "#653a34",
    900: "#321d1a",
  },
  "sp-lime": {
    50: "#f6fefc",
    100: "#eefcf8",
    200: "#dcf9f1",
    300: "#cbf6ea",
    400: "#b9f3e3",
    DEFAULT: "#a8f0dc",
    500: "#a8f0dc",
    600: "#86c0b0",
    700: "#659084",
    800: "#436058",
    900: "#22302c",
  },
  "sp-yellow": {
    50: "#fffaf1",
    100: "#fff5e3",
    200: "#ffecc7",
    300: "#ffe2aa",
    400: "#ffd98e",
    DEFAULT: "#ffcf72",
    500: "#ffcf72",
    600: "#cca65b",
    700: "#997c44",
    800: "#66532e",
    900: "#332917",
  },
  "sp-peach": {
    50: "#fffcfa",
    100: "#fff9f4",
    200: "#fff3ea",
    300: "#ffecdf",
    400: "#ffe6d5",
    DEFAULT: "#ffe0ca",
    500: "#ffe0ca",
    600: "#ccb3a2",
    700: "#998679",
    800: "#665a51",
    900: "#332d28",
  },

  // Non-Standard Additions
  "sp-red": {
    50: "#f5e6e6",
    100: "#eacccc",
    200: "#d59a99",
    300: "#c06766",
    400: "#ab3533",
    DEFAULT: "#960200",
    500: "#960200",
    600: "#780200",
    700: "#5a0100",
    800: "#3c0100",
    900: "#1e0000",
  },
  "sp-orange": {
    50: "#fceeea",
    100: "#faddd5",
    200: "#f4bcab",
    300: "#ef9a82",
    400: "#e97958",
    DEFAULT: "#e4572e",
    500: "#e4572e",
    600: "#b64625",
    700: "#a03d20",
    800: "#5b2312",
    900: "#2e1109",
  },

  // Investigative
  "sp-inspect": {
    0: "#ffffff",
    100: "#d0dbdc",
    200: "#a2b6b9",
    300: "#739297",
    400: "#456d74",
    500: "#164951",
    600: "#123a41",
    700: "#0d2c31",
    800: "#091d20",
    900: "#040f10",
    1000: "#000000",
    DEFAULT: "#164951",
  },
}

const semanticPalettes = {
  "sp-primary": setpointColors["sp-forest"],
  "sp-active": setpointColors["sp-forest"],
  "sp-link": setpointColors["sp-forest"],
  "sp-cta": setpointColors["sp-forest"],
  "sp-accent": setpointColors["sp-plum"],
  "sp-positive": setpointColors["sp-green"],
  "sp-warning": setpointColors["sp-yellow"],
  "sp-danger": setpointColors["sp-red"],
  "sp-color-1": setpointColors["sp-blue"],
  "sp-color-2": setpointColors["sp-orange"],
  "sp-color-3": setpointColors["sp-teal"],
}

const semanticColors = {
  "sp-background": setpointColors["sp-taupe"][500],
  "sp-large-icon": colors.white,
  "sp-large-icon-background": setpointColors["sp-plum"][400],
  "sp-positive-icon": colors.white,
  "sp-positive-icon-background": setpointColors["sp-green"][500],
  "sp-warning-icon": colors.white,
  "sp-warning-icon-background": setpointColors["sp-yellow"][500],
  "sp-danger-icon": colors.white,
  "sp-danger-icon-background": setpointColors["sp-red"][500],
}

const colorObjectToClassNameArray = (
  colorObject: Record<string, Record<string, string>> | Record<string, string>,
  prefix: string,
) =>
  Object.entries(colorObject).flatMap(([colorName, colorValuesObject]) =>
    Object.keys(colorValuesObject).map((colorValue) =>
      `${colorValue}` === "DEFAULT"
        ? `${prefix}-${colorName}`
        : `${prefix}-${colorName}-${colorValue}`,
    ),
  )

export default {
  content: ["./src/**/*.tsx", "./src/**/*.ts", "./src/**/*.js"],
  theme: {
    screens: {
      xs: "640px",
      sm: "768px",
      md: "1024px",
      lg: "1280px",
      xl: "1536px",
    },
    extend: {
      colors: {
        gray: colors.stone,
        ...setpointColors,
        ...semanticPalettes,
        ...semanticColors,
      },
      fontFamily: {
        sans: ["Inter var", ...defaultTheme.fontFamily.sans],
        serif: ["Nanum Myeongjo var", ...defaultTheme.fontFamily.serif],
      },
      animation: {
        "spin-slow": "spin 2.5s linear infinite",
        marquee: "marquee 25s linear infinite",
        marquee2: "marquee2 25s linear infinite",
      },
      keyframes: {
        marquee: {
          "0%": { transform: "translateX(0%)" },
          "100%": { transform: "translateX(-100%)" },
        },
        marquee2: {
          "0%": { transform: "translateX(100%)" },
          "100%": { transform: "translateX(0%)" },
        },
      },
    },
  },
  plugins: [require("@tailwindcss/forms")],
  safelist: [
    ...colorObjectToClassNameArray(setpointColors, "bg"),
    ...colorObjectToClassNameArray(semanticPalettes, "bg"),
    ...colorObjectToClassNameArray(semanticColors, "bg"),
    {
      pattern: /grid-cols-.+/,
    },
  ],
}
