export const setpointEnv =
  (process.env.NEXT_PUBLIC_SETPOINT_ENV ?? "development") || "development"

export const isProd = process.env.NEXT_PUBLIC_SETPOINT_ENV === "production"
export const isNonProd = process.env.NEXT_PUBLIC_SETPOINT_ENV !== "production"
export const isDev = process.env.NEXT_PUBLIC_SETPOINT_ENV === "development"
export const isDemo = process.env.NEXT_PUBLIC_SETPOINT_ENV === "demo"

const ENABLE_ROUTING_NUMBER_VALIDATION =
  process.env.NEXT_PUBLIC_ENABLE_ROUTING_NUMBER_VALIDATION || "false"

export const enableRoutingNumberValidation =
  isProd || ENABLE_ROUTING_NUMBER_VALIDATION === "true"

// react-query devtools enabled in dev by default but can be disabled
const ENABLE_REACT_QUERY_DEVTOOLS =
  process.env.NEXT_PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS || "true"

export const reactQueryDevToolsEnabled =
  isNonProd && ENABLE_REACT_QUERY_DEVTOOLS === "true"

export const FROM_EMAIL = isNonProd ? "app+dev@setpoint.io" : "app@setpoint.io"

export const BASE_URL = process.env.PUBLIC_NEXTAUTH_URL

export const APP_ORIGIN = process.env.NEXT_PUBLIC_APP_ORIGIN
const SERVER_PROXY_PATH = "/proxy-api"

export const ABSOLUTE_PROXY_URL = new URL(SERVER_PROXY_PATH, APP_ORIGIN)

export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
export const isSegmentEnabled =
  typeof SEGMENT_WRITE_KEY === "string" && Boolean(SEGMENT_WRITE_KEY)

export const gitHash = process.env.NEXT_PUBLIC_GIT_HASH

export const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
export const isDataDogEnabled =
  typeof DATADOG_APPLICATION_ID === "string" &&
  Boolean(DATADOG_APPLICATION_ID) &&
  typeof DATADOG_CLIENT_TOKEN === "string" &&
  Boolean(DATADOG_CLIENT_TOKEN) &&
  isProd

// TODO we probably need a better way to keep track of the churchill faciility IDs when doing the
// assignment fee calculation rather than hardcoding here.
// This is specifically used for Churchill to do some special front end logic.
export const CHURCHILL_FACILITY_IDS = [
  "018e339e-34b9-5a39-d3dd-6135cfeaf42a", // NYMT
  "018e61de-5d5d-072e-f14a-5dc7dd08235f", // Barrings
  "018e674d-f8a1-810c-ddcc-b2aa4feb729f", // Churchill holding facility
]

export const SPC_RTL_QUEUE_TYPE: "BY_LOAN" | "BY_DOCUMENT" = [
  "BY_LOAN",
  "BY_DOCUMENT",
].includes(process.env.NEXT_PUBLIC_SPC_RTL_QUEUE_TYPE ?? "")
  ? (process.env.NEXT_PUBLIC_SPC_RTL_QUEUE_TYPE as "BY_LOAN" | "BY_DOCUMENT")
  : "BY_LOAN"

export const CALC_AGENT_REPORT_PROGRESS_INTERVAL = Number(
  process.env.NEXT_PUBLIC_CALC_AGENT_REPORT_PROGRESS_INTERVAL ?? 500,
)
