/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running setpoint_util.gen_typescript.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export type AccountAVMProvider = "setpoint"
export type DemoFacilityType = "setpoint" | "proptech" | "sfr" | "lender"
export type DrawFeeMechanic = "entering-amount"
export type EntityReviewModel =
  | EntityReviewMoneyModel
  | EntityReviewDateModel
  | EntityReviewUnicodeModel
  | EntityReviewFloatModel
  | EntityReviewPercentModel
  | EntityReviewIntModel
  | EntityReviewEnumAsTextModel
export type EntityDataValidationModel =
  | EntityDataValidationMoneyModel
  | EntityDataValidationDateModel
  | EntityDataValidationUnicodeModel
  | EntityDataValidationFloatModel
  | EntityDataValidationIntModel
  | EntityDataValidationEnumAsTextModel
  | EntityDataValidationPercentModel
export type FacilityTestResultType =
  | "actual"
  | "excess"
  | "limit"
  | "other"
  | "pass-fail"
export type FundingEligibilityEffect =
  | "no-effect"
  | "contingent-on-other-results"
  | "contingent-on-asset-only"
export type BorrowingBaseAsset =
  | BorrowingBaseAssetStandard
  | BorrowingBaseAssetSFR
  | BorrowingBaseAssetFacilityAdvanceRate
  | BorrowingBaseAssetBDCapital
export type RTLCalculationType =
  | "rtl-age"
  | "rtl-as-is"
  | "rtl-rehab-level"
  | "rtl-ltaiv"
  | "rtl-ltarv"
  | "rtl-ltc"
  | "rtl-max-ltc"
  | "rtl-max-ltv"
  | "rtl-debt-yield"
  | "rtl-debt-value"
  | "rtl-total-costs"
  | "rtl-arv"
  | "rtl-gpp"
  | "rtl-repair-amount"
  | "rtl-holdback"
  | "rtl-loan-type"
  | "rtl-experience-tier"
  | "rtl-tier-matches"
  | "rtl-exp-bucket"
  | "rtl-strategy"
  | "rtl-bucket"
  | "rtl-loan-guc"
  | "rtl-asset-guc"
  | "rtl-note-amount"
  | "rtl-calc-as-is"
  | "rtl-number-units"
  | "rtl-budget-aiv-arv"
  | "rtl-mos-season"
  | "rtl-feasibility-req"
  | "rtl_value_score"
  | "rtl-high-value"
  | "rtl-client-loan"
  | "rtl-loan-delta"
  | "rtl-apollo-bucket"
  | "rtl-pp-ltc"
  | "rtl-mf-mu"
  | "rtl-loan-age"
  | "rtl-d1-la"
  | "rtl-navf"
  | "rtl-refi-purch"
  | "rtl-adjustments"
  | "rtl-2-4-unit"
  | "rtl-repairpp"
  | "rtl-loel"
  | "rtl-lorl"
  | "rtl-ltp"
  | "rtl-mon-own"
  | "rtl-prop-type"
  | "rtl-credit-age"
  | "rtl-appraisal-age"
export type Card =
  | AreaChartCard
  | LineChartCard
  | ProgressBarCard
  | MultiMetricCard
  | SimpleMetricCard
  | ScenarioMetricDiffCard
export type HelixAcquisitionStatus =
  | "Under Contract"
  | "In Progress"
  | "Won"
  | "Acquired"
  | "Lost"
  | "Direct Plus Closed"
  | "Direct Plus Offer Hold"

export interface AccountCreationPostBody {
  accountName: string
  accountType: AccountType
  accountIdOverride?: number
  boxFolderId?: string
  accountSettings?: AccountSettings
}
export interface AccountSettings {
  avmProvider?: AccountAVMProvider
  fundingTeamEmails?: string[]
  fundingAccountNumber?: string
  fundingRoutingNumber?: string
  fundingProducts?: ProductType[]
  fundingWireInstructionsS3Key?: string
  propertySource?: AccountPropertySource
  addAssetModalSchema?: AddAssetModalSchema
  addAssetModalToBorrowingBase?: boolean
  fundingDestination?: FundingDestination & string
  conrexAddPropertyModal?: boolean
  generateDefaultScenario?: boolean
  createBoxFolderOnAssetIngestion?: boolean
  forwardLoanTapeAddresses?: string[]
  isDemo?: boolean
  whitelabelSubdomain?: string
  disableIntercom?: boolean
  permissionConfigType?: AccountPermissionConfigType
  borrowerReportsProductName?: string
  enableIpWhitelist?: boolean
  ipWhitelist?: string[]
  dataRetentionPolicy?: AccountDataRetentionPolicy
}
export interface AccountDataRetentionPolicy {
  deleteCapOsApprovedReportAssetResultsAfterDays?: number
  deleteCapOsNotApprovedReportAssetResultsAfterDays?: number
  deleteCapOsApprovedReportReprocessJsonAfterDays?: number
  deleteCapOsNotApprovedReportReprocessJsonAfterDays?: number
}
export interface AccountCreationResponse {
  accountGlobalId?: string
}
export interface AccountMemberPatchBody {
  status?: AccountMemberStatus
}
export interface FullAccountModel {
  globalId: string
  name: string
  settings: AccountSettings
  id?: number
  originatorWireInstructionsUrl?: string
}
export interface GetAccountResponse {
  account: FullAccountModel
}
export interface GetBorrowingBaseTransferConfigsResponse {
  configs: BorrowingBaseTransferConfigs[]
}
export interface BorrowingBaseTransferConfigs {
  accountName: string
  accountGlobalId: string
  sourceFacilities: FacilityModel[]
  destinationFacilities: FacilityModel[]
}
export interface FacilityModel {
  name: string
  slug: string
  globalId: string
  mezzFacility?: FacilityModel
  size?: number
  settings: FacilitySettings
  originatorAccountName?: string
  lenderView?: string
  displayName?: string
  isSetpoint?: boolean
  logo?: string
  useFacilityWideReserveCalc?: boolean
  fundingVehicle?: FundingVehicleModel
  lenderAccountType?: AccountType
  diligenceCategory?: DiligenceCategory
}
export interface FacilitySettings {
  appraisalConditionsAssetValue?: boolean
  calculateInterest?: boolean
  calculateUpcomingAdvanceChanges?: boolean
  updateInterestRateMonthly?: boolean
  rebalanceInterestRateMonthly?: boolean
  externalBoxFolderId?: string
  facilityBoxFolderId?: string
  nonProdFacilityBoxFolderId?: string
  manualPayoff?: boolean
  opendoorSetpointDocumentVerification?: boolean
  skipDocumentVerification?: boolean
  thirdPartyDocumentVerification?: boolean
  backedBySetpointSecuritization?: boolean
  uncommittedFacility?: boolean
  demoAccountType?: DemoFacilityType
  documentUploadEnabled?: boolean
  borrowingBaseSchema?: FacilityBorrowingBaseSchema & string
  legacyFunding?: boolean
  usesDynamicAdvanceRateLadder?: boolean
  preFundingBpoRequiredIfAvmLowConfidence?: boolean
  removeExcessConcentration?: boolean
  initialCommitment?: number
  maxCommitment?: number
  originationDate?: string
  maturityDate?: string
  fullyExtendedMaturityDate?: string
  windDown?: number
  productSettings?: {
    [k: string]: unknown
  }
  fundedAmountLogic?: string
  fundingCutoffDate?: string
  minimumDraw?: number
  fundingFrequency?: number
  fundingDestination?: string
  commitmentFee?: string
  originationUpsizeFee?: string
  unusedFee?: number
  unusedFeeWaiverInitialReliefPeriod?: string
  unusedFeeWaiverThreshold?: number
  transactionFee?: string
  exitFee?: string
  extensionFee?: string
  yieldMaintenanceFeeAgeDays?: number
  yieldMaintenanceFeePercent?: number
  drawFeeMechanic?: DrawFeeMechanic
  drawFee?: number
  /**
   * Allows one to set a number less than zero for which `age_days` is treated as 0 - e.g. `-4` would allow an 'age' where the initial borrowing base was actually ≤ 4 days _after_ the effective date, treating the 'age' as zero rather than the raw calculation of -4 through -1. NOTE: this buffer is in the form of workdays
   */
  ageDaysBufferPeriod?: number
  reserveAmountCalculationDescription?: string
  pauseConcentrationLimitUpdates?: boolean
  /**
   * Represents whether or not an asset assigned to this facility is, in fact, 'unassigned.' Likely corresponds to an originator's equity
   */
  isUnassignedFacility?: boolean
  advanceChangeForecastWindowDays?: number
  valuationTypes?: ValuationType[]
  maxValuationAgeDaysPreFunding?: number
  maxValuationAgeDaysPostFunding?: number
  valuationPostFundingCadence?: Cadence
  valuationPostFundingGracePeriod?: number
  diligenceCategory?: DiligenceCategory & string
  showRtlOrSfrDiligenceGrades?: boolean
  showRtlOrSfrDiligenceGuidelines?: boolean
  showRtlOrSfrDiligenceValidations?: boolean
  diligenceMoneyValidationPrecision?: number
  showBorrowingBaseAssetList?: boolean
  /**
   * Whether the facility's relationship is indicative of exclusively diligence activities (e.g. RTL)
   */
  isDiligenceOnly?: boolean
  autoFund?: boolean
  showConstructionDraws?: boolean
  demoPaintedDoor?: boolean
  hideMonitoringTab?: boolean
  isSpcCapOs?: boolean
  enableBorrowingBaseDatepicker?: boolean
}
export interface FundingVehicleModel {
  name: FundingVehicleName
  globalId: string
}
export interface GetCapitalPartnersResponse {
  capitalPartners: [string, string][]
}
export interface GetFacilitiesSummaryResponse {
  totalAvailable?: number
  totalBorrowed?: number
  totalSize?: number
  facilities: FacilitySummary[]
  metricsByFundingVehicle?: {
    [k: string]: FacilitiesSummaryMetrics
  }
}
export interface FacilitySummary {
  availableToDraw?: number
  globalId: string
  originatorAccountName: string
  seniorFacilityName: string
  seniorFacilitySize?: number
  seniorBorrowedAmount: number
  mezzFacilityName?: string
  mezzFacilitySize?: number
  mezzBorrowedAmount?: number
  name?: string
  size?: number
  logo?: string
  borrowedAmount?: number
  isSetpoint: boolean
  uncommittedFacility: boolean
  isUnassignedFacility: boolean
  concentrationLimitResultCount: ConcentrationLimitResultCount
  lenderView: boolean
  visible: boolean
  fundingVehicle?: FundingVehicleModel
  borrowedAmountRow?: BorrowedAmountModel
  demoPaintedDoor: boolean
  isSpcCapOs?: boolean
}
export interface ConcentrationLimitResultCount {
  passCount: number
  failCount: number
  atRiskCount: number
}
export interface BorrowedAmountModel {
  outstanding: number
  availability: number
  size: number
}
export interface FacilitiesSummaryMetrics {
  totalAvailable?: number
  totalBorrowed?: number
  totalSize?: number
}
export interface AddAssetFlagBody {
  global_id: string
  flag_name: string
}
export interface AddAssetNoteRequest {
  note: string
  globalId: string
}
export interface AddAssetValuationRequest {
  amount: number
  effectiveDate?: string
  valuationType: ValuationType
  provider: ValuationProvider
}
export interface ApproveTransactionBody {
  asset_global_id: string
  funding_type: FundingType
  wire_instruction_override?: string
}
export interface AssetDataUploadReponse {
  success?: boolean
}
export interface Asset {
  assetId?: string
  assignmentFees?: number
  avm?: Avm
  city?: string
  clientEmail?: string
  clientName?: string
  closeDatePurchase?: string
  closeDateSell?: string
  contractPurchaseAmount?: number
  creditScore?: string
  currentValue?: number
  customerProduct?: string
  customerPropertyValue?: number
  customerStage?: string
  debugAccountName?: string
  debugId?: number
  debugFacilityId?: number
  debugDiligenceFacilityIds: number[]
  diligenceAssignments: DiligenceAssignmentModel[]
  displayName: string
  displayValue?: number
  diligenceReviewer?: string
  diligenceReviewerAccountMemberGlobalId?: string
  documentUploadEnabled?: boolean
  earnestMoneyAmount?: number
  earnestMoneyDeadline?: string
  enteredBorrowingBase?: string
  exitedBorrowingBase?: string
  externalBoxFolderId?: string
  facilityAssignments: FacilityAssignmentModel[]
  flags: AssetFlagModel[]
  friendlyType: string
  fundingRequestFederalReferenceNumber?: string
  hasFundingTab: boolean
  globalId: string
  guarantorCreditScore?: string
  initialSeniorFundingAmount?: number
  loanAmount?: number
  loanTape?: {
    [k: string]: unknown
  }
  loanTapeBorrowingBase?: {
    [k: string]: unknown
  }
  loanTapeDiligence?: {
    [k: string]: unknown
  }
  longTermStorageDocumentFolderId?: string
  marketValue?: number
  participationPaymentAmount?: number
  postalCode?: string
  product?: ProductType
  productPretty: string
  salesforceUrl?: string
  settlement?: AssetSettlementModel
  snoozeAdvanceChangeUntil?: string
  state?: string
  streetAddress?: string
  terminated: boolean
  type: AssetPropertyType
  valuations: AssetValuationModel[]
  wireInstructionOverride?: string
  zavviePartner?: string
  leverageFacility?: BackLeverageFacility
  custody?: string
  leverageStartDate?: string
  leverageEndDate?: string
  assetAssignmentTypes: AssetAssignmentType[]
  payoffs: PayoffModel[]
  hasReversibleFundingRequest?: boolean
  capitalPartnerCommitmentFacilityGlobalId?: string
  committedCapitalPartnerName?: string
  soldToCapitalPartnerName?: string
  ancillaryPayloads?: {
    [k: string]: unknown
  }
}
export interface Avm {
  result?: number
  status: AvmOrderStatus
  resultGuidance?: string
  preFundingBpoRequiredIfAvmLowConfidence?: boolean
}
export interface DiligenceAssignmentModel {
  facility: FacilityModel
}
export interface FacilityAssignmentModel {
  facility: FacilityModel
  type: FacilityType
}
export interface AssetFlagModel {
  globalId: string
  name: string
  internalOnly: boolean
}
export interface AssetSettlementModel {
  sales_price?: number
  appraised_value?: number
  senior_loan?: number
  mezz_loan?: number
  emd?: number
  agent_credit?: number
  seller_paid_closing_costs?: number
  cash_to_close?: number
  agent_commission_received?: number
  emd_received?: number
  funding_type?: FundingType
}
export interface AssetValuationModel {
  amount: number
  effectiveDate?: string
  availableForUse?: boolean
  expirationDate?: string
  expired: boolean
  globalId: string
  usedForFunding: boolean
  valuationType: ValuationType
  valuationTypePretty?: string
  provider?: ValuationProvider
  providerPretty?: string
}
export interface PayoffModel {
  currentAccountType?: AccountType
  amount: number
  accountName: string
  assetGlobalId: string
  displayName: string
  facility: FacilityModel
  globalId: string
  settledAt?: string
  settledBy?: string
  moneyReceivedAt?: string
  leverageFacility?: BackLeverageFacility
  fundedDate?: string
  paidOffDate?: string
  bankAccountTransaction?: BankAccountTransactionModel
  amountPaid?: number
  exitedBorrowingBaseDate?: string
  changeRequest?: ChangeRequestModel
}
export interface BankAccountTransactionModel {
  globalId: string
  providerTransactionId: string
  amount: number
  datetime?: string
  authorizedDatetime?: string
  name: string
  pending: boolean
  referenceNumber?: string
  payee?: string
  byOrderOf?: string
  payer?: string
  paymentMethod?: string
  reason?: string
  state: BankAccountTransactionState
  statePretty: string
  assetPayoffs: SlimPayoffModel[]
  bankAccountGlobalId: string
  bankActivityRemittance?: RemittanceModel
}
export interface SlimPayoffModel {
  currentAccountType?: AccountType
  amount: number
  globalId: string
  assetGlobalId: string
  assetDisplayName: string
  amountPaid: number
  excessAmount?: number
}
export interface RemittanceModel {
  changeRequestRemittanceGlobalId?: string
  amount: number
  note: string
  createdAt: string
  bankAccountGlobalId?: string
  bankAccountTransactionGlobalId?: string
  bankActivityRemittanceGlobalId?: string
}
export interface ChangeRequestModel {
  account: AccountModel
  effectiveFundingDate?: string
  fedRefNumber?: string
  wireAmount?: number
  facility: FacilityModel
  fundingDate: string
  globalId: string
  isPendingFunding: boolean
  openedByAccountMember: AccountMemberModel
  status: ChangeRequestStatus
  assetReserveAmountsCount?: number
  userCanModifyAssets?: boolean
  reviews: ChangeRequestReviewModel[]
  timeline: ChangeRequestTimelineModel[]
}
export interface AccountModel {
  globalId: string
  name: string
  settings: AccountSettingsModel
  id?: number
}
export interface AccountSettingsModel {
  fundingTeamEmails?: string[]
}
export interface AccountMemberModel {
  roles: AccountMemberRole[]
  globalId: string
  isActive: boolean
  user: AccountUserModel
}
export interface AccountUserModel {
  name: string
  email: string
  image?: string
}
export interface ChangeRequestReviewModel {
  globalId: string
  accountMember: AccountMemberAccountModel
  status: ChangeRequestReviewStatus
  approvedAt?: string
  rejectedAt?: string
}
export interface AccountMemberAccountModel {
  roles: AccountMemberRole[]
  globalId: string
  isActive: boolean
  user: AccountUserModel
  accountName: string
}
export interface ChangeRequestTimelineModel {
  accountMember?: AccountMemberAccountModel
  addedAt: string
  message?: string
  comment?: string
  globalId: string
  type: ChangeRequestTimelineType
  assetDisplayName?: string
  assetTransitionType?: ChangeCategory
  assetTransitionTypeName?: string
  assetCount?: number
  effectiveFundingDate?: string
  fedRefNumber?: string
  wireAmount?: number
}
export interface AssetGuidelineExplanationModel {
  globalId: string
  displayName: string
  explanation: string
  status: GuidelineStatus
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
}
export interface AssetReassignFacilityBody {
  effectiveDate?: string
  facilityReassignmentConfigGlobalId: string
}
export interface BulkDeleteAssetFlagBody {
  assetGlobalIds: string[]
  flagName: string
}
export interface BulkPayoffBody {
  assetGlobalIds: string[]
}
export interface ConfirmWireInstructionsBody {
  account_number: string
  beneficiary: string
  funding_requirement_global_id: string
  routing_number: string
}
export interface CreateUploadDocumentTokenBody {
  global_id: string
  filename: string
}
export interface CreateUploadDocumentTokenResponse {
  funding_requirement_id: string
  filename: string
  upload_token: string
  asset_folder_id: string
  file_folder_id: string
  overwritten_file_id?: string
}
/**
 * Helper class that provides a standard way to create an ABC using
 * inheritance.
 */
export interface DKScheduledPrepayment {
  prepaymentDate?: string
  prepaymentFees?: number
  legalFees?: number
  assetId?: string
  accountId?: number
  setpointAssetId?: number
  ancillaryFileType?: AncillaryFile & string
}
export interface EditAssetNoteRequest {
  note?: string
  setPinned?: boolean
}
export interface FacilityReassignmentConfigModel {
  category: FacilityReassignmentCategory
  globalId: string
  rule: FacilityReassignmentRule
  sourceFacility: SlimFacilityModel
  targetFacility: SlimFacilityModel
}
export interface SlimFacilityModel {
  name: string
  globalId: string
  logo?: string
  isSetpoint: boolean
}
export interface GetAssetBoxFolderResponse {
  debugBoxFolderId?: string
  externalSyncFolderId?: string
}
export interface GetAssetDataUploadOptionsResponse {
  options: string[]
}
export interface GetAssetEntityReviewsResponse {
  documentReviewDetails: EntityReviewModel[]
}
export interface EntityReviewMoneyModel {
  label: string
  fundingRequirement?: EntityReviewFundingRequirement
  updatedAt: string
  detailType: EntityReviewDetailType
  moneyValue: number
}
export interface EntityReviewFundingRequirement {
  name: string
  rtlProperty?: EntityReviewRTLProperty
  facility?: FacilityModel
}
export interface EntityReviewRTLProperty {
  displayName: string
}
export interface EntityReviewDateModel {
  label: string
  fundingRequirement?: EntityReviewFundingRequirement
  updatedAt: string
  detailType: EntityReviewDetailType
  dateValue: string
}
export interface EntityReviewUnicodeModel {
  label: string
  fundingRequirement?: EntityReviewFundingRequirement
  updatedAt: string
  detailType: EntityReviewDetailType
  unicodeTextValue: string
}
export interface EntityReviewFloatModel {
  label: string
  fundingRequirement?: EntityReviewFundingRequirement
  updatedAt: string
  detailType: EntityReviewDetailType
  floatValue: number
}
export interface EntityReviewPercentModel {
  label: string
  fundingRequirement?: EntityReviewFundingRequirement
  updatedAt: string
  detailType: EntityReviewDetailType
  percentValue: number
}
export interface EntityReviewIntModel {
  label: string
  fundingRequirement?: EntityReviewFundingRequirement
  updatedAt: string
  detailType: EntityReviewDetailType
  intValue: number
}
export interface EntityReviewEnumAsTextModel {
  label: string
  fundingRequirement?: EntityReviewFundingRequirement
  updatedAt: string
  detailType: EntityReviewDetailType
  enumAsTextValue: string
  valueAsEnumLabel: string
}
export interface GetAssetFacilityReassignmentConfigs {
  facilityReassignmentConfigs: FacilityReassignmentConfigModel[]
}
export interface GetAssetFlyhomesLoanTapeInfo {
  account_number?: string
  beneficiary?: string
  routing_number?: string
  wire_dollar_amount?: number
}
export interface GetAssetFundingDetailResponse {
  fundingDetail?: AssetFundingDetailModel
  fundingCalculations?: FundingDetail
  interestDetail?: FacilityInterestModel
}
export interface AssetFundingDetailModel {
  advancedAmount?: number
  advanceRate?: number
  avm?: number
  bpo?: number
  setpointBpo?: number
  appraisal?: number
  fundingDestination?: FundingDestination
  fundingDestinationPretty?: string
  marketValue?: number
  purchasePrice?: number
  wireAmount?: number
  participationAmount?: number
  valuationUsed?: number
}
export interface FundingDetail {
  advancedAmountSenior: number
  advanceRateSenior: number
  advancedAmountMezz: number
  advanceRateMezz: number
  advancedAmountTotal?: number
  advanceRateTotal?: number
  avm?: number
  bpo?: number
  setpointBpo?: number
  appraisal?: number
  assetDisplayName?: string
  assetGlobalId?: string
  accountNumber?: string
  beneficiary?: string
  fundingDestination?: FundingDestination
  fundingDestinationPretty?: string
  isFlyhomes?: boolean
  marketValue: number
  participationAmount?: number
  purchasePrice?: number
  routingNumber?: string
  wireAmount?: number
  valuationUsed?: number
  partialFunding?: boolean
  effectiveSeniorAdvanceRate?: number
  effectiveMezzAdvanceRate?: number
}
export interface FacilityInterestModel {
  interestRate: number
  rateFloor: number
  rateTypeDisplayName: string
}
export interface GetAssetFundingRequirementsDocumentsResponse {
  documents: FundingRequirementModel[]
  debugBoxFolderId?: string
  facilities: FacilityModel[]
  diligenceFilterType: DiligenceFilterType
}
export interface FundingRequirementModel {
  actions: FundingRequirementActionModel[]
  category: FundingRequirementCategory
  categoryName: string
  customUsername?: string
  detail?: string
  documentReviewText?: string
  documentReviewSubtext?: string
  dueDate?: string
  facilityGlobalId?: string
  facilityName?: string
  facilityCategory?: DiligenceCategory
  failedName?: string
  globalId: string
  isCompleted: boolean
  completedAt?: string
  skippedByAccountMember?: string
  skippedAt?: string
  isIncomplete: boolean
  isDocument: boolean
  isRequired?: boolean
  isSkipped: boolean
  isWaived: boolean
  isFlagged: boolean
  isParent: boolean
  isDisputed: boolean
  isForDataCollection: boolean
  isRecentlyUpdated?: boolean
  name: string
  originatorCanUncheck: boolean
  reviewNote?: string
  stage: FundingRequirementStage
  stageChangedByAccountMember?: string
  stageLastChangedAt?: string
  tasks?: FundingRequirementModel[]
  type: FundingRequirementType
  uploadedDocuments?: FundingRequirementDocument[]
  extra?: {
    [k: string]: unknown
  }
  reviewFormContext?: GetDocumentReviewFormContextResponse
  setpointDiligenceDocumentGlobalId?: string
  diligenceReviewType?: DiligenceReviewType & string
  debugId?: number
  rtlPropertyGlobalId?: string
  notes?: DocumentReviewNote[]
  hasNonDocumentTask?: boolean
  hasUpload: boolean
  checkOptionConfig?: CheckOption[]
  order: number
  hasFailingValidations?: boolean
  dataValidations?: EntityDataValidationModel[]
  calculatedThroughOtherValues?: boolean
  connectedFundingRequirementsToRefreshOnSave?: FundingRequirementType[]
}
export interface FundingRequirementActionModel {
  action: FundingRequirementAction
  form?: {
    [k: string]: DocumentReviewField
  }
  informationCollected: EntityReviewDetailType[]
  prettyName: string
}
export interface DocumentReviewField {
  type?: EntityReviewDetailType
  inputType?: PolymorphicValueType
  value?: string | ExternalValuationType | number
  valueOptions?: Option[]
  label?: string
  comment?: string
  optional?: boolean
  order?: number
  browserFormatter?: BrowserFormatter
  default?: string | ValueFromDataTape
  defaultValue?: string | ExternalValuationType | number
  /**
   * Whether a change in the field's value should trigger the funding requirement's 'tasks' or 'checks' (child funding reqs) to have their state re-evaluated. Useful for child funding reqs calculated through other values
   */
  shouldTriggerTaskRefresh?: boolean
}
export interface Option {
  label: string
  value: string
  isDisabled?: boolean
  isDefault?: boolean
}
export interface ValueFromDataTape {
  entity_type?: EntityType & string
  data_tape_field_name: string[]
  data_tape_field_type: DocumentReviewFormContextType
  data_tape_type?:
    | "asset/buyer"
    | "borrowing base"
    | "ancillary file"
    | "rtl property"
  ancillary_file?: AncillaryFile
  requires_tape_parsing?: boolean
}
export interface FundingRequirementDocument {
  asset_folder_id?: string
  download_url?: string
  file_id: string
  filename: string
  uploaded_at: string
  view_url?: string
}
export interface GetDocumentReviewFormContextResponse {
  contextValues: DocumentReviewFormContextValue[]
  form: {
    [k: string]: DocumentReviewField
  }
}
export interface DocumentReviewFormContextValue {
  money_value?: number
  text_value?: string
  label: string
  type: DocumentReviewFormContextType
  entity_review_detail_type?: EntityReviewDetailType
  entity_type?: EntityType
}
export interface DocumentReviewNote {
  accountMemberGlobalId: string
  addedAt: string
  globalId: string
  note: string
  userImage?: string
  username: string
}
export interface CheckOption {
  label: string
  subLabel?: string
  stage: FundingRequirementStage
  default?: boolean
  disabled?: boolean
}
export interface EntityDataValidationMoneyModel {
  browserFormatter?: BrowserFormatter & string
  detailType: EntityReviewDetailType
  globalId: string
  label: string
  status: EntityValidationStatus
  updatedAt: string
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
  rtlPropertyAddress?: string
  facilityGlobalId?: string
  entityReviewMoneyValue?: number
  loanTapeMoneyValue?: number
}
export interface EntityDataValidationDateModel {
  browserFormatter?: BrowserFormatter & string
  detailType: EntityReviewDetailType
  globalId: string
  label: string
  status: EntityValidationStatus
  updatedAt: string
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
  rtlPropertyAddress?: string
  facilityGlobalId?: string
  entityReviewDateValue?: string
  loanTapeDateValue?: string
}
export interface EntityDataValidationUnicodeModel {
  browserFormatter?: BrowserFormatter & string
  detailType: EntityReviewDetailType
  globalId: string
  label: string
  status: EntityValidationStatus
  updatedAt: string
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
  rtlPropertyAddress?: string
  facilityGlobalId?: string
  entityReviewUnicodeTextValue?: string
  loanTapeUnicodeTextValue?: string
}
export interface EntityDataValidationFloatModel {
  browserFormatter?: BrowserFormatter & string
  detailType: EntityReviewDetailType
  globalId: string
  label: string
  status: EntityValidationStatus
  updatedAt: string
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
  rtlPropertyAddress?: string
  facilityGlobalId?: string
  entityReviewFloatValue?: number
  loanTapeFloatValue?: number
}
export interface EntityDataValidationIntModel {
  browserFormatter?: BrowserFormatter & string
  detailType: EntityReviewDetailType
  globalId: string
  label: string
  status: EntityValidationStatus
  updatedAt: string
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
  rtlPropertyAddress?: string
  facilityGlobalId?: string
  entityReviewIntValue?: number
  loanTapeIntValue?: number
}
export interface EntityDataValidationEnumAsTextModel {
  browserFormatter?: BrowserFormatter & string
  detailType: EntityReviewDetailType
  globalId: string
  label: string
  status: EntityValidationStatus
  updatedAt: string
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
  rtlPropertyAddress?: string
  facilityGlobalId?: string
  entityReviewEnumAsTextValue?: string
  entityReviewValueAsEnumLabel?: string
  loanTapeEnumAsTextValue?: string
  loanTapeValueAsEnumLabel?: string
}
export interface EntityDataValidationPercentModel {
  browserFormatter?: BrowserFormatter & string
  detailType: EntityReviewDetailType
  globalId: string
  label: string
  status: EntityValidationStatus
  updatedAt: string
  waivedAt?: string
  waivedByAccountMember?: AccountMemberModel
  waiveNote?: string
  rtlPropertyAddress?: string
  facilityGlobalId?: string
  entityReviewPercentValue?: number
  loanTapePercentValue?: number
}
export interface GetAssetFundingRequirementsResponse {
  fundingRequirements: FundingRequirementModel[]
  fundingRequirementsByCategory: FundingRequirementCategoryModel[]
  facilities: FacilityModel[]
}
export interface FundingRequirementCategoryModel {
  category: FundingRequirementCategory
  categoryName?: string
  complete?: number
  total?: number
  key: string
  facilityName?: string
  facilityGlobalId?: string
  fundingRequirements: FundingRequirementModel[]
  rank?: number
}
export interface GetAssetFundingResponse {
  currentAdvanceCalculation: SummarySheet
}
export interface SummarySheet {
  sections: (
    | (
        | TitleSection
        | SubtitleSection
        | ConcentrationLimitSection
        | FilteredConcentrationLimitSection
        | EligibilitySection
        | CashTrapEventsSection
        | EventOfDefaultSection
        | EarlyAmortizationEventSection
        | PropertyLevelAlaCheckSection
        | ManagementFeesCheckSection
        | InterestRateCapSection
        | CovenantSection
        | ListSection
        | TotalSection
        | TableSection
        | MiscValueSection
        | ComposableTableSection
      )
    | CollapsibleSection
    | GroupSection
  )[]
  canDownload?: boolean
  title?: string
  simpleGrid?: boolean
}
export interface TitleSection {
  title: string
  section_type?: "title"
}
export interface SubtitleSection {
  title: string
  section_type?: "subtitle"
}
export interface ConcentrationLimitSection {
  title?: string
  section_type?: "concentration-limit"
  excessConcentrationColName?: string
  limitPercentColName?: string
  limitColName?: string
  actualPercentColName?: string
  actualColName?: string
  passFailColName?: string
  excessColName?: string
}
export interface FilteredConcentrationLimitSection {
  title?: string
  subCategory: ConcentrationLimitSubCategory
  columns?: string[]
  section_type?: "filtered-concentration-limit"
}
export interface EligibilitySection {
  title?: string
  section_type?: "eligibility"
}
export interface CashTrapEventsSection {
  title?: string
  section_type?: "cash-trap-events"
}
export interface EventOfDefaultSection {
  title?: string
  section_type?: "event-of-default"
}
export interface EarlyAmortizationEventSection {
  title?: string
  section_type?: "early-amortization-event"
}
export interface PropertyLevelAlaCheckSection {
  title?: string
  section_type?: "property-level-ala-check"
}
export interface ManagementFeesCheckSection {
  title?: string
  section_type?: "management-fees-check"
}
export interface InterestRateCapSection {
  title?: string
  section_type?: "interest-rate-cap"
}
export interface CovenantSection {
  title?: string
  section_type?: "covenant"
}
export interface ListSection {
  title?: string
  section_type?: "list"
  rows: ListRow[]
}
export interface ListRow {
  title?: string
  label: string
  value: string
  formatter?: BrowserFormatter & string
  tooltip?: string
}
export interface TotalSection {
  title?: string
  section_type?: "total"
  valueRows: BorrowingBaseSummaryValueRow[]
  calcTotal?: boolean
  total?: number
  verification?: BaseVerificationField
  totalLabel?: string
}
export interface BorrowingBaseSummaryValueRow {
  label?: string
  value?: number
  verification?: BaseVerificationField
  valuePercent?: number
  tooltip?: string
  subtract?: boolean
  subtitle?: boolean
  formatter?: BrowserFormatter & string
}
export interface BaseVerificationField {
  sheetname?: string
  sheetname_regex?: string
  file_key?: string
  input_label?: string
  calculated?: unknown
  provided?: unknown
  provided_raw?: unknown
  verified?: boolean
  validation_cell?: string
  parse_formula?: boolean
  allow_blank?: boolean
  allow_blank_provided?: boolean
  tooltip_description_override?: string
  provided_cell_formula?: string
  calculated_lender_cell_formula?: string
  download_output_formula?: string
  money_jitter_cents?: number
  money_jitter_pct?: number
  float_jitter?: number
  facility_test_calculation_type?: ConcentrationLimitCalculationType
  facility_test_result_type?: FacilityTestResultType
  show_value_detail?: boolean
  dispute_difference?: unknown
  browser_formatter?: BrowserFormatter & string
}
export interface TableSection {
  title?: string
  section_type?: "table"
  headers: TableHeader[]
  rows: TableRow[]
  showRowBorders?: boolean
  highlightHeader?: boolean
  hideHeader?: boolean
  sticky?: boolean
  rounded?: boolean
  infoText?: string
  fillsContainer?: boolean
  scrollable?: boolean
  transposeOnExport?: boolean
  collapsible?: boolean
  initExpanded?: boolean
}
export interface TableHeader {
  label: string
  alignRight?: boolean
  alignCenter?: boolean
  alignment?: "left" | "center" | "right"
  colSpan?: number
  oneLine?: boolean
  muted?: boolean
  sticky?: boolean
  tooltip?: string
  fontBold?: boolean
  backgroundColor?:
    | "gray"
    | "yellow"
    | "sp-green"
    | "green-lite"
    | "blue-lite"
    | "white"
}
export interface TableRow {
  cells: TableCell[]
  noBorder?: boolean
}
export interface TableCell {
  browserFormatter?: BrowserFormatter & string
  value?:
    | string
    | number
    | number
    | number
    | string
    | BaseInputField
    | BaseVerificationField
  placeholderIfValueNone?: string
  tooltip?: string
  alignRight?: boolean
  alignCenter?: boolean
  borderLeft?: boolean
  borderRight?: boolean
  borderTop?: boolean
  borderBottom?: boolean
  strongBorderLeft?: boolean
  strongBorderRight?: boolean
  strongBorderTop?: boolean
  strongBorderBottom?: boolean
  fullBorder?: boolean
  fullStrongBorder?: boolean
  strongBorderTopAndBottom?: boolean
  fontMedium?: boolean
  fontBold?: boolean
  fontItalic?: boolean
  fontUnderline?: boolean
  rowSpan?: number
  colSpan?: number
  verticalAlignTop?: boolean
  oneLine?: boolean
  indent?: boolean
  indentDouble?: boolean
  highlight?: boolean
  sticky?: boolean
  verification?: BaseVerificationField
  status?: TableCellStatus & string
  statusIcon?: TableCellStatusIcon
  fontColor?: "gray" | "red" | "green" | "blue" | "teal" | "white"
  backgroundColor?:
    | "gray"
    | "yellow"
    | "sp-green"
    | "green-lite"
    | "blue-lite"
    | "white"
  facilityTestResult?: SlimFacilityTestResultModel
  useBadge?: boolean
}
export interface BaseInputField {
  sheetname?: string
  sheetname_regex?: string
  file_key?: string
  input_label?: string
  input_cell: string
  input_value?: unknown
  allow_blank?: boolean
}
export interface SlimFacilityTestResultModel {
  globalId: string
  facilityConcentrationLimit: SlimFacilityConcentrationLimit
}
export interface SlimFacilityConcentrationLimit {
  calculationName: string
}
export interface MiscValueSection {
  title?: string
  section_type?: "misc"
  values: MiscValue[]
}
export interface MiscValue {
  label: string
  value?: string | number | string
  browserFormatter?: BrowserFormatter & string
}
export interface ComposableTableSection {
  title?: string
  section_type?: "composable-table"
  interfaceGetter: string
}
export interface CollapsibleSection {
  section_type?: "collapsible"
  sections: (
    | TitleSection
    | SubtitleSection
    | ConcentrationLimitSection
    | FilteredConcentrationLimitSection
    | EligibilitySection
    | CashTrapEventsSection
    | EventOfDefaultSection
    | EarlyAmortizationEventSection
    | PropertyLevelAlaCheckSection
    | ManagementFeesCheckSection
    | InterestRateCapSection
    | CovenantSection
    | ListSection
    | TotalSection
    | TableSection
    | MiscValueSection
    | ComposableTableSection
  )[]
  label: string
  level?: number
}
/**
 * This is a special section that is used to group other sections together. For example, this is useful for showing
 * specific sections (i.e. TablesSections) from the Report dropdown in the UI.
 */
export interface GroupSection {
  section_type?: "group"
  sections: (
    | (
        | TitleSection
        | SubtitleSection
        | ConcentrationLimitSection
        | FilteredConcentrationLimitSection
        | EligibilitySection
        | CashTrapEventsSection
        | EventOfDefaultSection
        | EarlyAmortizationEventSection
        | PropertyLevelAlaCheckSection
        | ManagementFeesCheckSection
        | InterestRateCapSection
        | CovenantSection
        | ListSection
        | TotalSection
        | TableSection
        | MiscValueSection
        | ComposableTableSection
      )
    | CollapsibleSection
  )[]
  label: string
}
export interface GetAssetGradesResponse {
  grades: AssetGradeModel[]
}
export interface AssetGradeModel {
  displayName: string
  globalId: string
  grade: RTLGrade
}
export interface GetAssetGuidelineExplanationResponse {
  guideline: AssetGuidelineExplanationModel
}
export interface GetAssetGuidelinesResponse {
  guidelines: AssetGuidelineModel[]
}
export interface AssetGuidelineModel {
  displayName: string
  globalId: string
  status: GuidelineStatus
}
export interface GetAssetNotesResponse {
  notes: AccountAssetNote[]
}
export interface AccountAssetNote {
  addedAt: string
  authorName: string
  authorImage?: string
  accountName?: string
  globalId: string
  noteContent: string
  isEditable?: boolean
  lastEditedAt?: string
  pinnedAt?: string
  pinnedByMemberName?: string
}
export interface GetAssetOwnersResponse {
  owners: AssetOwnerModel[]
  overallEffectiveCreditScore?: number
  /**
   * Non-standard / atypical fields for which we should also collect values
   */
  extraFieldsToCollect?: "is_primary_borrower"[]
}
export interface AssetOwnerModel {
  styleDefinitions?: {
    [k: string]: unknown
  }
  highlightRowColor?:
    | "gray"
    | "yellow"
    | "sp-green"
    | "green-lite"
    | "blue-lite"
    | "white"
  assetCustomerId?: string
  name: string
  ssn?: string
  equifaxScore?: number
  experianScore?: number
  transunionScore?: number
  creditReportDate?: string
  foreclosureCount?: number
  bankruptcyCount?: number
  felonyCount?: number
  liensJudgementsCount?: number
  memberOfac?: YesNo
  ofacDate?: string
  ofacMemo?: ClearNotClear
  backgroundCheckReportDate?: string
  backgroundCheckNote?: string
  foreignNational?: YesNo
  isPrimaryBorrower?: boolean
  email?: string
  address?: string
  phoneNumber?: string
}
export interface GetAssetRTLPropertiesResponse {
  rtlProperties: RTLPropertyModel[]
  documents: FundingRequirementModel[]
  diligenceFilterType: DiligenceFilterType
}
export interface RTLPropertyModel {
  displayName: string
  fundingRequirements: FundingRequirementModel[]
  fundingRequirementsByCategory: FundingRequirementCategoryModel[]
  globalId: string
  debugId?: number
}
export interface GetAssetSummaryMetricsResponse {
  metrics: SummarySheet
}
export interface GetCustomAssetTabResponse {
  summarySheet?: SummarySheet
}
export interface GetCustomAssetTabsResponse {
  tabs: CustomAssetTab[]
}
export interface CustomAssetTab {
  label: string
  key: string
}
export interface GetFacilitiesForDropdownResponse {
  lenders: Option[]
  aggregators: Option[]
  nonAggregators: Option[]
  all: Option[]
}
export interface GlobalIdBody {
  global_id: string
}
export interface PatchAssetExternalFolderBody {
  externalFolderUrl?: string
}
export interface PatchAssetOwnerBody {
  owners: AssetOwnerModel[]
}
export interface PatchAssetSnoozeAdvanceChange {
  snoozeUntil?: string
}
export interface RemoveUploadBody {
  global_id: string
  file_id: string
}
export interface RequestReviewBody {
  global_id: string
  note: string
}
export interface SaveSettlementBody {
  asset_global_id: string
  funding_requirement_global_id: string
  form_data: AssetSettlementModel
}
export interface UpdateAssetPropertyBody {
  assignmentFees?: string
  city?: string
  clientEmail?: string
  clientName?: string
  closeDatePurchase?: string
  closeDateSell?: string
  conrexBuyingEntity?: string
  contractPurchaseAmount?: number
  creditScore?: number
  diligenceReviewerAccountMemberGlobalId?: string
  dti?: number
  earnestMoneyAmount?: number
  earnestMoneyDeadline?: string
  guarantorCreditScore?: number
  loanAmount?: number
  postalCode?: string
  product?: ProductType
  state?: string
  streetAddress?: string
  type?: AssetPropertyType
  zavviePartner?: string
}
export interface UpdateAssetPropertyDueDate {
  diligenceDueDate?: string
}
export interface UpdateCustomerAssetIdBody {
  assetId: string
}
export interface UpdateDKPrepaymentBody {
  prepaymentDate?: string
  prepaymentFees?: number
  legalFees?: number
}
export interface UploadDocumentCompleteBody {
  global_id: string
  file_id: string
  file_folder_id: string
  asset_folder_id: string
  filename: string
}
export interface WaiveNoteBody {
  waiveNote: string
}
export interface AddAssetLoanTapeResponse {
  assetPageUrl: string
}
export interface AddEditAssetLoanTapeBody {
  sourcePayload: {
    [k: string]: unknown
  }
}
export interface AddPropertyForm {
  customerAssetId?: string
  assignmentFees?: string
  city?: string
  clientEmail?: string
  clientName?: string
  closeDatePurchase?: string
  closeDateSell?: string
  conrexBuyingEntity?: string
  contractPurchaseAmount?: number
  customerPropertyValue?: number
  creditScore?: number
  diligenceReviewerAccountMemberGlobalId?: string
  dti?: number
  earnestMoneyAmount?: number
  earnestMoneyDeadline?: string
  facilityGlobalId?: string
  guarantorCreditScore?: number
  loanAmount?: number
  postalCode?: string
  product?: ProductType
  state?: string
  streetAddress?: string
  type?: AssetPropertyType
  zavviePartner?: string
}
export interface AssetGlobalIdsBody {
  assetGlobalIds: string[]
}
export interface AssetZipDownloadBody {
  assetGlobalIds: string[]
  filename?: string
}
export interface AssetsResponse {
  assets: ReportProperty[]
  counts?: ReportCounts
  nextOffset?: number
}
export interface ReportProperty {
  assetId?: string
  city?: string
  closeDatePurchase?: string
  customerStage?: string
  currentValue?: number
  displayName: string
  displayValue?: number
  globalId: string
  postalCode?: string
  prevSeniorFacility?: string
  reportColumns: ReportColumn[]
  seniorFacility?: string
  seniorFacilityTypeDisplayName?:
    | "Originator"
    | "Capital Partner"
    | "Facility"
    | "Lender"
  diligenceFacility?: string
  state?: string
  streetAddress?: string
  tab?: AssetTab & string
  fundingEligibilityStatus?: AssetFundingEligibilityStatus
  selectionEnabled?: boolean
  additionalContext?: string
  flags: AssetFlagModel[]
}
export interface ReportColumn {
  content:
    | ReportColumnDueDate
    | ReportColumnFailedDocuments
    | ReportColumnFlags
    | ReportColumnProgressChart
    | ReportColumnText
    | ReportColumnDiligenceRequirement
    | ReportColumnValuationStatus
    | ReportColumnExtractedJSON
    | ReportColumnDiligenceExceptionCount
    | ReportColumnValidation
    | ReportColumnGuidelines
    | ReportColumnGrades
    | ReportColumnFundingRequirement
  name: string
}
export interface ReportColumnDueDate {
  type?: ReportColumnContentType & string
  dueRequirements: DueFundingRequirement[]
}
export interface DueFundingRequirement {
  dueDate: string
  name: string
}
export interface ReportColumnFailedDocuments {
  type?: ReportColumnContentType & string
  documents: FailedDocument[]
}
export interface FailedDocument {
  name: string
  reasons: string[]
}
export interface ReportColumnFlags {
  type?: ReportColumnContentType & string
  flags: AssetFlagModel[]
}
export interface ReportColumnProgressChart {
  type?: ReportColumnContentType & string
  itemsComplete: number
  itemsTotal: number
}
export interface ReportColumnText {
  type?: ReportColumnContentType & string
  text: string
}
export interface ReportColumnDiligenceRequirement {
  type?: ReportColumnContentType & string
  fundingRequirementStage: FundingRequirementStage
  reason: string
}
export interface ReportColumnValuationStatus {
  type?: ReportColumnContentType & string
  value: ValuationRequirementReportStatus
}
export interface ReportColumnExtractedJSON {
  type?: ReportColumnContentType & string
  jsonForExtraction: {
    [k: string]: unknown
  }
}
export interface ReportColumnDiligenceExceptionCount {
  type?: ReportColumnContentType & string
  totalCount: number
  completeCount: number
  exceptionCount: number
  disputeCount: number
  pendingCount?: number
}
export interface ReportColumnValidation {
  type?: ReportColumnContentType & string
  pendingCount: number
  passCount: number
  failCount: number
  waivedCount: number
}
export interface ReportColumnGuidelines {
  type?: ReportColumnContentType & string
  passCount: number
  pendingCount: number
  waivedCount: number
  failCount: number
}
export interface ReportColumnGrades {
  type?: ReportColumnContentType & string
  assetGrades: AssetGradeModel[]
}
export interface ReportColumnFundingRequirement {
  type?: ReportColumnContentType & string
  stage?: FundingRequirementStage
}
export interface ReportCounts {
  all: number
  last_week: number
  today: number
  tomorrow: number
  this_week: number
  next_week: number
  custom: number
}
export interface AssetsSearchBody {
  customDateFilterStart?: string
  customDateFilterEnd?: string
  dateFilter?: DateFilterOption
  report?: Report
  offset?: number
  searchTerm?: string
  selectedFacilityId?: string
}
export interface AssetsSearchSimpleBody {
  search_term?: string
  limit?: number & string
  offset?: number & string
}
export interface AssetsSearchSimpleResponse {
  assets: AssetPropertySimple[]
}
export interface AssetPropertySimple {
  globalId: string
  customerAssetId?: string
  displayName?: string
}
export interface BorrowingBaseTransferBody {
  sourceFacilityGlobalId: string
  destinationFacilityGlobalId: string
}
export interface ExportLoanTapeBody {
  customDateFilterStart?: string
  customDateFilterEnd?: string
  dateFilter?: DateFilterOption
  report?: Report
  offset?: number
  searchTerm?: string
  selectedFacilityId?: string
  assetGlobalId?: string
  exportType: ExportLoanTapeType
  reportName: string
  isBorrowingBase?: boolean
}
export interface ExportLoanTapeResponse {
  jobId: string
  status: ExportLoanTapeStatus
  downloadUrl?: string
}
export interface GetFundingProductsResponse {
  products: Option[]
}
export interface GetReportsResponse {
  reports: ReportOption[]
  facilityId?: string
}
export interface ReportOption {
  label: string
  value: Report
  enableAssetSelection?: boolean
  assetSelectionDefaultChecked?: boolean
  documentExport?: boolean
  showAssetCustomerStage?: boolean
  showCloseDate?: boolean
  compactDisplay?: boolean
}
export interface MarkAsSoldBody {
  assetGlobalIds: string[]
  capitalPartnerName: string
  capitalPartnerFacilityGlobalId?: string
  buyRate?: number
  tradeName?: string
}
export interface PostAssetsZipDownloadResponse {
  downloadUrl: string
}
export interface PostTriggerPreCloseReport {
  passFailFacility: PassFailReportFacility
}
export interface ReinstateBody {
  assetGlobalIds: string[]
}
export interface RequestFundingDetailResponse {
  facilities: FacilityFundingDetail[]
  isFlyhomes: boolean
}
export interface FacilityFundingDetail {
  advancedTotal: number
  assetFundingDetails: FundingDetail[]
  availableToDraw: number
  facilityGlobalId: string
  facilityName: string
  fundingDestination: FundingDestination
  participationTotal: number
  propertiesCount: number
  wireTotal: number
}
export interface TerminateBody {
  assetGlobalIds: string[]
}
export interface UndoMarkAsSoldBody {
  assetGlobalIds: string[]
}
export interface UploadLoanTapeResponse {
  validationErrors?: LoanTapeValidationError[]
  jobId: string
  status: UploadLoanTapeStatus
  created?: number
  updated?: number
  entityType?: string
}
export interface LoanTapeValidationError {
  missingSheets?: string[]
  missingFields: string[]
  invalidFields: string[]
  invalidFieldsMessages?: string[]
  rowNum?: number
  cell?: string
  fileDisplayName?: string
  sheetDisplayName?: string
  duplicateKeys?: string[]
}
export interface AssociateBankAccountTransactionBody {
  associations: BankAccountTransactionAssociation[]
}
export interface BankAccountTransactionAssociation {
  assetGlobalId: string
  amount: number
}
export interface CreateBankActivityRemittanceBody {
  amount: number
  note: string
}
export interface GetBankAccountActivityResponse {
  activity: BankAccountTransactionModel[]
  bankAccount?: BankAccountModel
}
export interface BankAccountModel {
  globalId: string
  createdAt: string
  name: string
  mask: string
  addedByAccountMember?: AccountMemberModel
  utilization?: BankAccountUtilizationModel
  needsReauth?: boolean
}
export interface BankAccountUtilizationModel {
  globalId?: string
  bankAccountGlobalId?: string
  facility?: FacilityModel
  accountType: BankAccountTransferType
}
export interface GetBankAccountsResponse {
  bankAccounts: BankAccountModel[]
}
export interface SaveBankAccountUtilizationBody {
  utilizations: BankAccountUtilizationModel[]
}
export interface GetBillingAccountsResponse {
  accounts: Option[]
}
export interface GetInvoiceResponse {
  billingEvents: BillingEventModel[]
  invoice: StripeInvoice
}
export interface BillingEventModel {
  displayName: string
  effectiveAt: string
  idempotencyKey: string
  stripePriceId: string
  stripeProductId: string
  fileUploads?: FileUploadModel[]
}
export interface FileUploadModel {
  uploadedAt: string
  filename: string
  globalId: string
  downloadUrl?: string
}
export interface StripeInvoice {
  amountDue: number
  amountDueDollars?: number
  created: number
  customer: string
  discount?: StripeDiscount
  excelExportUrl?: string
  hostedInvoiceUrl?: string
  invoiceDate?: string
  invoiceDateText?: string
  invoiceMonth?: number
  invoiceYear?: number
  invoicePdf?: string
  id?: string
  number?: string
  status: StripeInvoiceStatus
  subtotal: number
  subtotalDollars?: number
  upcoming?: boolean
  subscription?: string
  setpointLineItems?: SetpointInvoiceLineItem[]
  metadata?: {
    [k: string]: unknown
  }
}
export interface StripeDiscount {
  id: string
  coupon: StripeCoupon
}
export interface StripeCoupon {
  name: string
  percentOff?: number
}
export interface SetpointInvoiceLineItem {
  amount: number
  amountDollars?: number
  description: string
  discountAmounts?: StripeDiscountAmount[]
  lineItemDescription: string
  lineItemTooltip?: string
  price: StripePrice
  quantity: number
  unitPrice?: number
  unitPriceOverride?: string
}
export interface StripeDiscountAmount {
  amount: number
  amountDollars?: number
  discount: string
}
export interface StripePrice {
  id: string
  created: string
  product: string
  unitAmount?: number
  unitAmountDollars?: number
}
export interface GetInvoicesResponse {
  invoices: InvoiceOption[]
}
export interface InvoiceOption {
  label: string
  value: string
  isUpcoming: boolean
}
export interface PostInvoiceAdjustmentBody {
  itemDescription: string
  itemQuantity: number
  itemPrice: number
  direction: PaymentDirection
  subscription?: string
}
export interface AssetFilter {
  assetGlobalIds?: string[]
}
export interface BorrowingBaseExcessConcentrationResponse {
  eligibility: FacilityConcentrationLimitResultModel[]
  totalExcessEligibility?: number
  concentration: FacilityConcentrationLimitResultModel[]
  totalExcessConcentration?: number
  eligibleBalance?: number
  adjustedBalance?: number
  facilityAdvanceRate?: number
  assetBorrowedAmount: number
  borrowingBase: number
  pageCopy: ExcessConcentrationPageCopy
  removeExcessConcentration: boolean
}
export interface FacilityConcentrationLimitResultModel {
  globalId?: string
  facilityConcentrationLimit: FacilityConcentrationLimitModel
  limitValue?: number
  computedLimitValue?: number
  atRiskLimitValue?: number
  computedAtRiskLimitValue?: number
  actual?: number
  total?: number
  excess?: number
  status: FacilityComplianceStatus
  effectiveDate: string
  results?:
    | AggregatedConcentrationByStateResults
    | AggregatedConcentrationByCBSAResults
    | AggregatedConcentrationBySubGroupingResults
    | AggregatedConcentrationByCBSAResult[]
    | AggregatedConcentrationByStateResult[]
    | AggregatedConcentrationBySubGroupingResult[]
  displayColumns?: {
    [k: string]: ConcentrationLimitCalculationsDisplayColumn
  }
  isFloorCalculation?: boolean
  waiverCount?: number
  waterfallOrder?: number
  limitVerificationField?:
    | VerificationFieldFloat
    | VerificationFieldBool
    | VerificationFieldMoney
    | VerificationFieldDate
    | VerificationFieldText
  actualVerificationField?:
    | VerificationFieldFloat
    | VerificationFieldBool
    | VerificationFieldMoney
    | VerificationFieldDate
    | VerificationFieldText
  passFailVerificationField?: VerificationFieldBool
  excessVerificationField?: VerificationFieldMoney
  otherVerificationField?:
    | VerificationFieldFloat
    | VerificationFieldBool
    | VerificationFieldMoney
    | VerificationFieldDate
    | VerificationFieldText
  generatingAssetResults?: boolean
  limitAsPercentage?: number
}
export interface FacilityConcentrationLimitModel {
  globalId?: string
  limitBrowserFormatter: BrowserFormatter
  assetResultBrowserFormatter: BrowserFormatter
  calculationName: string
  calculationDescription?: string
  calculationType?: ConcentrationLimitCalculationType
  category: ConcentrationLimitCategory
  subCategory?: ConcentrationLimitSubCategory
  limitType: ConcentrationLimitType
  limitValue?: number
  calcTypeLabel?: string
  calculationAggregationLabel?:
    | "Asset Value"
    | "Purchase Price"
    | "Repurchase Price"
    | "Withdrawal Amount"
    | "Advanced Amount"
    | "Receivable Balance"
    | "Allocable Debt Amount"
  fundingEligibilityEffect?: FundingEligibilityEffect
  hideCalculationAggregationColumn?: boolean
  calculationAggregation?:
    | "market_value"
    | "repurchase_price"
    | "mixed"
    | "withdrawal_amount"
  facility: FacilityConcentrationLimitFacilityModel
  isSubGroupingCalculationResult: boolean
  isEligibility: boolean
  isWaterfall?: boolean
  isCalculatorDetails?: boolean
  limitAsPercentage?: number
}
export interface FacilityConcentrationLimitFacilityModel {
  globalId: string
  name: string
  structure: FacilityStructure
  agreementType: FacilityAgreementType
  account: SlimAccountModel
  isSetpoint: boolean
}
export interface SlimAccountModel {
  name: string
  logo?: string
}
export interface AggregatedConcentrationByStateResults {
  results: AggregatedConcentrationByStateResult[]
}
export interface AggregatedConcentrationByStateResult {
  sumValueRank?: number
  percentConcentration?: number
  rollingPercentConcentration?: number
  sumValueForConditionEvaluation?: number
  totalSumValueForConditionEvaluation?: number
  rollingSumValueForConditionEvaluation?: number
  isSpecifiedWindow?: boolean
  rankToStartWindow?: number
  state: USState
}
export interface AggregatedConcentrationByCBSAResults {
  results: AggregatedConcentrationByCBSAResult[]
}
export interface AggregatedConcentrationByCBSAResult {
  sumValueRank?: number
  percentConcentration?: number
  rollingPercentConcentration?: number
  sumValueForConditionEvaluation?: number
  totalSumValueForConditionEvaluation?: number
  rollingSumValueForConditionEvaluation?: number
  isSpecifiedWindow?: boolean
  rankToStartWindow?: number
  cbsaId?: number
  cbsaName?: string
}
export interface AggregatedConcentrationBySubGroupingResults {
  results: AggregatedConcentrationBySubGroupingResult[]
}
export interface AggregatedConcentrationBySubGroupingResult {
  sumValueRank?: number
  percentConcentration?: number
  rollingPercentConcentration?: number
  sumValueForConditionEvaluation?: number
  totalSumValueForConditionEvaluation?: number
  rollingSumValueForConditionEvaluation?: number
  isSpecifiedWindow?: boolean
  rankToStartWindow?: number
}
export interface ConcentrationLimitCalculationsDisplayColumn {
  value?: number | string | boolean
  label?: string
  browserFormatter: BrowserFormatter
  slug?: string
  sortOrder?: number
}
export interface VerificationFieldFloat {
  sheetname?: string
  sheetname_regex?: string
  file_key?: string
  input_label?: string
  calculated?: number
  provided?: number
  provided_raw?: unknown
  verified?: boolean
  validation_cell?: string
  parse_formula?: boolean
  allow_blank?: boolean
  allow_blank_provided?: boolean
  tooltip_description_override?: string
  provided_cell_formula?: string
  calculated_lender_cell_formula?: string
  download_output_formula?: string
  money_jitter_cents?: number
  money_jitter_pct?: number
  float_jitter?: number
  facility_test_calculation_type?: ConcentrationLimitCalculationType
  facility_test_result_type?: FacilityTestResultType
  show_value_detail?: boolean
  dispute_difference?: unknown
  browser_formatter?: BrowserFormatter & string
}
export interface VerificationFieldBool {
  sheetname?: string
  sheetname_regex?: string
  file_key?: string
  input_label?: string
  calculated?: boolean
  provided?: boolean
  provided_raw?: unknown
  verified?: boolean
  validation_cell?: string
  parse_formula?: boolean
  allow_blank?: boolean
  allow_blank_provided?: boolean
  tooltip_description_override?: string
  provided_cell_formula?: string
  calculated_lender_cell_formula?: string
  download_output_formula?: string
  money_jitter_cents?: number
  money_jitter_pct?: number
  float_jitter?: number
  facility_test_calculation_type?: ConcentrationLimitCalculationType
  facility_test_result_type?: FacilityTestResultType
  show_value_detail?: boolean
  dispute_difference?: unknown
  browser_formatter?: BrowserFormatter & string
  waiver_count?: number
  is_waived?: boolean
}
export interface VerificationFieldMoney {
  sheetname?: string
  sheetname_regex?: string
  file_key?: string
  input_label?: string
  calculated?: number
  provided?: number
  provided_raw?: unknown
  verified?: boolean
  validation_cell?: string
  parse_formula?: boolean
  allow_blank?: boolean
  allow_blank_provided?: boolean
  tooltip_description_override?: string
  provided_cell_formula?: string
  calculated_lender_cell_formula?: string
  download_output_formula?: string
  money_jitter_cents?: number
  money_jitter_pct?: number
  float_jitter?: number
  facility_test_calculation_type?: ConcentrationLimitCalculationType
  facility_test_result_type?: FacilityTestResultType
  show_value_detail?: boolean
  dispute_difference?: unknown
  browser_formatter?: BrowserFormatter & string
}
export interface VerificationFieldDate {
  sheetname?: string
  sheetname_regex?: string
  file_key?: string
  input_label?: string
  calculated?: string
  provided?: string
  provided_raw?: unknown
  verified?: boolean
  validation_cell?: string
  parse_formula?: boolean
  allow_blank?: boolean
  allow_blank_provided?: boolean
  tooltip_description_override?: string
  provided_cell_formula?: string
  calculated_lender_cell_formula?: string
  download_output_formula?: string
  money_jitter_cents?: number
  money_jitter_pct?: number
  float_jitter?: number
  facility_test_calculation_type?: ConcentrationLimitCalculationType
  facility_test_result_type?: FacilityTestResultType
  show_value_detail?: boolean
  dispute_difference?: unknown
  browser_formatter?: BrowserFormatter & string
}
export interface VerificationFieldText {
  sheetname?: string
  sheetname_regex?: string
  file_key?: string
  input_label?: string
  calculated?: string
  provided?: string
  provided_raw?: unknown
  verified?: boolean
  validation_cell?: string
  parse_formula?: boolean
  allow_blank?: boolean
  allow_blank_provided?: boolean
  tooltip_description_override?: string
  provided_cell_formula?: string
  calculated_lender_cell_formula?: string
  download_output_formula?: string
  money_jitter_cents?: number
  money_jitter_pct?: number
  float_jitter?: number
  facility_test_calculation_type?: ConcentrationLimitCalculationType
  facility_test_result_type?: FacilityTestResultType
  show_value_detail?: boolean
  dispute_difference?: unknown
  browser_formatter?: BrowserFormatter & string
}
export interface ExcessConcentrationPageCopy {
  assetValue: string
  totalIneligible: string
  eligible: string
  totalExcessConcentration: string
  adjustedBalance: string
  advanceRate: string
  borrowingBase: string
}
export interface BorrowingBaseFacilitySummary {
  seniorFacility: BorrowingBaseFacility
  mezzFacility?: BorrowingBaseFacility
  totalBorrowingBase?: number
}
export interface BorrowingBaseFacility {
  amount: number
  availableToDraw?: number
  pledgedCollateral?: number
  cashCollateral?: number
  globalId: string
  name: string
  size?: number
  structure: FacilityStructure
  type: FacilityType
  uncommittedFacility: boolean
  isUnassignedFacility: boolean
  removeExcessConcentration: boolean
  inputValues?: (
    | FacilityInputPayloadModel
    | FacilityInputDateModel
    | FacilityInputMoneyModel
    | FacilityInputIntModel
    | FacilityInputFloatModel
    | FacilityInputPercentageModel
  )[]
  hasCustomSummarySheet?: boolean
  hasSelectableAssets?: boolean
  supportedSummarySheetReports: string[]
}
export interface FacilityInputPayloadModel {
  type: FacilityInputValueType
  label: string
  value?: {
    [k: string]: unknown
  }
  effectiveAt?: string
  value_type?: "jsonb"
}
export interface FacilityInputDateModel {
  type: FacilityInputValueType
  label: string
  value?: string
  effectiveAt?: string
  value_type?: "date"
}
export interface FacilityInputMoneyModel {
  type: FacilityInputValueType
  label: string
  value?: number
  effectiveAt?: string
  value_type?: "money"
}
export interface FacilityInputIntModel {
  type: FacilityInputValueType
  label: string
  value?: number
  effectiveAt?: string
  value_type?: "int"
}
export interface FacilityInputFloatModel {
  type: FacilityInputValueType
  label: string
  value?: number
  effectiveAt?: string
  value_type?: "float"
}
export interface FacilityInputPercentageModel {
  type: FacilityInputValueType
  label: string
  value?: number
  effectiveAt?: string
  value_type?: "percentage"
}
export interface BorrowingBaseManualAdjustmentBody {
  amount: number
  effectiveAt?: string
}
export interface BorrowingBaseSublimitsResponse {
  sublimits: (SublimitBound | SublimitBoundForBinaryCriteria)[]
}
export interface SublimitBound {
  calculationName: string
  criteriaItems: CriteriaBoundItem[]
  excessAmount?: number
  isEligibility: boolean
  criteriaType?: string
}
export interface CriteriaBoundItem {
  criteria: string
  amountMatchingCriteria?: number
  maximumPossibleAmountForCriteria?: number
}
export interface SublimitBoundForBinaryCriteria {
  calculationName: string
  /**
   * @minItems 2
   * @maxItems 2
   */
  criteriaItems: [CriteriaBoundItemLiteralINOUT, CriteriaBoundItemLiteralINOUT]
  excessAmount?: number
  isEligibility: boolean
  criteriaType?: string
  totalAmount?: number
}
export interface CriteriaBoundItemLiteralINOUT {
  criteria: "IN" | "OUT"
  amountMatchingCriteria?: number
  maximumPossibleAmountForCriteria?: number
}
export interface ExportReportResponse {
  jobId: string
  status: ExportReportStatus
  downloadUrl?: string
}
export interface FacilityAdvanceChanges {
  accountName: string
  advanceChanges: AdvanceChangeAssetModel[]
  amount: number
  facilityGlobalId: string
  facilityName: string
  lenderAccountName: string
}
export interface AdvanceChangeAssetModel {
  customerAssetId?: string
  customerStatus?: string
  displayName: string
  assetGlobalId: string
  globalId?: string
  includedInAnotherOpenRequest?: boolean
  ignoresFundingCalculations?: boolean
  accountName: string
  ageDays: number
  amount: number
  assetAdvanceChangeGlobalId: string
  effectiveDate: string
  facility: FacilityModel
  facilityType: FacilityType
  lenderAccountName: string
  loanPercentage: number
  marketValue: number
  prevLoanPercentage: number
  prevMarketValue: number
}
export interface FetchBorrowingBaseAssetDetailsBody {
  asset_global_id: string
  facility_global_id: string
  view_date: string
}
export interface FetchBorrowingBaseAssetDetailsResponse {
  entries: BorrowingBaseEntry[]
  asset?: BorrowingBaseAsset
}
export interface BorrowingBaseEntry {
  amount?: number
  detail: string
  effectiveDate: string
  facilityName: string
  facilityType: FacilityType
  globalId: string
  loanPercentage?: number
  type: BorrowingBaseEntryType
}
export interface BorrowingBaseAssetStandard {
  facilityBorrowingBaseSchema?: FacilityBorrowingBaseSchema & string
  setpointAssetId: number
  assetGlobalId: string
  customerAssetId?: string
  displayName?: string
  seniorFacilityName?: string
  seniorFacilityAmount: number
  mezzFacilityName?: string
  mezzFacilityAmount?: number
  assetEnteredBase: string
  assetExitedBase?: string
  daysOnBase: number
  address?: string
  appraisalValue?: number
  creditScore?: number
  customerProduct?: string
  displayedProduct?: string
  dti?: number
  marketValue?: number
  msa?: string
  participationAmount?: number
  product?: ProductType
  productPretty: string
  salePrice: number
  seniorLoanPercentage?: number
  leverageFacility?: BackLeverageFacility
  custody?: string
}
export interface BorrowingBaseAssetSFR {
  facilityBorrowingBaseSchema?: FacilityBorrowingBaseSchema & string
  setpointAssetId: number
  assetGlobalId: string
  customerAssetId?: string
  displayName?: string
  seniorFacilityName?: string
  seniorFacilityAmount: number
  mezzFacilityName?: string
  mezzFacilityAmount?: number
  assetEnteredBase: string
  assetExitedBase?: string
  daysOnBase: number
  displayedPropertyType: string
  purchasePrice: number
  postStabilizedBorrowedAmount: number
  bpoAmount?: number
  currentlyLeased?: "Yes" | "No"
  underwrittenNetCashFlow: number
}
export interface BorrowingBaseAssetFacilityAdvanceRate {
  facilityBorrowingBaseSchema?: FacilityBorrowingBaseSchema & string
  setpointAssetId: number
  assetGlobalId: string
  customerAssetId?: string
  displayName?: string
  seniorFacilityName?: string
  seniorFacilityAmount: number
  mezzFacilityName?: string
  mezzFacilityAmount?: number
  assetEnteredBase: string
  assetExitedBase?: string
  daysOnBase: number
  address?: string
  appraisalValue?: number
  creditScore?: number
  customerProduct?: string
  displayedProduct?: string
  dti?: number
  marketValue?: number
  msa?: string
  participationAmount?: number
  product?: ProductType
  productPretty: string
  salePrice: number
  seniorLoanPercentage?: number
  leverageFacility?: BackLeverageFacility
  custody?: string
}
export interface BorrowingBaseAssetBDCapital {
  facilityBorrowingBaseSchema?: FacilityBorrowingBaseSchema & string
  setpointAssetId: number
  assetGlobalId: string
  customerAssetId?: string
  displayName?: string
  seniorFacilityName?: string
  seniorFacilityAmount: number
  mezzFacilityName?: string
  mezzFacilityAmount?: number
  assetEnteredBase: string
  assetExitedBase?: string
  daysOnBase: number
  address?: string
  appraisalValue?: number
  creditScore?: number
  customerProduct?: string
  displayedProduct?: string
  dti?: number
  marketValue: number
  msa?: string
  participationAmount?: number
  product?: ProductType
  productPretty: string
  salePrice: number
  seniorLoanPercentage?: number
  leverageFacility?: BackLeverageFacility
  custody?: string
  unpaidPrincipalBalance: number
  advanceRate: number
  purchasePrice: number
  unfundedAmount: number
}
export interface FetchBorrowingBaseAssetsBody {
  facilityGlobalId?: string
  calcAgentReportGlobalId?: string
  viewDate: string
  searchTerm: string
}
export interface FetchBorrowingBaseBody {
  facilityGlobalId?: string
  calcAgentReportGlobalId?: string
  viewDate: string
}
export interface FetchBorrowingBaseFacilityTestsBody {
  facilityGlobalId?: string
  calcAgentReportGlobalId?: string
  viewDate?: string
}
export interface FetchBorrowingBaseFacilityTestsResponse {
  eligibility: FacilityConcentrationLimitResultModel[]
  cashTrapResults: FacilityConcentrationLimitResultModel[]
  eventOfDefaultResults: FacilityConcentrationLimitResultModel[]
  earlyAmortizationEventResults: FacilityConcentrationLimitResultModel[]
  interestRateCapResults: FacilityConcentrationLimitResultModel[]
  allocatedLoanAmountTestResults: FacilityConcentrationLimitResultModel[]
  managementFeesTestResults: FacilityConcentrationLimitResultModel[]
  totalExcessEligibility?: number
  totalExcessEligibilityVerification?: BaseVerificationField
  concentration: FacilityConcentrationLimitResultModel[]
  totalExcessConcentration?: number
  totalExcessConcentrationVerification?: BaseVerificationField
  covenant: FacilityConcentrationLimitResultModel[]
  pageCopy: ExcessConcentrationPageCopy
}
export interface FetchBorrowingBaseSummarySheet {
  facilityGlobalId?: string
  calcAgentReportGlobalId?: string
  viewDate: string
  report: string
  assetFilter?: unknown
}
export interface FetchBorrowingBaseUpcomingChanges {
  endDate?: string
  facilityGlobalId?: string
}
export interface GetBorrowingBaseResponse {
  borrowingBaseAssets: BorrowingBaseAsset[]
  borrowingBaseAssetCount: number
  borrowingBaseHeaders?: BorrowingBaseAssetHeaderGrouping[]
  facilityReassignmentConfigs: FacilityReassignmentConfigModel[]
  includeLeverageFacility: boolean
  isSetpointFacility: boolean
  showBorrowingBaseAssetList: boolean
}
export interface BorrowingBaseAssetHeaderGrouping {
  groupKey: "Identifiers" | "Descriptors" | "Metrics" | "Actions"
  fields?: BorrowingBaseAssetHeader[]
  widthWeight?: number
}
export interface BorrowingBaseAssetHeader {
  fieldName: string
  displayName?: string
  alignment?: "left" | "right"
  browserFormatter?: BrowserFormatter & string
}
export interface GetBorrowingBaseSummarySheet {
  sections: (
    | (
        | TitleSection
        | SubtitleSection
        | ConcentrationLimitSection
        | FilteredConcentrationLimitSection
        | EligibilitySection
        | CashTrapEventsSection
        | EventOfDefaultSection
        | EarlyAmortizationEventSection
        | PropertyLevelAlaCheckSection
        | ManagementFeesCheckSection
        | InterestRateCapSection
        | CovenantSection
        | ListSection
        | TotalSection
        | TableSection
        | MiscValueSection
        | ComposableTableSection
      )
    | CollapsibleSection
    | GroupSection
  )[]
  canDownload?: boolean
  title?: string
  simpleGrid?: boolean
  determinationDate?: string
}
export interface MarketValueOverrideBody {
  amount: number
}
export interface UpcomingAdvanceChangesBody {
  facilities: FacilityAdvanceChanges[]
}
export interface GetBoxFolderResponse {
  type: string
  id: string
  sequenceId?: string
  name: string
  createdAt?: string
  modifiedAt?: string
  description: string
  pathCollection: BoxCollection
  createdBy: BoxObjectUserDetail
  modifiedBy?: BoxObjectUserDetail
  contentCreatedAt?: string
  contentModifiedAt?: string
  ownedBy: BoxObjectUserDetail
  parent?: BoxFileEntry
  itemStatus: string
  itemCollection: BoxItemCollection
  [k: string]: unknown
}
export interface BoxCollection {
  totalCount?: number
  entries?: BoxFileEntry[]
  [k: string]: unknown
}
export interface BoxFileEntry {
  type: string
  id: string
  sequenceId?: string
  name: string
  downloadUrl?: string
  categories?: DocumentCategoryLabel[]
  [k: string]: unknown
}
export interface DocumentCategoryLabel {
  label: string
  probability: number
}
export interface BoxObjectUserDetail {
  type: string
  id: string
  name?: string
  login?: string
  [k: string]: unknown
}
export interface BoxItemCollection {
  totalCount?: number
  entries?: BoxFileEntry[]
  offset: number
  limit: number
  [k: string]: unknown
}
export interface GetBoxFolderResponseErrorMessage {
  message: string
}
export interface BuyerGroupReport {
  globalId: string
  status: BuyerGroupStatus
  buyers: Buyer[]
  upcomingRequirements: DueFundingRequirement[]
  overdueRequirements: DueFundingRequirement[]
  personnel: BuyerGroupPersonnel[]
  properties: BuyerGroupPropertyReport[]
}
export interface Buyer {
  globalId: string
  firstName: string
  lastName: string
  email?: string
}
export interface BuyerGroupPersonnel {
  globalId: string
  name?: string
  email?: string
  type: BuyerGroupPersonnelType
}
export interface BuyerGroupPropertyReport {
  city: string
  globalId: string
  mlsId?: string
  postalCode: string
  state: string
  streetAddress: string
}
export interface ConvertBuyerGroupPropertyForm {
  closeDatePurchase?: string
  city?: string
  contractPurchaseAmount?: number
  netPurchaseAmount?: number
  creditScore?: number
  dti?: number
  facilityGlobalId?: string
  postalCode?: string
  product?: ProductType
  state?: string
  streetAddress?: string
  type?: AssetPropertyType
}
export interface CreateBuyerGroupBody {
  buyers: CreateBuyerBody[]
  personnel: CreateBuyerGroupPersonnelBody[]
  customerId?: string
  facilityGlobalId?: string
}
export interface CreateBuyerBody {
  email?: string
  firstName: string
  lastName: string
}
export interface CreateBuyerGroupPersonnelBody {
  name?: string
  email?: string
  accountMemberGlobalId?: string
  type: BuyerGroupPersonnelType
}
export interface GetBuyerGroupPublicResponse {
  buyerGroup: BuyerGroupPublic
}
export interface BuyerGroupPublic {
  accountName: string
  affordAmount?: number
  buyers: Buyer[]
  globalId: string
  personnel: BuyerGroupPersonnel[]
}
export interface GetBuyerGroupResponse {
  buyerGroup: BuyerGroup
}
export interface BuyerGroup {
  affordAmount?: number
  buyers: Buyer[]
  globalId: string
  personnel: BuyerGroupPersonnel[]
  documents: FundingRequirementModel[]
  facility?: FacilityModel
  feed: ActivityFeedModel[]
  fundingRequirements: FundingRequirementModel[]
  fundingRequirementsByCategory: FundingRequirementCategoryModel[]
  properties: BuyerGroupProperty[]
  status: BuyerGroupStatus
  creditScore?: number
  fundingDetails?: BuyerGroupFundingDetails
  dti?: number
}
export interface ActivityFeedModel {
  addedAt: string
  assetName?: string
  assetGlobalId?: string
  detail?: string
  globalId: string
  newCloseDate?: string
  noteContent?: string
  prevCloseDate?: string
  streetAddress?: string
  type: ActivityFeedType
  userImage?: string
  username?: string
  prevMarketValue?: number
  newMarketValue?: number
  categoryName?: string
}
export interface BuyerGroupProperty {
  city: string
  documents: FundingRequirementModel[]
  fundingRequirements: FundingRequirementModel[]
  fundingRequirementsByCategory: FundingRequirementCategoryModel[]
  globalId: string
  mlsId?: string
  postalCode: string
  state: string
  streetAddress: string
  type: AssetPropertyType
  assetPropertyGlobalId?: string
  assetUrl?: string
  avm?: Avm
}
export interface BuyerGroupFundingDetails {
  preApprovalAmount?: number
  properties: BuyerGroupPropertyFundingDetails[]
  reserves?: number
}
export interface BuyerGroupPropertyFundingDetails {
  streetAddress?: string
  avmAmount?: string | number
  setpointApprovedAmount?: string | number
}
export interface GetBuyerGroupTabCounts {
  tabCounts: {
    [k: string]: number
  }
  currentTab: BuyersTab
}
export interface GetBuyerGroupsResponse {
  buyerGroups: BuyerGroupReport[]
  nextOffset?: number
}
export interface SaveBuyerGroupPropertyBody {
  buyerGroupGlobalId: string
  city: string
  mlsId: string
  postalCode: string
  state: string
  streetAddress: string
  type: AssetPropertyType
  listingPrice: number
}
export interface UpdateBuyerGroupBody {
  buyers?: (UpdateBuyerInGroupBody | CreateBuyerBody)[]
  fundingRequirementGlobalId?: string
  personnel?: (UpdateBuyerGroupPersonnelBody | CreateBuyerGroupPersonnelBody)[]
  preApprovalAmount?: number
}
export interface UpdateBuyerInGroupBody {
  email?: string
  firstName?: string
  lastName?: string
  globalId: string
}
export interface UpdateBuyerGroupPersonnelBody {
  name?: string
  email?: string
  accountMemberGlobalId?: string
  type: BuyerGroupPersonnelType
  globalId: string
}
export interface UpdateBuyerGroupPropertyBody {
  fundingRequirementGlobalId?: string
  earnestMoneyDown?: number
  purchasePrice?: number
}
export interface UpdateBuyerGroupStatusBody {
  status: BuyerGroupStatus
}
export interface CalcAgentAssetSearchBody {
  facilityGlobalId: string
  limit?: number
  searchTerm?: string
  transitionType?: ChangeCategory & string
  fundingDate?: string
  calcAgentReportGlobalId?: string
}
export interface CalcAgentAssetSearchResponse {
  searchAssets: (EnteringAssetModel | ExitingAssetModel)[]
}
export interface EnteringAssetModel {
  customerAssetId?: string
  customerStatus?: string
  displayName: string
  assetGlobalId: string
  globalId?: string
  includedInAnotherOpenRequest?: boolean
  ignoresFundingCalculations?: boolean
  fundingCalculations?: FundingDetail
  readyForFunding: boolean
  seniorAdvanced?: number
  seniorAdvanceRate?: number
  assetProperty?: ChangeRequestAssetProperty
}
export interface ChangeRequestAssetProperty {
  assetId?: string
  creditScore?: string
  streetAddress?: string
  dti?: number
  msa?: string
  currentValue?: number
  valueTotal?: number
  contractPurchaseAmount?: number
  customerPropertyValue?: number
  customerProduct?: string
  productPretty?: string
}
export interface ExitingAssetModel {
  customerAssetId?: string
  customerStatus?: string
  displayName: string
  assetGlobalId: string
  globalId?: string
  includedInAnotherOpenRequest?: boolean
  ignoresFundingCalculations?: boolean
  payoffAmountSenior?: number
  payoffAmountMezz?: number
  payoffAmount?: number
  seniorFacility: FacilityModel
}
export interface CalcAgentReportFacility {
  name: string
  globalId: string
  logo?: string
  isSetpoint: boolean
  calcAgentInputFileSchema?: {
    [k: string]: CalcAgentInputFileConfig[]
  }
  calcAgentInputModalSchema?: {
    [k: string]: CalcAgentInputModalConfig[]
  }
  calcAgentReportOptions?: Option[]
}
export interface CalcAgentInputFileConfig {
  fileType: CalcAgentInputFileType
  label: string
  fileKey: string
  description?: string
  acceptedFileTypes?: {
    [k: string]: string[]
  }
  inputKeyDependencies?: string[]
  fundingDateInputKey?: string
  multiple?: boolean
}
export interface CalcAgentInputModalConfig {
  label: string
  inputKey: string
  inputType: CalcAgentInputModalType
  modelField?: CalcAgentInputModelField
  description?: string
  reportTypes?: CalcAgentReportType[]
  reportApprovedOnly?: boolean
  allowNullForInitialReport?: boolean
}
export interface CalcAgentReportFile {
  filename: string
  presignedUrl: string
  fileType?: CalcAgentInputFileType
}
export interface CalcAgentReportListModel {
  validationErrors?: CalcAgentValidationError[]
  facility: SlimFacilityModel
  globalId: string
  reportType: CalcAgentReportType
  reportName: string
  status: CalcAgentStatus
  reviewStatus: CalcAgentReviewStatus
  productCategory: CalcAgentProductCategory
  reportTypeName: string
  month?: number
  year?: number
  effectiveDate?: string
  verifiedCount?: number
  disputedCount?: number
  debugCalcAgentReportId?: number
  errorMessage?: string
}
export interface CalcAgentValidationError {
  missingSheets?: string[]
  missingFields: string[]
  invalidFields: string[]
  invalidFieldsMessages?: string[]
  rowNum?: number
  cell?: string
  fileDisplayName?: string
  sheetDisplayName?: string
  duplicateKeys?: string[]
}
export interface CalcAgentReportModel {
  validationErrors?: CalcAgentValidationError[]
  facility: SlimFacilityModel
  globalId: string
  reportType: CalcAgentReportType
  reportName: string
  status: CalcAgentStatus
  reviewStatus: CalcAgentReviewStatus
  productCategory: CalcAgentProductCategory
  reportTypeName: string
  month?: number
  year?: number
  effectiveDate?: string
  verifiedCount?: number
  disputedCount?: number
  debugCalcAgentReportId?: number
  errorMessage?: string
  reviews: CalcAgentReviewModel[]
  timeline: (CalcAgentTimelineModel | TimelineModel)[]
  inputCalcAgentReports: CalcAgentReportInputReportModel[]
  setpointManualReview: boolean
  originatorLogo?: string
  isApprovedBySetpoint?: boolean
  isApprovedByBorrower?: boolean
}
export interface CalcAgentReviewModel {
  globalId: string
  accountMember: AccountMemberAccountModel
  submittedToLenderAt?: string
  approvedAt?: string
  fundedAt?: string
  rejectedAt?: string
  status: CalcAgentReviewStatus
}
export interface CalcAgentTimelineModel {
  accountMember?: AccountMemberAccountModel
  addedAt: string
  message?: string
  comment?: string
  globalId: string
  type: CalcAgentTimelineType
  summaryComment?: string
}
export interface TimelineModel {
  accountMember?: AccountMemberAccountModel
  addedAt: string
  message?: string
  comment?: string
  globalId: string
  type?: unknown
}
export interface CalcAgentReportInputReportModel {
  inputReportGlobalId: string
  inputReportName: string
  description: string
}
export interface CalcAgentReportProgress {
  progressPercentage: number
  message: string
}
export interface CalcAgentReportWaiverModel {
  globalId: string
  assetId: string
  displayName: string
  takesEffectDate: string
  expirationDate?: string
  inEffect: boolean
  calculationType?: ConcentrationLimitCalculationType
  customWaiverType?: CustomWaiverType
}
export interface CalcAgentReportWithComment {
  report: CalcAgentReportListModel
  comment?: CalcAgentTimelineModel
  logo?: string
  customerName?: string
}
export interface CalcAgentUpcomingAssetsBody {
  facilityGlobalId: string
  fundingDate: string
  calcAgentReportGlobalId?: string
}
export interface CalcAgentUpcomingAssetsResponse {
  enteringAssets: EnteringAssetModel[]
}
export interface FacilityWithAuditLogs {
  facilityName: string
  facilityGlobalId: string
  inputValues: AuditLogModel[]
}
export interface AuditLogModel {
  changedBy?: AccountMemberModel
  changedByAccount?: SlimAccountModel
  action: Action
  changedType: TrackedChanges
  changedAt: string
  changeSummary: string
}
export interface FacilityWithInputs {
  facilityName: string
  facilityGlobalId: string
  inputValues: (
    | FacilityInputPayloadModel
    | FacilityInputDateModel
    | FacilityInputMoneyModel
    | FacilityInputIntModel
    | FacilityInputFloatModel
    | FacilityInputPercentageModel
  )[]
}
export interface GetBorrowingRequestModalConfigResponse {
  borrowingRequestModalConfig: CalcAgentBorrowingRequestModalConfig[]
  inputErrors?: {
    [k: string]: string[]
  }
}
export interface CalcAgentBorrowingRequestModalConfig {
  label: string
  inputKey?: string
  interfaceField?: string
  inputType?: CalcAgentBorrowingRequestModalType
  inputIncrement?: number
  inputMinimum?: number
  inputMaximum?: number
  inputDefault?: number | string
  inputCurrency?: CalcAgentCurrencyType
  inputDisabled?: boolean
  description?: string
  displayValue?: string
  resetValue?: number
}
export interface GetCalcAgentAccountsResponse {
  accounts: Option[]
}
export interface GetCalcAgentAuditLogResponse {
  facilitiesWithLogs: FacilityWithAuditLogs[]
}
export interface GetCalcAgentComposableTableResponse {
  tableKey: string
  composableTableData?: CalcAgentComposableTableData
}
export interface CalcAgentComposableTableData {
  headers: ComposableTableHeader[]
  data: {
    [k: string]: unknown
  }[]
}
export interface ComposableTableHeader {
  label: string
  key: string
  browserFormatter: BrowserFormatter
  alignRight: boolean
}
export interface GetCalcAgentFacilitySettingsResponse {
  facilitiesWithInputs: FacilityWithInputs[]
}
export interface GetCalcAgentFacilityWaiverOptionsResponse {
  waiverOptions: Option[]
}
export interface GetCalcAgentInputReportOptionsResponse {
  reportOptions: Option[]
}
export interface GetCalcAgentIntegrationInputStatusResponse {
  integrationStatus: IntegrationStatus
}
export interface IntegrationStatus {
  status: boolean
  assetCount?: number
}
export interface GetCalcAgentReportDownloadResponse {
  inputFiles: CalcAgentReportFile[]
  outputFiles: CalcAgentReportFile[]
  attachedFiles: CalcAgentReportFile[]
  lenderSubmittedFiles: CalcAgentReportFile[]
  generatingReports?: boolean
}
export interface GetCalcAgentReportEnteringExitingAssetsResponse {
  enteringAssets?: EnteringAssetModel[]
  exitingAssets?: ExitingAssetModel[]
}
export interface GetCalcAgentReportFacilitiesResponse {
  facilities: CalcAgentReportFacility[]
}
export interface GetCalcAgentReportProgressResponse {
  progress?: CalcAgentReportProgress
}
export interface GetCalcAgentReportResponse {
  calcAgentReport: CalcAgentReportModel
}
export interface GetCalcAgentReportSettingsResponse {
  settings?: (
    | FacilityInputPayloadModel
    | FacilityInputDateModel
    | FacilityInputMoneyModel
    | FacilityInputIntModel
    | FacilityInputFloatModel
    | FacilityInputPercentageModel
  )[]
}
export interface GetCalcAgentReportSummarySheetResponse {
  summarySheet?: SummarySheet
}
export interface GetCalcAgentReportVerificationDisputesResponse {
  verificationDisputes: VerificationDispute[]
  accountName: string
}
export interface VerificationDispute {
  verificationField: BaseVerificationField
}
export interface GetCalcAgentReportWaiverResponse {
  waivers: CalcAgentReportWaiverModel[]
}
export interface GetCalcAgentReportsResponse {
  calcAgentReports: CalcAgentReportWithComment[]
  isLender: boolean
}
export interface PatchCalcAgentCommentBody {
  comment?: string
}
export interface PatchCalcAgentReportManualReprocessBody {
  enteringAssetIds?: string[]
  exitingAssetIds?: string[]
}
export interface PatchCalcAgentReviewBody {
  comment?: string
  reviewStatus: CalcAgentReviewStatus
}
export interface PostCalcAgentFilemap {
  inputFiles: CalcAgentInputFileSubmission[]
  attachedFiles?: string[]
}
export interface CalcAgentInputFileSubmission {
  filename: string
  fileKey: string
  fileType: CalcAgentInputFileType
}
export interface PostCalcAgentLenderSubmissionBody {
  calcAgentReportGlobalId: string
}
export interface PostCalcAgentReportBody {
  facilityGlobalId: string
  reportType: CalcAgentReportType
  fileNameMap?: string
  enteringAssetGlobalIds?: string
  exitingAssetGlobalIds?: string
}
export interface PostReprocessCalcAgentBody {
  modalValues?: {
    [k: string]: string
  }
}
export interface PostReprocessCalcAgentResponse {
  jobId: string
  status: CalcAgentStatus
}
export interface CalculateChangeRequestResponse {
  seniorFacility: FacilityModel
  fundingDetail: ChangeRequestFundingDetail
  partialFunding: boolean
  canOpenChangeRequest: boolean
}
export interface ChangeRequestFundingDetail {
  advanceRateChangeSenior: number
  advanceRateChangeMezz: number
  advanceRateChangeTotal?: number
  advanceRateChangeStepUpTotal?: number
  advanceRateChangeStepDownTotal?: number
  availableToDrawSenior: number
  availableToDrawMezz?: number
  currentlyAvailableToDrawSenior: number
  currentlyAvailableToDrawMezz?: number
  amountFundedSenior: number
  amountFundedMezz: number
  amountFundedTotal?: number
  enteringAmountSenior: number
  enteringAmountMezz: number
  enteringAmountTotal?: number
  endingBalanceSenior: number
  endingBalanceMezz?: number
  payoffAmountSenior: number
  payoffAmountMezz: number
  payoffAmountTotal?: number
  remittancesTotal?: number
  payoffExcessAmountSenior: number
  payoffExcessAmountMezz: number
  payoffExcessAmountTotal?: number
  payoffExcessAmountOverageTotal?: number
  payoffExcessAmountShortageTotal?: number
  fundingParty?: AccountType
  partialFunding: boolean
  pendingFundingSenior: number
  pendingFundingMezz: number
  pendingFundingRequests: ChangeRequestPendingFunding[]
  reserveAccountBalance?: number
  reserveBalanceRequired?: number
  reserveTrueUp?: number
  drawFee?: number
  totalPledgedCollateral?: number
  netFundedBorrowingBase?: number
  unfundedBalanceAvailable?: number
  enteringAssets: EnteringAssetModel[]
  advanceChangeAssets: AdvanceChangeAssetModel[]
  exitingAssets: ExitingAssetModel[]
  payoffExcesses: AssetPayoffExcessModel[]
  remittances: RemittanceModel[]
}
export interface ChangeRequestPendingFunding {
  fundingDate: string
  globalId: string
}
export interface AssetPayoffExcessModel {
  customerAssetId?: string
  customerStatus?: string
  displayName: string
  assetGlobalId: string
  globalId?: string
  includedInAnotherOpenRequest?: boolean
  ignoresFundingCalculations?: boolean
  payoffAmount: number
  amountPaid: number
  excessOwed: number
  payoffDate: string
  facility: FacilityModel
  facilityType: FacilityType
}
export interface CalculateConcentrationLimitChangesResponse {
  concentrationLimitChanges: ChangeRequestConcentrationLimitChange[]
}
export interface ChangeRequestConcentrationLimitChange {
  facilityConcentrationLimitGlobalId: string
  calculationName: string
  calculationType: ConcentrationLimitCalculationType
  limitType: ConcentrationLimitType
  limitValue?: number
  actualBefore?: number
  actualPercentBefore?: number
  totalBefore?: number
  statusBefore?: FacilityComplianceStatus
  actualAfter?: number
  actualPercentAfter?: number
  totalAfter?: number
  statusAfter?: FacilityComplianceStatus
  actualBrowserFormatter?: BrowserFormatter
}
export interface ChangeRequestAssetSearchBody {
  facilityGlobalId: string
  limit?: number
  searchTerm?: string
  transitionType?: ChangeCategory & string
  fundingDate?: string
}
export interface ChangeRequestAssetSearchResponse {
  searchAssets: (EnteringAssetModel | ExitingAssetModel)[]
}
export interface ChangeRequestBody {
  facilityGlobalId: string
  fundingDate?: string
  enteringAssets: EnteringAssetModel[]
  advanceChangeAssets: AdvanceChangeAssetModel[]
  exitingAssets: ExitingAssetModel[]
}
export interface ChangeRequestFundingDetailModel {
  fundingDetail?: ChangeRequestFundingDetail
  concentrationLimitChanges?: ChangeRequestConcentrationLimitChange[]
}
export interface ChangeRequestUpcomingBody {
  facilityGlobalId: string
  fundingDate: string
}
export interface ChangeRequestUpcomingResponse {
  advanceChangeAssets: AdvanceChangeAssetModel[]
  enteringAssets: EnteringAssetModel[]
  exitingAssetCount: number
}
export interface CreateChangeRequestBody {
  facilityGlobalId: string
  fundingDate: string
  enteringAssets: EnteringAssetModel[]
  advanceChangeAssets: AdvanceChangeAssetModel[]
  exitingAssets: ExitingAssetModel[]
  note: string
}
export interface CreateChangeRequestResponse {
  changeRequestGlobalId: string
}
export interface ExportAssetReserveAmountsReport {
  style_definitions?: {
    [k: string]: unknown
  }
  highlight_row_color?:
    | "gray"
    | "yellow"
    | "sp-green"
    | "green-lite"
    | "blue-lite"
    | "white"
  asset: string
  asset_id?: string
  reserve_amount: number
}
export interface GetChangeRequestCountsResponse {
  changeRequestCounts: {
    [k: string]: number
  }
}
export interface GetChangeRequestFundingDetailResponse {
  changeRequestFundingDetail: ChangeRequestFundingDetailModel
  partialFunding: boolean
  isFundingParty: boolean
  canSubmitFundingDetails: boolean
}
export interface GetChangeRequestListResponse {
  changeRequests?: ChangeRequestListModel[]
}
export interface ChangeRequestListModel {
  account: AccountModel
  effectiveFundingDate?: string
  fedRefNumber?: string
  wireAmount?: number
  facility: FacilityModel
  fundingDate: string
  globalId: string
  isPendingFunding: boolean
  openedByAccountMember: AccountMemberModel
  status: ChangeRequestStatus
  assetReserveAmountsCount?: number
}
export interface GetChangeRequestResponse {
  changeRequest: ChangeRequestModel
}
export interface PatchChangeRequestCloseBody {
  comment?: string
}
export interface PatchChangeRequestCommentBody {
  comment: string
}
export interface PatchChangeRequestFedRefNumberBody {
  fedRefNumber: string
}
export interface PatchChangeRequestFundingDetailBody {
  effectiveDate: string
  wireAmount: number
}
export interface PatchChangeRequestReviewBody {
  comment?: string
  reviewStatus: ChangeRequestReviewStatus
}
export interface UpdateChangeRequestBody {
  transitionType: ChangeCategory
  assets: (EnteringAssetModel | ExitingAssetModel)[]
}
export interface GetFacilitiesComplianceRequirementsResponse {
  complianceRequirements: FacilityComplianceRequirementModel[]
  lenderView: boolean
  displayNames: string[]
}
export interface FacilityComplianceRequirementModel {
  facility: FacilityModel
  reportingPeriod: FacilityComplianceReportingPeriod
  dueDate: string
  displayName: string
  globalId: string
  fileUploads?: FileUploadModel[]
  status?: FacilityComplianceRequirementStatus
}
export interface FacilityComplianceReportingPeriod {
  cadence: FacilityComplianceRequirementCadence
  month?: number
  quarter?: number
  year: number
}
export interface GetFacilitiesComplianceRequirementsSummariesResponse {
  facilitiesComplianceRequirementsSummaries: FacilityComplianceRequirementSummaries[]
}
export interface FacilityComplianceRequirementSummaries {
  facility: FacilityModel
  facilityComplianceRequirementSummaries: {
    [k: string]: FacilityComplianceRequirementModel[]
  }
}
export interface PostFacilityComplianceRequirementFlagBody {
  flag: boolean
}
export interface FetchAssetConcentrationLimitsResponse {
  assetCalculationResults: AssetConcentrationLimitResultWithFundingImpactModel[]
  fundingEligibilityStatus: AssetFundingEligibilityStatus
  mostRecentExemptionRequest?: AssetConcentrationLimitExemptionRequestModel
}
export interface AssetConcentrationLimitResultWithFundingImpactModel {
  assetDisplayName?: string
  marketValue?: number
  meetsConditionCriteria: boolean
  rawValueForConditionEvaluation?: unknown
  stateName?: string
  cbsaName?: string
  cbsaSpecificCalculationName?: string
  cbsaMinimumPurchasePrice?: number
  cbsaMaximumPurchasePrice?: number
  hasExemption?: boolean
  excluded?: boolean
  ineligible?: boolean
  displayColumns?: {
    [k: string]: ConcentrationLimitCalculationsDisplayColumn
  }
  verificationFields?: (
    | VerificationFieldFloat
    | VerificationFieldBool
    | VerificationFieldMoney
    | VerificationFieldDate
    | VerificationFieldText
  )[]
  assetValueLabel?: string
  asset: SlimAssetModel
  concentrationLimitResult?: FacilityConcentrationLimitResultModel
  relevantNumberBeforeFunding?: number
  relevantNumberAfterFunding?: number
}
export interface SlimAssetModel {
  id?: number
  displayName: string
  globalId: string
  customerAssetId?: string
}
export interface AssetConcentrationLimitExemptionRequestModel {
  globalId: string
  status: ExemptionStatus
  requestedBy: string
  requestedAt: string
  note?: string
  reviewedBy?: string
  reviewedAt?: string
}
export interface FetchConcentrationLimitDetailBody {
  facilityGlobalId?: string
  calculationType?: ConcentrationLimitCalculationType
  isScenario?: boolean
  viewDate?: string
  assetFilter?: unknown
  resultLimit?: number
  searchTerm?: string
}
export interface FetchConcentrationLimitsBody {
  facilityGlobalId?: string
  viewDate?: string
  assetFilter?: unknown
}
export interface FetchConcentrationLimitsResponse {
  responseType?: "lender" | "originator"
  calculationAggregation?:
    | "market_value"
    | "repurchase_price"
    | "mixed"
    | "withdrawal_amount"
  calculationResults: FacilityConcentrationLimitResultModel[]
}
export interface FetchExemptionRequestsResponse {
  exemptionRequests?: AssetConcentrationLimitExemptionModel[]
}
export interface AssetConcentrationLimitExemptionModel {
  asset: AssetModel
  facility: FacilityModel
  exemptionRequest: AssetConcentrationLimitExemptionRequestModel
}
export interface AssetModel {
  id?: number
  displayName: string
  globalId: string
  customerAssetId?: string
  borrowingBaseCalculationDate?: string
  enteredBorrowingBase?: string
  product?: ProductType
  productPretty?: string
}
export interface GetCalcAgentFacilityConcentrationLimitDetailResponse {
  calcAgentAssetConcentrationLimitResults: (
    | CalcAgentAssetConcentrationLimitResultModel
    | BaseAssetConcentrationLimitResultModel
  )[]
  concentrationLimitDetails?: unknown
  additionalCbsaConditionColumnsToDisplay?: ("MSA Min" | "MSA Max")[]
  counts?: FetchAssetCount
  generatingAssetResults?: boolean
}
export interface CalcAgentAssetConcentrationLimitResultModel {
  assetDisplayName?: string
  marketValue?: number
  meetsConditionCriteria: boolean
  rawValueForConditionEvaluation?: unknown
  stateName?: string
  cbsaName?: string
  cbsaSpecificCalculationName?: string
  cbsaMinimumPurchasePrice?: number
  cbsaMaximumPurchasePrice?: number
  hasExemption?: boolean
  excluded?: boolean
  ineligible?: boolean
  displayColumns?: {
    [k: string]: ConcentrationLimitCalculationsDisplayColumn
  }
  verificationFields?: (
    | VerificationFieldFloat
    | VerificationFieldBool
    | VerificationFieldMoney
    | VerificationFieldDate
    | VerificationFieldText
  )[]
  assetValueLabel?: string
  calcAgentReportId?: number
  projectedAssetDisplayName?: string
  waived?: boolean
  assetCalcId?: number
}
export interface BaseAssetConcentrationLimitResultModel {
  assetDisplayName?: string
  marketValue?: number
  meetsConditionCriteria: boolean
  rawValueForConditionEvaluation?: unknown
  stateName?: string
  cbsaName?: string
  cbsaSpecificCalculationName?: string
  cbsaMinimumPurchasePrice?: number
  cbsaMaximumPurchasePrice?: number
  hasExemption?: boolean
  excluded?: boolean
  ineligible?: boolean
  displayColumns?: {
    [k: string]: ConcentrationLimitCalculationsDisplayColumn
  }
  verificationFields?: (
    | VerificationFieldFloat
    | VerificationFieldBool
    | VerificationFieldMoney
    | VerificationFieldDate
    | VerificationFieldText
  )[]
  assetValueLabel?: string
}
export interface FetchAssetCount {
  compliantResult?: number
  nonCompliantResult?: number
  excludedFromCalcResult?: number
  disputed?: number
  waived?: number
}
export interface GetCalcAgentFacilityTestResultResponse {
  facilityTestResult: FacilityConcentrationLimitResultModel
}
export interface GetFacilityConcentrationLimitDetailResponse {
  assetConcentrationLimitResults: (
    | AssetConcentrationLimitResultModel
    | BaseAssetConcentrationLimitResultModel
  )[]
  concentrationLimitDetails?: unknown
  additionalCbsaConditionColumnsToDisplay?: ("MSA Min" | "MSA Max")[]
  counts: FetchAssetCount
}
export interface AssetConcentrationLimitResultModel {
  assetDisplayName?: string
  marketValue?: number
  meetsConditionCriteria: boolean
  rawValueForConditionEvaluation?: unknown
  stateName?: string
  cbsaName?: string
  cbsaSpecificCalculationName?: string
  cbsaMinimumPurchasePrice?: number
  cbsaMaximumPurchasePrice?: number
  hasExemption?: boolean
  excluded?: boolean
  ineligible?: boolean
  displayColumns?: {
    [k: string]: ConcentrationLimitCalculationsDisplayColumn
  }
  verificationFields?: (
    | VerificationFieldFloat
    | VerificationFieldBool
    | VerificationFieldMoney
    | VerificationFieldDate
    | VerificationFieldText
  )[]
  assetValueLabel?: string
  asset: SlimAssetModel
}
export interface RequestExemptionBody {
  assetGlobalId: string
  note: string
}
export interface ReviewExemptionRequestBody {
  exemptionRequestGlobalId: string
  reviewOutcome: ExemptionStatus
}
export interface AssociateCustomerTransactionsBody {
  transactionGlobalIds: string[]
  requiredExplicitApproval: boolean
}
export interface CustomerBankTransactionsResponse {
  transactions: CustomerBankTransactionModel[]
}
export interface CustomerBankTransactionModel {
  globalId: string
  state: CustomerBankTransactionState
  amount: number
  settledAt: string
  description: string
  externalTransactionId: string
  customerInvoiceGlobalId?: string
}
export interface CustomerInvoicesResponse {
  invoices: CustomerInvoiceModel[]
}
export interface CustomerInvoiceModel {
  globalId: string
  state: CustomerInvoiceState
  externalIdentifier: string
  receivedAt: string
  date: string
  amount: number
  invoiceItems: CustomerInvoiceItemModel[]
}
export interface CustomerInvoiceItemModel {
  globalId: string
  displayName: string
  date: string
  amount: number
}
export interface GetEntityDataValidationResponse {
  entityDataValidations: EntityDataValidationModel[]
}
export interface BaseSupersetParser {
  account_id?: number
  raw_committed_aggregator_facility?: string
  raw_setpoint_capital_facility?: string
  committed_aggregator_facility_id?: number
  setpoint_capital_facility_id?: number
}
export interface DiligenceReportModel {
  name: string
  globalId: string
  publishedAt: string
  username: string
  userImage?: string
}
export interface EntityDiligenceCalculationModel {
  globalId: string
  type: RTLCalculationType
  displayName: string
  value?: number | string
  valueType: PolymorphicValueType
}
export interface ExportDiligenceAggregatorReportBody {
  aggregatorGlobalId: string
  originatorGlobalId?: string
  customInputs?: string[]
  startDate?: string
  endDate?: string
  tag?: string
  status?: DocumentAssetQueueFilterType
  persistReport?: boolean
}
export interface ExportDiligenceAggregatorReportResponse {
  jobId: string
  status: ExportReportStatus
  downloadUrl?: string
  persistReport?: boolean
  aggregatorAccountName?: string
}
export interface ExportDiligenceReportBody {
  accountGlobalId: string
  facilityGlobalId: string
  lookbackDays?: number
  customInputs?: string[]
  tag?: string
  persistReport?: boolean
}
export interface ExportDiligenceReportResponse {
  jobId: string
  status: ExportReportStatus
  downloadUrl?: string
  persistReport?: boolean
  originatorAccountName?: string
  aggregatorAccountName?: string
}
export interface ExportSFRDiligenceReportBody {
  accountGlobalId: string
  facilityGlobalId?: string
  customInputs?: string[]
  startDate?: string
  endDate?: string
  tag?: string
  status?: DocumentAssetQueueFilterType
  persistReport?: boolean
}
export interface GetDiligenceAccountFacilitiesResponse {
  facilities: Option[]
}
export interface GetDiligenceAccountsResponse {
  accounts: Option[]
}
export interface GetDiligenceAggregatorOriginatorsResponse {
  originators: Option[]
}
export interface GetDiligenceQueueFacilitiesResponse {
  originators: Option[]
  aggregators: Option[]
  facilities: Option[]
}
export interface GetDiligenceReportDownloadUrlResponse {
  downloadUrl: string
}
export interface GetDiligenceReportsResponse {
  diligenceReports: DiligenceReportModel[]
}
export interface GetEntityDiligenceCalculationResponse {
  calculations: EntityDiligenceCalculationModel[]
  entityType: EntityType
}
export interface GetLoanTapeParsersResponse {
  parsers: ParserOption[]
}
export interface ParserOption {
  label: string
  value: string
  isDisabled?: boolean
  isDefault?: boolean
  exampleUrl?: string
}
export interface GetLoanTapeTagsResponse {
  tags: Option[]
}
export interface GetDocumentAssetQueueBody {
  queueType?: DocumentAssetQueueType & string
  filterType: DocumentAssetQueueFilterType
  assetSearchTerm?: string
  facilityGlobalId?: string
}
export interface GetRTLDocumentAssetQueueResponse {
  queueItems?: RTLDocumentAssetQueueItem[]
}
export interface RTLDocumentAssetQueueItem {
  displayName: string
  assetGlobalId: string
  customerAssetId?: string
  accountName: string
  facilityName: string
  facilityAccountId: number
  facilityGlobalId: string
  lenderAccountName?: string
  lenderAccountId?: number
  itemsTotal: number
  itemsComplete: number
  checksTotal: number
  checksComplete: number
  exceptionCount: number
  pendingCount?: number
  disputeCount: number
  isHiddenFromReview: boolean
  isTerminated: boolean
  category?: DiligenceCategory & string
  boxSyncFolderId?: string
  rtlPropertiesCount: number
  grades?: AssetGradeModel[]
  guidelinePassCount?: number
  guidelinePendingCount?: number
  guidelineWaivedCount?: number
  guidelineFailCount?: number
  totalGuidelines?: number
  validationPassCount?: number
  validationFailCount?: number
  validationPendingCount?: number
  validationWaivedCount?: number
  totalValidations?: number
  borrowingEntityName?: string
  flags: AssetFlagModel[]
  loanId?: string
  dueDate?: string
  hasRecentUpdates?: boolean
  recentUpdateChanges?: string
  mostRecentDocUpload?: string
  lastReviewedAt?: string
  lastReviewedBy?: string
  mostRecentCommentAt?: string
}
export interface GetRTLDocumentAssetQueueSummaryResponse {
  summaries: RTLDocumentAssetQueueSummary[]
}
export interface RTLDocumentAssetQueueSummary {
  assetFlagName?: string
  diligenceDueDate?: string
  isCleared?: boolean
  facilityGlobalId?: string
  rtlPropertiesCount?: number
  lenderAccountId?: number
  facilityName?: string
  facilityAccountId?: number
  facilityAccountName?: string
  reviewsNotStarted?: number
  reviewsInProgress?: number
  reviewsException?: number
  reviewsComplete?: number
  reviewsTotal?: number
}
export interface GetSFRDocumentAssetQueueResponse {
  queueItems?: SFRDocumentAssetQueueItem[]
}
export interface SFRDocumentAssetQueueItem {
  displayName: string
  assetGlobalId: string
  customerAssetId?: string
  accountName: string
  facilityName: string
  facilityAccountId: number
  facilityGlobalId: string
  lenderAccountName?: string
  lenderAccountId?: number
  itemsTotal: number
  itemsComplete: number
  checksTotal: number
  checksComplete: number
  exceptionCount: number
  pendingCount?: number
  disputeCount: number
  isHiddenFromReview: boolean
  isTerminated: boolean
  category?: DiligenceCategory & string
  boxSyncFolderId?: string
  borrowingEntityName?: string
  flags: AssetFlagModel[]
  loanId?: string
  dueDate?: string
}
export interface GetSFRDocumentAssetQueueSummaryResponse {
  summaries: SFRDocumentAssetQueueSummary[]
}
export interface SFRDocumentAssetQueueSummary {
  assetFlagName?: string
  diligenceDueDate?: string
  itemsTotal: number
  itemsComplete: number
  checksTotal: number
  checksComplete: number
  exceptionCount: number
  disputeCount: number
  validationTotal: number
  isCleared: boolean
  facilityGlobalId: string
  lenderAccountId?: number
  facilityName: string
  facilityAccountId: number
  facilityAccountName: string
}
export interface PostAddRTLPropertyBody {
  assetGlobalId: string
  address: string
  city: string
  state: string
  postalCode: string
}
export interface GetDocumentReviewContextResponse {
  documents?: FundingRequirementModel[]
  notes: DocumentReviewNote[]
  passFail: PassFailCheck[]
  primaryDocumentParentGlobalId?: string
  secondaryDocumentParentGlobalId?: string
  diligenceFilterType: DiligenceFilterType
}
export interface PassFailCheck {
  global_id: string
  name: string
  pass_fail?: DocumentReviewStatus
  subtext?: string
}
export interface GetDocumentViewUrlResponse {
  view_url: string
  filename: string
}
export interface GetDocumentsQueueBody {
  escalatedPriority: boolean
  searchTerm: string
  fetchRtlDocs?: boolean
  facilityAccountGlobalId?: string
  filterType?: DocumentQueueFilterType & string
}
export interface GetDocumentsQueueResponse {
  documents: QueueDocument[]
}
export interface QueueDocument {
  accountName: string
  assetGlobalId?: string
  buyerGroupGlobalId?: string
  closeDate?: string
  globalId: string
  name: string
  referenceName: string
  rtlPropertyGlobalId?: string
  surfaceOnDocumentQueueAt: string
  queueItemType?: "document"
  fundingRequirementCategory: string
  reviewSubmittedAt?: string
  reviewSubmittedBy?: string
  customerAssetId?: string
  flags?: AssetFlagModel1[]
  rtlPropertyCount?: number
}
/**
 * Note this is a copy of a model to get around a circular import
 */
export interface AssetFlagModel1 {
  globalId: string
  name: string
  internalOnly: boolean
}
export interface SaveDocumentReviewBody {
  appraisal?: number
  appraisalDate?: string
  assetGlobalId?: string
  bpoAmount?: number
  bpoDate?: string
  interiorBpoAmount?: number
  interiorBpoDate?: string
  borrowerLiquidity?: number
  buyerGroupGlobalId?: string
  closeDate?: string
  closingCosts?: number
  commitmentDate?: string
  creditScore?: string
  debtToIncome?: number
  downPayment?: number
  earnestMoneySent?: number
  externalValuationAmount?: number
  externalValuationType?: ExternalValuationType
  fundingAmount?: number
  fundingRequirementGlobalId?: string
  grossPurchasePrice?: number
  income?: number
  insuranceAmount?: number
  insuranceEndDate?: string
  insuranceStartDate?: string
  internalValuation?: number
  leaseEndDate?: string
  leaseStartDate?: string
  loanMaturityDate?: string
  loanOriginationDate?: string
  loanToValue?: number
  monthlyRent?: number
  mortgageAmount?: number
  mortgageDate?: string
  netPurchasePrice?: number
  numberOfBathrooms?: number
  numberOfBedrooms?: number
  opendoorServiceCharge?: number
  opendoorCommission?: number
  opendoorSellerCredit?: number
  opendoorBuyerCredit?: number
  optionPrice?: number
  postCloseBpo?: number
  postCloseBpoDate?: string
  preCloseAppraisal?: number
  preClosePurchasePrice?: number
  preApprovalAmount?: number
  propertyTax?: number
  purchaseDate?: string
  rehabCosts?: number
  repairsDate?: string
  reserves?: number
  reviewNote?: string
  reviewedRequirements?: ReviewedRequirement[]
  rtlPropertyGlobalId?: string
  setpointBpoAmount?: number
  subscriptionFee?: number
  taxId?: string
  verifiedPurchasePrice?: number
  vestingEntity?: string
  conrexBuyingEntity?: ConrexBuyingEntity | string
  hazardInsuranceCoverageAmount?: number
  hazardInsuranceStartDate?: string
  hazardInsuranceEndDate?: string
  inFloodZone?: YesNo
  floodZoneCode?: string
  floodInsuranceCoverageAmount?: number
  floodInsuranceStartDate?: string
  floodInsuranceEndDate?: string
  homewardProgramFee?: number
  homewardListingCredit?: number
  homewardCommissions?: number
  constructionDrawAmount1?: number
  constructionDrawDate1?: string
  constructionDrawAmount2?: number
  constructionDrawDate2?: string
  constructionDrawAmount3?: number
  constructionDrawDate3?: string
  rtlLoanApplicationDate?: string
  rtlLoanApplicationDated?: YesNo
  rtlLoanApplicationMembers?: string
  rtlLoanApplicationSignedExecuted?: YesNo
  rtlLoanBorrowersAddress?: string
  rtlLoanBorrowersPhone?: string
  rtlLoanBorrowersEmail?: string
  rtlBorrowerExperienceBucket?: RTLBorrowerExperienceBucket
  rtlBorrowerExperienceNumber?: number
  rtlTrackRecordDollars?: number
  rtlDepositAccountStatements?: number
  rtlStockAccountStatements?: number
  rtlCashBackOnHud?: number
  rtlHudFlag?: HUDFlag
  rtlHudDate?: string
  rtlHudAssignmentFee?: number
  rtlHudInterestReserve?: number
  rtlHudPurchasePrice?: number
  rtlHudGrossPurchasePrice?: number
  rtlBorrowerContribution?: number
  rtlTitleType?: TitleType
  rtlPropertyLocatedInHoa?: YesNo
  rtlHoaName?: string
  rtlTitleDate?: string
  rtlLoanTypeCategory?: LoanTypeCategory
  rtlCondoMasterInsuranceExpirationDate?: string
  rtlSellerName?: string
  rtlLoanAmount?: number
  rtlLoanAgreementOriginationDate?: string
  rtlLoanAgreementMaturityDate?: string
  rtlDeedType?: DeedType
  rtlVesting?: string
  rtlNoteAmount?: number
  rtlOriginatorName?: string
  rtlHoldbackAmount?: number
  rtlOriginationDate?: string
  rtlMaturityDate?: string
  rtlRateType?: RateType
  rtlInterestRate?: number
  rtlArmIndex?: string
  rtlArmMargin?: number
  rtlInterestRateFloor?: number
  rtlInterestRateCeiling?: number
  rtlFirstRateChangeDate?: string
  rtlNextRateChangeDate?: string
  rtlRollFrequency?: string
  rtlPrePaymentPenalty?: YesNo
  rtlPrePaymentPenaltyMonths?: number
  rtlPrePaymentPenaltyStructure?: string
  rtlDefaultInterestRate?: number
  rtlLoanTerm?: number
  rtlPaymentAmount?: number
  rtlAccrualType?: AccrualType
  rtlIsDutch?: Dutch
  rtlFirstPaymentDate?: string
  rtlLastPaymentDate?: string
  rtlLateFee?: string
  rtlLateFeeGraceDays?: number
  rtlBuiltInExtension?: YesNo
  rtlCrossDefaultProvisions?: YesNo
  rtlEventDefaultGraceDays?: number
  rtlCrossCollateralLoan?: YesNo
  rtlReleasePriceProvided?: YesNo
  rtlReleasePrice?: number
  rtlNoteFirstReview?: string
  rtlUsForeignEntity?: USForeign
  rtlIncorporationEntityName?: string
  rtlDateOfIncorporation?: string
  rtlEinTaxIdNumber?: string
  rtlOperatingEntityName?: string
  rtlOfacReportDate?: string
  rtlContractorLicenseInfo?: YesNo
  rtlContractorName?: string
  rtlContractorLicenseNumber?: string
  rtlGuarantyAmount?: number
  rtlGuarantyType?: GuarantyType
  rtlFullRecourse?: YesNo
  rtlPurchaseAgreementDocumentType?: string
  rtlGrossPurchasePrice?: number
  rtlSettlementDate?: string
  rtlEarnestMoneyDepositOnPurchase?: number
  rtlHazardPolicyNumber?: string
  rtlIsaoaAtima?: string
  rtlHazardInsuranceCoverageAmount?: number
  rtlHazardInsuranceStartDate?: string
  rtlHazardInsuranceEndDate?: string
  rtlHazardProofOfPayment?: YesNo
  rtlHazardPolicyType?: HazardPolicyType
  rtlHazardPolicyCarrier?: string
  rtlHazardPolicyDeductible?: number
  rtlHazardPolicyPremium?: number
  rtlGeneralLiabilityCoverageAmount?: number
  rtlGeneralLiabilityStartDate?: string
  rtlGeneralLiabilityEndDate?: string
  rtlInFloodZone?: YesNo
  rtlFloodZoneCode?: string
  rtlFloodInsuranceCoverageAmount?: number
  rtlFloodInsuranceStartDate?: string
  rtlFloodInsuranceEndDate?: string
  rtlFloodInsuranceCarrier?: string
  rtlFloodInsuranceDeductible?: number
  rtlFloodInsurancePremium?: number
  rtlReplacementCostCoverageAmount?: number
  rtlValuationType?: RTLValuationType
  rtlNumberOfUnits?: number
  rtlRealEstateTaxes?: number
  rtlRealEstateTaxesYear?: number
  rtlOccupied?: OccupiedType
  rtlHoaFees?: number
  rtlLocation?: LocationType
  rtlMarketCondition?: MarketCondition
  rtlZoningCompliance?: ZoningCompliance
  rtlPropertyType?: RTLPropertyType
  rtlNymtPropertyType?: RTLNYMTPropertyType
  rtlAnnualizedGrossIncome?: number
  rtlConditionRating?: ConditionRating
  rtlDeferredMaintenanceYN?: YesNo
  rtlDeferredMaintenanceAmount?: number
  rtlAsIsAppraisedValue?: number
  rtlAsRepairedAppraisedValue?: number
  rtlAppraisalDate?: string
  rtlNumberOfSalesComps?: number
  rtlMold?: YesNo
  rtlPhotosOfSalesComps?: YesNo
  rtlBuildingSketch?: YesNo
  rtlLocationMap?: YesNo
  rtlAppraiserAttachedLicenseInfo?: YesNo
  rtlAppraiserAttachedInsuranceInfo?: YesNo
  rtlPostRehabSqFt?: number
  rtlRelianceLanguage?: YesNo
  rtlSalesCompProximity?: YesNo
  rtlSalesCompTolerance?: YesNo
  rtlAppraisalCert?: YesNoNA
  rtlAppraisalExecuted?: YesNoNA
  rtlAppraisalComplianceAddendum?: YesNo
  rtlReconValueProvided?: YesNo
  rtlReconValueDate?: string
  rtlAsIsReconValue?: number
  rtlAsRepairedReconValue?: number
  rtlRepairBudgetAmount?: number
  sfrPurchaseAgreementDocumentType?: PurchaseAgreementType
  sfrPurchaseAgreementWrongDocument?: string
  sfrGrossPurchasePrice?: number
  sfrSettlementDate?: string
  sfrPropertyOccupied?: YesNo
  sfrLeaseWrongDocument?: string
  sfrLeaseStartDate?: string
  sfrLeaseEndDate?: string
  sfrLeaseRent?: number
  sfrLeaseSection8?: YesNo
  sfrDeedWrongDocument?: string
  sfrVesting?: string
  sfrTitleWrongDocument?: string
  sfrTitleType?: SFRTitleType
  sfrTitleIssues?: SFRTitleIssues
  sfrTitleIssueComments?: string
  sfrTitleHoa?: YesNo
  sfrHoaType?: SFRTitleHOAOptions
  sfrHoaName?: string
  sfrBpoWrongDocument?: string
  sfrBpoAmount?: number
  sfrBpoDate?: string
  sfrBedCount?: number | string
  sfrBatchCount?: number | string
  sfrMortgageWrongDocument?: string
  sfrMortgageVesting?: string
  sfrAssignmentOfRents?: YesNo
  sfrSecurityAgreement?: YesNo
  sfrFloodCertWrongDocument?: string
  sfrFloodZone?: YesNo
  sfrFloodZoneCode?: string
  sfrOccupancyCertWrongDocument?: string
}
export interface ReviewedRequirement {
  global_id: string
  status: DocumentReviewStatus
}
export interface AdvanceRateLadder {
  label: string
  steps: AdvanceRateStep[]
}
export interface AdvanceRateStep {
  ageStart: number
  ageEnd?: number
  rate?: number
  description?: string
}
export interface AssetsSelectableResponse {
  assets: AssetToSelect[]
}
export interface AssetToSelect {
  globalId: string
  status?: string
  displayName?: string
  facility?: string
  lessee?: string
  country?: string
  initialAmount?: number
  isDefault?: boolean
}
export interface GetFacilityCalcAgentReportWaiverResponse {
  waivers: FacilityCalcAgentReportWaiverModel[]
}
export interface FacilityCalcAgentReportWaiverModel {
  globalId: string
  assetId: string
  displayName: string
  takesEffectDate: string
  expirationDate?: string
  inEffect: boolean
  calculationType?: ConcentrationLimitCalculationType
  customWaiverType?: CustomWaiverType
  facilityName: string
}
export interface GetFacilityEditOptions {
  fundingVehicles: FundingVehicleName[]
}
export interface GetFacilityOptionsResponse {
  facilities: Option[]
}
export interface GetFacilityResponse {
  name?: string
  originatorAccountName: string
  seniorFacility: SlimFacilityModel
  mezzFacility?: SlimFacilityModel
  lenderView: boolean
  checkpointDateOptions: Option[]
  showAssetSelect?: boolean
  activeCalcAgentReportGlobalId?: string
  hideMonitoringTab?: boolean
  enableBorrowingBaseDatepicker?: boolean
}
export interface GetFacilityTermsResponse {
  name: string
  lenderName: string
  originatorName: string
  structure: string
  size?: number
  fundingVehicle?: FundingVehicleName
  agreementType: FacilityAgreementType
  interestRates: InterestRateConfig[]
  transactionFees: TransactionFeeConfig[]
  advanceRates: AdvanceRateLadder[]
  requirements: FacilityFundingRequirementModel[]
  settings: FacilitySettings
  facilityReassignmentConfigs: FacilityReassignmentConfigModel[]
  inputValues: (
    | FacilityInputPayloadModel
    | FacilityInputDateModel
    | FacilityInputMoneyModel
    | FacilityInputIntModel
    | FacilityInputFloatModel
    | FacilityInputPercentageModel
  )[]
}
export interface InterestRateConfig {
  product: string
  interestRate: number
  rateFloor: number
  rateType: string
}
export interface TransactionFeeConfig {
  fee: number
  product: string
  calculationBase: string
  trigger: string
}
export interface FacilityFundingRequirementModel {
  globalId: string
  type: string
  category?: string
  isDocument?: boolean
  isRequired?: boolean
  isForDataCollection?: boolean
  children?: FacilityFundingRequirementModel[]
  displayName: string
  documentReviewText?: string
  failedReviewText?: string
  documentReviewSubtext?: string
  fileSyncRegex?: string
  actions?: FundingRequirementAction[]
  informationCollected?: string[]
  originatorCanUncheck?: boolean
  checkOptionConfig?: CheckOption[]
  calculatedThroughOtherValues?: boolean
  connectedFundingRequirementsToRefreshOnSave?: FundingRequirementType[]
}
export interface PortfolioDataResponse {
  portfolioData: PortfolioDatumModel[]
}
export interface PortfolioDatumModel {
  label: string
  value?: number
  browserFormatter: BrowserFormatter
  facilityName?: string
  category?: string
  tooltip?: string
  sortOrder?: number
}
export interface PostCalcAgentReportWaiverBody {
  facilityGlobalId: string
  assetId: string
  waiverCondition: string
  takesEffectDate: string
  expirationDate?: string
}
export interface UpdateFacilityInputValues {
  inputValues: (
    | FacilityInputPayloadModel
    | FacilityInputDateModel
    | FacilityInputMoneyModel
    | FacilityInputIntModel
    | FacilityInputFloatModel
    | FacilityInputPercentageModel
  )[]
}
export interface UpdateFacilityTerms {
  name: string
  size: number
  originationDate?: string
  fundingVehicle?: string
  drawFee?: number
  unusedFee?: number
}
export interface FetchFacilityMetricAreaChartResponse {
  values: {
    [k: string]: unknown
  }[]
  ydomain?: number[]
}
export interface FetchFacilityMetricConfigurationBody {
  facilityGlobalId: string
}
export interface FetchFacilityMetricConfigurationResponse {
  cards: Card[]
}
export interface AreaChartCard {
  metricType: ScenarioMetricType
  metricName?: string
  cardType?: FacilityMetricCardType & string
  cardWidth?: 1 | 2 | 3 | 4
  cardHeight?: 1 | 2 | 3
  axisBrowserFormatter?: BrowserFormatter & string
  tooltipBrowserFormatter?: BrowserFormatter & string
}
export interface LineChartCard {
  metricType: ScenarioMetricType
  metricName?: string
  cardType?: FacilityMetricCardType & string
  cardWidth?: 1 | 2 | 3 | 4
  cardHeight?: 1 | 2 | 3
  axisBrowserFormatter?: BrowserFormatter & string
  tooltipBrowserFormatter?: BrowserFormatter & string
  yReferenceLine?: number
  showTodayReferenceLine?: boolean
  overrideYDomain?: number[]
}
export interface ProgressBarCard {
  metricType: ScenarioMetricType
  metricName?: string
  cardType?: FacilityMetricCardType & string
  cardWidth?: 1 | 2 | 3 | 4
  cardHeight?: 1 | 2 | 3
  totalValueType?: ScenarioMetricType
  mainDatumBrowserFormatter?: BrowserFormatter & string
  valueBrowserFormatter?: BrowserFormatter & string
  totalValueBrowserFormatter?: BrowserFormatter & string
  percentageLabelFormatter?: BrowserFormatter & string
}
export interface MultiMetricCard {
  metricType: ScenarioMetricType
  metricName?: string
  cardType?: FacilityMetricCardType & string
  cardWidth?: 1 | 2 | 3 | 4
  cardHeight?: 1 | 2 | 3
  valueFormatter?: BrowserFormatter & string
  metricOrder?: string[]
}
export interface SimpleMetricCard {
  metricType: ScenarioMetricType
  metricName?: string
  cardType?: FacilityMetricCardType & string
  cardWidth?: 1 | 2 | 3 | 4
  cardHeight?: 1 | 2 | 3
  valueFormatter?: BrowserFormatter & string
  firstAuxMetric?: ScenarioMetricType
  firstAuxMetricName?: string
  firstAuxDataFormatter?: BrowserFormatter & string
  secondAuxMetric?: ScenarioMetricType
  secondAuxMetricName?: string
  secondAuxDataFormatter?: BrowserFormatter & string
}
export interface ScenarioMetricDiffCard {
  metricType: ScenarioMetricType
  metricName?: string
  cardType?: FacilityMetricCardType & string
  cardWidth?: 1 | 2 | 3 | 4
  cardHeight?: 1 | 2 | 3
  totalValueFormatter?: BrowserFormatter & string
  facilityValueFormatter?: BrowserFormatter & string
  dynamicHeight?: boolean
}
export interface FetchFacilityMetricDayValueBody {
  facilityGlobalId: string
  metricType: ScenarioMetricType
  totalMetricType?: ScenarioMetricType
  viewDate?: string
  lookBackWeeks?: number
  lookForwardWeeks?: number
  scenarioGlobalId?: string
}
export interface FetchFacilityMetricDayValueResponse {
  value?:
    | {
        [k: string]: unknown
      }
    | number
    | string
  totalValue?: number | string
}
export interface ExportActivityFeedBody {
  filters: ActivityFeedType[]
  timeframe: ActivityFeedExportTimeframe
}
export interface GetActivityFeedBody {
  filters: ActivityFeedType[]
}
export interface GetActivityFeedResponse {
  activityFeed: ActivityFeedModel[]
}
export interface CopyFundingRequirementReponse {
  updatedFundingRequirementIds: string[]
}
export interface CopyFundingRequirementReviewBody {
  sourceAssetGlobalId?: string
  destinationAssetGlobalIds: string[]
  fundingRequirementGlobalIds: string[]
  shouldCopyOwners?: boolean
}
export interface CreateAssetFundingRequirementBody {
  fundingRequirementGlobalId: string
  note: string
}
export interface GetFundingRequirementReviewResponse {
  entityDataValidations: EntityDataValidationModel[]
  notes?: DocumentReviewNote[]
  reviewFormContext?: GetDocumentReviewFormContextResponse
  tasks: FundingRequirementModel[]
}
export interface PatchLinkFundingRequirementDocument {
  targetFundingRequirementGlobalId: string
  documentFileId: string
}
export interface PatchUnlinkFundingRequirementDocument {
  currentFundingRequirementGlobalId: string
  documentFileId: string
}
export interface UpdateFundingRequirementBody {
  globalId: string
  stage?: FundingRequirementStage
  extra?: {
    [k: string]: unknown
  }
  documentFields?: CustomerReviewFormFieldBody
  waiveNote?: string
  fundingRequirementNote?: string
}
export interface CustomerReviewFormFieldBody {
  appraisal?: number
  appraisalDate?: string
  assetGlobalId?: string
  bpoAmount?: number
  bpoDate?: string
  interiorBpoAmount?: number
  interiorBpoDate?: string
  borrowerLiquidity?: number
  buyerGroupGlobalId?: string
  closeDate?: string
  closingCosts?: number
  commitmentDate?: string
  creditScore?: string
  debtToIncome?: number
  downPayment?: number
  earnestMoneySent?: number
  externalValuationAmount?: number
  externalValuationType?: ExternalValuationType
  fundingAmount?: number
  fundingRequirementGlobalId?: string
  grossPurchasePrice?: number
  income?: number
  insuranceAmount?: number
  insuranceEndDate?: string
  insuranceStartDate?: string
  internalValuation?: number
  leaseEndDate?: string
  leaseStartDate?: string
  loanMaturityDate?: string
  loanOriginationDate?: string
  loanToValue?: number
  monthlyRent?: number
  mortgageAmount?: number
  mortgageDate?: string
  netPurchasePrice?: number
  numberOfBathrooms?: number
  numberOfBedrooms?: number
  opendoorServiceCharge?: number
  opendoorCommission?: number
  opendoorSellerCredit?: number
  opendoorBuyerCredit?: number
  optionPrice?: number
  postCloseBpo?: number
  postCloseBpoDate?: string
  preCloseAppraisal?: number
  preClosePurchasePrice?: number
  preApprovalAmount?: number
  propertyTax?: number
  purchaseDate?: string
  rehabCosts?: number
  repairsDate?: string
  reserves?: number
  reviewNote?: string
  reviewedRequirements?: ReviewedRequirement
  rtlPropertyGlobalId?: string
  setpointBpoAmount?: number
  subscriptionFee?: number
  taxId?: string
  verifiedPurchasePrice?: number
  vestingEntity?: string
  conrexBuyingEntity?: ConrexBuyingEntity | string
  hazardInsuranceCoverageAmount?: number
  hazardInsuranceStartDate?: string
  hazardInsuranceEndDate?: string
  inFloodZone?: YesNo
  floodZoneCode?: string
  floodInsuranceCoverageAmount?: number
  floodInsuranceStartDate?: string
  floodInsuranceEndDate?: string
  homewardProgramFee?: number
  homewardListingCredit?: number
  homewardCommissions?: number
  constructionDrawAmount1?: number
  constructionDrawDate1?: string
  constructionDrawAmount2?: number
  constructionDrawDate2?: string
  constructionDrawAmount3?: number
  constructionDrawDate3?: string
  rtlLoanApplicationDate?: string
  rtlLoanApplicationDated?: YesNo
  rtlLoanApplicationMembers?: string
  rtlLoanApplicationSignedExecuted?: YesNo
  rtlLoanBorrowersAddress?: string
  rtlLoanBorrowersPhone?: string
  rtlLoanBorrowersEmail?: string
  rtlBorrowerExperienceBucket?: RTLBorrowerExperienceBucket
  rtlBorrowerExperienceNumber?: number
  rtlTrackRecordDollars?: number
  rtlDepositAccountStatements?: number
  rtlStockAccountStatements?: number
  rtlCashBackOnHud?: number
  rtlHudFlag?: HUDFlag
  rtlHudDate?: string
  rtlHudAssignmentFee?: number
  rtlHudInterestReserve?: number
  rtlHudPurchasePrice?: number
  rtlHudGrossPurchasePrice?: number
  rtlBorrowerContribution?: number
  rtlTitleType?: TitleType
  rtlPropertyLocatedInHoa?: YesNo
  rtlHoaName?: string
  rtlTitleDate?: string
  rtlLoanTypeCategory?: LoanTypeCategory
  rtlCondoMasterInsuranceExpirationDate?: string
  rtlSellerName?: string
  rtlLoanAmount?: number
  rtlLoanAgreementOriginationDate?: string
  rtlLoanAgreementMaturityDate?: string
  rtlDeedType?: DeedType
  rtlVesting?: string
  rtlNoteAmount?: number
  rtlOriginatorName?: string
  rtlHoldbackAmount?: number
  rtlOriginationDate?: string
  rtlMaturityDate?: string
  rtlRateType?: RateType
  rtlInterestRate?: number
  rtlArmIndex?: string
  rtlArmMargin?: number
  rtlInterestRateFloor?: number
  rtlInterestRateCeiling?: number
  rtlFirstRateChangeDate?: string
  rtlNextRateChangeDate?: string
  rtlRollFrequency?: string
  rtlPrePaymentPenalty?: YesNo
  rtlPrePaymentPenaltyMonths?: number
  rtlPrePaymentPenaltyStructure?: string
  rtlDefaultInterestRate?: number
  rtlLoanTerm?: number
  rtlPaymentAmount?: number
  rtlAccrualType?: AccrualType
  rtlIsDutch?: Dutch
  rtlFirstPaymentDate?: string
  rtlLastPaymentDate?: string
  rtlLateFee?: string
  rtlLateFeeGraceDays?: number
  rtlBuiltInExtension?: YesNo
  rtlCrossDefaultProvisions?: YesNo
  rtlEventDefaultGraceDays?: number
  rtlCrossCollateralLoan?: YesNo
  rtlReleasePriceProvided?: YesNo
  rtlReleasePrice?: number
  rtlNoteFirstReview?: string
  rtlUsForeignEntity?: USForeign
  rtlIncorporationEntityName?: string
  rtlDateOfIncorporation?: string
  rtlEinTaxIdNumber?: string
  rtlOperatingEntityName?: string
  rtlOfacReportDate?: string
  rtlContractorLicenseInfo?: YesNo
  rtlContractorName?: string
  rtlContractorLicenseNumber?: string
  rtlGuarantyAmount?: number
  rtlGuarantyType?: GuarantyType
  rtlFullRecourse?: YesNo
  rtlPurchaseAgreementDocumentType?: string
  rtlGrossPurchasePrice?: number
  rtlSettlementDate?: string
  rtlEarnestMoneyDepositOnPurchase?: number
  rtlHazardPolicyNumber?: string
  rtlIsaoaAtima?: string
  rtlHazardInsuranceCoverageAmount?: number
  rtlHazardInsuranceStartDate?: string
  rtlHazardInsuranceEndDate?: string
  rtlHazardProofOfPayment?: YesNo
  rtlHazardPolicyType?: HazardPolicyType
  rtlHazardPolicyCarrier?: string
  rtlHazardPolicyDeductible?: number
  rtlHazardPolicyPremium?: number
  rtlGeneralLiabilityCoverageAmount?: number
  rtlGeneralLiabilityStartDate?: string
  rtlGeneralLiabilityEndDate?: string
  rtlInFloodZone?: YesNo
  rtlFloodZoneCode?: string
  rtlFloodInsuranceCoverageAmount?: number
  rtlFloodInsuranceStartDate?: string
  rtlFloodInsuranceEndDate?: string
  rtlFloodInsuranceCarrier?: string
  rtlFloodInsuranceDeductible?: number
  rtlFloodInsurancePremium?: number
  rtlReplacementCostCoverageAmount?: number
  rtlValuationType?: RTLValuationType
  rtlNumberOfUnits?: number
  rtlRealEstateTaxes?: number
  rtlRealEstateTaxesYear?: number
  rtlOccupied?: OccupiedType
  rtlHoaFees?: number
  rtlLocation?: LocationType
  rtlMarketCondition?: MarketCondition
  rtlZoningCompliance?: ZoningCompliance
  rtlPropertyType?: RTLPropertyType
  rtlNymtPropertyType?: RTLNYMTPropertyType
  rtlAnnualizedGrossIncome?: number
  rtlConditionRating?: ConditionRating
  rtlDeferredMaintenanceYN?: YesNo
  rtlDeferredMaintenanceAmount?: number
  rtlAsIsAppraisedValue?: number
  rtlAsRepairedAppraisedValue?: number
  rtlAppraisalDate?: string
  rtlNumberOfSalesComps?: number
  rtlMold?: YesNo
  rtlPhotosOfSalesComps?: YesNo
  rtlBuildingSketch?: YesNo
  rtlLocationMap?: YesNo
  rtlAppraiserAttachedLicenseInfo?: YesNo
  rtlAppraiserAttachedInsuranceInfo?: YesNo
  rtlPostRehabSqFt?: number
  rtlRelianceLanguage?: YesNo
  rtlSalesCompProximity?: YesNo
  rtlSalesCompTolerance?: YesNo
  rtlAppraisalCert?: YesNoNA
  rtlAppraisalExecuted?: YesNoNA
  rtlAppraisalComplianceAddendum?: YesNo
  rtlReconValueProvided?: YesNo
  rtlReconValueDate?: string
  rtlAsIsReconValue?: number
  rtlAsRepairedReconValue?: number
  rtlRepairBudgetAmount?: number
  sfrPurchaseAgreementDocumentType?: PurchaseAgreementType
  sfrPurchaseAgreementWrongDocument?: string
  sfrGrossPurchasePrice?: number
  sfrSettlementDate?: string
  sfrPropertyOccupied?: YesNo
  sfrLeaseWrongDocument?: string
  sfrLeaseStartDate?: string
  sfrLeaseEndDate?: string
  sfrLeaseRent?: number
  sfrLeaseSection8?: YesNo
  sfrDeedWrongDocument?: string
  sfrVesting?: string
  sfrTitleWrongDocument?: string
  sfrTitleType?: SFRTitleType
  sfrTitleIssues?: SFRTitleIssues
  sfrTitleIssueComments?: string
  sfrTitleHoa?: YesNo
  sfrHoaType?: SFRTitleHOAOptions
  sfrHoaName?: string
  sfrBpoWrongDocument?: string
  sfrBpoAmount?: number
  sfrBpoDate?: string
  sfrBedCount?: number | string
  sfrBatchCount?: number | string
  sfrMortgageWrongDocument?: string
  sfrMortgageVesting?: string
  sfrAssignmentOfRents?: YesNo
  sfrSecurityAgreement?: YesNo
  sfrFloodCertWrongDocument?: string
  sfrFloodZone?: YesNo
  sfrFloodZoneCode?: string
  sfrOccupancyCertWrongDocument?: string
}
export interface UpdateFundingRequirementDueDate {
  dueDate?: string
}
export interface UpdateFundingRequirementsBody {
  fundingRequirements: UpdateFundingRequirementBody[]
  reviewNote?: string
}
export interface GetGuidelineChecksResponse {
  aggregators: AggregatorModel[]
  matchedAssetCount: number
  notMatchedAssetCount: number
}
export interface AggregatorModel {
  name: string
  assets: GuidelineCheckAssetModel[]
  guidelines?: AggregatorGuidelineModel[]
  matchedCount?: number
  notMatchedCount?: number
  notMatchedGroup?: boolean
}
export interface GuidelineCheckAssetModel {
  displayName: string
  guidelines?: {
    [k: string]: GuidelineModel
  }
  matches?: boolean
}
export interface GuidelineModel {
  browserFormatter: BrowserFormatter
  displayValue?: number | string | number
  status: GuidelineStatus
}
export interface AggregatorGuidelineModel {
  key: string
  headerLabel: string
}
export interface ManageEmailBody {
  emailId: number
}
/**
 * Reference: https://docs.sendgrid.com/for-developers/parsing-email/setting-up-the-inbound-parse-webhook#default-parameters
 */
export interface SendgridInboundParseFormBody {
  headers: string
  dkim?: string
  "content-ids"?: string
  to: string
  from: string
  cc?: string
  subject?: string
  sender_ip?: string
  text?: string
  html?: string
  spam_report?: string
  spam_score?: string
  envelope?: SMTPEnvelope
  attachments?: number
  "attachment-info"?: {
    [k: string]: AttachmentItemInfo
  }
  charsets?: {
    [k: string]: string
  }
  SPF?: string
}
export interface SMTPEnvelope {
  /**
   * @minItems 1
   * @maxItems 1
   */
  to?: [string]
  from?: string
}
/**
 * This interface was referenced by `undefined`'s JSON-Schema definition
 * via the `patternProperty` "attachment\d+".
 */
export interface AttachmentItemInfo {
  filename?: string
  type?: string
  "content-id"?: string
}
export interface DunmorStatusCheckResponse {
  loanId: string
  overallStatus: string
  documents?: DunmorFundingRequirementStatus[]
}
export interface DunmorFundingRequirementStatus {
  name: string
  type: string
  versionNumber: string
  status: string
  errorMessage?: string
}
export interface DunmorSyncDataTapeBody {
  loanId: string
  documents?: DunmorFundingRequirement[]
}
export interface DunmorFundingRequirement {
  name: string
  type: string
  versionNumber: string
  s3PresignedDocumentUrl: string
}
export interface GetValuationsResponse {
  valuations: Valuation[]
}
export interface Valuation {
  accountName: string
  assetGlobalId?: string
  buyerGroupGlobalId?: string
  createdAt: string
  documents?: FundingRequirementDocument[]
  fundingRequirementGlobalId?: string
  globalId: string
  status: ValuationOrderStatus
  streetAddress: string
  valuationType: ValuationType
  value?: number
}
export interface OrderAvmForm {
  fundingRequirementGlobalId: string
}
export interface AssignDataMapperFacilityBody {
  facilityGlobalId: string
  dataMapperGlobalId: string
}
export interface AssignDataMapperFacilityResponse {
  assignmentGlobalId: string
  facilityGlobalId: string
  dataMapperGlobalId: string
}
export interface CreateDataMapperBody {
  name: string
  mappers: TapeSheetConfig[]
  facilityId?: string
}
export interface TapeSheetConfig {
  parserType: ParserType
  skipRows?: number
  sheetName: string
  mappedColumns: ColumnMapper[]
}
export interface ColumnMapper {
  columnName: string
  mappedFieldName: string
  reviewFieldType?: EntityReviewDetailType
  fieldType: string
}
export interface DataMappingFieldOption {
  value: string
  label: string
  browserFormatter: string
  dataType: string
}
export interface GetAccountFacilitiesResponse {
  facilities: AccountFacilitySummary[]
}
export interface AccountFacilitySummary {
  accountGlobalId: string
  accountName: string
  facilityGlobalId: string
  facilityName: string
  facilityType: FacilityType
  lenderAccountName?: string
  lenderAccountGlobalId?: string
  facilityLogo?: string
}
export interface GetAccountSummaryResponse {
  accounts: AccountSummary[]
}
export interface AccountSummary {
  accountName: string
  accountGlobalId: string
  accountType: AccountType
  logo?: string
}
export interface GetDataMapperResponse {
  globalId: string
  name: string
  config: TapeSheetConfig[]
}
export interface GetDataMappingFieldOptionsResponse {
  fieldOptions: {
    [k: string]: DataMappingFieldOption
  }
}
export interface GetDataTapeMappersResponse {
  tapeMappers: DataTapeMapperSummary[]
}
export interface DataTapeMapperSummary {
  globalId: string
  parserName: string
}
export interface OnboardNewFacilityBody {
  facilityName: string
  logoUrl?: string
  accountId: string
  lenderAccountId?: string
}
export interface OnboardNewFacilityResponse {
  facilityName: string
  facilityId: string
  accountName: string
}
export interface CreateOptimizationScenarioBody {
  name: string
  description: string
  parameters: {
    [k: string]: unknown
  }
}
export interface GetOptimizationScenarioResponse {
  optimizationScenario: OptimizationScenarioModel
}
export interface OptimizationScenarioModel {
  globalId: string
  createdByAccountMember?: AccountMemberModel
  createdAt: string
  name: string
  description?: string
  parameters: {
    [k: string]: unknown
  }
  startedAt?: string
  finishedAt?: string
  scenarioStartDate: string
  scenarioEndDate: string
}
export interface PlaidCreateLinkTokenBody {
  bank_account_global_id?: string
}
export interface PlaidCreateLinkTokenResponse {
  link_token: string
}
export interface PlaidExchangePublicTokenBody {
  public_token: string
  metadata: {
    [k: string]: unknown
  }
}
export interface GetQueueLockResponse {
  locks: QueueLockModel[]
}
export interface QueueLockModel {
  queueItemGlobalId: string
  accountMemberName: string
  accountMemberImage?: string
}
export interface PostQueueLockBody {
  queueItemGlobalId: string
}
export interface PostQueueLockResponse {
  success: boolean
}
export interface CreateScenarioBody {
  name: string
  description: string
  scenarioPatches?: ScenarioPatchModel[]
  facilityGlobalIds?: string[]
}
export interface ScenarioPatchModel {
  operation: ScenarioOperationType
  facilityGlobalId: string
  assetGlobalId: string
  targetFacilityGlobalId?: string
}
export interface GetOptimizationConfigResponse {
  accountConfig?: OptimizationAccountConfig
  facilityConfigs: OptimizationFacilityConfig[]
}
export interface OptimizationAccountConfig {
  assetPopulation: OptimizationAssetPopulation
  optimizationMethod?: OptimizationMethod
}
export interface OptimizationFacilityConfig {
  globalId: string
  minPercentGrossAllocation?: number
  name?: string
  size?: number
}
export interface GetSampleScenarioResponse {
  downloadUrl: string
}
export interface GetScenarioAssetDetailsListResponse {
  scenarioAssetDetails: ScenarioAssetDetails[]
}
export interface ScenarioAssetDetails {
  scenarioAssetId: number
  assetGlobalId: string
  customerAssetId?: string
  acquisitionStatus?: HelixAcquisitionStatus
  msa?: string
  streetAddress?: string
  state?: string
  yearBuilt?: number
  bedrooms?: string
  bathrooms?: string
  acquisitionPrice?: number
  coeDate?: string
  marketValue?: number
  optimalAllocationFacilityGlobalId?: string
  optimalAllocationFacilityName?: string
  advancedAmount?: number
  advanceRate?: number
  costOfCapital?: number
  facilityEligibilities?: ScenarioAssetFacilityEligibility[]
  optimizationCostOfCapital?: OptimizationCostOfCapital
}
export interface ScenarioAssetFacilityEligibility {
  assetGlobalId?: string
  facilityGlobalId?: string
  facilityName?: string
  scenarioFacilityId?: number
  isEligible?: boolean
  isUnassignedFacility?: boolean
}
export interface OptimizationCostOfCapital {
  advanceSenior?: number
  advanceMezz?: number
  costSenior?: number
  costMezz?: number
  equityPortion?: number
  costEquity?: number
  costReassignment?: number
  costCapital?: number
}
export interface GetScenarioFacilityConcentrationLimitResponse {
  concentrationLimits: ScenarioFacilityConcentrationLimitResultsForecastModel[]
}
export interface ScenarioFacilityConcentrationLimitResultsForecastModel {
  dayOneResult: FacilityConcentrationLimitResultModel
  oneMonthResult?: FacilityConcentrationLimitResultModel
  twoMonthResult?: FacilityConcentrationLimitResultModel
  threeMonthResult?: FacilityConcentrationLimitResultModel
}
export interface GetScenarioListResponse {
  scenarios: ScenarioListModel[]
}
export interface ScenarioListModel {
  globalId: string
  name: string
  status: ScenarioStatus
  description?: string
  createdAt: string
  createdByAccountMember?: AccountMemberModel
  facilityImpacts?: FacilityImpactModel[]
  facilitySummaries?: ScenarioFacilitySummary[]
  optimizationScenarioId?: number
}
export interface FacilityImpactModel {
  facilityName: string
  impactAmount?: number
}
export interface ScenarioFacilitySummary {
  facilityName: string
  facilityGlobalId: string
  amountBorrowed?: number
  totalSize?: number
  isSetpoint?: boolean
  logo?: string
  isUnassignedFacility?: boolean
  minPercentGrossAllocation?: number
}
export interface GetScenarioResponse {
  scenario: ScenarioModel
}
export interface ScenarioModel {
  globalId: string
  name: string
  description?: string
  createdAt: string
  createdByAccountMember?: AccountMemberModel
  cardConfig: FacilityMetricConfig
  scenarioFacilities: ScenarioFacilityModel[]
  startDate?: string
  endDate?: string
  assetSummary?: ScenarioAssetModel[]
}
/**
 * Helper class that provides a standard way to create an ABC using
 * inheritance.
 */
export interface FacilityMetricConfig {
  key: string
  cards: Card[]
}
export interface ScenarioFacilityModel {
  facility: SlimFacilityModel
  globalId: string
}
export interface ScenarioAssetModel {
  asset: SlimAssetModel
  oldFacility?: SlimFacilityModel
  newFacility?: SlimFacilityModel
  operation: ScenarioOperationType
  operationDate: string
}
export interface PostFetchScenarioMetricBody {
  scenarioGlobalId: string
  metricType: ScenarioMetricType
  viewDate?: string
}
export interface PostFetchScenarioMetricResponse {
  scenarioMetricDiff: ScenarioMetricValueDiff
}
export interface ScenarioMetricValueDiff {
  metricType: ScenarioMetricType
  totalValue?: number | string
  totalBaseValue?: number | string
  totalPercentDiff?: number
  facilityMetricValues: FacilityMetricValue[]
}
export interface FacilityMetricValue {
  metricType: ScenarioMetricType
  scenarioFacility: ScenarioFacilityModel
  value?: number | string | number
  baseValue?: number | string | number
  percentDiff?: number
}
export interface AcceptInviteBody {
  email: string
  code: string
}
export interface AddCustomNotificationBody {
  email?: string
  notificationName?: string
  notificationType: NotificationType
  teamMembersGlobalIds: string[]
  fundingRequirementGlobalId?: string
  facilityGlobalId?: string
}
export interface CreateInviteBody {
  name: string
  email: string
  roles: (AccountMemberRole | CustomerRole | SetpointRole)[]
}
export interface CustomNotificationModel {
  addedAt: string
  email?: string
  facilityName?: string
  fundingRequirementName?: string
  globalId: string
  members: AccountMemberModel[]
  methods: NotificationMethod[]
  name: string
  type: NotificationType
}
export interface DataTapeModel {
  id?: number
  createdAt: string
  account?: SlimAccountModel
  sourcePretty?: string
  attachments?: InboundEmailAttachmentModel[]
  processedAt?: string
  skippedAt?: string
  skippedBy?: number
  skippedByAccountMemberName?: string
  toAddress?: string
  fromAddress?: string
  ccAddress?: string
  subject?: string
  fileUpload?: FileUploadModel1
}
export interface InboundEmailAttachmentModel {
  filename: string
  globalId: string
}
export interface FileUploadModel1 {
  uploadedBy?: AccountMemberModel
  filename: string
  globalId: string
}
export interface GetInvitesResponse {
  invites: Invite[]
}
export interface Invite {
  globalId: string
  name: string
  email: string
  accepted: boolean
  lastSentAt?: string
  roles: (AccountMemberRole | CustomerRole | SetpointRole)[]
}
export interface GetSettingsCustomNotificationsResponse {
  customNotifications: CustomNotificationModel[]
}
export interface GetSettingsDataTapeResponse {
  dataTapes: DataTapeModel[]
  nextPage?: number
}
export interface GetSettingsFundingRequirementsResponse {
  facilities: SettingsFacility[]
}
export interface SettingsFacility {
  name: string
  globalId: string
  fundingRequirements: SettingsFacilityFundingRequirement[]
}
export interface SettingsFacilityFundingRequirement {
  displayName: string
  globalId: string
  isDocument: boolean
}
export interface GetSettingsTeamResponse {
  members: AccountMemberModel[]
}
export interface InvalidateUserSessionBody {
  email: string
}
export interface PostDataTapeDownloadResponse {
  url: string
}
export interface RedirectResponse {
  location: string
}
export interface ResendInviteBody {
  globalId: string
}
export interface UpdatePermissionsBody {
  teamMemberGlobalId: string
  roles: (AccountMemberRole | CustomerRole | SetpointRole)[]
}
export interface GetMetabaseSsoTokenResponse {
  url?: string
}
export interface GetSummarySheetDebugResponse {
  summarySheet?: SummarySheet
}
export interface GetFundingRequestsCountsResponse {
  fundingRequestsCounts: {
    [k: string]: number
  }
}
export interface GetFundingRequestsResponse {
  fundingRequests: FundingRequestListModel[] | FundingRequest[]
  status?: FundingRequestsStatus
}
export interface FundingRequestListModel {
  asset: FundingRequestAssetListModel
  executeOutgoingAt?: string
  failedAt?: string
  federalReferenceNumber?: string
  fundingDestination: FundingDestination
  fundingDestinationPretty: string
  globalId: string
  outgoingExecutedAt?: string
  unverifiedBeneficiary?: string
  unverifiedRoutingNumber?: string
  unverifiedAccountNumber?: string
  unverifiedOutgoingAmount: number
  verifiedBeneficiary?: string
  verifiedRoutingNumber?: string
  verifiedAccountNumber?: string
  verifiedOutgoingAmount?: number
}
export interface FundingRequestAssetListModel {
  account: FundingRequestAccount
  assetProperty: FundingRequestAssetProperty
  globalId: string
  displayName: string
}
export interface FundingRequestAccount {
  globalId: string
  name: string
  originatorAccountNumber?: string
  originatorRoutingNumber?: string
  originatorWireInstructionsUrl?: string
}
export interface FundingRequestAssetProperty {
  closeDatePurchase?: string
}
export interface FundingRequest {
  asset: FundingRequestAsset
  executeOutgoingAt?: string
  failedAt?: string
  federalReferenceNumber?: string
  fundingDestination: FundingDestination
  fundingDestinationPretty: string
  globalId: string
  outgoingExecutedAt?: string
  unverifiedBeneficiary?: string
  unverifiedRoutingNumber?: string
  unverifiedAccountNumber?: string
  unverifiedOutgoingAmount: number
  verifiedBeneficiary?: string
  verifiedRoutingNumber?: string
  verifiedAccountNumber?: string
  verifiedOutgoingAmount?: number
  settlementProvider?: SettlementProviderModel
}
export interface FundingRequestAsset {
  account: FundingRequestAccount
  assetProperty: FundingRequestAssetProperty
  globalId: string
  displayName: string
  customerWireInstructions?: FundingRequirementDocument[]
  fundingDetail: AssetFundingDetailModel
  participationPayment?: FundingRequestAssetParticipationPayment
  preCloseComplete: boolean
}
export interface FundingRequestAssetParticipationPayment {
  participationPayment: FundingRequestParticipationPayment
}
export interface FundingRequestParticipationPayment {
  isSettled: boolean
}
export interface SettlementProviderModel {
  phoneNumber?: string
  validatedAt?: string
}
export interface ManualExecuteFundingRequestBody {
  federalReferenceNumber: string
}
export interface VerifyFundingRequestBody {
  accountNumber?: string
  beneficiary?: string
  executeAt: string
  routingNumber?: string
  phoneNumberUsedForValidation?: string
}
export interface GetNetTransferCountsResponse {
  netTransferCounts: {
    [k: string]: number
  }
}
export interface GetNetTransferListResponse {
  netTransfers: NetTransferListModel[]
  status?: NetTransferStatus
}
export interface NetTransferListModel {
  currentAccountType?: AccountType
  amount: number
  account: AccountModel
  category: NetTransferCategory
  categoryPretty: string
  currentPaymentOrder?: PaymentOrderListModel
  excelDownloadUrl: string
  facility: FacilityModel
  facilityTransferDirection: TransferDirection
  globalId: string
  receivingAccount?: BankAccountModel
  originatorTransferDirection: TransferDirection
  paymentType: PaymentType
  requestedAt?: string
  settledAt?: string
  stagedAt?: string
  ignoredAt?: string
  status?: PaymentStatus
  openChangeRequestGlobalId?: string
}
export interface PaymentOrderListModel {
  globalId: string
  type: PaymentType
  amount: number
  status: PaymentStatus
  originatingAccount?: BankAccountModel
  receivingAccount?: BankAccountModel
  direction: PaymentDirection
  effectiveDate: string
}
export interface GetNetTransferResponse {
  transfer: NetTransferModel
}
export interface NetTransferModel {
  currentAccountType?: AccountType
  amount: number
  account: AccountModel
  category: NetTransferCategory
  categoryPretty: string
  currentPaymentOrder?: PaymentOrderListModel
  excelDownloadUrl: string
  facility: FacilityModel
  facilityTransferDirection: TransferDirection
  globalId: string
  receivingAccount?: BankAccountModel
  originatorTransferDirection: TransferDirection
  paymentType: PaymentType
  requestedAt?: string
  settledAt?: string
  stagedAt?: string
  ignoredAt?: string
  status?: PaymentStatus
  openChangeRequestGlobalId?: string
  summarySheet: SummarySheet
}
export interface GetPayoffResponse {
  payoff: PayoffModel
}
export interface GetPayoffsCountsResponse {
  payoffsCounts: {
    [k: string]: number
  }
}
export interface GetPayoffsResponse {
  payoffs: PayoffModel[]
  status?: PayoffStatus
}
export interface NetTransferSettledBody {
  effectiveDate: string
}
export interface PatchNetTransferMoveAdvanceChangesResponse {
  changeRequestUrl: string
}
export interface PatchNetTransferMoveToChangeRequestBody {
  changeRequestGlobalId: string
}
export interface PayoffReceivedBody {
  amountPaid: number
  effectiveDate: string
}
export interface GetParticipationPaymentCountsResponse {
  participationPaymentCounts: {
    [k: string]: number
  }
}
export interface GetParticipationPaymentResponse {
  participationPayment: ParticipationPaymentModel
}
export interface ParticipationPaymentModel {
  account: AccountModel
  amount: number
  facility: FacilityModel
  globalId: string
  initiatedAt: string
  settledAt?: string
  assetParticipationPayments: AssetParticipationPaymentModel[]
}
export interface AssetParticipationPaymentModel {
  amount: number
  asset: AssetModel
}
export interface GetParticipationPaymentsByStatusResponse {
  participationPayments: ParticipationPaymentListModel[]
  status: ParticipationPaymentStatus
}
export interface ParticipationPaymentListModel {
  account: AccountModel
  amount: number
  facility: FacilityModel
  globalId: string
  initiatedAt: string
  settledAt?: string
}
export interface GetParticipationPaymentsResponse {
  pendingParticipations: ParticipationPaymentListModel[]
  settledParticipations: ParticipationPaymentListModel[]
  settledParticipationsCount: number
}
export interface GetPaymentOrderListResponse {
  pendingPaymentOrders: PaymentOrderListModel[]
  settledPaymentOrders: PaymentOrderListModel[]
  failedPaymentOrders: PaymentOrderListModel[]
}
export interface PaymentOrderModel {
  globalId: string
  type: PaymentType
  amount: number
  status: PaymentStatus
  originatingAccount?: BankAccountModel
  receivingAccount?: BankAccountModel
  direction: PaymentDirection
  effectiveDate: string
  netTransfers: NetTransferListModel[]
}
export interface GetValuationOrderResponse {
  orders: ValuationOrderModel[]
}
export interface ValuationOrderModel {
  status: ValuationOrderStatus
  valuationType: ValuationType
  valuationProvider: ValuationProvider
  valuationProviderDisplayName: string
  asset: ValuationAsset
  globalId: string
  externalId?: string
  orderedAt: string
  isRush?: boolean
}
export interface ValuationAsset {
  currentValuation?: Valuation1
  customerAssetId?: string
  displayName: string
  globalId: string
  msa?: string
  priorValuation?: Valuation1
  dueDate?: string
  mostRecentValuationOrder?: ValuationOrderModel
  availableValuations?: ValuationType[]
  facilityName?: string
  closeDate?: string
  daysOnBase?: number
}
export interface Valuation1 {
  amount: number
  effectiveDate?: string
  valuationType: ValuationType
  provider?: ValuationProvider
}
export interface GetValuationProviderTypeResponse {
  providers: ValuationProviderType[]
}
export interface ValuationProviderType {
  valuationProvider: ValuationProvider
  valuationProviderDisplayName: string
  valuationType: ValuationType
  valuationTypeDisplayName: string
  collectsContactInfo: boolean
  collectsAccessInfo: boolean
  rushDeliverySupported: boolean
}
export interface PostValuationOrderBody {
  assetGlobalIds: string[]
  valuationType: ValuationType
  valuationProvider: ValuationProvider
  isRushDelivery: boolean
  contactName?: string
  contactPhone?: string
  accessInfo?: string
}
export interface PostValuationsBody {
  searchTerm?: string
  valuationDue?: number
  offset?: number
}
export interface PostValuationsResponse {
  assets: ValuationAsset[]
  nextOffset?: number
}

export enum AccountType {
  LENDER = "lender",
  ORIGINATOR = "originator",
  AGGREGATOR = "aggregator",
}
export enum ProductType {
  BBYS = "bbys",
  CASH_OFFER = "cash-offer",
  IBUYER = "ibuyer",
  FIX_AND_FLIP = "fix-flip",
  INSURANCE = "insurance",
  SFR = "sfr",
  RENT_TO_OWN = "rto",
  SBYL = "sbyl",
  EQUITY_ADVANCE = "equity-advance",
  TRADE_UP = "trade-up",
  BRIDGE_LOAN = "bridge-loan",
  COMMERCIAL = "commercial",
  ORCHARD_AGED_GOLDMAN_SACHS = "orchard-gs",
  ORCHARD_OFFERBOOST = "orchard-ob",
  HW_BBYS_AGED = "hw-bbys-aged",
  HW_CASH_OFFER_AGED = "hw-cash-offer-aged",
  HW_FLOOR_PRICE_ACTIVATION = "hw-floor-price-activation",
  HW_BACKED_BY_CASH = "hw-backed-by-cash",
  IVF = "ivf",
  EGG_FREEZING = "egg-freezing",
  AUTO = "auto",
  AIRPLANE_LEASE = "airplane-lease",
  METRO_TRAILING = "metro-trailing",
  RTL = "rtl",
  FUND_TO_BUILD = "fund-to-build",
  RECEIVABLE = "receivable",
}
export enum AccountPropertySource {
  MODAL = "modal",
}
export enum AddAssetModalSchema {
  CONREX = "conrex",
  DAVIDSON_KEMPNER = "davidson-kempner",
  PSF = "psf",
  STANDARD = "standard",
}
export enum FundingDestination {
  ESCROW = "escrow",
  ORIGINATOR = "originator",
}
export enum AccountPermissionConfigType {
  ANALYTICS_ONLY = "analytics-only",
  ASSET_OS = "asset_os",
  BORROWER_REPORTS_ANALYTICS_LENDER = "borrower_reports_analytics_lender",
  BORROWER_REPORTS_LENDER = "borrower_reports_lender",
  BORROWER_REPORTS_ORIGINATOR = "borrower_reports",
  BORROWER_REPORTS_VERIFICATION_ORIGINATOR = "borrower_reports_verification",
  CALC_AGENT_ORIGINATOR = "calc_agent_originator",
  RTL_ORIGINATOR_ASSET_OS = "rtl_originator_collateral_manager",
  SETPOINT_AND_RTL_ORIGINATOR_WITH_ASSET_OS_AND_CHANGE_REQUEST = "setpoint_and_rtl_originator_with_collateral_manager_and_change_request",
  SETPOINT_ORIGINATOR_LEGACY = "setpoint_originator_legacy",
  SETPOINT_ORIGINATOR = "setpoint_originator",
}
export enum AccountMemberStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
export enum FacilityBorrowingBaseSchema {
  STANDARD = "standard",
  SFR = "sfr",
  FACILITY_ADVANCE_RATE = "facility-advance-rate",
  BD_CAPITAL = "bd-capital",
}
export enum ValuationType {
  TEN_O_FOUR_TRADITIONAL = "1004tra",
  TEN_O_FOUR_DESKTOP = "1004des",
  TRADITIONAL_DESKTOP = "desk",
  LEGACY_APPRAISAL = "legacy-appraisal",
  BPO = "bpo",
  INTERIOR_BPO = "interior-bpo",
  AVM = "avm",
  ARBPO = "arbpo",
  AIRPLANE_HALF_LIFE = "air-hl",
}
export enum Cadence {
  ANNUAL = "annual",
  QUARTERLY = "quarterly",
  MONTHLY = "monthly",
  DAILY = "daily",
}
export enum DiligenceCategory {
  RTL = "rtl",
  SETPOINT = "setpoint",
  SFR = "sfr",
  SUPERSET = "superset",
}
export enum FundingVehicleName {
  SETPOINT_FUND_1 = "SRFF",
  SETPOINT_FUND_2 = "SRFFII",
  SETPOINT_SAS_22_1 = "SAS 22-1",
  SETPOINT_SAS_24_1 = "SAS 24-1",
}
export enum ValuationProvider {
  LOAN_TAPE = "loan-tape",
  PRO_TECK = "pro-teck",
  CLEAR_CAPITAL = "clear-capital",
  HOUSE_CANARY = "house-canary",
  BK = "bk",
  IBA = "iba",
  MBA = "mba",
}
export enum FundingType {
  FUNDED = "funded",
  EQUITY = "equity",
}
export enum AvmOrderStatus {
  AUTO_COMPLETED = "auto-completed",
  API_ERROR = "api-error",
  AUTO_RESULT_UNRELIABLE = "auto-result-unreliable",
}
export enum FacilityType {
  SENIOR = "senior",
  MEZZ = "mezz",
}
export enum AssetPropertyType {
  UNKNOWN = "unknown",
  SINGLE_FAMILY = "single-family",
  CONDO = "condo",
  DUPLEX = "duplex",
  TOWNHOUSE = "townhouse",
  LUXURY = "luxury",
  COMMERCIAL = "commercial",
  AUTOMOBILE = "automobile",
  FERTILITY = "fertility",
  RTL = "rtl",
  SFR = "sfr",
  AIRCRAFT = "aircraft",
  ENGINE = "engine",
  EQUIPMENT = "equipment",
  PRODUCE = "produce",
}
export enum BackLeverageFacility {
  CHURCHILL = "CH-23",
  BAWAG = "BAWAG-23",
}
export enum AssetAssignmentType {
  BORROWING_BASE = "borrowing-base",
  DILIGENCE = "diligence",
}
export enum BankAccountTransactionState {
  UNRECONCILED = "unreconciled",
  RECONCILED = "reconciled",
  SKIPPED = "skipped",
}
export enum AccountMemberRole {
  CAPITAL_SOLUTIONS = "capital-solutions",
  DOCUMENT_VERIFICATION = "document-verification",
  DOCUMENT_VERIFICATION_LEAD = "document-verification-lead",
  ENGINEER = "engineer",
  RTL_DOCUMENT_VERIFICATION = "rtl-document-verification",
  RTL_DOCUMENT_VERIFICATION_LEAD = "rtl-document-verification-lead",
  SALES = "sales",
  SETPOINT_CAPITAL = "setpoint-capital",
  SFR_DOCUMENT_VERIFICATION = "sfr-document-verification",
  SUPER_ADMIN = "super-admin",
  ACCOUNTING = "accounting",
  ADMIN = "admin",
  BILLING = "billing",
  CLOSING_COORDINATOR = "closing-coordinator",
  FINANCE = "finance",
  MEMBER = "member",
  API_KEY = "api-key",
}
export enum ChangeRequestStatus {
  OPEN = "open",
  CLOSED = "closed",
  APPROVED = "approved",
  FUNDED = "funded",
}
export enum ChangeRequestReviewStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export enum ChangeRequestTimelineType {
  ASSET_REMOVED = "asset-removed",
  ADVANCED_CHANGE_REMOVED = "advance-change-removed",
  PAYOFF_EXCESS_REMOVED = "payoff-excess-removed",
  REMITTANCE_REMOVED = "remittance-removed",
  ASSET_ADDED = "asset-added",
  COMMENT = "comment",
  OPEN_COMMENT = "open-comment",
  EMAIL_COMMENT = "email-comment",
  APPROVAL = "approval",
  REJECTION = "rejection",
  FUNDED = "funded",
  CLOSED = "closed",
  FED_REF_ADDED = "fed-ref-added",
}
export enum ChangeCategory {
  ADVANCE_CHANGE = "adv-change",
  ENTERING = "entering",
  EXITING = "exiting",
  PAYOFF_EXCESS = "payoff-excess",
}
export enum GuidelineStatus {
  PASS = "pass",
  FAIL = "fail",
  PENDING = "pending",
  WAIVED = "waived",
  N_A = "n/a",
}
export enum AncillaryFile {
  AMHERST_GS_APPROVED_CBSAS = "amherst-gs-approved-cbsas",
  AMHERST_GS_BPO = "amherst-gs-bpo",
  AMHERST_GS_BUNGALO = "amherst-gs-bunglo",
  AMHERST_GS_DETERMINATION_DATE = "amherst-determination-date",
  AMHERST_GS_FIFTEEN_DAY_DILIGENCE = "amherst-gs-fifteen-day-diligence",
  AMHERST_GS_FMV = "amherst-gs-fmv",
  AMHERST_GS_MIDPOINT = "amherst-gs-midpoint",
  AMHERST_GS_PENDING_BUNGALO_WAIVER = "amherst-gs-pending-bunglo-waiver",
  AMHERST_GS_RELEVANT_DATES = "amherst-gs-relevant-dates",
  AMHERST_GS_STABLE_DELINQUENCIES = "amherst-gs-stable-delinquencies",
  AMHERST_GS_STABLE_VACANCIES = "amherst-gs-stable-vacancies",
  AMHERST_GS_STUDIO_BUILD = "amherst-studio-build",
  AMHERST_GS_TWENTY_FIVE_DAY_DILIGENCE = "amherst-gs-twenty-five-day-diligence",
  AMHERST_GS_TWO_SEVENTY_PLUS_PRE_STABLE = "amherst-gs-two-seventy-plus-pre-stable",
  AMHERST_GS_TWO_SEVENTY_PLUS_PRE_STABLE_CURRENT = "amherst-gs-two-seventy-plus-pre-stable-current",
  AMHERST_NOMURA_BPO = "amherst-nomura-bpo",
  AMHERST_NOMURA_BUNGALO = "amherst-nomura-bungalo",
  AMHERST_NOMURA_TWENTY_FIVE_DAY_DILIGENCE = "amherst-nomura-twenty-five-day-diligence",
  DK_LOAN_SCHEDULE = "davidson-kempner-loan-schedule",
  DK_SCHEDULED_PREPAYMENT = "dk-scheduled-prepayment",
  DK_CS_CONSOLIDATED_IMPORT = "dk-cs-consolidated-import",
  DK_MARKED_VALUES_I_IMPORT = "dk-marked-values-1-import",
  DK_MARKED_VALUES_II_IMPORT = "dk-marked-values-2-import",
  DK_HISTORICAL_MARKED_VALUES_IMPORT = "dk-historical-marked-values-import",
  DK_TOTAL_MV_APL_II = "dk-total-marked-value-apl-ii",
  DK_AIRLINE_CATALOG = "dk-airline-catalog",
  DK_AIRCRAFT_CATALOG = "dk-aircraft-catalog",
  DK_COUNTRY_CATALOG = "dk-country-catalog",
  DK_AGE_ADJUST_CATALOG = "dk-age-adjust-catalog",
  DK_TIER_ADJUST_CATALOG = "dk-tier-adjust-catalog",
  DK_AIRCRAFT_SUMMARY = "dk-aircraft-summary",
  DK_CS_AIRCRAFT_SUMMARY = "dk-cs-aircraft-summary",
  DK_BANK_ACCOUNT_PAYMENTS = "dk-bank-account-payments",
  DK_2023_ABS_STRUCTURE = "dk-2023-abs-structure",
  DK_PAYMENT_DATE = "dk-payment-date",
  DEMO_BANK_ACCOUNT_PAYMENTS = "demo-bank-account-payments",
  DEMO_PAYMENT_DATE = "demo-payment-date",
  DEMO_AIRLINE_CATALOG = "demo-airline-catalog",
  DEMO_AIRCRAFT_CATALOG = "demo-aircraft-catalog",
  DEMO_COUNTRY_CATALOG = "demo-country-catalog",
  DEMO_AGE_ADJUST_CATALOG = "demo-age-adjust-catalog",
  DEMO_TIER_ADJUST_CATALOG = "demo-tier-adjust-catalog",
  DEMO_AIRCRAFT_SUMMARY = "demo-aircraft-summary",
  HELIX_EXPORT = "helix-export",
  METRO_ASSET_FORM = "metro-asset-form",
  OPENDOOR_RESALE_PIPELINE = "opendoor-resale-pipeline",
  ORCHARD_FUNDING_TAPE = "orchard-funding-tape",
  ORCHARD_GS_BB_VALIDATION = "orchard-bb-validation",
  OPAD_JPM_HPI_MARKET_STEP_DOWNS = "opad-jpm-hpi-market-step-downs",
  OPAD_JPM_MARKET_RAW_VOLUMES = "opad-jpm-market-raw-volumes",
  FUNDRISE_CA_JPM_ACCOUNT = "fundrise-ca-jpm-account",
  FUNDRISE_CA_LOAN_ACCOUNT = "fundrise-ca-loan-account",
  FUNDRISE_CA_RESERVE_ACCOUNT = "fundrise-ca-reserve-account",
  FUNDRISE_CA_BORROWER_ACCOUNT = "fundrise-ca-borrower-account",
  FUNDRISE_CA_DILIGENCE_ACCOUNT = "fundrise-ca-diligence-account",
  SETPOINT_BACK_LEVERAGE = "setpoint-back-leverage",
  PSF_LOAN_REPORT = "psf-loan-report",
  PSF_ASSET_INTEREST_SCHEDULE = "psf-asset-interest-schedule",
  PSF_FACILITY_SERVICER_PAYMENTS = "psf-facility-servicer-payments",
  SOFR_CURVE = "sofr-curve",
}
export enum FacilityReassignmentCategory {
  PRE_FUNDING = "pre-funding",
  POST_FUNDING = "post-funding",
  TRANSFER = "transfer",
}
export enum FacilityReassignmentRule {
  RESET = "reset",
  UNRESTRICTED = "unrestricted",
}
export enum EntityReviewDetailType {
  APPRAISAL = "appraisal",
  APPRAISAL_DATE = "appraisal_date",
  AVM = "avm",
  BORROWER_LIQUIDITY = "borrower_liquidity",
  BPO_AMOUNT = "bpo_amount",
  BPO_DATE = "bpo_date",
  INTERIOR_BPO_AMOUNT = "interior_bpo_amount",
  INTERIOR_BPO_DATE = "interior_bpo_date",
  CEILING_AMOUNT = "ceiling_amount",
  CLOSE_DATE = "close_date",
  CLOSING_COSTS = "closing_costs",
  COMMITMENT_DATE = "commitment_date",
  CREDIT_SCORE = "credit_score",
  DEBT_TO_INCOME = "debt_to_income",
  DOWN_PAYMENT = "down_payment",
  EARNEST_MONEY_SENT = "earnest_money_sent",
  FUNDING_AMOUNT = "funding_amount",
  GROSS_PURCHASE_PRICE = "gross_purchase_price",
  INCOME = "income",
  INSURANCE_AMOUNT = "insurance_amount",
  INSURANCE_END_DATE = "insurance_end_date",
  INSURANCE_START_DATE = "insurance_start_date",
  INTERNAL_VALUATION = "internal_valuation",
  LEASE_END_DATE = "lease_end_date",
  LEASE_START_DATE = "lease_start_date",
  LOAN_MATURITY_DATE = "loan_maturity_date",
  LOAN_ORIGINATION_DATE = "loan_origination_date",
  LOAN_TO_VALUE = "loan_to_value",
  MONTHLY_RENT = "monthly_rent",
  MORTGAGE_AMOUNT = "mortgage_amount",
  MORTGAGE_DATE = "mortgage_date",
  NET_PURCHASE_PRICE = "net_purchase_price",
  NUMBER_OF_BATHROOMS = "number_of_bathrooms",
  NUMBER_OF_BEDROOMS = "number_of_bedrooms",
  OPENDOOR_BUYER_CREDIT = "opendoor_buyer_credit",
  OPENDOOR_COMMISSION = "opendoor_commission",
  OPENDOOR_SELLER_CREDIT = "opendoor_seller_credit",
  OPENDOOR_SERVICE_CHARGE = "opendoor_service_charge",
  HOMEWARD_PROGRAM_FEE = "homeward_program_fee",
  HOMEWARD_LISTING_CREDIT = "homeward_listing_credit",
  HOMEWARD_COMMISSIONS = "homeward_commissions",
  HAZARD_INSURANCE_COVERAGE_AMOUNT = "hazard_insurance_coverage_amount",
  HAZARD_INSURANCE_START_DATE = "hazard_insurance_start_date",
  HAZARD_INSURANCE_END_DATE = "hazard_insurance_end_date",
  FLOOD_INSURANCE_COVERAGE_AMOUNT = "flood_insurance_coverage_amount",
  FLOOD_INSURANCE_START_DATE = "flood_insurance_start_date",
  FLOOD_INSURANCE_END_DATE = "flood_insurance_end_date",
  IN_FLOOD_ZONE = "in_flood_zone",
  FLOOD_ZONE_CODE = "flood_zone_code",
  OPTION_PRICE = "option_price",
  ORIGINAL_LOAN_AMOUNT = "original_loan_amount",
  PERSONAL_FINANCIAL_STATEMENT = "personal_financial_statement",
  POST_CLOSE_BPO = "post_close_bpo",
  POST_CLOSE_BPO_DATE = "post_close_bpo_date",
  PROPERTY_TAX = "property_tax",
  PRE_APPROVAL_AMOUNT = "pre_approval_amount",
  PRE_CLOSE_APPRAISAL = "pre_close_appraisal",
  PRE_CLOSE_PURCHASE_PRICE = "pre_close_purchase_price",
  PURCHASE_DATE = "purchase_date",
  REHAB_COSTS = "rehab_costs",
  REPAIRS_DATE = "repairs_date",
  RESERVES = "reserves",
  SETPOINT_BPO_AMOUNT = "setpoint_bpo_amount",
  SUBSCRIPTION_FEE = "subscription_fee",
  VERIFIED_PURCHASE_PRICE = "verified_purchase_price",
  TAX_ID = "tax_id",
  VESTING_ENTITY = "vesting_entity",
  CONREX_BUYING_ENTITY = "conrex_buying_entity",
  CONSTRUCTION_DRAW_AMOUNT_1 = "construction_draw_amount_1",
  CONSTRUCTION_DRAW_DATE_1 = "construction_draw_date_1",
  CONSTRUCTION_DRAW_AMOUNT_2 = "construction_draw_amount_2",
  CONSTRUCTION_DRAW_DATE_2 = "construction_draw_date_2",
  CONSTRUCTION_DRAW_AMOUNT_3 = "construction_draw_amount_3",
  CONSTRUCTION_DRAW_DATE_3 = "construction_draw_date_3",
  CONSTRUCTION_DRAW_AMOUNT_4 = "construction_draw_amount_4",
  CONSTRUCTION_DRAW_DATE_4 = "construction_draw_date_4",
  CONSTRUCTION_DRAW_AMOUNT_5 = "construction_draw_amount_5",
  CONSTRUCTION_DRAW_DATE_5 = "construction_draw_date_5",
  CONSTRUCTION_DRAW_AMOUNT_6 = "construction_draw_amount_6",
  CONSTRUCTION_DRAW_DATE_6 = "construction_draw_date_6",
  CONSTRUCTION_DRAW_AMOUNT_7 = "construction_draw_amount_7",
  CONSTRUCTION_DRAW_DATE_7 = "construction_draw_date_7",
  RTL_LOAN_APPLICATION_DATE = "rtl_loan_application_date",
  RTL_LOAN_APPLICATION_DATED = "rtl_loan_application_dated",
  RTL_LOAN_APPLICATION_MEMBERS = "rtl_loan_application_members",
  RTL_LOAN_APPLICATION_SIGNED_EXECUTED = "rtl_loan_application_signed_executed",
  RTL_LOAN_BORROWERS_ADDRESS = "rtl_loan_borrowers_address",
  RTL_LOAN_BORROWERS_PHONE = "rtl_loan_borrowers_phone",
  RTL_LOAN_BORROWERS_EMAIL = "rtl_loan_borrowers_email",
  RTL_BORROWER_EXPERIENCE_BUCKET = "rtl_borrower_experience_bucket",
  RTL_BORROWER_EXPERIENCE_NUMBER = "rtl_borrower_experience_number",
  RTL_TRACK_RECORD_DOLLARS = "rtl_track_record_dollars",
  RTL_CALCULATED_FICO_SCORE = "rtl_calculated_fico_score",
  RTL_DEPOSIT_ACCOUNT_STATEMENTS = "rtl_deposit_account_statements",
  RTL_STOCK_ACCOUNT_STATEMENTS = "rtl_stock_account_statements",
  RTL_CASH_BACK_ON_HUD = "rtl_cash_back_on_hud",
  RTL_HUD_FLAG = "rtl_hud_flag",
  RTL_HUD_DATE = "rtl_hud_date",
  RTL_HUD_ASSIGNMENT_FEE = "rtl_hud_assignment_fee",
  RTL_HUD_INTEREST_RESERVE = "rtl_hud_interest_reserve",
  RTL_HUD_PURCHASE_PRICE = "rtl_hud_purchase_price",
  RTL_HUD_GROSS_PURCHASE_PRICE = "rtl_hud_gross_purchase_price",
  RTL_BORROWER_CONTRIBUTION = "rtl_borrower_contribution",
  RTL_TITLE_TYPE = "rtl_title_type",
  RTL_PROPERTY_LOCATED_IN_HOA = "rtl_property_located_in_hoa",
  RTL_HOA_NAME = "rtl_hoa_name",
  RTL_TITLE_DATE = "rtl_title_date",
  RTL_CONDO_MASTER_INSURANCE_EXPIRATION_DATE = "rtl_condo_master_expiration_date",
  RTL_SELLER_NAME = "rtl_seller_name",
  RTL_LOAN_TYPE_CATEGORY = "rtl_loan_type_category",
  RTL_DEED_TYPE = "rtl_deed_type",
  RTL_VESTING = "rtl_vesting",
  RTL_NOTE_AMOUNT = "rtl_note_amount",
  RTL_ORIGINATOR_NAME = "rtl_originator_name",
  RTL_HOLDBACK_AMOUNT = "rtl_holdback_amount",
  RTL_ORIGINATION_DATE = "rtl_origination_date",
  RTL_MATURITY_DATE = "rtl_maturity_date",
  RTL_RATE_TYPE = "rtl_rate_type",
  RTL_INTEREST_RATE = "rtl_interest_rate",
  RTL_ARM_INDEX = "rtl_arm_index",
  RTL_ARM_MARGIN = "rtl_arm_margin",
  RTL_INTEREST_RATE_FLOOR = "rtl_interest_rate_floor",
  RTL_INTEREST_RATE_CEILING = "rtl_interest_rate_ceiling",
  RTL_FIRST_RATE_CHANGE_DATE = "rtl_first_rate_change_date",
  RTL_NEXT_RATE_CHANGE_DATE = "rtl_next_rate_change_date",
  RTL_ROLL_FREQUENCY = "rtl_roll_frequency",
  RTL_PRE_PAYMENT_PENALTY = "rtl_pre_payment_penalty",
  RTL_PRE_PAYMENT_PENALTY_MONTHS = "rtl_pre_payment_penalty_months",
  RTL_PRE_PAYMENT_PENALTY_STRUCTURE = "rtl_pre_payment_penalty_structure",
  RTL_DEFAULT_INTEREST_RATE = "rtl_default_interest_rate",
  RTL_LOAN_TERM = "rtl_loan_term",
  RTL_PAYMENT_AMOUNT = "rtl_payment_amount",
  RTL_LOAN_AMOUNT = "rtl_loan_amount",
  RTL_LOAN_AGREEMENT_ORIGINATION_DATE = "rtl_loan_agreement_origination_date",
  RTL_LOAN_AGREEMENT_MATURITY_DATE = "rtl_loan_agreement_maturity_date",
  RTL_ACCRUAL_TYPE = "rtl_accrual_type",
  RTL_IS_DUTCH = "rtl_is_dutch",
  RTL_FIRST_PAYMENT_DATE = "rtl_first_payment_date",
  RTL_LAST_PAYMENT_DATE = "rtl_last_payment_date",
  RTL_LATE_FEE = "rtl_late_fee",
  RTL_LATE_FEE_GRACE_DAYS = "rtl_late_fee_grace_days",
  RTL_BUILT_IN_EXTENSION = "rtl_built_in_extension",
  RTL_CROSS_DEFAULT_PROVISIONS = "rtl_cross_default_provisions",
  RTL_EVENT_DEFAULT_GRACE_DAYS = "rtl_event_default_grace_days",
  RTL_CROSS_COLLATERAL_LOAN = "rtl_cross_collateral_loan",
  RTL_RELEASE_PRICING_PROVIDED = "rtl_release_price_provided",
  RTL_RELEASE_PRICE = "rtl_release_price",
  RTL_NOTE_FIRST_REVIEW = "rtl_note_first_review",
  RTL_SERVICING_FEES = "rtl_servicing_fees",
  RTL_US_FOREIGN_ENTITY = "rtl_us_foreign_entity",
  RTL_INCORPORATION_ENTITY_NAME = "rtl_incorporation_entity_name",
  RTL_DATE_OF_INCORPORATION = "rtl_date_of_incorporation",
  RTL_EIN_TAX_ID_NUMBER = "rtl_ein_tax_id_number",
  RTL_OPERATING_ENTITY_NAME = "rtl_operating_entity_name",
  RTL_OFAC_REPORT_DATE = "rtl_ofac_report_date",
  RTL_CONTRACTOR_LICENSE_INFO = "rtl_contractor_license_info",
  RTL_CONTRACTOR_NAME = "rtl_contractor_name",
  RTL_CONTRACTOR_LICENSE_NUMBER = "rtl_contractor_license_number",
  RTL_GUARANTY_AMOUNT = "rtl_guaranty_amount",
  RTL_GUARANTY_TYPE = "rtl_guaranty_type",
  RTL_FULL_RECOURSE = "rtl_full_recourse",
  RTL_PURCHASE_AGREEMENT_DOCUMENT_TYPE = "rtl_purchase_agreement_document_type",
  RTL_GROSS_PURCHASE_PRICE = "rtl_gross_purchase_price",
  RTL_SETTLEMENT_DATE = "rtl_settlement_date",
  RTL_EARNEST_MONEY_DEPOSIT_ON_PURCHASE = "rtl_earnest_money_deposit_on_purchase",
  RTL_REPLACEMENT_COST_COVERAGE_AMOUNT = "rtl_replacement_cost_coverage_amount",
  RTL_HAZARD_POLICY_TYPE = "rtl_hazard_policy_type",
  RTL_HAZARD_POLICY_CARRIER = "rtl_hazard_policy_carrier",
  RTL_HAZARD_POLICY_NUMBER = "rtl_hazard_policy_number",
  RTL_ISAOA_ATIMA = "rtl_isaoa_atima",
  RTL_HAZARD_INSURANCE_COVERAGE_AMOUNT = "rtl_hazard_insurance_coverage_amount",
  RTL_HAZARD_INSURANCE_START_DATE = "rtl_hazard_insurance_start_date",
  RTL_HAZARD_INSURANCE_END_DATE = "rtl_hazard_insurance_end_date",
  RTL_HAZARD_PROOF_OF_PAYMENT = "rtl_hazard_proof_of_payment",
  RTL_HAZARD_POLICY_DEDUCTIBLE = "rtl_hazard_policy_deductible",
  RTL_HAZARD_POLICY_PREMIUM = "rtl_hazard_policy_premium",
  RTL_GENERAL_LIABILITY_COVERAGE_AMOUNT = "rtl_general_liability_coverage_amount",
  RTL_GENERAL_LIABILITY_COVERAGE_START_DATE = "rtl_general_liability_start_date",
  RTL_GENERAL_LIABILITY_COVERAGE_END_DATE = "rtl_general_liability_end_date",
  RTL_IN_FLOOD_ZONE = "rtl_in_flood_zone",
  RTL_FLOOD_ZONE_CODE = "rtl_flood_zone_code",
  RTL_FLOOD_INSURANCE_CARRIER = "rtl_flood_insurance_carrier",
  RTL_FLOOD_INSURANCE_COVERAGE_AMOUNT = "rtl_flood_insurance_coverage_amount",
  RTL_FLOOD_INSURANCE_START_DATE = "rtl_flood_insurance_start_date",
  RTL_FLOOD_INSURANCE_END_DATE = "rtl_flood_insurance_end_date",
  RTL_FLOOD_INSURANCE_DEDUCTIBLE = "rtl_flood_insurance_deductible",
  RTL_FLOOD_INSURANCE_PREMIUM = "rtl_flood_insurance_premium",
  RTL_VALUATION_TYPE = "rtl_valuation_type",
  RTL_NUMBER_OF_UNITS = "rtl_number_of_units",
  RTL_REAL_ESTATE_TAXES = "rtl_real_estate_taxes",
  RTL_REAL_ESTATE_TAXES_YEAR = "rtl_real_estate_taxes_year",
  RTL_OCCUPIED = "rtl_occupied",
  RTL_HOA_FEES = "rtl_hoa_fees",
  RTL_LOCATION = "rtl_location",
  RTL_MARKET_CONDITION = "rtl_market_condition",
  RTL_ZONING_COMPLIANCE = "rtl_zoning_compliance",
  RTL_PROPERTY_TYPE = "rtl_property_type",
  RTL_NYMT_PROPERTY_TYPE = "rtl_nymt_property_type",
  RTL_ANNUALIZED_GROSS_INCOME = "rtl_annualized_gross_income",
  RTL_CONDITION_RATING = "rtl_condition_rating",
  RTL_DEFERRED_MAINTENANCE_Y_N = "rtl_deferred_maintenance_y_n",
  RTL_DEFERRED_MAINTENANCE_AMOUNT = "rtl_deferred_maintenance_amount",
  RTL_AS_IS_APPRAISED_VALUE = "rtl_as_is_appraised_value",
  RTL_AS_REPAIRED_APPRAISED_VALUE = "rtl_as_repaired_appraised_value",
  RTL_APPRAISAL_DATE = "rtl_appraisal_date",
  RTL_NUMBER_OF_SALES_COMPS = "rtl_number_of_sales_comps",
  RTL_MOLD = "rtl_mold",
  RTL_PHOTOS_OF_SALES_COMPS = "rtl_photos_of_sales_comps",
  RTL_BUILDING_SKETCH = "rtl_building_sketch",
  RTL_LOCATION_MAP = "rtl_location_map",
  RTL_APPRAISER_ATTACHED_LICENSE_INFO = "rtl_appraiser_attached_license_info",
  RTL_APPRAISER_ATTACHED_INSURANCE_INFO = "rtl_appraiser_attached_insurance_info",
  RTL_POST_REHAB_SQ_FT = "rtl_post_rehab_sq_ft",
  RTL_RELIANCE_LANGUAGE = "rtl_reliance_language",
  RTL_APPRAISAL_SALES_COMPS_PROXIMITY = "rtl_sales_comp_proximity",
  RTL_APPRAISAL_SALES_COMPS_TOLERANCE = "rtl_sales_comp_tolerance",
  RTL_APPRAISAL_CERT = "rtl_appraisal_cert",
  RTL_APPRAISAL_EXECUTED = "rtl_appraisal_executed",
  RTL_APPRAISAL_COMPLIANCE_ADDENDUM = "rtl_appraisal_compliance_addendum",
  RTL_RECON_VALUE_PROVIDED = "rtl_recon_value_provided",
  RTL_RECON_VALUE_DATE = "rtl_recon_value_date",
  RTL_AS_IS_RECON_VALUE = "rtl_as_is_recon_value",
  RTL_AS_REPAIRED_RECON_VALUE = "rtl_as_repaired_recon_value",
  RTL_REPAIR_BUDGET_AMOUNT = "rtl_repair_budget_amount",
  RTL_LOAN_TYPE_CALCULATED = "rtl_loan_type_calculated",
  RTL_SALUDA_GRADE_SECURITIZATION_CODE = "rtl_saluda_grade_securitization_code",
  SFR_PURCHASE_AGREEMENT_DOCUMENT_TYPE = "sfr_purchase_agreement_document_type",
  SFR_PURCHASE_AGREEMENT_WRONG_DOCUMENT = "sfr_purchase_agreement_wrong_document",
  SFR_GROSS_PURCHASE_PRICE = "sfr_gross_purchase_price",
  SFR_SETTLEMENT_DATE = "sfr_settlement_date",
  SFR_LEASE_WRONG_DOCUMENT = "sfr_lease_wrong_document",
  SFR_LEASE_START_DATE = "sfr_lease_start_date",
  SFR_LEASE_END_DATE = "sfr_lease_end_date",
  SFR_LEASE_RENT = "sfr_lease_rent",
  SFR_LEASE_SECTION_8 = "sfr_lease_section_8",
  SFR_DEED_WRONG_DOCUMENT = "sfr_deed_wrong_document",
  SFR_VESTING = "sfr_vesting",
  SFR_TITLE_WRONG_DOCUMENT = "sfr_title_wrong_document",
  SFR_TITLE_TYPE = "sfr_title_type",
  SFR_TITLE_ISSUES = "sfr_title_issues",
  SFR_TITLE_ISSUE_COMMENTS = "sfr_title_issue_comments",
  SFR_TITLE_HOA = "sfr_title_hoa",
  SFR_HOA_TYPE = "sfr_hoa_type",
  SFR_HOA_NAME = "sfr_hoa_name",
  SFR_BPO_WRONG_DOCUMENT = "sfr_bpo_wrong_document",
  SFR_BPO_AMOUNT = "sfr_bpo_amount",
  SFR_BPO_DATE = "sfr_bpo_date",
  SFR_BED_COUNT = "sfr_bed_count",
  SFR_BATH_COUNT = "sfr_batch_count",
  SFR_MORTGAGE_WRONG_DOCUMENT = "sfr_mortgage_wrong_document",
  SFR_MORTGAGE_VESTING = "sfr_mortgage_vesting",
  SFR_ASSIGNMENT_OF_RENTS = "sfr_assignment_of_rents",
  SFR_SECURITY_AGREEMENT = "sfr_security_agreement",
  SFR_FLOOD_CERT_WRONG_DOCUMENT = "sfr_flood_cert_wrong_document",
  SFR_FLOOD_ZONE = "sfr_flood_zone",
  SFR_FLOOD_ZONE_CODE = "sfr_flood_zone_code",
  SFR_OCCUPANCY_CERT_WRONG_DOCUMENT = "sfr_occupancy_cert_wrong_document",
}
export enum FundingRequirementAction {
  ORDER_AVM = "order-avm",
  ORDER_BPO = "order-bpo",
  ORDER_APPRAISAL = "order-appraisal",
}
export enum PolymorphicValueType {
  DATE = "date",
  DEFAULT = "text",
  ENUM_AS_TEXT = "enum_as_text",
  FLOAT = "float",
  INT = "int",
  ANCILLARY_JSONB = "jsonb",
  MONEY = "money",
  UNICODE_TEXT = "unicode_text",
  PERCENTAGE = "percentage",
  JSON = "json",
}
export enum ExternalValuationType {
  AVM = "avm",
  BPO = "bpo",
  INTERIOR_BPO = "interior-bpo",
}
export enum BrowserFormatter {
  ABBREVIATED_MONEY = "abbreviated-money",
  ACCOUNTING_MONEY = "accounting-money",
  ACCOUNTING_MONEY_GBP = "accounting-money-gbp",
  ACCOUNTING_MONEY_EUR = "accounting-money-eur",
  BOOL = "bool",
  BOOL_ONE_ZERO = "bool-one-zero",
  BOOL_YES_NO_SHORT = "bool-yes-no-short",
  BOOL_YES_NO_FULL = "bool-yes-no-full",
  BOOL_PASS_FAIL = "bool-pass-fail",
  CREDIT_SCORE = "credit-score",
  DATE = "date",
  MONTH_YEAR_DATE = "month-year-date",
  DAYS = "days",
  FULL_MONEY = "full-money",
  NUMERIC = "numeric",
  NUMERIC_PRECISION_ZERO = "numeric-precision-zero",
  NUMERIC_PRECISION_ONE = "numeric-precision-one",
  NUMERIC_PRECISION_TWO = "numeric-precision-two",
  NUMERIC_PRECISION_THREE = "numeric-precision-three",
  NUMERIC_PRECISION_FOUR = "numeric-precision-four",
  NUMERIC_PRECISION_TWO_X = "numeric-precision-two-x",
  PERCENTAGE = "percentage",
  PERCENTAGE_PRECISION_ONE = "percentage-precision-one",
  PERCENTAGE_PRECISION_TWO = "percentage-precision-two",
  PERCENTAGE_PRECISION_THREE = "percentage-precision-three",
  PERCENTAGE_PRECISION_FOUR = "percentage-precision-four",
  PRODUCT_TYPE = "product-type",
  PROPERTY_TYPE = "property-type",
  SHORT_MONEY = "short-money",
  TEXT = "text",
  EMPTY = "empty",
}
export enum EntityType {
  ASSET = "asset",
  BUYER_GROUP = "buyer_group",
  BUYER_GROUP_PROPERTY = "buyer_group_property",
  RTL_PROPERTY = "rtl_property",
}
export enum DocumentReviewFormContextType {
  DATE = "date",
  MONEY = "money",
  TEXT = "text",
}
export enum FundingRequirementCategory {
  PRE_CLOSE = "pre-close",
  AT_CLOSE = "at-close",
  POST_CLOSE = "post-close",
  SETTLEMENT = "settlement",
  INTERNAL = "internal",
  SETPOINT_VERIFICATION = "setpoint-verification",
  VALUATION = "valuation",
  SUPERSET = "superset",
  INTERNAL_CHECKS = "internal-checks",
  DOCUMENT_EXPORT = "document-export",
  PRE_FUNDING = "pre-funding",
  POST_FUNDING = "post-funding",
  LOAN_TAPE_VERIFICATION = "loan-tape-verification",
  FUNDING = "funding",
  PAYOFF = "payoff",
  OPENDOOR_PHASE_1 = "opendoor-phase-1",
  OPENDOOR_PHASE_2 = "opendoor-phase-2",
  OPENDOOR_EXTERNAL = "opendoor-external",
  OPENDOOR_SETPOINT_VERIFICATION = "opendoor-setpoint-verification",
  BUYER_INTRODUCTION = "buyer-introduction",
  BUYER_ACTIVE = "buyer-active",
  BUYER_UNDER_CONTRACT = "buyer-under-contract",
  PURCHASE = "purchase",
  RENOVATION = "renovation",
  DISPOSITION = "disposition",
  DOCUMENT_VERIFICATION = "document-verification",
  DOCUMENTS = "documents",
  TRANSFER = "transfer",
  TRANSFER_DOCUMENT_EXPORT = "transfer-document-export",
}
export enum FundingRequirementStage {
  INCOMPLETE = "incomplete",
  IN_PROGRESS = "in-progress",
  FLAGGED = "flagged",
  COMPLETE = "complete",
  SUPERSET = "superset",
}
export enum FundingRequirementType {
  HW_BUYER_PURCHASE_CONTRACT = "hw-buyer-purchase-contract",
  HW_BUYER_PURCHASE_CONTRACT_UPLOAD = "hw-buyer-purchase-contract-upload",
  HW_BUYER_PURCHASE_CONTRACT_FUND_NAME = "hw-buyer-purchase-contract-fund-name",
  HW_BUYER_PURCHASE_CONTRACT_FUND_NAME_CS = "hw-buyer-purchase-contract-fund-name-cs",
  HW_BUYER_PURCHASE_CONTRACT_PRICE_CHECK = "hw-buyer-purchase-contract-price-check",
  HW_BUYER_PURCHASE_CONTRACT_INCLUDES_AMENDMENTS = "hw-buyer-purchase-contract-includes-amendments",
  HW_BUYER_PURCHASE_CONTRACT_SIGNED = "hw-buyer-purchase-contract-signed",
  HW_EMD = "hw-emd",
  HW_EMD_UPLOAD = "hw-emd-upload",
  HW_EMD_APPROVED = "hw-emd-approved",
  HW_VPAL_SUMMARY = "hw-vpal-summary",
  HW_VPAL_SUMMARY_UPLOAD = "hw-vpal-summary-upload",
  HW_VPAL_SUMMARY_CREDIT_SCORE_THRESHOLD = "hw-vpal-summary-credit-score-threshold",
  HW_VPAL_QUALIFIES_AGENCY_BACKED_MORTGAGE = "hw-vpal-summary-qualifies-agency-backed-mortgage",
  HW_VPAL_SUMMARY_DECISION_FIELD_APPROVED = "hw-vpal-summary-decision-field-approved",
  HW_UNDERWRITING_DOCUMENT = "hw-underwriting-document",
  HW_UNDERWRITING_DOCUMENT_UPLOAD = "hw-underwriting-document-upload",
  HW_UNDERWRITING_DOCUMENT_APPROVED = "hw-underwriting-document-approved",
  HW_PRE_APPROVAL_LETTER = "hw-pre-approval-letter",
  HW_PRE_APPROVAL_LETTER_UPLOAD = "hw-pre-approval-letter-upload",
  HW_PRE_APPROVAL_LETTER_APPROVED_CUSTOMER = "hw-pre-approval-letter-approved-customer",
  HW_PRE_APPROVAL_LETTER_PURCHASE_AMOUNT = "hw-pre-approval-letter-purchase-amount",
  HW_PRE_APPROVAL_LETTER_APPROVED = "hw-pre-approval-letter-approved",
  HW_SELLER_PURCHASE_CONTRACT = "hw-seller-purchase-contract",
  HW_SELLER_PURCHASE_CONTRACT_UPLOAD = "hw-seller-purchase-contract-upload",
  HW_SELLER_PURCHASE_CONTRACT_FUND_NAME = "hw-seller-purchase-contract-fund-name",
  HW_SELLER_PURCHASE_CONTRACT_PURCHASE_PRICE = "hw-seller-purchase-contract-purchase-price",
  HW_SELLER_PURCHASE_CONTRACT_EXECUTED = "hw-seller-purchase-contract-executed",
  HW_SELLER_PURCHASE_CONTRACT_PROPERTY_DETAILS_MATCH = "hw-seller-purchase-contract-property-details-match",
  HW_SELLER_PURCHASE_CONTRACT_SALE_PRICE_MATCH = "hw-seller-purchase-contract-sale-price-match",
  HW_FULL_SERVICE_AGREEMENT = "hw-full-service-agreement",
  HW_FULL_SERVICE_AGREEMENT_UPLOAD = "hw-full-service-agreement-upload",
  HW_FULL_SERVICE_AGREEMENT_APPROVED = "hw-full-service-agreement-approved",
  HW_SERVICE_AGREEMENT_ADDENDUM = "hw-service-agreement-addendum",
  HW_SERVICE_AGREEMENT_ADDENDUM_UPLOAD = "hw-service-agreement-addendum-upload",
  HW_SERVICE_AGREEMENT_ADDENDUM_SIGNED = "hw-full-service-agreement-addendum-signed",
  HW_SERVICE_AGREEMENT_ADDENDUM_ADDRESS_CHECK = "hw-service-agreement-addendum-address-check",
  HW_SERVICE_AGREEMENT_ADDENDUM_EMD_PCT = "hw-service-agreement-addendum-emd-pct",
  HW_SERVICE_AGREEMENT_ADDENDUM_CONTRACT_PRICE = "hw-service-agreement-addendum-contract-price",
  HW_SERVICE_AGREEMENT_ADDENDUM_CONVENIENCE_FEE = "hw-service-agreement-addendum-convenience-fee",
  HW_SERVICE_AGREEMENT_ADDENDUM_EXECUTED = "hw-service-agreement-addendum-executed",
  HW_SERVICE_AGREEMENT_ADDENDUM_PROPERTY_ADDRESS_MATCH = "hw-service-agreement-addendum-property-address-match",
  HW_SERVICE_AGREEMENT_ADDENDUM_PROPERTY_STATE = "hw-service-agreement-addendum-property-state",
  HW_LBR_SERVICE_AGREEMENT_WAIVER = "hw-lbr-service-agreement-waiver",
  HW_LBR_SERVICE_AGREEMENT_WAIVER_UPLOAD = "hw-lbr-service-agreement-waiver-upload",
  HW_LBR_SERVICE_AGREEMENT_WAIVER_BUYER_SIGN = "hw-lbr-service-agreement-waiver-buyer-sign",
  HW_LBR_SERVICE_AGREEMENT_WAIVER_AGENT_SIGN = "hw-lbr-service-agreement-waiver-agent-sign",
  HW_LEASE_AGREEMENT = "hw-lease-agreement",
  HW_LEASE_AGREEMENT_UPLOAD = "hw-lease-agreement-upload",
  HW_LEASE_AGREEMENT_SIGNED = "hw-lease-agreement-signed",
  HW_LEASE_AGREEMENT_RENT_AMOUNT = "hw-lease-agreement-rent-amount",
  HW_LEASE_AGREEMENT_RENT_ADDRESS = "hw-lease-agreement-rent-address",
  HW_BPO_APPRAISAL = "hw-bpo-appraisal",
  HW_BPO_APPRAISAL_UPLOAD = "hw-bpo-appraisal-upload",
  HW_BPO_APPRAISAL_BPO_REPORT = "hw-bpo-appraisal-bpo-report",
  HW_BPO_APPRAISAL_WAIVER_SIGNED = "hw-bpo-appraisal-waiver-signed",
  HW_BPO_APPRAISAL_APPRAISAL_REPORT = "hw-bpo-appraisal-appraisal-report",
  HW_BPO_ADDRESS_MATCH = "hw-bpo-appraisal-address-match",
  HW_BPO_VALUATION_MATCH = "hw-bpo-appraisal-valuation-match",
  HW_BPO_VALUATION_METHODOLOGY_MATCH = "hw-bpo-appraisal-valuation-methodology-match",
  HW_INSPECTION_REPORT = "hw-inspection-report",
  HW_INSPECTION_REPORT_UPLOAD = "hw-inspection-report-upload",
  HW_INSPECTION_REPORT_APPROVED = "hw-inspection-report-approved",
  HW_TITLE_COMMITMENT = "hw-title-commitment",
  HW_TITLE_COMMITMENT_UPLOAD = "hw-title-commitment-upload",
  HW_TITLE_COMMITMENT_FUND_NAME = "hw-title-commitment-fund-name",
  HW_TITLE_COMMITMENT_LEGAL_DESCRIPTION = "hw-title-commitment-legal-description",
  HW_TITLE_COMMITMENT_CHECKED_EXCEPTIONS = "hw-title-commitment-checked-exceptions",
  HW_TITLE_COMMITMENT_QUALIFIED_TITLE_COMPANY = "hw-title-commitment-qualified-title-company",
  HW_TITLE_COMMITMENT_ADDRESS = "hw-title-commitment-address",
  HW_FEMA_FLOOD_ZONE_MAP = "hw-fema-flood-zone-map",
  HW_FEMA_FLOOD_ZONE_MAP_UPLOAD = "hw-fema-flood-zone-map-upload",
  HW_FEMA_FLOOD_MINIMAL_FLOOD_INSURANCE = "hw-eoi-fema-flood-minimal-flood-insurance",
  HW_EOI = "hw-eoi",
  HW_EOI_UPLOAD = "hw-eoi-upload",
  HW_EOI_FUND_NAME = "hw-eoi-fund-name",
  HW_EOI_INSURED_AMOUNT = "hw-eoi-insured-amount",
  HW_EOI_APPROVED = "hw-eoi-approved",
  HW_EOI_ADDRESS = "hw-eoi-address",
  HW_EXECUTED_WARRANTY_DEED = "hw-executed-warranty-deed",
  HW_EXECUTED_WARRANTY_DEED_UPLOAD = "hw-executed-warranty-deed-upload",
  HW_EXECUTED_WARRANTY_DEED_BID_RECEIPT_ABSENCE = "hw-executed-warranty-deed-bid-receipt-absence",
  HW_EXECUTED_WARRANTY_DEED_VESTED_CORRECT_ENTITY = "hw-executed-warranty-deed-vested-correct-entity",
  HW_EXECUTED_WARRANTY_DEED_CORRECT_COUNTY = "hw-executed-warranty-deed-correct-county",
  HW_EXECUTED_WARRANTY_DEED_CORRECT_ADDRESS = "hw-executed-warranty-deed-correct-address",
  HW_EXECUTED_WARRANTY_DEED_LEGAL_DESCRIPTION = "hw-executed-warranty-deed-legal-description",
  HW_EXECUTED_MORTGAGE = "hw-executed-mortgage",
  HW_EXECUTED_MORTGAGE_UPLOAD = "hw-executed-mortgage-upload",
  HW_EXECUTED_MORTGAGE_APPROVED = "hw-executed-mortgage-approved",
  HW_RECORDED_DEED = "hw-recorded-deed",
  HW_RECORDED_DEED_UPLOAD = "hw-recorded-deed-upload",
  HW_RECORDED_DEED_CORRECT_COUNTY = "hw-recorded-deed-correct-county",
  HW_RECORDED_DEED_MATERIAL_CHANGES = "hw-recorded-deed-material-changes",
  HW_TITLE_POLICY = "hw-title-policy",
  HW_TITLE_POLICY_UPLOAD = "hw-title-policy-upload",
  HW_TITLE_POLICY_MATCHES_TITLE_COMMITMENT_ADDRESS = "hw-title-policy-matches-title-commitment-address",
  HW_TITLE_POLICY_MATCHES_TITLE_COMMITMENT_INSURED_VALUE = "hw-title-policy-matches-title-commitment-insured-value",
  HW_TITLE_POLICY_MATCHES_TITLE_COMMITMENT_INSURED_PARTY = "hw-title-policy-matches-title-commitment-insured-party",
  HW_TITLE_POLICY_MATCHES_TITLE_COMMITMENT_LEGAL_DESC = "hw-title-policy-matches-title-commitment-legal-desc",
  HW_TITLE_POLICY_MATCHES_TITLE_COMMITMENT = "hw-title-policy-matches-title-commitment",
  HW_PRE_CLOSE_HUD = "hw-pre-close-hud",
  HW_PRE_CLOSE_HUD_UPLOAD = "hw-pre-close-hud-upload",
  HW_PRE_CLOSE_HUD_APPROVED = "hw-pre-close-hud-approved",
  HW_PRE_CLOSE_HUD_ADDRESS = "hw-pre-close-hud-address",
  HW_PRE_CLOSE_HUD_PURCHASE_PRICE = "hw-pre-close-hud-purchase-price",
  HW_PRE_CLOSE_HUD_NET_PURCHASE_PRICE = "hw-pre-close-hud-net-purchase-price",
  HW_PRE_CLOSE_HUD_ACQUISITION_DATE = "hw-pre-close-hud-acquisition-date",
  HW_CLOSING_PROTECTION_LETTER = "hw-closing-protection-letter",
  HW_CLOSING_PROTECTION_LETTER_UPLOAD = "hw-closing-protection-letter-upload",
  HW_CLOSING_PROTECTION_LETTER_CORRECT_ATTN = "hw-closing-protection-letter-correct-attn",
  HW_ELIGIBLE_AGENT_LETTER = "hw-eligible-agent-letter",
  HW_ELIGIBLE_AGENT_LETTER_UPLOAD = "hw-eligible-agent-letter-upload",
  HW_ELIGIBLE_AGENT_LETTER_ADDRESS = "hw-eligible-agent-letter-address",
  HW_HUD = "hw-hud",
  HW_HUD_UPLOAD = "hw-hud-upload",
  HW_HUD_EXECUTED = "hw-hud-executed",
  HW_HUD_PROPERTY_DETAILS_MATCH = "hw-hud-property-details-match",
  HW_HUD_SALES_PRICE_MATCH = "hw-hud-sales-price-match",
  HW_HUD_MORTGAGE_DEBT_EXTINGUISHED = "hw-hud-mortage-debt-extinguished",
  HW_HUD_ADDRESS = "hw-hud-address",
  HW_HUD_NET_PURCHASE_PRICE = "hw-hud-net-purchase-price",
  HW_FUNDING_GOOD_TO_GO = "hw-funding-good-to-go",
  HW_HUD_INFO_EXTRACTED = "hw-hud-info-extracted",
  HW_INTERNAL_TRANSACTION_APPROVAL = "hw-internal-transaction-approval",
  HW_WIRE = "hw-wire",
  HW_WIRE_INFO_UPLOAD = "hw-wire-info-upload",
  HW_WIRE_READY_FOR_APPROVAL = "hw-wire-ready-for-approval",
  HW_WIRE_INITIATED = "hw-wire-initiated",
  HW_WIRE_CONFIRMATION_UPLOAD = "hw-wire-confirmation-upload",
  HW_WIRE_INFO = "hw-wire-info",
  HW_WIRE_INFO_APPROVED = "hw-wire-info-approved",
  HW_PURCHASE_CONTRACT_HW_AND_SELLER = "hw-purchase-contract-hw-and-seller",
  HW_PURCHASE_CONTRACT_HW_AND_SELLER_UPLOAD = "hw-purchase-contract-hw-and-seller-upload",
  HW_PURCHASE_CONTRACT_HW_AND_SELLER_ADDRESS = "hw-purchase-contract-hw-and-seller-address",
  HW_PURCHASE_CONTRACT_HW_AND_SELLER_PURCHASE_PRICE = "hw-purchase-contract-hw-and-seller-purchase-price",
  HW_PURCHASE_CONTRACT_HW_AND_SELLER_SIGNED = "hw-purchase-contract-hw-and-seller-signed",
  HW_PURCHASE_CONTRACT_BUYER_AND_HW = "hw-purchase-contract-buyer-and-hw",
  HW_PURCHASE_CONTRACT_BUYER_AND_HW_UPLOAD = "hw-purchase-contract-buyer-and-hw-upload",
  HW_PURCHASE_CONTRACT_BUYER_AND_HW_ADDRESS = "hw-purchase-contract-buyer-and-hw-address",
  HW_PURCHASE_CONTRACT_BUYER_AND_HW_SIGNED = "hw-purchase-contract-buyer-and-hw-signed",
  HW_SERVICE_AGREEMENT = "hw-service-agreement",
  HW_SERVICE_AGREEMENT_UPLOAD = "hw-service-agreement-upload",
  HW_SERVICE_AGREEMENT_ADDRESS = "hw-service-agreement-address",
  HW_SERVICE_AGREEMENT_SIGNED = "hw-service-agreement-signed",
  HW_FPA_SERVICE_AGREEMENT = "hw-fpa-service-agreement",
  HW_FPA_SERVICE_AGREEMENT_UPLOAD = "hw-fpa-service-agreement-upload",
  HW_FPA_SERVICE_AGREEMENT_ADDRESS = "hw-fpa-service-agreement-address",
  HW_FPA_SERVICE_AGREEMENT_QUALIFICATION = "hw-fpa-service-agreement-qualification",
  HW_BBC_SERVICE_AGREEMENT = "hw-bbc-service-agreement",
  HW_BBC_SERVICE_AGREEMENT_UPLOAD = "hw-bbc-service-agreement-upload",
  HW_BBC_SERVICE_AGREEMENT_ADDRESS = "hw-bbc-service-agreement-address",
  HW_BBC_SERVICE_AGREEMENT_SIGNED = "hw-bbc-service-agreement-signed",
  FH_PRE_APPROVAL_LETTER = "fh-pre-approval-letter",
  FH_PRE_APPROVAL_LETTER_UPLOAD = "fh-pre-approval-letter-upload",
  FH_PRE_APPROVAL_LETTER_APPROVED_AMOUNT = "fh-pre-approval-letter-approved-amount",
  FH_PRE_APPROVAL_LETTER_CREDIT_SCORE = "fh-pre-approval-letter-credit-score",
  FH_APPRAISAL = "fh-appraisal",
  FH_APPRAISAL_UPLOAD = "fh-appraisal-upload",
  FH_APPRAISAL_ADDRESS = "fh-appraisal-address",
  FH_APPRAISAL_VALUATION = "fh-appraisal-valuation",
  FH_SIGNED_IBUYER_AGREEMENT = "fh-signed-ibuyer-agreement",
  FH_SIGNED_IBUYER_AGREEMENT_UPLOAD = "fh-signed-ibuyer-agreement-upload",
  FH_SIGNED_IBUYER_AGREEMENT_SIGNED = "fh-signed-ibuyer-agreement-signed",
  FH_LOAN_ESTIMATE = "fh-loan-estimate",
  FH_LOAN_ESTIMATE_UPLOAD = "fh-loan-estimate-upload",
  FH_LOAN_ESTIMATE_ADDRESS = "fh-loan-estimate-address",
  FH_LOAN_ESTIMATE_AMOUNT = "fh-loan-estimate-amount",
  FH_TITLE_COMMITMENT = "fh-title-commitment",
  FH_TITLE_COMMITMENT_UPLOAD = "fh-title-commitment-upload",
  FH_TITLE_COMMITMENT_NO_EXCEPTIONS = "fh-title-commitment-no-exceptions",
  FH_TITLE_COMMITMENT_ADDRESS = "fh-title-commitment-address",
  FH_CLOSING_DISCLOSURE = "fh-closing-disclosure",
  FH_CLOSING_DISCLOSURE_UPLOAD = "fh-closing-disclosure-upload",
  FH_CLOSING_DISCLOSURE_FEES_MATCH = "fh-closing-disclosure-fees-match",
  FH_FUNDING_BALANCING_WORKSHEET_UPLOAD = "fh-funding-balancing-worksheet-upload",
  FH_WIRE_INSTRUCTIONS_UPLOAD = "fh-wire-instructions-upload",
  FH_VERIFY_LOAN_TAPE = "fh-verify-loan-tape",
  FH_VERIFY_LOAN_TAPE_COLLATERAL_AMOUNT = "fh-verify-loan-tape-collateral-amount",
  FH_VERIFY_LOAN_TAPE_WIRE_INSTRUCTIONS = "fh-verify-loan-tape-wire-instructions",
  FH_EXPORT_LOAN_TAPE = "fh-export-loan-tape",
  FH_SCHEDULE_WIRE = "fh-schedule-wire",
  FH_HUD = "fh-hud",
  FH_HUD_UPLOAD = "fh-hud-upload",
  FH_HUD_ADDRESS = "fh-hud-address",
  FH_HUD_SALE_PRICE = "fh-hud-sale-price",
  FH_HUD_PREV_MORTGAGE_PAID_OFF = "fh-hud-prev-mortgage-paid-off",
  FH_HUD_EXECUTED = "fh-hud-executed",
  FH_EXECUTED_DEED = "fh-executed-deed",
  FH_EXECUTED_DEED_UPLOAD = "fh-executed-deed-upload",
  FH_EXECUTED_DEED_GRANTEE = "fh-executed-deed-grantee",
  FH_EXECUTED_DEED_ADDRESS = "fh-executed-deed-address",
  FH_EXECUTED_DEED_CORRECT_COUNTY = "fh-executed-deed-correct-county",
  FH_EXECUTED_DEED_LEGAL_DESCRIPTION = "fh-executed-deed-legal-description",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE = "fh-signed-short-term-mortgage-note",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_UPLOAD = "fh-signed-short-term-mortgage-note-upload",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_ADDRESS = "fh-signed-short-term-mortgage-note-address",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_AMOUNT = "fh-signed-short-term-mortgage-note-amount",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_SIGNED = "fh-signed-short-term-mortgage-note-signed",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_MATURITY_DATE = "fh-signed-short-term-mortgage-note-maturity-date",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_MATURITY_DATE_MATCHES_TAPE = "fh-signed-short-term-mortgage-note-maturity-date-tape",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_ORIGINATION_DATE = "fh-signed-short-term-mortgage-note-origination-date",
  FH_SIGNED_SHORT_TERM_MORTGAGE_NOTE_ORIGINATION_DATE_MATCHES_TAPE = "fh-signed-short-term-mortgage-note-origination-date-tape",
  FH_POWER_OF_ATTORNEY_UPLOAD = "fh-power-of-attorney-upload",
  FH_CLOSE_REFI = "fh-close-refi",
  ZAVVIE_SIGNED_CASH_CONTRACT_UPLOAD = "zavvie-signed-cash-contract-upload",
  ZAVVIE_SIGNED_BUY_BACK_CONTRACT = "zavvie-signed-buy-back-contract",
  ZAVVIE_SIGNED_BUY_BACK_CONTRACT_UPLOAD = "zavvie-signed-buy-back-contract-upload",
  ZAVVIE_SIGNED_BUY_BACK_CONTRACT_ADDRESS = "zavvie-signed-buy-back-contract-address",
  ZAVVIE_SIGNED_BUY_BACK_CONTRACT_AMOUNT = "zavvie-signed-buy-back-contract-amount",
  ZAVVIE_SIGNED_BUY_BACK_CONTRACT_SIGNED = "zavvie-signed-buy-back-contract-signed",
  ZAVVIE_CLIENT_DEPOSIT_INVOICE_SENT_UPLOAD = "zavvie-client-deposit-invoice-sent-upload",
  ZAVVIE_CLIENT_DEPOSIT_RECEIVED = "zavvie-client-deposit-received",
  ZAVVIE_EARNEST_MONEY_SENT = "zavvie-earnest-money-sent",
  ZAVVIE_AVM = "zavvie-avm",
  ZAVVIE_AVM_UPLOAD = "zavvie-avm-upload",
  ZAVVIE_AVM_APPROVED = "zavvie-avm-approved",
  ZAVVIE_INSPECTION_UPLOAD = "zavvie-inspection-upload",
  ZAVVIE_HUD_STATEMENT_TRANSACTION_1 = "zavvie-hud-statement-transaction-1",
  ZAVVIE_HUD_STATEMENT_TRANSACTION_1_UPLOAD = "zavvie-hud-statement-transaction-1-upload",
  ZAVVIE_HUD_STATEMENT_TRANSACTION_1_APPROVED = "zavvie-hud-statement-transaction-1-approved",
  ZAVVIE_EARNEST_MONEY_RECEIVED = "zavvie-earnest-money-received",
  ZAVVIE_FUNDS_WIRED = "zavvie-funds-wired",
  ZAVVIE_AT_CLOSE_HUD_TRANSACTION_1 = "zavvie-at-close-hud-transaction-1",
  ZAVVIE_AT_CLOSE_HUD_TRANSACTION_1_UPLOAD = "zavvie-at-close-hud-transaction-1-upload",
  ZAVVIE_AT_CLOSE_HUD_TRANSACTION_1_ADDRESS = "zavvie-at-close-hud-transaction-1-address",
  ZAVVIE_AT_CLOSE_HUD_TRANSACTION_1_SALE_PRICE = "zavvie-at-close-hud-transaction-1-sale-price",
  ZAVVIE_AT_CLOSE_HUD_TRANSACTION_1_PREV_MORTGAGE_PAID_OFF = "zavvie-at-close-hud-transaction-1-prev-mortgage-paid-off",
  ZAVVIE_AT_CLOSE_HUD_TRANSACTION_1_EXECUTED = "zavvie-at-close-hud-transaction-1-executed",
  ZAVVIE_HUD_STATEMENT_TRANSACTION_2_UPLOAD = "zavvie-hud-statement-transaction-2-upload",
  ZAVVIE_DEPOSIT_REFUND_OR_ADDITIONAL_INVOICE_SENT_UPLOAD = "zavvie-deposit-refund-or-additional-invoice-sent-upload",
  ZAVVIE_CLOSE_SALE = "zavvie-close-sale",
  ZAVVIE_EOI_UPLOAD = "zavvie-eoi-upload",
  ZAVVIE_SIGNED_LEASE_UPLOAD = "zavvie-signed-lease-upload",
  ZAVVIE_DOC_INVOICE_SENT = "zavvie-doc-invoice-sent",
  ZAVVIE_INVOICE_PAID = "zavvie-invoice-paid",
  ZAVVIE_8Z_CLIENT_DEPOSIT_INVOICE_SENT_UPLOAD = "zavvie-8z-client-deposit-invoice-sent-upload",
  ZAVVIE_8Z_SIGNED_BUY_BACK_CONTRACT_UPLOAD = "zavvie-8z-signed-buy-back-contract-upload",
  ZAVVIE_8Z_SIGNED_CASH_CONTRACT_UPLOAD = "zavvie-8z-signed-cash-contract-upload",
  ZAVVIE_8Z_CLIENT_DEPOSIT_RECEIVED = "zavvie-8z-client-deposit-received",
  ZAVVIE_8Z_AVM_UPLOAD = "zavvie-8z-avm-upload",
  ZAVVIE_8Z_INSPECTION_UPLOAD = "zavvie-8z-inspection-upload",
  ZAVVIE_8Z_HUD_SETTLEMENT_STATEMENT_TRANSACTION_1_UPLOAD = "zavvie-8z-hud-settlement-statement-transaction-1-upload",
  ZAVVIE_8Z_HUD_SETTLEMENT_STATEMENT_TRANSACTION_2_UPLOAD = "zavvie-8z-hud-settlement-statement-transaction-2-upload",
  ZAVVIE_8Z_DEPOSIT_REFUND_OR_ADDITIONAL_INVOICE_SENT_UPLOAD = "zavvie-8z-deposit-refund-or-additional-invoice-sent-upload",
  ZAVVIE_8Z_EOI_UPLOAD = "zavvie-8z-eoi-upload",
  ZAVVIE_8Z_SIGNED_LEASE_UPLOAD = "zavvie-8z-signed-lease-upload",
  OPENDOOR_SETTLEMENT_STATEMENT = "opendoor-settlement-statement",
  OPENDOOR_SETTLEMENT_STATEMENT_UPLOAD = "opendoor-settlement-statement-upload",
  OPENDOOR_SETTLEMENT_STATEMENT_CONFIRM_BUYER = "opendoor-settlement-statement-confirm-buyer",
  OPENDOOR_SETTLEMENT_STATEMENT_CONFIRM_SELLER = "opendoor-settlement-statement-confirm-seller",
  OPENDOOR_SETTLEMENT_STATEMENT_CONFIRM_ADDRESS = "opendoor-settlement-statement-confirm-address",
  OPENDOOR_SETTLEMENT_STATEMENT_CONFIRM_DATE = "opendoor-settlement-statement-confirm-date",
  OPENDOOR_SETTLEMENT_STATEMENT_CONFIRM_SALES_PRICE = "opendoor-settlement-statement-confirm-sales-price",
  OPENDOOR_SETTLEMENT_STATEMENT_SERVICE_CHARGE = "opendoor-settlement-statement-service-charge",
  OPENDOOR_SETTLEMENT_STATEMENT_REPAIRS_IN_CONTRACT = "opendoor-settlement-statement-repairs-in-contract",
  OPENDOOR_VESTING_DEED = "opendoor-vesting-deed",
  OPENDOOR_VESTING_DEED_UPLOAD = "opendoor-vesting-deed-upload",
  OPENDOOR_VESTING_DEED_CONFIRM_GRANTEE = "opendoor-vesting-deed-confirm-grantee",
  OPENDOOR_VESTING_DEED_CONFIRM_LEGAL_DESC = "opendoor-vesting-deed-confirm-legal-desc",
  OPENDOOR_PROPOSED_DEED = "opendoor-proposed-deed",
  OPENDOOR_PROPOSED_DEED_UPLOAD = "opendoor-proposed-deed-upload",
  OPENDOOR_PROPOSED_DEED_CONFIRM_GRANTEE = "opendoor-proposed-deed-confirm-grantee",
  OPENDOOR_PROPOSED_DEED_CONFIRM_ADDRESS = "opendoor-proposed-deed-confirm-address",
  OPENDOOR_PROPOSED_DEED_CONFIRM_GRANTOR = "opendoor-proposed-deed-confirm-grantor",
  OPENDOOR_PROPOSED_DEED_CONFIRM_LEGAL_DESC = "opendoor-proposed-deed-confirm-legal-desc",
  OPENDOOR_MARKED_COMMITMENT = "opendoor-marked-commitment",
  OPENDOOR_MARKED_COMMITMENT_UPLOAD = "opendoor-marked-commitment-upload",
  OPENDOOR_MARKED_COMMITMENT_OWNER_INSURED = "opendoor-marked-commitment-owner-insured",
  OPENDOOR_MARKED_COMMITMENT_CONFIRM_EFFECTIVE_DATE = "opendoor-marked-commitment-confirm-effective-date",
  OPENDOOR_MARKED_COMMITMENT_FEE_SIMPLE_INTEREST = "opendoor-marked-commitment-fee-simple-interest",
  OPENDOOR_MARKED_COMMITMENT_CONFIRM_TITLE_VESTED = "opendoor-marked-commitment-confirm-title-vested",
  OPENDOOR_MARKED_COMMITMENT_CONFIRM_LEGAL_DESC = "opendoor-marked-commitment-confirm-legal-desc",
  OPENDOOR_MARKED_COMMITMENT_REQS_MARKED_THROUGH = "opendoor-marked-commitment-reqs-marked-through",
  OPENDOOR_MARKED_COMMITMENT_REVIEW_EXCEPTIONS = "opendoor-marked-commitment-review-exceptions",
  OPENDOOR_CONTRACT = "opendoor-contract",
  OPENDOOR_CONTRACT_UPLOAD = "opendoor-contract-upload",
  OPENDOOR_CONTRACT_SELLERS_LISTED = "opendoor-contract-sellers-listed",
  OPENDOOR_CONTRACT_CLOSE_DATE_CURRENT = "opendoor-contract-close-date-current",
  OPENDOOR_CONTRACT_EXECUTED = "opendoor-contract-executed",
  OPENDOOR_CONTRACT_SERVICE_CHARGES = "opendoor-contract-service-charges",
  OPENDOOR_CONTRACT_ADDENDUMS_ON_SETTLEMENT_STATEMENT = "opendoor-contract-addendums-on-settlement-statement",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT = "opendoor-final-settlement-statement",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_UPLOAD = "opendoor-final-settlement-statement-upload",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_EXECUTED = "opendoor-final-settlement-statement-executed",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_CERTIFIED_STAMP = "opendoor-final-settlement-statement-certified-stamp",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_MATCHES_PRELIM = "opendoor-final-settlement-statement-matches-prelim",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED = "opendoor-certified-or-recorded-deed",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_UPLOAD = "opendoor-certified-or-recorded-deed-upload",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_TYPE_SELECTED = "opendoor-certified-or-recorded-deed-type-selected",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_EXECUTED = "opendoor-certified-or-recorded-deed-executed",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_CONFIRM_COUNTY_OR_STAMP = "opendoor-certified-or-recorded-deed-confirm-county_or_stamp",
  OPENDOOR_OPENDOOR_CERTIFIED_OR_RECORDED_DEED_CONFIRM_GRANTEE = "opendoor-certified-or-recorded-deed-confirm-grantee",
  OPENDOOR_OPENDOOR_CERTIFIED_OR_RECORDED_DEED_MATCHES_PROPOSED_DEED = "opendoor-certified-or-recorded-deed-matches-proposed-deed",
  OPENDOOR_POA = "opendoor-poa",
  OPENDOOR_POA_UPLOADED_IF_NEEDED = "opendoor-poa-uploaded-if-needed",
  OPENDOOR_INTERVENING_DEED = "opendoor-intervening-deed",
  OPENDOOR_INTERVENING_DEED_UPLOADED_IF_NEEDED = "opendoor-intervening-deed-uploaded-if-needed",
  OPENDOOR_AMENDMENT = "opendoor-amendment",
  OPENDOOR_AMENDMENT_UPLOADED_IF_NEEDED = "opendoor-amendment-uploaded-if-needed",
  OPENDOOR_EXTERNAL_DOCUMENT_VERIFICATION = "opendoor-external-document-verification",
  OPENDOOR_BPO_SETPOINT_REVIEW = "opendoor-bpo-setpoint-review",
  OPENDOOR_BPO_SETPOINT_REVIEW_UPLOAD = "opendoor-bpo-setpoint-review-upload",
  OPENDOOR_BPO_SETPOINT_REVIEW_ADDRESS_MATCHES = "opendoor-bpo-setpoint-review-address-matches",
  OPENDOOR_BPO_SETPOINT_REVIEW_VALUE_MATCHES = "opendoor-bpo-setpoint-review-value-matches",
  OPENDOOR_BPO_SETPOINT_REVIEW_DATE_MATCHES = "opendoor-bpo-setpoint-review-date-matches",
  OPENDOOR_VESTING_DEED_SETPOINT_REVIEW = "opendoor-vesting-deed-setpoint-review",
  OPENDOOR_VESTING_DEED_SETPOINT_REVIEW_ADDRESS = "opendoor-vesting-deed-setpoint-address",
  OPENDOOR_VESTING_DEED_SETPOINT_REVIEW_LEGAL_NAMES = "opendoor-vesting-deed-setpoint-review-legal-names",
  OPENDOOR_MARKED_COMMITMENT_SETPOINT_REVIEW = "opendoor-marked-commitment-setpoint-review",
  OPENDOOR_MARKED_COMMITMENT_SETPOINT_REVIEW_CORRECT_ADDRESS = "opendoor-marked-commitment-setpoint-review-correct-address",
  OPENDOOR_MARKED_COMMITMENT_SETPOINT_REVIEW_QUALIFIED_TITLE_CO = "opendoor-marked-commitment-setpoint-review-qualified-title",
  OPENDOOR_MARKED_COMMITMENT_SETPOINT_REVIEW_LEGAL_NAME = "opendoor-marked-commitment-setpoint-review-legal-name",
  OPENDOOR_MARKED_COMMITMENT_SETPOINT_REVIEW_LEGAL_DESC = "opendoor-marked-commitment-setpoint-review-legal-desc",
  OPENDOOR_MARKED_COMMITMENT_SETPOINT_REVIEW_CLEAR_TITLE = "opendoor-marked-commitment-setpoint-review-clear-title",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_SETPOINT_REVIEW = "opendoor-final-settlement-statement-sp-review",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_SETPOINT_REVIEW_NET_PURCHASE_PRICE_VERIFICATION = "opendoor-final-settlement-statement-sp-review-net-price",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_SETPOINT_REVIEW_GROSS_PURCHASE_PRICE_VERIFICATION = "opendoor-final-settlement-statement-sp-review-gross-price",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_SETPOINT_REVIEW_SETTLEMENT_DATE_VERIFICATION = "opendoor-final-settlement-statement-sp-review-date",
  OPENDOOR_FINAL_SETTLEMENT_STATEMENT_SETPOINT_REVIEW_CERTIFIED = "opendoor-final-settlement-statement-sp-review-certified",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_SETPOINT_REVIEW = "opendoor-certified-deed-setpoint-review",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_SETPOINT_REVIEW_GRANTEE = "opendoor-certified-deed-setpoint-review-grantee",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_SETPOINT_REVIEW_ADDRESS = "opendoor-certified-deed-setpoint-review-address",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_SETPOINT_COUNTY = "opendoor-certified-deed-setpoint-review-county",
  OPENDOOR_CERTIFIED_OR_RECORDED_DEED_SETPOINT_REVIEW_LEGAL_DESC = "opendoor-certified-deed-setpoint-review-legal-desc",
  OPENDOOR_AMENDMENT_UPLOAD = "opendoor-amendment-upload",
  OPENDOOR_POA_UPLOAD = "opendoor-poa-upload",
  OPENDOOR_INTERVENING_DEED_UPLOAD = "opendoor-intervening-deed-upload",
  OPENDOOR_BUYER_SIGNED_SETTLEMENT_STATEMENT_UPLOAD = "opendoor-buyer-signed-settlement-statement-upload",
  OPENDOOR_SELLER_SIGNED_SETTLEMENT_STATEMENT_UPLOAD = "opendoor-seller-signed-settlement-statement-upload",
  REALI_PRE_CLOSE_HUD_PURCHASE_PRICE = "reali-pre-close-hud-purchase-price",
  BACKFLIP_ANTI_MONEY_LAUNDERING = "backflip-anti-money-laundering",
  BACKFLIP_ANTI_MONEY_LAUNDERING_UPLOAD = "backflip-anti-money-laundering-upload",
  BACKFLIP_ANTI_MONEY_LAUNDERING_CLEAR = "backflip-anti-money-laundering-clear",
  BACKFLIP_SIGNED_TERM_SHEET = "backflip-signed-term-sheet",
  BACKFLIP_SIGNED_TERM_SHEET_UPLOAD = "backflip-signed-term-sheet-upload",
  BACKFLIP_SIGNED_TERM_SHEET_SIGNED = "backflip-signed-term-sheet-signed",
  BACKFLIP_SIGNED_TERM_SHEET_MIN_CASH_RESERVES = "backflip-signed-term-sheet-min-cash-reserves",
  BACKFLIP_SIGNED_TERM_SHEET_FUNDING_AMOUNT_CONFIRMED = "backflip-signed-term-sheet-confirmed",
  BACKFLIP_SIGNED_TERM_SHEET_TOTAL_LOAN_AMOUNT = "backflip-signed-term-sheet-total-loan-amount",
  BACKFLIP_SIGNED_TERM_SHEET_INITIAL_LOAN_FUNDING_AMOUNT = "backflip-signed-term-sheet-initial-loan-funding-amount",
  BACKFLIP_SIGNED_TERM_SHEET_PURCHASE_PRICE = "backflip-signed-term-sheet-purchase-price",
  BACKFLIP_SIGNED_TERM_SHEET_REHAB_COSTS = "backflip-signed-term-sheet-rehab-costs",
  BACKFLIP_SIGNED_TERM_SHEET_AFTER_REPAIR_VALUE = "backflip-signed-term-sheet-after-repair-value",
  BACKFLIP_SIGNED_TERM_SHEET_CONSTRUCTION_BUDGET = "backflip-signed-term-sheet-construction-budget",
  BACKFLIP_SIGNED_TERM_SHEET_LOAN_TERM = "backflip-signed-term-sheet-loan-term",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE = "backflip-executed-mortgage-note",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_UPLOAD = "backflip-executed-mortgage-note-upload",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_ADDRESS = "backflip-executed-mortgage-note-address",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_AMOUNT = "backflip-executed-mortgage-note-amount",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_SIGNED = "backflip-executed-mortgage-note-signed",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_MATURITY_DATE_MATCHES_TAPE = "backflip-executed-mortgage-note-maturity-date-matches-tape",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_MATURITY_DATE = "backflip-executed-mortgage-note-maturity-date",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_ORIGINATION_DATE_MATCHES_TAPE = "backflip-executed-mortgage-note-orig-date-matches-tape",
  BACKFLIP_EXECUTED_MORTGAGE_NOTE_ORIGINATION_DATE = "backflip-executed-mortgage-note-origination-date",
  BACKFLIP_HUD = "backflip-hud",
  BACKFLIP_HUD_UPLOAD = "backflip-hud-upload",
  BACKFLIP_HUD_ADDRESS = "backflip-hud-address",
  BACKFLIP_HUD_PURCHASE_PRICE = "backflip-hud-purchase-price",
  BACKFLIP_HUD_LOAN_AMOUNT = "backflip-hud-loan-amount",
  BACKFLIP_HUD_PREV_MORTGAGE_PAID_OFF = "backflip-hud-prev-mortgage-paid-off",
  BACKFLIP_HUD_EXECUTED = "backflip-hud-executed",
  BACKFLIP_DOCS_SENT_TO_CUSTODIAN = "backflip-docs-sent-to-custodian",
  DOORVEST_REQUEST_FUNDING = "doorvest-request-funding",
  ORCHARD_EXECUTED_RIGHT_TO_PURCHASE_CONTRACT = "orchard-executed-right-to-purchase-contract",
  ORCHARD_EXECUTED_RIGHT_TO_PURCHASE_CONTRACT_UPLOAD = "orchard-executed-right-to-purchase-contract-upload",
  ORCHARD_EXECUTED_RIGHT_TO_PURCHASE_CONTRACT_ADDRESS = "orchard-executed-right-to-purchase-contract-address",
  ORCHARD_EXECUTED_RIGHT_TO_PURCHASE_CONTRACT_SIGNED = "orchard-executed-right-to-purchase-contract-signed",
  ORCHARD_EXECUTED_RIGHT_TO_PURCHASE_CONTRACT_TERM = "orchard-executed-right-to-purchase-contract-term",
  ORCHARD_EXECUTED_RIGHT_TO_PURCHASE_CONTRACT_TERM_MAX = "orchard-executed-right-to-purchase-contract-term-max",
  ORCHARD_EXECUTED_CONTRACT_A = "orchard-executed-contract-a",
  ORCHARD_EXECUTED_CONTRACT_A_UPLOAD = "orchard-executed-contract-a-upload",
  ORCHARD_EXECUTED_CONTRACT_A_ADDRESS = "orchard-executed-contract-a-address",
  ORCHARD_EXECUTED_CONTRACT_A_SIGNED = "orchard-executed-contract-a-signed",
  ORCHARD_LEASEBACK_AGREEMENT_A = "orchard-leaseback-agreement-a",
  ORCHARD_LEASEBACK_AGREEMENT_A_UPLOAD = "orchard-leaseback-agreement-a-upload",
  ORCHARD_LEASEBACK_AGREEMENT_A_ADDRESS = "orchard-leaseback-agreement-a-address",
  ORCHARD_LEASEBACK_AGREEMENT_A_SIGNED = "orchard-leaseback-agreement-a-signed",
  ORCHARD_EXECUTED_CONTRACT_B = "orchard-executed-contract-b",
  ORCHARD_EXECUTED_CONTRACT_B_UPLOAD = "orchard-executed-contract-b-upload",
  ORCHARD_EXECUTED_CONTRACT_B_ADDRESS = "orchard-executed-contract-b-address",
  ORCHARD_EXECUTED_CONTRACT_B_SIGNED = "orchard-executed-contract-b-signed",
  ORCHARD_LEASEBACK_AGREEMENT_B = "orchard-leaseback-agreement-b",
  ORCHARD_LEASEBACK_AGREEMENT_B_UPLOAD = "orchard-leaseback-agreement-b-upload",
  ORCHARD_LEASEBACK_AGREEMENT_B_ADDRESS = "orchard-leaseback-agreement-b-address",
  ORCHARD_LEASEBACK_AGREEMENT_B_SIGNED = "orchard-leaseback-agreement-b-signed",
  ORCHARD_LEASEBACK_AGREEMENT = "orchard-leaseback-agreement",
  ORCHARD_LEASEBACK_AGREEMENT_UPLOAD = "orchard-leaseback-agreement-upload",
  ORCHARD_LEASEBACK_AGREEMENT_ADDRESS = "orchard-leaseback-agreement-address",
  ORCHARD_LEASEBACK_AGREEMENT_SIGNED = "orchard-leaseback-agreement-signed",
  ORCHARD_UNDERWRITING_MODEL = "orchard-underwriting-model",
  ORCHARD_UNDERWRITING_MODEL_UPLOAD = "orchard-underwriting-model-upload",
  ORCHARD_UNDERWRITING_MODEL_ADDRESS = "orchard-underwriting-model-address",
  ORCHARD_UNRECORDED_WARRANTY_DEED_UPLOAD = "orchard-unrecorded-warranty-deed-upload",
  ORCHARD_RECORDED_WARRANTY_DEED = "orchard-recorded-warranty-deed",
  ORCHARD_RECORDED_WARRANTY_DEED_UPLOAD = "orchard-recorded-warranty-deed-upload",
  ORCHARD_RECORDED_WARRANTY_DEED_GRANTEE = "orchard-recorded-warranty-deed-grantee",
  ORCHARD_RECORDED_WARRANTY_DEED_ADDRESS = "orchard-recorded-warranty-deed-address",
  ORCHARD_RECORDED_WARRANTY_DEED_MATCHES_TITLE = "orchard-recorded-warranty-deed-matches-title",
  ORCHARD_TITLE_COMMITMENT = "orchard-title-commitment",
  ORCHARD_TITLE_COMMITMENT_UPLOAD = "orchard-title-commitment-upload",
  ORCHARD_TITLE_COMMITMENT_NO_EXCEPTIONS = "orchard-title-commitment-no-exceptions",
  ORCHARD_TITLE_COMMITMENT_ADDRESS = "orchard-title-commitment-address",
  ORCHARD_TITLE_COMMITMENT_LEGAL_NAME = "orchard-title-commitment-legal-name",
  ORCHARD_PSA = "orchard-psa",
  ORCHARD_PSA_UPLOAD = "orchard-psa-upload",
  ORCHARD_PSA_SIGNED = "orchard-psa-signed",
  ORCHARD_PSA_VALID_AMOUNT = "orchard-psa-valid-amount",
  ORCHARD_PSA_PURCHASE_PRICE = "orchard-psa-purchase-price",
  AMHERST_OWNERS_TITLE_POLICY_INSURED_VALUE_MATCHES_TITLE_COMMITMENT = "amherst-owners-title-policy-value-matches-title-commitment",
  AMHERST_OWNERS_TITLE_POLICY_INSURED_PARTY_MATCHES_TITLE_COMMITMENT = "amherst-owners-title-policy-party-matches-title-commitment",
  AMHERST_TITLE_COMMITMENT_POST_CLOSE = "amherst-title-commitment-post-close",
  AMHERST_TITLE_COMMITMENT_POST_CLOSE_UPLOAD = "amherst-title-commitment-post-close-upload",
  DIVVY_OPTION_TO_PURCHASE_UPLOAD = "divvy-signed-lease-agreement-purchase-option-upload",
  DEFAULT_EARNEST_MONEY_FUNDS_RECEIPT = "default-earnest-money-funds-receipt",
  DEFAULT_EARNEST_MONEY_FUNDS_RECEIPT_UPLOAD = "default-earnest-money-funds-receipt-upload",
  DEFAULT_EARNEST_MONEY_FUNDS_RECEIPT_APPROVED = "default-earnest-money-funds-receipt-approved",
  DEFAULT_PRE_APPROVAL_LETTER = "default-pre-approval-letter",
  DEFAULT_PRE_APPROVAL_LETTER_UPLOAD = "default-pre-approval-letter-upload",
  DEFAULT_PRE_APPROVAL_LETTER_APPROVED_AMOUNT = "default-pre-approval-letter-approved-amount",
  DEFAULT_PRE_APPROVAL_LETTER_CREDIT_SCORE = "default-pre-approval-letter-credit-score",
  DEFAULT_PRE_APPROVAL_LETTER_NAME = "default-pre-approval-letter-name",
  DEFAULT_PRE_APPROVAL_LETTER_LENDER = "default-pre-approval-letter-lender",
  DEFAULT_PRE_APPROVAL_LETTER_SETPOINT_REVIEW = "default-pre-approval-letter-setpoint-review",
  DEFAULT_PRE_APPROVAL_LETTER_SETPOINT_REVIEW_NAME = "default-pre-approval-letter-setpoint-review-name",
  DEFAULT_PRE_APPROVAL_LETTER_SETPOINT_REVIEW_APPROVED_AMOUNT = "default-pre-approval-letter-setpoint-review-approved-amount",
  DEFAULT_PRE_APPROVAL_LETTER_SETPOINT_REVIEW_LENDER = "default-pre-approval-letter-setpoint-review-lender",
  DEFAULT_ORDER_AVM = "default-order-avm",
  DEFAULT_UCC_FILED = "default-ucc-filed",
  DEFAULT_AUTO_FUNDED = "default-auto-funded",
  DEFAULT_AGREEMENT_PARTICIPATION = "default-agreement-participation",
  DEFAULT_AGREEMENT_PARTICIPATION_UPLOAD = "default-agreement-participation-upload",
  DEFAULT_AGREEMENT_PARTICIPATION_ORIGINATOR_SIGNATURE = "default-agreement-participation-originator-signature",
  DEFAULT_AGREEMENT_PARTICIPATION_COMPANY_SIGNATURE = "default-agreement-participation-company-signature",
  DEFAULT_AGREEMENT_EQUITY_SHARE = "default-agreement-equity-share",
  DEFAULT_AGREEMENT_EQUITY_SHARE_UPLOAD = "default-agreement-equity-share-upload",
  DEFAULT_AGREEMENT_EQUITY_SHARE_ORIGINATOR_SIGNATURE = "default-agreement-equity-share-originator-signature",
  DEFAULT_AGREEMENT_EQUITY_SHARE_COMPANY_SIGNATURE = "default-agreement-equity-share-company-signature",
  DEFAULT_AGREEMENT_JOINDER_LLC = "default-agreement-joinder-llc",
  DEFAULT_AGREEMENT_JOINDER_LLC_UPLOAD = "default-agreement-joinder-llc-upload",
  DEFAULT_AGREEMENT_JOINDER_LLC_ENTITY_NAME = "default-agreement-joinder-llc-entity-name",
  DEFAULT_AGREEMENT_JOINDER_LLC_ORIGINATOR_SIGNATURE = "default-agreement-joinder-llc-originator-signature",
  DEFAULT_AGREEMENT_JOINDER_LLC_ENTITY_SIGNATURE = "default-agreement-joinder-llc-entity-signature",
  DEFAULT_AGREEMENT_JOINDER_GUARANTY = "default-agreement-joinder-guaranty",
  DEFAULT_AGREEMENT_JOINDER_GUARANTY_UPLOAD = "default-agreement-joinder-guaranty-upload",
  DEFAULT_AGREEMENT_JOINDER_GUARANTY_ENTITY_NAME = "default-agreement-joinder-guaranty-entity-name",
  DEFAULT_AGREEMENT_JOINDER_GUARANTY_ORIGINATOR_SIGNATURE = "default-agreement-joinder-guaranty-originator-signature",
  DEFAULT_AGREEMENT_JOINDER_GUARANTY_LIST_OF_ENTITY_LLCS = "default-agreement-joinder-guaranty-list-entity-llcs",
  DEFAULT_EXTERNAL_VALUATION = "default-external-valuation",
  DEFAULT_EXTERNAL_VALUATION_UPLOAD = "default-external-valuation-upload",
  DEFAULT_EXTERNAL_VALUATION_APPROVED = "default-external-valuation-approved",
  DEFAULT_PRE_CLOSE_APPRAISAL = "default-pre-close-appraisal",
  DEFAULT_PRE_CLOSE_APPRAISAL_UPLOAD = "default-pre-close-appraisal-upload",
  DEFAULT_PRE_CLOSE_APPRAISAL_ADDRESS = "default-pre-close-appraisal-address",
  DEFAULT_PRE_CLOSE_APPRAISAL_DOCUMENT_TYPE = "default-pre-close-appraisal-document-type",
  DEFAULT_PRE_CLOSE_APPRAISAL_APPROVED = "default-pre-close-appraisal-approved",
  DEFAULT_PRE_CLOSE_APPRAISAL_AMOUNT = "default-pre-close-appraisal-amount",
  DEFAULT_PRE_CLOSE_APPRAISAL_DATE = "default-pre-close-appraisal-date",
  DEFAULT_PRE_CLOSE_AVM = "default-pre-close-avm",
  DEFAULT_PRE_CLOSE_AVM_UPLOAD = "default-pre-close-avm-upload",
  DEFAULT_PRE_CLOSE_AVM_APPROVED = "default-pre-close-avm-approved",
  DEFAULT_PRE_CLOSE_AVM_AMOUNT = "default-pre-close-avm-amount",
  DEFAULT_PRE_CLOSE_AVM_ADDRESS = "default-pre-close-avm-address",
  DEFAULT_APPRAISAL = "default-appraisal",
  DEFAULT_APPRAISAL_ORDER = "default-appraisal-order",
  DEFAULT_APPRAISAL_UPLOAD = "default-appraisal-upload",
  DEFAULT_APPRAISAL_ADDRESS = "default-appraisal-address",
  DEFAULT_APPRAISAL_VALUATION = "default-appraisal-valuation",
  DEFAULT_APPRAISAL_AGE = "default-appraisal-age",
  DEFAULT_APPRAISAL_AMOUNT = "default-appraisal-amount",
  DEFAULT_APPRAISAL_APPRAISER_RECONCILED = "default-appraisal-appraiser-reconciled",
  DEFAULT_APPRAISAL_SIGNED = "default-appraisal-signed",
  DEFAULT_APPRAISAL_SETPOINT_REVIEW = "default-appraisal-setpoint-review",
  DEFAULT_APPRAISAL_SETPOINT_REVIEW_ADDRESS = "default-appraisal-setpoint-review-address",
  DEFAULT_APPRAISAL_SETPOINT_REVIEW_AMOUNT = "default-appraisal-setpoint-review-amount",
  DEFAULT_SIGNED_CUSTOMER_AGREEMENT = "default-signed-customer-agreement",
  DEFAULT_SIGNED_CUSTOMER_AGREEMENT_UPLOAD = "default-signed-customer-agreement-upload",
  DEFAULT_SIGNED_CUSTOMER_AGREEMENT_SIGNED = "default-signed-customer-agreement-signed",
  DEFAULT_SIGNED_CUSTOMER_AGREEMENT_ADDRESS = "default-signed-customer-agreement-address",
  DEFAULT_SIGNED_CUSTOMER_AGREEMENT_PURCHASE_PRICE = "default-signed-customer-agreement-purchase-price",
  DEFAULT_SIGNED_CUSTOMER_AGREEMENT_FICO = "default-signed-customer-agreement-fico",
  DEFAULT_TITLE_COMMITMENT = "default-title-commitment",
  DEFAULT_TITLE_COMMITMENT_UPLOAD = "default-title-commitment-upload",
  DEFAULT_TITLE_COMMITMENT_NO_EXCEPTIONS = "default-title-commitment-no-exceptions",
  DEFAULT_TITLE_COMMITMENT_ADDRESS = "default-title-commitment-address",
  DEFAULT_TITLE_COMMITMENT_SIGNED = "default-title-commitment-signed",
  DEFAULT_TITLE_COMMITMENT_LEGAL_DESCRIPTION = "default-title-commitment-legal-description",
  DEFAULT_TITLE_COMMITMENT_LEGAL_NAME = "default-title-commitment-legal-name",
  DEFAULT_TITLE_COMMITMENT_LEGAL_MATCHES_DEED = "default-title-commitment-legal-matches-deed",
  DEFAULT_TITLE_COMMITMENT_AMOUNT = "default-title-commitment-amount",
  DEFAULT_TITLE_COMMITMENT_QUALIFIED_COMPANY = "default-title-commitment-qualified-company",
  DEFAULT_TITLE_COMMITMENT_MATERIAL_CHANGES = "default-title-commitment-material-changes",
  DEFAULT_TITLE_COMMITMENT_GRANTEE = "default-title-commitment-grantee",
  DEFAULT_TITLE_COMMITMENT_SETPOINT_REVIEW = "default-title-commitment-setpoint-review",
  DEFAULT_TITLE_COMMITMENT_SETPOINT_REVIEW_LEGAL_MATCHES_DEED = "default-title-commitment-setpoint-review-legal-matches-deed",
  DEFAULT_TITLE_COMMITMENT_SETPOINT_REVIEW_NO_EXCEPTIONS = "default-title-commitment-setpoint-review-no-exceptions",
  DEFAULT_TITLE_COMMITMENT_SETPOINT_REVIEW_QUALIFIED_COMPANY = "default-title-commitment-setpoint-review-qualified-company",
  DEFAULT_TITLE_COMMITMENT_SETPOINT_REVIEW_AMOUNT = "default-title-commitment-setpoint-review-amount",
  DEFAULT_TITLE_COMMITMENT_SETPOINT_REVIEW_MATERIAL_CHANGES = "default-title-commitment-setpoint-review-material-changes",
  DEFAULT_TITLE_POLICY = "default-title-policy",
  DEFAULT_TITLE_POLICY_UPLOAD = "default-title-policy-upload",
  DEFAULT_TITLE_POLICY_CORRECT = "default-title-policy-correct",
  DEFAULT_TITLE_POLICY_ADDRESS = "default-title-policy-address",
  DEFAULT_TITLE_POLICY_SIGNED = "default-title-policy-signed",
  DEFAULT_TITLE_POLICY_HOA_PRESENT = "default-title-policy-hoa-present",
  DEFAULT_TITLE_POLICY_HOA_TYPE = "default-title-policy-hoa-type",
  DEFAULT_HOA_AGREEMENT = "default-hoa-agreement",
  DEFAULT_HOA_AGREEMENT_UPLOAD = "default-hoa-agreement-upload",
  DEFAULT_HOA_AGREEMENT_APPROVED = "default-hoa-agreement-approved",
  DEFAULT_BPO = "default-bpo",
  DEFAULT_BPO_ORDER = "default-bpo-order",
  DEFAULT_BPO_UPLOAD = "default-bpo-upload",
  DEFAULT_BPO_APPROVED = "default-bpo-approved",
  DEFAULT_BPO_ADDRESS = "default-bpo-address",
  DEFAULT_BPO_AMOUNT_MATCHES_DATA_TAPE = "default-bpo-amount-matches-data-tape",
  DEFAULT_BPO_DATE_MATCHES_DATA_TAPE = "default-bpo-date-matches-data-tape",
  DEFAULT_BPO_AMOUNT = "default-bpo-amount",
  DEFAULT_BPO_VALID_DATE = "default-bpo-valid-date",
  DEFAULT_INTERIOR_BPO = "default-interior-bpo",
  DEFAULT_INTERIOR_BPO_ORDER = "default-interior-bpo-order",
  DEFAULT_INTERIOR_BPO_UPLOAD = "default-interior-bpo-upload",
  DEFAULT_INTERIOR_BPO_APPROVED = "default-interior-bpo-approved",
  DEFAULT_INTERIOR_BPO_ADDRESS = "default-interior-bpo-address",
  DEFAULT_INTERIOR_BPO_AMOUNT_MATCHES_DATA_TAPE = "default-interior-bpo-amount-matches-data-tape",
  DEFAULT_INTERIOR_BPO_DATE_MATCHES_DATA_TAPE = "default-interior-bpo-date-matches-data-tape",
  DEFAULT_INTERIOR_BPO_AMOUNT = "default-interior-bpo-amount",
  DEFAULT_INTERIOR_BPO_VALID_DATE = "default-interior-bpo-valid-date",
  DEFAULT_BPO_STANDARD = "default-bpo-standard",
  DEFAULT_BPO_STANDARD_UPLOAD = "default-bpo-standard-upload",
  DEFAULT_BPO_STANDARD_ADDRESS = "default-bpo-standard-address",
  DEFAULT_BPO_STANDARD_AMOUNT = "default-bpo-standard-amount",
  DEFAULT_BPO_STANDARD_APPROVED = "default-bpo-standard-approved",
  DEFAULT_BPO_STANDARD_VALID_DATE = "default-bpo-standard-valid-date",
  DEFAULT_DEPARTING_PROPERTY_PRE_CLOSE_AVM = "default-departing-property-pre-close-avm",
  DEFAULT_DEPARTING_PROPERTY_PRE_CLOSE_AVM_UPLOAD = "default-departing-property-pre-close-avm-upload",
  DEFAULT_DEPARTING_PROPERTY_PRE_CLOSE_AVM_AMOUNT = "default-departing-property-pre-close-avm-amount",
  DEFAULT_DEPARTING_PROPERTY_PRE_CLOSE_AVM_ADDRESS = "default-departing-property-pre-close-avm-address",
  DEFAULT_DEPARTING_PROPERTY_PRE_CLOSE_AVM_APPROVED = "default-departing-property-pre-close-avm-approved",
  DEFAULT_COLLATERAL_AGENCY_AND_SECURITY_AGREEMENT = "default-collateral-agency-and-security-agreement",
  DEFAULT_COLLATERAL_AGENCY_AND_SECURITY_AGREEMENT_UPLOAD = "default-collateral-agency-and-security-agreement-upload",
  DEFAULT_COLLATERAL_AGENCY_AND_SECURITY_AGREEMENT_NAME = "default-collateral-agency-and-security-agreement-name",
  DEFAULT_COLLATERAL_AGENCY_AND_SECURITY_AGREEMENT_SIGNED = "default-collateral-agency-and-security-agreement-signed",
  DEFAULT_COLLATERAL_AGENCY_AND_SECURITY_AGREEMENT_ENTITY = "default-collateral-agency-and-security-agreement-entity",
  DEFAULT_INTERNAL_VALUATION = "default-internal-valuation",
  DEFAULT_INTERNAL_VALUATION_UPLOAD = "default-internal-valuation-upload",
  DEFAULT_INTERNAL_VALUATION_APPROVED = "default-internal-valuation-approved",
  DEFAULT_EOI = "default-eoi",
  DEFAULT_EOI_UPLOAD = "default-eoi-upload",
  DEFAULT_EOI_ADDRESS_MATCHES = "default-eoi-address-matches",
  DEFAULT_PRE_CLOSE_HUD = "default-pre-close-hud",
  DEFAULT_PRE_CLOSE_HUD_UPLOAD = "default-pre-close-hud-upload",
  DEFAULT_PRE_CLOSE_HUD_APPROVED = "default-pre-close-hud-approved",
  DEFAULT_MORTGAGE_PRE_APPROVAL = "default-mortgage-pre-approval",
  DEFAULT_MORTGAGE_PRE_APPROVAL_UPLOAD = "default-mortgage-pre-approval-upload",
  DEFAULT_MORTGAGE_PRE_APPROVAL_APPROVED_CLIENT = "default-mortgage-pre-approval-approved-client",
  DEFAULT_MORTGAGE_PRE_APPROVAL_CREDIT_VERIFIED = "default-mortgage-pre-approval-credit-verified",
  DEFAULT_MORTGAGE_PRE_APPROVAL_AMOUNT = "default-mortgage-pre-approval-amount",
  DEFAULT_WIRE_INSTRUCTIONS_UPLOAD = "default-wire-instructions-upload",
  DEFAULT_CONFIRM_WIRE_INSTRUCTIONS = "default-confirm-wire-instructions",
  DEFAULT_SCHEDULE_WIRE = "default-schedule-wire",
  DEFAULT_FUNDING_AMOUNT_CONFIRMED = "default-funding-amount-confirmed",
  DEFAULT_REQUEST_FUNDING = "default-request-funding",
  DEFAULT_FUNDED = "default-funded",
  DEFAULT_HUD = "default-hud",
  DEFAULT_HUD_UPLOAD = "default-hud-upload",
  DEFAULT_HUD_ADDRESS = "default-hud-address",
  DEFAULT_HUD_MSA = "default-hud-msa",
  DEFAULT_HUD_CLOSE_DATE = "default-hud-close-date",
  DEFAULT_HUD_SALE_PRICE = "default-hud-sale-price",
  DEFAULT_HUD_PREV_MORTGAGE_PAID_OFF = "default-hud-prev-mortgage-paid-off",
  DEFAULT_HUD_EXECUTED = "default-hud-executed",
  DEFAULT_HUD_BUYER_EXECUTED = "default-hud-buyer-executed",
  DEFAULT_HUD_HOA_MATCHES_DOCUMENTS = "default-hud-hoa-matches-documents",
  DEFAULT_HUD_FEES = "default-hud-fees",
  DEFAULT_HUD_ENTITY = "default-hud-entity",
  DEFAULT_HUD_SELLER = "default-hud-seller",
  DEFAULT_HUD_SALE_PRICE_W_FEES = "default-hud-sale-price-w-fees",
  DEFAULT_HUD_VERIFY_TAX = "default-hud-verify-tax",
  DEFAULT_HUD_LOAN_AMOUNT = "default-hud-loan-amount",
  DEFAULT_HUD_SETPOINT_REVIEW = "default-hud-setpoint-review",
  DEFAULT_HUD_SETPOINT_REVIEW_ADDRESS = "default-hud-setpoint-review-address",
  DEFAULT_HUD_SETPOINT_REVIEW_SALE_PRICE = "default-hud-setpoint-review-sale-price",
  DEFAULT_HUD_SETPOINT_REVIEW_SALE_PRICE_W_FEES = "default-hud-setpoint-review-sale-price-w-fees",
  DEFAULT_HUD_SETPOINT_REVIEW_FEES = "default-hud-setpoint-review-fees",
  DEFAULT_HUD_SETPOINT_REVIEW_VERIFY_TAX = "default-hud-setpoint-review-verify-tax",
  DEFAULT_EXECUTED_DEED = "default-executed-deed",
  DEFAULT_EXECUTED_DEED_UPLOAD = "default-executed-deed-upload",
  DEFAULT_EXECUTED_DEED_GRANTEE = "default-executed-deed-grantee",
  DEFAULT_EXECUTED_DEED_ADDRESS = "default-executed-deed-address",
  DEFAULT_EXECUTED_DEED_SIGNATURE = "default-executed-deed-signature",
  DEFAULT_EXECUTED_DEED_CORRECT_COUNTY = "default-executed-deed-correct-county",
  DEFAULT_EXECUTED_DEED_LEGAL_DESCRIPTION = "default-executed-deed-legal-description",
  DEFAULT_EXECUTED_DEED_PURCHASE_PRICE = "default-executed-deed-purchase-price",
  DEFAULT_LOAN_AND_SECURITY_AGREEMENT = "default-loan-and-security-agreement",
  DEFAULT_LOAN_AND_SECURITY_AGREEMENT_UPLOAD = "default-loan-and-security-agreement-upload",
  DEFAULT_LOAN_AND_SECURITY_AGREEMENT_SIGNED = "default-loan-and-security-agreement-signed",
  DEFAULT_LOAN_AND_SECURITY_AGREEMENT_ENTITY = "default-loan-and-security-agreement-entity",
  DEFAULT_LOAN_AND_SECURITY_AGREEMENT_LOAN_AMOUNT = "default-loan-and-security-agreement-loan-amount",
  DEFAULT_RECORDED_DEED = "default-recorded-deed",
  DEFAULT_RECORDED_DEED_UPLOAD = "default-recorded-deed-upload",
  DEFAULT_RECORDED_DEED_GRANTEE = "default-recorded-deed-grantee",
  DEFAULT_RECORDED_DEED_ADDRESS = "default-recorded-deed-address",
  DEFAULT_RECORDED_DEED_SIGNATURE = "default-recorded-deed-signature",
  DEFAULT_RECORDED_DEED_CORRECT_COUNTY = "default-recorded-deed-correct-county",
  DEFAULT_RECORDED_DEED_CORRECT_TYPE = "default-recorded-deed-correct-type",
  DEFAULT_RECORDED_DEED_LEGAL_DESCRIPTION = "default-recorded-deed-legal-description",
  DEFAULT_RECORDED_DEED_VERIFIED_VESTING = "default-recorded-deed-vesting",
  DEFAULT_RECORDED_DEED_PROPERTY_TYPE = "default-recorded-deed-property-type",
  DEFAULT_RECORDED_DEED_NO_MATERIAL_CHANGES = "default-recorded-deed-no-material-changes",
  DEFAULT_RECORDED_DEED_PURCHASE_PRICE = "default-recorded-deed-purchase-price",
  DEFAULT_RECORDED_DEED_SETPOINT_REVIEW = "default-recorded-deed-setpoint-review",
  DEFAULT_RECORDED_DEED_SETPOINT_REVIEW_ADDRESS = "default-recorded-deed-setpoint-review-address",
  DEFAULT_RECORDED_DEED_SETPOINT_REVIEW_CORRECT_COUNTY = "default-recorded-deed-setpoint-review-correct-county",
  DEFAULT_RECORDED_DEED_SETPOINT_REVIEW_LEGAL_DESCRIPTION = "default-recorded-deed-setpoint-review-legal-description",
  DEFAULT_RECORDED_DEED_SETPOINT_REVIEW_VERIFIED_VESTING = "default-recorded-deed-setpoint-review-verified-vesting",
  DEFAULT_RECORDED_DEED_SETPOINT_REVIEW_NO_MATERIAL_CHANGES = "default-recorded-deed-setpoint-review-no-material-changes",
  DEFAULT_RENOVATION_SCHEDULE = "default-renovation-schedule",
  DEFAULT_RENOVATION_SCHEDULE_UPLOAD = "default-renovation-schedule-upload",
  DEFAULT_RENOVATION_PHOTOS = "default-renovation-photos",
  DEFAULT_RENOVATION_EXPENSES = "default-renovation-expenses",
  DEFAULT_RENOVATION_EXPENSES_UPLOAD = "default-renovation-expenses-upload",
  DEFAULT_RENOVATION_EXPENSES_TOTAL_SUM = "default-renovation-expenses-total-sum",
  DEFAULT_RENOVATION_EXPENSES_MATCH = "default-renovation-expenses-match",
  DEFAULT_RENOVATION_EXPENSES_ADDRESS_MATCH = "default-renovation-address-match",
  DEFAULT_SIGNED_PURCHASE_CONTRACT = "default-signed-purchase-contract",
  DEFAULT_SIGNED_PURCHASE_CONTRACT_UPLOAD = "default-signed-purchase-contract-upload",
  DEFAULT_SIGNED_PURCHASE_CONTRACT_ADDRESS = "default-signed-purchase-contract-address",
  DEFAULT_SIGNED_PURCHASE_CONTRACT_MSA = "default-signed-purchase-contract-msa",
  DEFAULT_SIGNED_PURCHASE_CONTRACT_CLOSE_DATE = "default-signed-purchase-close-date",
  DEFAULT_SIGNED_PURCHASE_CONTRACT_AMOUNT = "default-signed-purchase-contract-amount",
  DEFAULT_SIGNED_PURCHASE_CONTRACT_SIGNED = "default-signed-purchase-contract-signed",
  DEFAULT_SIGNED_PURCHASE_CONTRACT_PURCHASE_PRICE = "default-signed-purchase-contract-purchase-price",
  DEFAULT_SIGNED_LEASE_AGREEMENT = "default-signed-lease-agreement",
  DEFAULT_SIGNED_LEASE_AGREEMENT_UPLOAD = "default-signed-lease-agreement-upload",
  DEFAULT_SIGNED_LEASE_AGREEMENT_SIGNED = "default-signed-lease-agreement-signed",
  DEFAULT_SIGNED_LEASE_AGREEMENT_LANDLORD_SIGNED = "default-signed-lease-agreement-landlord-signed",
  DEFAULT_SIGNED_LEASE_AGREEMENT_TENANT_SIGNED = "default-signed-lease-agreement-tenant-signed",
  DEFAULT_SIGNED_LEASE_AGREEMENT_JURISDICTION_TERMS = "default-signed-lease-agreement-jurisdiction-terms",
  DEFAULT_SIGNED_LEASE_AGREEMENT_AMOUNT = "default-signed-lease-agreement-amount",
  DEFAULT_SIGNED_LEASE_AGREEMENT_START_DATE = "default-signed-lease-agreement-start-date",
  DEFAULT_SIGNED_LEASE_AGREEMENT_EXPIRATION = "default-signed-lease-agreement-expiration",
  DEFAULT_SIGNED_LEASE_AGREEMENT_SECTION_8 = "default-signed-lease-agreement-section-8",
  DEFAULT_SIGNED_LEASE_AGREEMENT_PURCHASE_OPTION = "default-signed-lease-agreement-purchase-option",
  DEFAULT_SIGNED_LEASE_AGREEMENT_PRICE_MATCHES_LOAN_TAPE = "default-signed-lease-agreement-price-matches-loan-tape",
  DEFAULT_SIGNED_LEASE_AGREEMENT_PROPERTY_OCCUPATION = "default-signed-lease-agreement-property-occupation",
  DEFAULT_SIGNED_LEASE_AGREEMENT_NAME = "default-signed-lease-agreement-name",
  DEFAULT_SIGNED_LEASE_AGREEMENT_LANDLORD = "default-signed-lease-agreement-landlord",
  DEFAULT_SIGNED_LEASE_AGREEMENT_NO_EXCEPTIONS = "default-signed-lease-agreement-no-exceptions",
  DEFAULT_SIGNED_LEASE_AGREEMENT_ADDRESS = "default-signed-lease-agreement-address",
  DEFAULT_SIGNED_LEASE_AGREEMENT_SETPOINT_REVIEW = "default-signed-lease-agreement-setpoint-review",
  DEFAULT_SIGNED_LEASE_AGREEMENT_SETPOINT_REVIEW_ADDRESS = "default-signed-lease-agreement-setpoint-review-address",
  DEFAULT_SIGNED_LEASE_AGREEMENT_SETPOINT_REVIEW_START_DATE = "default-signed-lease-agreement-setpoint-review-start-date",
  DEFAULT_SIGNED_LEASE_AGREEMENT_SETPOINT_REVIEW_TENANT_SIGNED = "default-signed-lease-agreement-setpoint-review-tenant-signed",
  DEFAULT_SIGNED_LEASE_AGREEMENT_SETPOINT_REVIEW_AMOUNT = "default-signed-lease-agreement-setpoint-review-amount",
  DEFAULT_SUBSCRIPTION_DOCUMENT = "default-subscription-document",
  DEFAULT_SUBSCRIPTION_DOCUMENT_UPLOAD = "default-subscription-document-upload",
  DEFAULT_SUBSCRIPTION_DOCUMENT_ADDRESS = "default-subscription-document-address",
  DEFAULT_SUBSCRIPTION_DOCUMENT_SIGNED = "default-subscription-document-signed",
  DEFAULT_SUBSCRIPTION_DOCUMENT_ENTITY = "default-subscription-document-entity",
  DEFAULT_SUBSCRIPTION_DOCUMENT_EFFECTIVE_DATE = "default-subscription-document-effective-date",
  DEFAULT_TENANT_AGENCY_NOTICE = "default-tenant-agency-notice",
  DEFAULT_TITLE_PAYOFF = "default-title-payoff",
  DEFAULT_OPTION_TO_PURCHASE_REAL_ESTATE = "default-option-to-purchase-real-estate",
  DEFAULT_OPTION_TO_PURCHASE_REAL_ESTATE_UPLOAD = "default-option-to-purchase-real-estate-upload",
  DEFAULT_OPTION_TO_PURCHASE_REAL_ESTATE_NAME = "default-option-to-purchase-real-estate-name",
  DEFAULT_OPTION_TO_PURCHASE_REAL_ESTATE_SIGNED = "default-option-to-purchase-real-estate-signed",
  DEFAULT_OPTION_TO_PURCHASE_REAL_ESTATE_ENTITY = "default-option-to-purchase-real-estate-entity",
  DEFAULT_PURCHASE_AND_SALE_AGREEMENT = "default-purchase-and-sale-agreement",
  DEFAULT_PURCHASE_AND_SALE_AGREEMENT_UPLOAD = "default-purchase-and-sale-upload",
  DEFAULT_PURCHASE_AND_SALE_AGREEMENT_SIGNED = "default-purchase-and-sale-signed",
  DEFAULT_PURCHASE_AND_SALE_AGREEMENT_AMOUNT = "default-purchase-and-sale-amount",
  DEFAULT_PURCHASE_AND_SALE_AGREEMENT_ADDRESS = "default-purchase-and-sale-address",
  DEFAULT_EQUITY_ADVANCE_NOTE = "default-equity-advance-note",
  DEFAULT_EQUITY_ADVANCE_NOTE_UPLOAD = "default-equity-advance-note-upload",
  DEFAULT_EQUITY_ADVANCE_NOTE_ADDRESS = "default-equity-advance-note-address",
  DEFAULT_EQUITY_ADVANCE_NOTE_LOAN_AMOUNT = "default-equity-advance-note-loan-amount",
  DEFAULT_EQUITY_ADVANCE_NOTE_LOAN_ORIGINATION_DATE = "default-equity-advance-note-loan-origination-date",
  DEFAULT_EQUITY_ADVANCE_NOTE_SIGNED = "default-equity-advance-note-signed",
  DEFAULT_PURCHASE_OFFER = "default-purchase-offer",
  DEFAULT_PURCHASE_OFFER_UPLOAD = "default-purchase-offer-upload",
  DEFAULT_PURCHASE_OFFER_APPROVED = "default-purchase-offer-approved",
  DEFAULT_PURCHASE_OFFER_ADDRESS = "default-purchase-offer-address",
  DEFAULT_PURCHASE_OFFER_INITIALED = "default-purchase-offer-initialed",
  DEFAULT_PURCHASE_OFFER_EXECUTED = "default-purchase-offer-executed",
  DEFAULT_PURCHASE_OFFER_BINDING_DATE = "default-purchase-offer-binding-date",
  DEFAULT_EVIDENCE_OF_PERMITTED_INDEBTEDNESS = "default-evidence-of-permitted-indebtedness",
  DEFAULT_EVIDENCE_OF_PERMITTED_INDEBTEDNESS_UPLOAD = "default-evidence-of-permitted-indebtedness-upload",
  DEFAULT_EVIDENCE_OF_PERMITTED_INDEBTEDNESS_AMOUNT = "default-evidence-of-permitted-indebtedness-amount",
  DEFAULT_EXECUTED_MORTGAGE_NOTE = "default-executed-mortgage-note",
  DEFAULT_EXECUTED_MORTGAGE_NOTE_UPLOAD = "default-executed-mortgage-note-upload",
  DEFAULT_EXECUTED_MORTGAGE_NOTE_ADDRESS = "default-executed-mortgage-note-address",
  DEFAULT_EXECUTED_MORTGAGE_NOTE_AMOUNT = "default-executed-mortgage-note-amount",
  DEFAULT_EXECUTED_MORTGAGE_NOTE_SIGNED = "default-executed-mortgage-note-signed",
  DEFAULT_EXECUTED_MORTGAGE_NOTE_MATURITY_DATE = "default-executed-mortgage-note-maturity-date",
  DEFAULT_EXECUTED_MORTGAGE_NOTE_ORIGINATION_DATE = "default-executed-mortgage-note-origination-date",
  DEFAULT_PROMISSORY_NOTE_UPLOAD = "default-promissory-note-upload",
  DEFAULT_CLOSE_SALE = "default-close-sale",
  DEFAULT_PRE_CLOSE_EXTERNAL_DOCUMENT_VERIFICATION = "default-pre-close-external-document-verification",
  DEFAULT_AT_CLOSE_EXTERNAL_DOCUMENT_VERIFICATION = "default-at-close-external-document-verification",
  DEFAULT_VALUATION_REPORT = "default-valuation-report",
  DEFAULT_VALUATION_REPORT_UPLOAD = "default-valuation-report-upload",
  DEFAULT_INSPECTION_REPORT = "default-inspection-report",
  DEFAULT_INSPECTION_REPORT_UPLOAD = "default-inspection-report-upload",
  DEFAULT_INSPECTION_REPORT_ADDRESS = "default-inspection-report-address",
  DEFAULT_INSPECTION_REPORT_APPROVED = "default-inspection-report-approved",
  DEFAULT_INSPECTION_REPORT_SIGNED = "default-inspection-report-signed",
  DEFAULT_PROPERTY_INSURANCE = "default-property-insurance",
  DEFAULT_PROPERTY_INSURANCE_UPLOAD = "default-property-insurance-upload",
  DEFAULT_PROPERTY_INSURANCE_ADDRESS = "default-property-insurance-address",
  DEFAULT_PROPERTY_INSURANCE_DATE = "default-property-insurance-date",
  DEFAULT_PROPERTY_INSURANCE_YEARLY_COVERAGE = "default-property-insurance-correct-coverage",
  DEFAULT_PROPERTY_INSURANCE_COVERAGE_GT_ADVANCE = "default-property-insurance-coverage-gt-advance",
  DEFAULT_FLOOD_INSURANCE = "default-flood-insurance",
  DEFAULT_FLOOD_INSURANCE_UPLOAD = "default-flood-insurance-upload",
  DEFAULT_FLOOD_INSURANCE_ADDRESS = "default-flood-insurance-address",
  DEFAULT_FLOOD_INSURANCE_YEARLY_COVERAGE = "default-flood-insurance-correct-coverage",
  DEFAULT_SURVEY = "default-survey",
  DEFAULT_SURVEY_UPLOAD = "default-survey-upload",
  DEFAULT_SURVEY_ADDRESS = "default-survey-address",
  DEFAULT_OWNERS_TITLE_POLICY = "default-owners-title-policy",
  DEFAULT_OWNERS_TITLE_POLICY_UPLOAD = "default-owners-title-policy-upload",
  DEFAULT_OWNERS_TITLE_POLICY_ADDRESS = "default-owners-title-policy-address",
  DEFAULT_OWNERS_TITLE_POLICY_CLEAR = "default-owners-title-policy-clear",
  DEFAULT_OWNERS_TITLE_POLICY_QUALIFIED_COMPANY = "default-owners-title-policy-qualified-company",
  DEFAULT_OWNERS_TITLE_POLICY_MATCHES_TITLE_COMMITMENT = "default-owners-title-policy-matches-title-commitment",
  DEFAULT_OWNERS_TITLE_POLICY_MATCHES_RECORDED_DEED = "default-owners-title-policy-matches-recorded-deed",
  DEFAULT_OWNERS_TITLE_POLICY_LEGAL_DESCRIPTION = "default-owners-title-policy-legal-description",
  DEFAULT_OWNERS_TITLE_POLICY_AMOUNT = "default-owners-title-policy-amount",
  DEFAULT_FLOOD_CERT = "default-flood-cert",
  DEFAULT_FLOOD_CERT_UPLOAD = "default-flood-cert-upload",
  DEFAULT_FLOOD_CERT_ADDRESS = "default-flood-cert-address",
  DEFAULT_FLOOD_CERT_MATCHES_DATA_TAPE = "default-flood-cert-matches-data-tape",
  DEFAULT_TAX_REVIEW = "default-tax-review",
  DEFAULT_TAX_REVIEW_TAX_MATCHES_DATA_TAPE = "default-tax-review-tax-matches-data-tape",
  DEFAULT_EXTERNAL_DOCUMENT_VERIFICATION = "default-external-document-verification",
  DEFAULT_LOAN_TAPE = "default-loan-tape",
  DEFAULT_LOAN_TAPE_UPLOAD = "default-loan-tape-upload",
  DEFAULT_FUNDING_TAPE = "default-funding-tape",
  DEFAULT_FUNDING_TAPE_UPLOAD = "default-funding-tape-upload",
  DEFAULT_DOCUMENT_EXPORT = "default-document-export",
  DEFAULT_DEMO_DOCUMENT = "default-demo-document",
  DEFAULT_UNRECORDED_DEED = "default-unrecorded-deed",
  DEFAULT_UNRECORDED_DEED_UPLOAD = "default-unrecorded-deed-upload",
  DEFAULT_UNRECORDED_DEED_ADDRESS = "default-unrecorded-deed-address",
  DEFAULT_UNRECORDED_DEED_LEGAL_DESC = "default-unrecorded-deed-legal-desc",
  DEFAULT_UNRECORDED_DEED_SETPOINT_REVIEW = "default-unrecorded-deed-setpoint-review",
  DEFAULT_UNRECORDED_DEED_SETPOINT_REVIEW_ADDRESS = "default-unrecorded-deed-setpoint-review-address",
  DEFAULT_UNRECORDED_DEED_SETPOINT_REVIEW_LEGAL_DESC = "default-unrecorded-deed-setpoint-review-legal-desc",
  DEFAULT_TITLE_INSURANCE_POLICY = "default-title-insurance-policy",
  DEFAULT_TITLE_INSURANCE_POLICY_UPLOAD = "default-title-insurance-policy-upload",
  DEFAULT_TITLE_INSURANCE_POLICY_ADDRESS = "default-title-insurance-policy-address",
  DEFAULT_TITLE_INSURANCE_POLICY_INSURED_AMOUNT = "default-title-insurance-policy-insured-amount",
  DEFAULT_TITLE_INSURANCE_POLICY_LEGAL_DESC = "default-title-insurance-policy-legal-desc",
  DEFAULT_TITLE_INSURANCE_POLICY_NO_EXCEPTIONS = "default-title-insurance-policy-no-exceptions",
  DEFAULT_TITLE_INSURANCE_POLICY_NO_CHANGES = "default-title-insurance-policy-no-changes",
  DEFAULT_PSA = "default-psa",
  DEFAULT_PSA_UPLOAD = "default-psa-upload",
  DEFAULT_PSA_ADDRESS = "default-psa-address",
  DEFAULT_PSA_SIGNED = "default-psa-signed",
  DEFAULT_PSA_VALID_AMOUNT = "default-psa-valid-amount",
  DEFAULT_AUCTION_RECEIPT = "default-auction-receipt",
  DEFAULT_AUCTION_RECEIPT_UPLOAD = "default-auction-receipt-upload",
  DEFAULT_AUCTION_RECEIPT_ADDRESS = "default-auction-receipt-address",
  DEFAULT_AUCTION_RECEIPT_PURCHASE_PRICE = "default-auction-receipt-purchase-price",
  DEFAULT_TRANSFER_TITLE_COMMITMENT = "default-transfer-title-commitment",
  DEFAULT_TRANSFER_TITLE_COMMITMENT_UPLOAD = "default-transfer-title-commitment-upload",
  DEFAULT_TRANSFER_BPO = "default-transfer-bpo",
  DEFAULT_TRANSFER_BPO_UPLOAD = "default-transfer-bpo-upload",
  DEFAULT_TRANSFER_LEASE = "default-transfer-lease",
  DEFAULT_TRANSFER_LEASE_UPLOAD = "default-transfer-lease-upload",
  DEFAULT_TRANSFER_DEED = "default-transfer-deed",
  DEFAULT_TRANSFER_DEED_UPLOAD = "default-transfer-deed-upload",
  DEFAULT_TRANSFER_RECORDED_DEED = "default-transfer-recorded-deed",
  DEFAULT_TRANSFER_RECORDED_DEED_UPLOAD = "default-transfer-recorded-deed-upload",
  DEFAULT_TRANSFER_OWNERS_POLICY = "default-transfer-owners-policy",
  DEFAULT_TRANSFER_OWNERS_POLICY_UPLOAD = "default-transfer-owners-policy-upload",
  DEFAULT_CONSTRUCTION_DRAW = "default-construction-draw",
  DEFAULT_CONSTRUCTION_DRAW_UPLOAD = "default-construction-draw-upload",
  DEFAULT_CONSTRUCTION_DRAW_ADDRESS = "default-construction-draw-address",
  WANDER_ADDITIONAL_INSURANCE = "wander-additional-insurance",
  WANDER_ADDITIONAL_INSURANCE_UPLOAD = "wander-additional-insurance-upload",
  WANDER_ADDITIONAL_INSURANCE_ADDRESS = "wander-additional-insurance-address",
  WANDER_CERTIFICATE_OF_RESPONSIBLE = "wander-certificate-of-responsible",
  WANDER_CERTIFICATE_OF_RESPONSIBLE_UPLOAD = "wander-certificate-of-responsible-upload",
  WANDER_COLLATERAL_REPORT = "wander-collateral-report",
  WANDER_COLLATERAL_REPORT_UPLOAD = "wander-collateral-report-upload",
  WANDER_COLLATERAL_REPORT_APPRAISAL_AMOUNT = "wander-collateral-report-appraisal-amount",
  FIX_AND_FLIP_RESALE_SIGNED_PURCHASE_CONTRACT = "fix-and-flip-resale-signed-purchase-contract",
  FIX_AND_FLIP_RESALE_SIGNED_PURCHASE_CONTRACT_UPLOAD = "fix-and-flip-resale-signed-purchase-contract-upload",
  FIX_AND_FLIP_RESALE_SIGNED_PURCHASE_CONTRACT_ADDRESS = "fix-and-flip-resale-signed-purchase-contract-address",
  FIX_AND_FLIP_RESALE_SIGNED_PURCHASE_CONTRACT_CLOSE_DATE = "fix-and-flip-resale-signed-purchase-contract-close-date",
  FIX_AND_FLIP_RESALE_SIGNED_PURCHASE_CONTRACT_AMOUNT = "fix-and-flip-resale-signed-purchase-contract-amount",
  FIX_AND_FLIP_RESALE_SIGNED_PURCHASE_CONTRACT_SIGNED = "fix-and-flip-resale-signed-purchase-contract-signed",
  FIX_AND_FLIP_RESALE_HUD = "fix-and-flip-resale-hud",
  FIX_AND_FLIP_RESALE_HUD_UPLOAD = "fix-and-flip-resale-hud-upload",
  FIX_AND_FLIP_RESALE_HUD_ADDRESS = "fix-and-flip-resale-hud-address",
  FIX_AND_FLIP_RESALE_HUD_CLOSE_DATE = "fix-and-flip-resale-hud-close-date",
  FIX_AND_FLIP_RESALE_HUD_SALE_PRICE = "fix-and-flip-resale-hud-sale-price",
  FIX_AND_FLIP_RESALE_HUD_EXECUTED = "fix-and-flip-resale-hud-executed",
  FIX_AND_FLIP_RESALE_HUD_SELLER_EXECUTED = "fix-and-flip-resale-hud-seller-executed",
  FIX_AND_FLIP_RESALE_TITLE_COMMITMENT = "fix-and-flip-title-commitment",
  FIX_AND_FLIP_RESALE_TITLE_COMMITMENT_UPLOAD = "fix-and-flip-title-commitment-upload",
  FIX_AND_FLIP_RESALE_TITLE_COMMITMENT_ADDRESS = "fix-and-flip-title-commitment-address",
  FIX_AND_FLIP_RESALE_TITLE_COMMITMENT_SIGNED = "fix-and-flip-title-commitment-signed",
  FIX_AND_FLIP_RENOVATION_RECEIPTS_UPLOAD = "fix-and-flip-renovation-receipts-upload",
  BUYER_PRE_APPROVAL = "buyer-pre-approval",
  BUYER_PRE_APPROVAL_UPLOAD = "buyer-pre-approval-upload",
  BUYER_PRE_APPROVAL_APPROVED = "buyer-pre-approval-approved",
  BUYER_PRE_APPROVAL_SUMMARY = "buyer-pre-approval-summary",
  BUYER_PRE_APPROVAL_SUMMARY_UPLOAD = "buyer-pre-approval-summary-upload",
  BUYER_PRE_APPROVAL_SUMMARY_APPROVED = "buyer-pre-approval-summary-approved",
  BUYER_SIGNED_CUSTOMER_AGREEMENT = "buyer-signed-customer-agreement",
  BUYER_SIGNED_CUSTOMER_AGREEMENT_UPLOAD = "buyer-signed-customer-agreement-upload",
  BUYER_SIGNED_CUSTOMER_AGREEMENT_APPROVED = "buyer-signed-customer-agreement-approved",
  BUYER_BPO_ORDER = "buyer-bpo-order",
  BUYER_BPO_ORDER_UPLOAD = "buyer-bpo-order-upload",
  BUYER_APPRAISAL_ORDER = "buyer-appraisal-order",
  BUYER_APPRAISAL_ORDER_UPLOAD = "buyer-appraisal-order-upload",
  BUYER_AVM_ORDER = "buyer-avm-order",
  BUYER_CCM_ELIGIBILITY_LETTER = "buyer-ccm-eligibility-letter",
  BUYER_CCM_PROGRAM_AGREEMENT_UPLOAD = "buyer-program-agreement-upload",
  BUYER_MB_WELCOME_EMAIL = "buyer-mb-welcome-email",
  BUYER_MB_WELCOME_EMAIL_UPLOAD = "buyer-mb-welcome-email-upload",
  BUYER_MB_WELCOME_EMAIL_APPROVED = "buyer-mb-welcome-email-approved",
  BUYER_MB_PROGRAM_AGREEMENT = "buyer-mb-program-agreement",
  BUYER_MB_PROGRAM_AGREEMENT_UPLOAD = "buyer-mb-program-agreement-upload",
  BUYER_MB_PROGRAM_AGREEMENT_APPROVED = "buyer-mb-program-agreement-approved",
  BUYER_MB_OCCUPANCY_COST_GUIDE = "buyer-mb-occupancy-cost-guide",
  BUYER_MB_OCCUPANCY_COST_GUIDE_UPLOAD = "buyer-mb-occupancy-cost-guide-upload",
  BUYER_MB_OCCUPANCY_COST_GUIDE_APPROVED = "buyer-mb-occupancy-cost-guide-approved",
  BUYER_MB_PRE_APPROVAL = "buyer-mb-pre-approval",
  BUYER_MB_PRE_APPROVAL_UPLOAD = "buyer-mb-pre-approval-upload",
  BUYER_MB_PRE_APPROVAL_APPROVED = "buyer-mb-pre-approval-approved",
  BUYER_MB_EXCLUSIVE_LISTING_COMMITMENT_AGREEMENT = "buyer-mb-exclusive-listing-commitment-agreement",
  BUYER_MB_EXCLUSIVE_LISTING_COMMITMENT_AGREEMENT_UPLOAD = "buyer-mb-exclusive-listing-commitment-agreement-upload",
  BUYER_MB_EXCLUSIVE_LISTING_COMMITMENT_AGREEMENT_APPROVED = "buyer-mb-exclusive-listing-commitment-agreement-approved",
  BUYER_MB_PURCHASE_POWER = "buyer-mb-purchase-power",
  BUYER_MB_PURCHASE_POWER_UPLOAD = "buyer-mb-purchase-power-upload",
  BUYER_MB_PURCHASE_POWER_AMOUNT_SAVED = "buyer-mb-purchase-power-amount-saved",
  BUYER_MB_PROOF_OF_FUNDS = "buyer-mb-proof-of-funds",
  BUYER_MB_PROOF_OF_FUNDS_UPLOAD = "buyer-mb-proof-of-funds-upload",
  BUYER_MB_PROOF_OF_FUNDS_APPROVED = "buyer-mb-proof-of-funds-approved",
  BUYER_MB_PURCHASE_POWER_CERTIFICATE = "buyer-mb-purchase-power-certificate",
  BUYER_MB_PURCHASE_POWER_CERTIFICATE_UPLOAD = "buyer-mb-purchase-power-certificate-upload",
  BUYER_MB_PURCHASE_POWER_CERTIFICATE_APPROVED = "buyer-mb-purchase-power-certificate-approved",
  BUYER_MB_PURCHASE_AND_SALES_AGREEMENT = "buyer-mb-purchase-and-sales-agreement",
  BUYER_MB_PURCHASE_AND_SALES_AGREEMENT_UPLOAD = "buyer-mb-purchase-and-sales-agreement-upload",
  BUYER_MB_PURCHASE_AND_SALES_AGREEMENT_APPROVED = "buyer-mb-purchase-and-sales-agreement-approved",
  BUYER_MB_UNDER_CONTRACT_EMAIL = "buyer-mb-under-contract-email",
  BUYER_MB_UNDER_CONTRACT_EMAIL_UPLOAD = "buyer-mb-under-contract-email-upload",
  BUYER_MB_UNDER_CONTRACT_EMAIL_APPROVED = "buyer-mb-under-contract-email-approved",
  BUYER_MB_INSPECTION_REPORT = "buyer-mb-inspection-report",
  BUYER_MB_INSPECTION_REPORT_UPLOAD = "buyer-mb-inspection-report-upload",
  BUYER_MB_INSPECTION_REPORT_APPROVED = "buyer-mb-inspection-report-approved",
  BUYER_MB_APPRAISAL_REPORT = "buyer-mb-appraisal-report",
  BUYER_MB_APPRAISAL_REPORT_UPLOAD = "buyer-mb-appraisal-report-upload",
  BUYER_MB_APPRAISAL_REPORT_APPROVED = "buyer-mb-appraisal-report-approved",
  BUYER_MB_OCCUPANCY_AGREEMENT = "buyer-mb-occupancy-agreement",
  BUYER_MB_OCCUPANCY_AGREEMENT_UPLOAD = "buyer-mb-occupancy-agreement-upload",
  BUYER_MB_OCCUPANCY_AGREEMENT_APPROVED = "buyer-mb-occupancy-agreement-approved",
  BUYER_MB_SECONDARY_PURCHASE_AND_SALES_AGREEMENT = "buyer-mb-secondary-purchase-and-sales-agreement",
  BUYER_MB_SECONDARY_PURCHASE_AND_SALES_AGREEMENT_UPLOAD = "buyer-mb-secondary-purchase-and-sales-agreement-upload",
  BUYER_MB_SECONDARY_PURCHASE_AND_SALES_AGREEMENT_APPROVED = "buyer-mb-secondary-purchase-and-sales-agreement-approved",
  BUYER_MB_DUE_DILIGENCE_EMAIL = "buyer-mb-due-diligence-email",
  BUYER_MB_DUE_DILIGENCE_EMAIL_UPLOAD = "buyer-mb-due-diligence-email-upload",
  BUYER_MB_DUE_DILIGENCE_EMAIL_APPROVED = "buyer-mb-due-diligence-email-approved",
  BUYER_MB_NET_PURCHASE_PRICE = "buyer-net-purchase-price",
  BUYER_MB_GROSS_PURCHASE_PRICE = "buyer-gross-purchase-price",
  OSN_CONREX_TITLE_COMMITMENT = "osn-conrex-title-commitment",
  OSN_CONREX_TITLE_COMMITMENT_UPLOAD = "osn-conrex-title-commitment-upload",
  OSN_CONREX_TITLE_COMMITMENT_EFFECTIVE_DATE = "osn-conrex-title-commitment-effective-date",
  OSN_CONREX_TITLE_COMMITMENT_INSURANCE_AMOUNT = "osn-conrex-title-commitment-insurance-amount",
  OSN_CONREX_TITLE_COMMITMENT_ENTITY = "osn-conrex-title-commitment-entity",
  OSN_CONREX_TITLE_COMMITMENT_VESTED_INTEREST_FEE_SIMPLE = "osn-conrex-title-commitment-vested-interest-fee-simple",
  OSN_CONREX_TITLE_COMMITMENT_VESTED_INTEREST = "osn-conrex-title-commitment-vested-interest",
  OSN_CONREX_TITLE_COMMITMENT_LEGAL_DESCRIPTION_ADDRESS = "osn-conrex-title-commitment-legal-description-address",
  OSN_CONREX_TITLE_COMMITMENT_LEGAL_DESCRIPTION_TRANSFER_DEED = "osn-conrex-title-commitment-legal-description-transfer-deed",
  OSN_CONREX_TITLE_COMMITMENT_REQUIREMENTS_COMPLETE = "osn-conrex-title-commitment-requirements-complete",
  OSN_CONREX_TITLE_COMMITMENT_GAP_EXCEPTIONS = "osn-conrex-title-commitment-gap-exceptions",
  OSN_CONREX_TITLE_COMMITMENT_SPECIFIC_EXCEPTIONS = "osn-conrex-title-commitment-specific-exceptions",
  OSN_CONREX_PROPOSED_TRANSFER_DEED = "osn-conrex-proposed-transfer-deed",
  OSN_CONREX_PROPOSED_TRANSFER_DEED_UPLOAD = "osn-conrex-proposed-transfer-deed-upload",
  OSN_CONREX_PROPOSED_TRANSFER_DEED_GRANTEE = "osn-conrex-proposed-transfer-deed-grantee",
  OSN_CONREX_PROPOSED_TRANSFER_DEED_ENTITY = "osn-conrex-proposed-transfer-deed-entity",
  OSN_CONREX_PROPOSED_TRANSFER_DEED_ADDRESS = "osn-conrex-proposed-transfer-deed-address",
  OSN_CONREX_PROPOSED_TRANSFER_DEED_TITLE = "osn-conrex-proposed-transfer-deed-title",
  OSN_CONREX_VESTING_DEED = "osn-conrex-vesting-deed",
  OSN_CONREX_VESTING_DEED_UPLOAD = "osn-conrex-vesting-deed-upload",
  OSN_CONREX_VESTING_DEED_GRANTEE = "osn-conrex-vesting-deed-grantee",
  OSN_CONREX_VESTING_DEED_LEGAL_DESCRIPTION = "osn-conrex-vesting-deed-legal-description",
  OSN_CONREX_CCRS = "osn-conrex-ccrs",
  OSN_CONREX_CCRS_CHECK = "osn-conrex-ccrs-check",
  OSN_CONREX_INHERITED_TENANTS = "osn-conrex-inherited-tenants",
  OSN_CONREX_INHERITED_TENANTS_CHECK = "osn-conrex-inherited-tenants-check ",
  OSN_CONREX_HOA = "osn-conrex-hoa",
  OSN_CONREX_HOA_ACTIVE = "osn-conrex-hoa-active",
  OSN_CONREX_HOA_UPLOAD = "osn-conrex-hoa-upload",
  OSN_CONREX_EXECUTED_DEED = "osn-conrex-executed-deed",
  OSN_CONREX_EXECUTED_DEED_UPLOAD = "osn-conrex-executed-deed-upload",
  OSN_CONREX_EXECUTED_DEED_ALIGNMENT = "osn-conrex-executed-deed-alignment",
  OSN_CONREX_EXECUTED_DEED_SELLER_EXECUTION = "osn-conrex-executed-deed-seller-execution",
  OSN_CONREX_EXECUTED_DEED_NOTARIZED = "osn-conrex-executed-deed-notarized",
  OSN_CONREX_SETTLEMENT_STATEMENT = "osn-conrex-settlement-statement",
  OSN_CONREX_SETTLEMENT_STATEMENT_UPLOAD = "osn-conrex-settlement-statement-upload",
  OSN_CONREX_SETTLEMENT_STATEMENT_ENTITY = "osn-conrex-settlement-statement-entity",
  OSN_CONREX_SETTLEMENT_STATEMENT_SELLER = "osn-conrex-settlement-statement-seller",
  OSN_CONREX_SETTLEMENT_STATEMENT_PURCHASE_PRICE = "osn-conrex-settlement-statement-purchase-price",
  OSN_CONREX_SETTLEMENT_STATEMENT_CLOSING_DATE = "osn-conrex-settlement-statement-closing-date",
  OSN_CONREX_SETTLEMENT_STATEMENT_ADDRESS = "osn-conrex-settlement-statement-address",
  OSN_CONREX_FINAL_SETTLEMENT_STATEMENT = "osn-conrex-final-settlement-statement",
  OSN_CONREX_FINAL_SETTLEMENT_STATEMENT_UPLOAD = "osn-conrex-final-settlement-statement-upload",
  OSN_CONREX_FINAL_SETTLEMENT_STATEMENT_ALIGNMENT = "osn-conrex-final-settlement-statement-alignment",
  OSN_CONREX_FINAL_SETTLEMENT_STATEMENT_EXECUTED = "osn-conrex-final-settlement-statement-executed",
  OSN_CONREX_VESTING_DEED_WAIVER_UPLOAD = "osn-conrex-vesting-deed-waiver-upload",
  OSN_CONREX_CCRS_LEASING_RESTRICTIONS_UPLOAD = "osn-conrex-ccrs-leasing-restrictions-upload",
  OSN_CONREX_CONTRACT_ADDENDUMS_UPLOAD = "osn-conrex-contract-addendums-upload",
  OSN_CONREX_TITLE_COMMITMENT_EMAIL_CONFIRMATION_UPLOAD = "osn-conrex-title-commitment-email-confirmation-upload",
  OFFERPAD_HUD = "offerpad-hud",
  OFFERPAD_HUD_UPLOAD = "offerpad-hud-upload",
  OFFERPAD_HUD_ADDRESS = "offerpad-hud-address",
  OFFERPAD_HUD_ACQUISITION_DATE = "offerpad-hud-acquisition-date",
  OFFERPAD_HUD_PURCHASE_PRICE = "offerpad-hud-purchase-price",
  OFFERPAD_HUD_NET_PURCHASE_PRICE = "offerpad-hud-net-purchase-price",
  OFFERPAD_HUD_C_AND_R_ADDENDUM = "offerpad-hud-c-and-r-addendum",
  OFFERPAD_HUD_TRANSACTION_FEES = "offerpad-hud-transaction-fees",
  OFFERPAD_HUD_SIGNED = "offerpad-hud-signed",
  OFFERPAD_BPO = "offerpad-bpo",
  OFFERPAD_BPO_UPLOAD = "offerpad-bpo-upload",
  OFFERPAD_UPDATED_TITLE_COMMITMENT = "offerpad-updated-title-commitment",
  OFFERPAD_UPDATED_TITLE_COMMITMENT_UPLOAD = "offerpad-updated-title-commitment-upload",
  OFFERPAD_UPDATED_TITLE_COMMITMENT_NO_EXCEPTIONS = "offerpad-updated-title-commitment-no-exceptions",
  OFFERPAD_UPDATED_TITLE_COMMITMENT_ADDRESS = "offerpad-updated-title-commitment-address",
  OFFERPAD_UPDATED_TITLE_COMMITMENT_ENTITY = "offerpad-updated-title-commitment-entity",
  FUNDRISE_HUD_SETTLEMENT_STATEMENT = "fundrise-hud-settlement-statement",
  FUNDRISE_HUD_SETTLEMENT_STATEMENT_UPLOAD = "fundrise-hud-settlement-statement-upload",
  FUNDRISE_OCCUPANCY_CERTIFICATE = "fundrise-occupancy-certificate",
  FUNDRISE_OCCUPANCY_CERTIFICATE_UPLOAD = "fundrise-occupancy-certificate-upload",
  FUNDRISE_PURCHASE_AND_SALE_AGREEMENT = "fundrise-purchase-and-sale-agreement",
  FUNDRISE_PURCHASE_AND_SALE_AGREEMENT_UPLOAD = "fundrise-purchase-and-sale-agreement-upload",
  FUNDRISE_CLOSING_PACKAGE_UPLOAD = "fundrise-closing-package-upload",
  FUNDRISE_CLOSING_PACKAGE = "fundrise-closing-package",
  FUNDRISE_HUD_SETTLEMENT_STATEMENT_ADDRESS = "fundrise-hud-settlement-statement-address",
  FUNDRISE_HUD_SETTLEMENT_STATEMENT_PURCHASE_PRICE = "fundrise-hud-settlement-statement-purchase-price",
  FUNDRISE_HUD_SETTLEMENT_STATEMENT_PURCHASE_DATE = "fundrise-hud-settlement-statement-purchase-date",
  FUNDRISE_HUD_SETTLEMENT_STATEMENT_SIGNED = "fundrise-settlement-statement-signed",
  FUNDRISE_MORTGAGE_REVIEW = "fundrise-mortgage-review",
  FUNDRISE_MORTGAGE_REVIEW_UPLOAD = "fundrise-mortgage-review-upload",
  FUNDRISE_MORTGAGE_REVIEW_ADDRESS = "fundrise-mortgage-review-address",
  FUNDRISE_MORTGAGE_REVIEW_COUNTY = "fundrise-mortgage-review-county",
  FUNDRISE_MORTGAGE_REVIEW_LEGAL_DESCRIPTION = "fundrise-mortgage-review-legal-description",
  FUNDRISE_REHAB_INSPECTION = "fundrise-rehab-inspection",
  FUNDRISE_REHAB_INSPECTION_UPLOAD = "fundrise-rehab-inspection-upload",
  FUNDRISE_REHAB_INSPECTION_ADDRESS = "fundrise-rehab-inspection-address",
  FUNDRISE_REHAB_INSPECTION_VALUE = "fundrise-rehab-inspection-value",
  FUNDRISE_REHAB_INSPECTION_DATE = "fundrise-rehab-inspection-date",
  UE_EQUITY_ADVANCE_EXECUTED_DEED_GRANTEE = "ue-equity-advance-executed-deed-grantee",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET = "rtl-initial-loan-application-term-sheet",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET_UPLOAD = "rtl-initial-loan-application-term-sheet-upload",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET_CORRECT_DOC = "rtl-initial-loan-application-term-sheet-correct-doc",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET_EACH_MEMBER = "rtl-initial-loan-application-each-member",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET_SIGNED = "rtl-initial-loan-application-term-sheet-signed",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET_INITIAL_AMOUNT = "rtl-initial-loan-application-term-sheet-initial-amount",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET_TOTAL_AMOUNT = "rtl-initial-loan-application-term-sheet-total-amount",
  RTL_INITIAL_LOAN_APPLICATION_TERM_SHEET_FICO = "rtl-initial-loan-application-term-sheet-fico",
  RTL_TRACK_RECORD = "rtl-track-record",
  RTL_TRACK_RECORD_UPLOAD = "rtl-track-record-upload",
  RTL_TRACK_RECORD_CORRECT_DOC = "rtl-track-record-doc",
  RTL_TRACK_RECORD_VERIFIABLE = "rtl-track-record-verifiable",
  RTL_TRACK_RECORD_HAS_OA = "rtl-track-record-has-oa",
  RTL_TRACK_RECORD_HAS_REO_SHEET = "rtl-track-record-has-reo-sheet",
  RTL_PHOTO_ID = "rtl-photo-id",
  RTL_PHOTO_ID_UPLOAD = "rtl-photo-id-upload",
  RTL_PHOTO_ID_CORRECT_DOC = "rtl-photo-id-correct-doc",
  RTL_CREDIT_REPORT = "rtl-credit-report",
  RTL_CREDIT_REPORT_UPLOAD = "rtl-credit-report-upload",
  RTL_CREDIT_REPORT_CORRECT_DOC = "rtl-credit-report-correct-doc",
  RTL_CREDIT_REPORT_AGED = "rtl-credit-report-aged",
  RTL_CREDIT_REPORT_EACH_MEMBER = "rtl-credit-report-each-member",
  RTL_CREDIT_REPORT_FICO = "rtl-credit-report-fico",
  RTL_ASSET_DOCUMENTATION = "rtl-asset-documentation",
  RTL_ASSET_DOCUMENTATION_UPLOAD = "rtl-asset-documentation-upload",
  RTL_ASSET_DOCUMENTATION_CORRECT_DOC = "rtl-asset-documentation-correct-doc",
  RTL_ASSET_DOCUMENTATION_AGED = "rtl-asset-documentation-aged",
  RTL_ASSET_DOCUMENTATION_MISSING_OA = "rtl-asset-documentation-missing-oa",
  RTL_ASSET_DOCUMENTATION_TWO_MONTHS_BANK = "rtl-asset-documentation-two-months-bank",
  RTL_FINAL_HUD = "rtl-final-hud",
  RTL_FINAL_HUD_UPLOAD = "rtl-final-hud-upload",
  RTL_FINAL_HUD_CORRECT_DOC = "rtl-final-hud-correct-doc",
  RTL_FINAL_HUD_NOT_ESTIMATE = "rtl-final-hud-not-estimate",
  RTL_PRELIM_TITLE = "rtl-prelim-title",
  RTL_PRELIM_TITLE_UPLOAD = "rtl-prelim-title-upload",
  RTL_PRELIM_TITLE_CORRECT_DOC = "rtl-prelim-title-correct-doc",
  RTL_PRELIM_TITLE_CLEAR = "rtl-prelim-title-clear",
  RTL_PRELIM_TITLE_ADDRESS = "rtl-prelim-title-address",
  RTL_PRELIM_TITLE_ENTITY = "rtl-prelim-title-entity",
  RTL_PRELIM_TILE_IS_CONDO = "rtl-prelim-tile-is-condo",
  RTL_PRELIM_TITLE_CONDO_BYLAWS_AVAILABLE = "rtl-prelim-title-condo-bylaws-available",
  RTL_PRELIM_TITLE_CONDO_CCR_AVAILABLE = "rtl-prelim-title-condo-ccr-available",
  RTL_PRELIM_TITLE_CONDO_QUESTIONNAIRE_AVAILABLE = "rtl-prelim-title-condo-questionnaire-available",
  RTL_PRELIM_TITLE_CONDO_MASTER_INSURANCE_AVAILABLE = "rtl-prelim-title-condo-master-insurance-available",
  RTL_PRELIM_TITLE_HOA_PAID_CURRENT_STATEMENT = "rtl-prelim-title-hoa-paid-current-statement",
  RTL_PRELIM_TITLE_CLOSING_PROTECTION_LETTER = "rtl-prelim-title-closing-protection-letter",
  RTL_TITLE = "rtl-title",
  RTL_TITLE_UPLOAD = "rtl-title-upload",
  RTL_TITLE_CORRECT_DOC = "rtl-title-correct-doc",
  RTL_TITLE_CLEAR = "rtl-title-clear",
  RTL_TITLE_ADDRESS = "rtl-title-address",
  RTL_TITLE_ENTITY = "rtl-title-entity",
  RTL_TITLE_IS_CONDO = "rtl-title-is-condo",
  RTL_TITLE_CONDO_BYLAWS_AVAILABLE = "rtl-title-condo-bylaws-available",
  RTL_TITLE_CONDO_CCR_AVAILABLE = "rtl-title-condo-ccr-available",
  RTL_TITLE_CONDO_QUESTIONNAIRE_AVAILABLE = "rtl-title-condo-questionnaire-available",
  RTL_TITLE_CONDO_MASTER_INSURANCE_AVAILABLE = "rtl-title-condo-master-insurance-available",
  RTL_TITLE_HOA_PAID_CURRENT_STATEMENT = "rtl-title-hoa-paid-current-statement",
  RTL_TITLE_CLOSING_PROTECTION_LETTER = "rtl-title-closing-protection-letter",
  RTL_TITLE_COMPANY_MATCHES_CPL_AND_COMMITMENT = "rtl-title-company-matches-cpl-and-commitment",
  RTL_TITLE_UNDERWRITER_MATCHES_CPL_AND_COMMITMENT = "rtl-title-underwriter-matches-cpl-and-commitment",
  RTL_TITLE_ADDRESS_MATCHES_CPL_AND_COMMITMENT = "rtl-title-address-matches-cpl-and-commitment",
  RTL_MORTGAGE_OR_DEED = "rtl-mortgage-or-deed",
  RTL_MORTGAGE_OR_DEED_UPLOAD = "rtl-mortgage-or-deed-upload",
  RTL_MORTGAGE_OR_DEED_CORRECT_DOC = "rtl-mortgage-or-deed-correct-doc",
  RTL_MORTGAGE_OR_DEED_ASSIGNMENT_OF_RENTS = "rtl-mortgage-or-deed-assignment-of-rents",
  RTL_MORTGAGE_OR_DEED_SECURITY_AGREEMENT = "rtl-mortgage-or-deed-security-agreement",
  RTL_MORTGAGE_OR_DEED_LEGAL_DESCRIPTION_PRESENT = "rtl-mortgage-or-deed-legal-description-present",
  RTL_MORTGAGE_OR_DEED_LEGAL_DESCRIPTION_MATCHES_TITLE = "rtl-mortgage-or-deed-legal-description-matches-title",
  RTL_NOTE = "rtl-note",
  RTL_NOTE_UPLOAD = "rtl-note-upload",
  RTL_NOTE_CORRECT_DOC = "rtl-note-correct-doc",
  RTL_NOTE_BORROWER_NAME = "rtl-note-borrower-name",
  RTL_NOTE_EXECUTED = "rtl-note-executed",
  RTL_NOTE_ADDRESS = "rtl-note-address",
  RTL_NOTE_PAY_HISTORY = "rtl-note-pay-history",
  RTL_NOTE_PAY_HISTORY_NO_EXCEPTION = "rtl-note-pay-history-no-exception",
  RTL_NOTE_ORIGINATION_DATE = "rtl-note-origination-date",
  RTL_NOTE_MATURITY_DATE = "rtl-note-maturity-date",
  RTL_NOTE_SERVICING_FEES_PROVIDED = "rtl-note-servicing-fees-provided",
  RTL_LOAN_AGREEMENT = "rtl-loan-agreement",
  RTL_LOAN_AGREEMENT_UPLOAD = "rtl-loan-agreement-upload",
  RTL_LOAN_AGREEMENT_CORRECT_DOC = "rtl-loan-agreement-correct-doc",
  RTL_LOAN_AGREEMENT_ENTITY = "rtl-loan-agreement-entity",
  RTL_LOAN_AGREEMENT_EXECUTED = "rtl-loan-agreement-executed",
  RTL_CEMA_NOTE = "rtl-cema-note",
  RTL_275_AFFIDAVIT = "rtl-275-affidavit",
  RTL_ASSIGNMENT_OF_MORTGAGE = "rtl-assignment-of-mortgage",
  RTL_GREEN_LIGHT_MEMO = "rtl-green-light-memo",
  RTL_GREEN_LIGHT_MEMO_UPLOAD = "rtl-green-light-memo-upload",
  RTL_GREEN_LIGHT_MEMO_CORRECT_DOC = "rtl-green-light-memo-correct-doc",
  RTL_CERTIFICATE_OF_BUSINESS_PURPOSE = "rtl-certificate-of-business-purpose",
  RTL_CERTIFICATE_OF_BUSINESS_PURPOSE_UPLOAD = "rtl-certificate-of-business-purpose-upload",
  RTL_CERTIFICATE_OF_BUSINESS_PURPOSE_CORRECT_DOC = "rtl-certificate-of-business-purpose-correct-doc",
  RTL_CERTIFICATE_OF_BUSINESS_PURPOSE_ENTITY_NAME = "rtl-certificate-of-business-purpose-entity-name",
  RTL_CERTIFICATE_OF_BUSINESS_PURPOSE_EXECUTED = "rtl-certificate-of-business-purpose-executed",
  RTL_ARTICLES_OF_INCORPORATION = "rtl-articles-of-incorporation",
  RTL_ARTICLES_OF_INCORPORATION_UPLOAD = "rtl-articles-of-incorporation-upload",
  RTL_ARTICLES_OF_INCORPORATION_CORRECT_DOC = "rtl-articles-of-incorporation-correct-doc",
  RTL_ARTICLES_OF_INCORPORATION_CERT_OF_GOOD_STANDING = "rtl-articles-of-incorporation-cog",
  RTL_ARTICLES_OF_INCORPORATION_CERT_OF_GOOD_STANDING_NOT_AGED = "rtl-articles-of-incorporation-cog-not-aged",
  RTL_ARTICLES_OF_INCORPORATION_CERT_OF_GOOD_STANDING_CORRECT_ENTITY = "rtl-articles-of-incorporation-cog-correct-entity",
  RTL_ARTICLES_OF_INCORPORATION_EIN_W9 = "rtl-articles-of-incorporation-ein-w9",
  RTL_OPERATING_AGREEMENT = "rtl-operating-agreement",
  RTL_OPERATING_AGREEMENT_UPLOAD = "rtl-operating-agreement-upload",
  RTL_OPERATING_AGREEMENT_CORRECT_DOC = "rtl-operating-agreement-correct-doc",
  RTL_OPERATING_AGREEMENT_EXECUTED = "rtl-operating-agreement-executed",
  RTL_OPERATING_AGREEMENT_ALL_MEMBERS = "rtl-operating-agreement-all-members",
  RTL_OFAC_ENTITY = "rtl-ofac-entity",
  RTL_OFAC_ENTITY_UPLOAD = "rtl-ofac-entity-upload",
  RTL_OFAC_ENTITY_CORRECT_DOC = "rtl-ofac-entity-correct-doc",
  RTL_OFAC_ENTITY_NOT_AGED = "rtl-ofac-entity-not-aged",
  RTL_OFAC_ENTITY_CLEAR = "rtl-ofac-entity-clear",
  RTL_BACKGROUND_CHECK = "rtl-background-check",
  RTL_BACKGROUND_CHECK_UPLOAD = "rtl-background-check-upload",
  RTL_BACKGROUND_CHECK_CORRECT_DOC = "rtl-background-check-correct-doc",
  RTL_BACKGROUND_CHECK_ALL_MEMBERS = "rtl-background-check-all-members",
  RTL_BACKGROUND_CHECK_MEMBER_OFAC = "rtl-background-check-member-ofac",
  RTL_PERSONAL_GUARANTY = "rtl-personal-guaranty",
  RTL_PERSONAL_GUARANTY_UPLOAD = "rtl-personal-guaranty-upload",
  RTL_PERSONAL_GUARANTY_CORRECT_DOC = "rtl-personal-guaranty-correct-doc",
  RTL_PERSONAL_GUARANTY_EXECUTED = "rtl-personal-guaranty-executed",
  RTL_PERSONAL_GUARANTY_ALL_GUARANTORS = "rtl-personal-guaranty-all-guarantors",
  RTL_PURCHASE_AGREEMENT = "rtl-purchase-agreement",
  RTL_PURCHASE_AGREEMENT_UPLOAD = "rtl-purchase-agreement-upload",
  RTL_PURCHASE_AGREEMENT_CORRECT_DOC = "rtl-purchase-agreement-correct-doc",
  RTL_REPLACEMENT_COST_COVERAGE = "rtl-replacement-cost-coverage",
  RTL_REPLACEMENT_COST_COVERAGE_UPLOAD = "rtl-replacement-cost-coverage-upload",
  RTL_HAZARD_INSURANCE_POLICY = "rtl-hazard-insurance-policy",
  RTL_HAZARD_INSURANCE_POLICY_UPLOAD = "rtl-hazard-insurance-policy-upload",
  RTL_HAZARD_INSURANCE_POLICY_ADDRESS = "rtl-hazard-insurance-policy-address",
  RTL_HAZARD_INSURANCE_POLICY_COVERAGE = "rtl-hazard-insurance-policy-coverage",
  RTL_HAZARD_INSURANCE_POLICY_CORRECT_DOC = "rtl-hazard-insurance-policy-correct-doc",
  RTL_HAZARD_INSURANCE_POLICY_EXPIRED = "rtl-hazard-insurance-policy-expired",
  RTL_HAZARD_INSURANCE_POLICY_INCLUDES_ISAOA = "rtl-hazard-insurance-policy-includes-isaoa",
  RTL_HAZARD_INSURANCE_POLICY_NOT_GENERAL_LIABILITY_ONLY = "rtl-hazard-insurance-policy-not-general-liability-only",
  RTL_HAZARD_INSURANCE_POLICY_REPLACEMENT_COST_PRESENT = "rtl-hazard-insurance-policy-replacement-cost-present",
  RTL_HAZARD_INSURANCE_COVERAGE_AMOUNT_COVERS_PROPERTY_VALUE = "rtl-hazard-insurance-coverage-amount-covers-property-value",
  RTL_GENERAL_LIABILITY = "rtl-general-liability",
  RTL_GENERAL_LIABILITY_UPLOAD = "rtl-general-liability-upload",
  RTL_GENERAL_LIABILITY_HAS_INSURANCE = "rtl-general-liability-has-insurance",
  RTL_GENERAL_LIABILITY_INSURANCE_EXPIRED = "rtl-general-liability-insurance-policy-expired",
  RTL_FLOOD_CERT = "rtl-flood-cert",
  RTL_FLOOD_CERT_UPLOAD = "rtl-flood-cert-upload",
  RTL_FLOOD_CERT_CORRECT_DOC = "rtl-flood-cert-correct-doc",
  RTL_FLOOD_INSURANCE_POLICY = "rtl-flood-insurance-policy",
  RTL_FLOOD_INSURANCE_POLICY_UPLOAD = "rtl-flood-insurance-policy-upload",
  RTL_FLOOD_INSURANCE_POLICY_CORRECT_DOC = "rtl-flood-insurance-policy-correct-doc",
  RTL_APPRAISAL = "rtl-appraisal",
  RTL_APPRAISAL_UPLOAD = "rtl-appraisal-upload",
  RTL_APPRAISAL_CORRECT_DOC = "rtl-appraisal-correct-doc",
  RTL_APPRAISAL_CORRECT_ADDRESS = "rtl-appraisal-correct-address",
  RTL_APPRAISAL_HAS_PHOTOS_SUBJECT = "rtl-appraisal-has-photos",
  RTL_APPRAISAL_HAS_PHOTOS_SALES = "rtl-appraisal-has-photos-sales",
  RTL_APPRAISAL_RELIANCE_LANGUAGE = "rtl-appraisal-reliance-language",
  RTL_REPAIR_BUDGET = "rtl-repair-budget",
  RTL_REPAIR_BUDGET_UPLOAD = "rtl-repair-budget-upload",
  RTL_REPAIR_BUDGET_CORRECT_DOC = "rtl-repair-budget-correct-doc",
  RTL_REPAIR_BUDGET_FEASIBILITY_REPORT = "rtl-repair-budget-feasibility-report",
  RTL_INSPECTION_REPORT = "rtl-inspection-report",
  RTL_INSPECTION_REPORT_UPLOAD = "rtl-inspection-report-upload",
  RTL_INSPECTION_REPORT_ADDRESS = "rtl-inspection-report-address",
  RTL_PROPERTY_HUD = "rtl-property-hud",
  RTL_PROPERTY_HUD_UPLOAD = "rtl-property-hud-upload",
  RTL_PROPERTY_HUD_ADDRESS = "rtl-property-hud-address",
  RTL_PROPERTY_HUD_DATE = "rtl-property-hud-date",
  RTL_PROPERTY_HUD_PRICE = "rtl-property-hud-price",
  RTL_PROPERTY_HUD_EXECUTED = "rtl-property-hud-executed",
  RTL_EXECUTED_DEED = "rtl-executed-deed",
  RTL_EXECUTED_DEED_UPLOAD = "rtl-executed-deed-upload",
  RTL_EXECUTED_DEED_ADDRESS = "rtl-executed-deed-address",
  RTL_EXECUTED_DEED_GRANTEE = "rtl-executed-deed-grantee",
  RTL_EXECUTED_DEED_MATCH_TITLE = "rtl-executed-deed-match-title",
  RTL_ADDITIONAL_DOCUMENTS = "rtl-additional-documents",
  SFR_PURCHASE_AGREEMENT = "sfr-purchase-agreement",
  SFR_PURCHASE_AGREEMENT_UPLOAD = "sfr-purchase-agreement-upload",
  SFR_PURCHASE_AGREEMENT_CORRECT_DOC = "sfr-purchase-agreement-correct-doc",
  SFR_LEASE = "sfr-lease",
  SFR_LEASE_UPLOAD = "sfr-lease-upload",
  SFR_LEASE_CORRECT_DOC = "sfr-lease-correct-doc",
  SFR_LEASE_PROPERTY_OCCUPIED = "sfr-lease-property-occupied",
  SFR_LEASE_SIGNED_LANDLORD = "sfr-lease-signed-landlord",
  SFR_LEASE_SIGNED_TENANT = "sfr-lease-signed-tenant",
  SFR_PURCHASE_OPTION = "sfr-lease-purchase-option",
  SFR_DEED = "sfr-deed",
  SFR_DEED_UPLOAD = "sfr-deed-upload",
  SFR_DEED_CORRECT_DOC = "sfr-deed-correct-doc",
  SFR_DEED_TYPE = "sfr-deed-type",
  SFR_TITLE = "sfr-title",
  SFR_TITLE_UPLOAD = "sfr-title-upload",
  SFR_TITLE_CORRECT_DOC = "sfr-title-correct-doc",
  SFR_TITLE_CLEAR = "sfr-title-clear",
  SFR_BPO = "sfr-bpo",
  SFR_BPO_UPLOAD = "sfr-bpo-upload",
  SFR_BPO_CORRECT_DOC = "sfr-bpo-correct-doc",
  SFR_MORTGAGE = "sfr-mortgage",
  SFR_MORTGAGE_UPLOAD = "sfr-mortgage-upload",
  SFR_MORTGAGE_CORRECT_DOC = "sfr-mortgage-correct-doc",
  SFR_MORTGAGE_DEED_TYPE = "sfr-mortgage-deed-type",
  SFR_FLOOD_CERT = "sfr-flood-cert",
  SFR_FLOOD_CERT_UPLOAD = "sfr-flood-cert-upload",
  SFR_FLOOD_CERT_CORRECT_DOC = "sfr-flood-cert-correct-doc",
  SFR_OCCUPANCY_CERT = "sfr-occupancy-cert",
  SFR_OCCUPANCY_CERT_UPLOAD = "sfr-occupancy-cert-upload",
  SFR_OCCUPANCY_CERT_CORRECT_DOC = "sfr-occupancy-cert-correct-doc",
}
export enum DiligenceReviewType {
  SETPOINT = "setpoint",
  CUSTOMER = "customer",
  NO_REVIEW = "no-review",
}
export enum EntityValidationStatus {
  PASS = "pass",
  FAIL = "fail",
  PENDING = "pending",
  WAIVED = "waived",
  NA = "na",
}
export enum DiligenceFilterType {
  ALL = "all",
  RTL = "rtl",
  SPC = "spc",
  SFR = "sfr",
}
export enum ConcentrationLimitSubCategory {
  TEMPLATE_LIMIT_SUBCAT_REPLACE_ME = "template-only-dont-use",
  CREDIT_PARTY_LIMIT = "credit-party-limits",
  SINGLE_LOCATION_LIMIT = "single-location-limits",
  REGIONAL_LIMIT = "regional-limits",
  ELIGIBLE_TYPE_LIMIT = "eligibile-type-limits",
  ELIGIBLE_LOAN_TYPE_LIMIT = "eligible-loan-type-limits",
  COMMUNITY_LIMIT = "community-limits",
  MSA_LIMIT = "msa-limits",
  CONDO_LIMIT = "condo-limits",
}
export enum ConcentrationLimitCalculationType {
  ADVANCE_AMOUNT = "advance-amount",
  BORROWING_BASE_RATIO = "borrowing-base-ratio",
  DATA_TAPE_VALUE = "data-tape-value",
  DELINQUENCY_RATIO = "delinquency-ratio",
  DELINQUENT_MORTGAGE = "delinquent-mortgage",
  EXCESS_SPREAD_RATIO = "excess-spread-ratio",
  FICO = "fico",
  LOAN_MATURITY = "loan-maturity",
  LOAN_ORIGINATION = "loan-origination",
  LOAN_TO_VALUE = "loan-to-value",
  MARKET_VALUE = "market-value",
  MARKET_VALUE_BY_MSA = "market-value-by-msa",
  MAXIMUM_EXTENSION_RATIO = "maximum-extension-ratio",
  MSA_LOCATION = "msa-location",
  NET_LOSS_RATIO = "net-loss-ratio",
  ORCHARD_SUBTRANCHE = "orchard-subtranche",
  ORIGINAL_LOAN_AMOUNT = "original-loan-amount",
  ORIGINAL_LOAN_AMOUNT_BY_MSA = "original-loan-amount-by-msa",
  ORIGINAL_PURCHASE_PRICE = "original-purchase-price",
  ORIGINAL_PURCHASE_PRICE_BY_MSA = "original-purchase-price-by-msa",
  PRODUCT_TYPE = "product-type",
  PROPERTY_TYPE = "property-type",
  PURCHASE_TO_VALUATION_PERCENT = "purchase-to-valuation-percent",
  STATE_LOCATION = "state-location",
  TIME_ON_BORROWING_BASE = "time-on-borrowing-base",
  TOP_MSA_CONCENTRATION = "top-msa-concentration",
  TOP_STATE_CONCENTRATION = "top-state-concentration",
  WEIGHTED_AVERAGE_APR = "weighted-average-apr",
  WEIGHTED_AVERAGE_CREDIT_SCORE = "weighted-average-credit-score",
  WEIGHTED_AVERAGE_FICO = "weighted-average-fico",
  WEIGHTED_AVERAGE_ORIGINAL_TERM = "weighted-average-original-term",
  FUTURE_FAMILY_BANKRUPTCY = "future-family-bankruptcy",
  FUTURE_FAMILY_FIRST_PAYMENT_RECEIVED = "future-family-first-payment-received",
  FUTURE_FAMILY_FIRST_PAYMENT_QUICK = "future-family-first-payment-quick",
  FUTURE_FAMILY_DELINQUENT_RECEIVABLE = "future-family-delinquent-receivable",
  FUTURE_FAMILY_CHARGED_OFF_RECEIVABLE = "future-family-charged-off-receivable",
  FUTURE_FAMILY_AMENDED_RECEIVABLE = "future-family-amended-receivable",
  FUTURE_FAMILY_FICO_LOAN_TERM = "future-family-fico-loan-term",
  FUTURE_FAMILY_ORIGINAL_TERM = "future-family-original-term",
  FUTURE_FAMILY_FICO_SINGLE_OBLIGOR = "future-family-fico-single-obligor",
  FUTURE_FAMILY_FICO_SINGLE_OBLIGOR_ZERO_APR = "future-family-fico-single-obligor-zero-apr",
  FUTURE_FAMILY_FICO_DUAL_OBLIGOR = "future-family-fico-dual-obligor",
  FUTURE_FAMILY_FICO_DUAL_OBLIGOR_ZERO_APR = "future-family-fico-dual-obligor-zero-apr",
  FUTURE_FAMILY_ORIGINAL_RECEIVABLE_BALANCE = "future-family-original-receivable-balance",
  FUTURE_FAMILY_ORIGINATION_INTEREST_RATE = "future-family-origination-interest-rate",
  FUTURE_FAMILY_ORIGINATION_APPROVED_STATE = "future-family-approved-state",
  FUTURE_FAMILY_ORIGINATION_APPROVED_PROCEDURE = "future-family-approved-procedure",
  FUTURE_FAMILY_PAYMENT_ON_HOLD = "future-family-payment-on-hold",
  FUTURE_FAMILY_PAYMENT_TO_INCOME_RATIO = "future-family-payment-to-income-ratio",
  FUTURE_FAMILY_DAYS_PAST_DUE = "future-family-days-past-due",
  FUTURE_FAMILY_PERMITTED_SUBSCRIPTION_FEE = "future-family-permitted-subscription-fee",
  FUTURE_FAMILY_CLINIC_FEE_FOR_ZERO_APR_RECEIVABLE = "future-family-clinic-fee-for-zero-apr-receivable",
  FUTURE_FAMILY_AVG_ORIGINAL_RECEIVABLE_BALANCE = "future-family-avg-original-receivable-balance",
  FUTURE_FAMILY_WEIGHTED_AVG_FICO = "future-family-weighted-avg-fico",
  FUTURE_FAMILY_FICO_CONCENTRATION = "future-family-fico-concentration",
  FUTURE_FAMILY_FICO_CONCENTRATION_ACCOUNT_OBLIGOR = "future-family-fico-concentration-account-obligor",
  FUTURE_FAMILY_WEIGHTED_AVG_PAYMENT_TO_INCOME_RATIO = "future-family-fico-weighted-avg-payment-to-income-ratio",
  FUTURE_FAMILY_WEIGHTED_AVG_INCOME = "future-family-weighted-avg-income",
  FUTURE_FAMILY_WEIGHTED_AVG_TERM = "future-family-weighted-avg-term",
  FUTURE_FAMILY_TERM_CONCENTRATION = "future-family-term-concentration",
  FUTURE_FAMILY_CALIFORNIA_CONCENTRATION = "future-family-california-concentration",
  FUTURE_FAMILY_WEIGHTED_AVG_INTEREST_AND_YIELD = "future-family-weighted-avg-interest-and-yield",
  FUTURE_FAMILY_ZERO_APR = "future-family-zero-apr",
  FUTURE_FAMILY_MODIFIED_NOT_LEGACY = "future-family-modified-not-legacy",
  FUTURE_FAMILY_MODIFIED_LEGACY = "future-family-modified-legacy",
  FUTURE_FAMILY_RECEIVABLE_72_MONTHS = "future-family-receivable-72-months",
  FUTURE_FAMILY_RECEIVABLE_CA_RANGE = "future-family-receivable-ca-range",
  FUTURE_FAMILY_RECEIVABLE_CA_MAX = "future-family-receivable-ca-max",
  FUTURE_FAMILY_RECEIVABLE_TWO_STATE_RANGE = "future-family-receivable-two-state-range",
  FUTURE_FAMILY_RECEIVABLE_TWO_STATE_MAX = "future-family-receivable-two-state-max",
  FUTURE_FAMILY_RECEIVABLE_THREE_STATE_RANGE = "future-family-receivable-three-state-range",
  FUTURE_FAMILY_RECEIVABLE_THREE_STATE_MAX = "future-family-receivable-three-state-max",
  FUTURE_FAMILY_LEGACY_ZERO_APR_RECEIVABLE = "future-family-legacy-zero-apr-receivable",
  FUTURE_FAMILY_RECEIVABLES_LEGACY = "future-family-receivables-legacy",
  FUTURE_FAMILY_RECEIVABLES_LESS_680_FICO = "future-family-receivables-less-680-fico",
  FUTURE_FAMILY_RECEIVABLES_SHORT = "future-family-receivables-short",
  FUTURE_FAMILY_RECEIVABLES_ZERO_APR = "future-family-receivables-zero-apr",
  FUTURE_FAMILY_RECEIVABLES_OTHER = "future-family-receivables-other",
  FUTURE_FAMILY_MINIMUM_ADVANCE_100K = "future-family-minimum-advance-100k",
  FUTURE_FAMILY_MAXIMUM_DRAW_FREQUENCY = "future-family-maximum-draw-frequency",
  FUTURE_FAMILY_LEVEL_ONE_REGULATORY_TRIGGER = "future-family-level-one-regulatory-trigger",
  FUTURE_FAMILY_EVENT_OF_DEFAULT = "future-family-event-of-default",
  FUTURE_FAMILY_PORTFOLIO_DELINQUENCY_RATIO = "future-family-portfolio-delinquency-ratio",
  FUTURE_FAMILY_ANNUALIZED_EXCESS_SPREAD = "future-family-annualized-excess-spread",
  FUTURE_FAMILY_ANNUALIZED_NET_LOSS = "future-family-annualized-net-loss",
  FUTURE_FAMILY_TANGIBLE_NET_WORTH = "future-family-tangible-net-worth",
  FUTURE_FAMILY_MINIMUM_LIQUIDITY = "future-family-minimum-liquidity",
  FUTURE_FAMILY_DEBT_TO_TNW = "future-family-debt-to-tnw",
  OFFERPAD_CITI_PERMITTED_LIEN = "offerpad-citi-permitted-lien",
  OFFERPAD_CITI_LEASED = "offerpad-citi-leased",
  OFFERPAD_CITI_EXTENDED_STAY = "offerpad-citi-extended-stay",
  OFFERPAD_CITI_CAPEX_RENO_PERCENT = "offerpad-citi-capex-reno-percent",
  OFFERPAD_CITI_CAPEX_INITIAL_RENO_ACQ_PERCENT = "offerpad-citi-capex-initial-reno-acq-percent",
  OFFERPAD_CITI_CAPEX_RENO_ACQ_PERCENT = "offerpad-citi-capex-reno-percent-acquisition-price",
  OFFERPAD_CITI_DWELLING_NUMBER = "offerpad-citi-dwelling-number",
  OFFERPAD_CITI_ACQUISITION_OR_VALUATION = "offerpad-citi-acquisition-or-valuation",
  OFFERPAD_CITI_BPO_DAYS_PRIOR_TO_FUNDING = "offerpad-citi-bpo-days-prior-to-funding",
  OFFERPAD_CITI_MODIFIED_TIME_ON_BORROWING_BASE = "offerpad-citi-modified-time-on-borrowing-base",
  OFFERPAD_JPM_IS_OCCUPIED = "offerpad-jpm-is-occupied",
  OFFERPAD_JPM_MINIMUM_BEDROOMS = "offerpad-jpm-minimum-bedrooms",
  OFFERPAD_JPM_MINIMUM_BATHROOMS = "offerpad-jpm-minimum-bathrooms",
  OFFERPAD_JPM_NUMBER_OF_BED_AND_BATH = "offerpad-jpm-number-of-bed-and-bath",
  OFFERPAD_JPM_OCCUPIED_EXTENDED_STAY = "offerpad-jpm-occupied-extended-stay",
  OFFERPAD_JPM_UPDATED_PROPERTY_VALUE_REPORT = "offerpad-jpm-updated-property-value-report",
  HOMEWARD_ASSET_VALUE = "homeward-asset-value",
  HOMEWARD_SENIOR_DEBT = "homeward-senior-debt",
  HOMEWARD_SYBL_HOMES = "homeward-sybl-homes",
  AMHERST_AVG_3_MONTH_VACANCY = "amherst-avg-3-month-vacancy",
  AMHERST_AVG_3_MONTH_DELINQUENCY = "amherst-avg-3-month-delinquency",
  AMHERST_DEBT_YIELD = "amherst-debt-yield",
  AMHERST_EXTENSION_TERM = "amherst-extension-term",
  AMHERST_DSCR = "amherst-dscr",
  AMHERST_FMV = "amherst-fmv",
  AMHERST_NON_MIPOINT_STABLE_PROPERTIES_360_PLUS = "amherst-non-midpoint-stable-properties-360-plus",
  AMHERST_UNDERWRITING_PACKAGE_NOT_RECEIVED = "amherst-underwriting-package-not-received",
  AMHERST_DILIGENCE_AGENT_CERTIFICATION_NOT_DELIVERED = "amherst-diligence-agent-certification-not-delivered",
  AMHERST_PROPERTY_LOCATED_IN_NON_APPROVED_MSA = "amherst-property-located-in-non-approved-msa",
  AMHERST_NON_PERMITTED_LIENS = "amherst-non-permitted-liens",
  AMHERST_FINANCED_FOR_120_DAYS_NO_BPO = "amherst-financed-for-120-days-no-bpo",
  AMHERST_POST_STABILIZED_FOR_30_DAYS_NO_BPO = "amherst-post-stabilized-for-30-days-no-bpo",
  AMHERST_POST_STABILIZED_STUDIO_BUILD_90_DAYS_NO_BPO = "amherst-post-stabilized-studio-build-90-days-no-bpo",
  AMHERST_PRE_STABILIZED_OR_BUNGALO_365_DAYS_NO_SUBSEQUENT_BPO = "amherst-pre-stabilized-or-bungalo-365-days-no-subsequent_bpo",
  AMERHST_PRE_STABLIZED_COLLATERAL_VALUE_WITH_RENOVATION_EXPENSE_GT_40_PCT = "amherst-pre-stabilized-collateral-value-with-renovation-expense-gt-40-pct",
  AMHERST_CARRYOVER_COLLATERAL_VALUE = "amherst-carryover-collateral-value",
  AMHERST_STUDIO_BUILD_COLLATERAL_VALUE = "amherst-studio-build-collateral-value",
  AMHERST_EXPEDITED_ADVANCE_PROPERTY_VALUE = "amherst-expedited-advance-property-value",
  AMHERST_PST_TEST = "amherst-pst-test",
  ORCHARD_GS_LOAN_TO_VALUE = "orchard-gs-loan-to-value",
  UP_EQUITY_TIME_ON_BORROWING_BASE_FOR_PRODUCT_TYPES = "up-equity-time-on-borrowing-base-for-product-types",
  PPM_DAYS_SINCE_ORIGINATION_DATE = "ppm-days-since-origination-date",
  CAPCHASE_TOP_1_ELIGIBLE_OBLIGOR = "capchase-top-1-eligible-obligor",
  CAPCHASE_TOP_2_ELIGIBLE_OBLIGORS = "capchase-top-2-eligible-obligors",
  CAPCHASE_TOP_5_ELIGIBLE_OBLIGORS = "capchase-top-5-eligible-obligors",
  CAPCHASE_INDUSTRY_MARKETING = "capchase-industry-marketing",
  CAPCHASE_INDUSTRY_PAYMENTS = "capchase-industry-payments",
  CAPCHASE_INDUSTRY_REAL_ESTATE = "capchase-industry-real-estate",
  CAPCHASE_INDUSTRY_CUSTOMER_RELATIONSHIP_MGMT = "capchase-industry-customer-relationship-mgmt",
  CAPCHASE_INDUSTRY_AI = "capchase-industry-ai",
  CAPCHASE_INDUSTRY_WELLNESS_BEAUTY = "capchase-industry-wellness-beauty",
  CAPCHASE_INDUSTRY_HUMAN_RESOURCES = "capchase-industry-human-resources",
  CAPCHASE_INDUSTRY_LEGAL = "capchase-industry-legal",
  CAPCHASE_INDUSTRY_OUTSOURCING_BPO = "capchase-industry-outsourcing-bpo",
  CAPCHASE_INDUSTRY_TRANSPORTATION_LOGISTICS = "capchase-industry-transportation-logistics",
  CAPCHASE_INDUSTRY_TELECOM_COMMUNICATIONS = "capchase-industry-telecom-communications",
  CAPCHASE_INDUSTRY_MEDIA = "capchase-industry-media",
  CAPCHASE_INDUSTRY_SUBSCRIPTION_BOX = "capchase-industry-subscription-box",
  CAPCHASE_INDUSTRY_FINANCIAL_MGMT_SOLUTIONS = "capchase-industry-financial-mgmt-solutions",
  CAPCHASE_INDUSTRY_HEALTH_LIFE_SCIENCE = "capchase-industry-health-life-science",
  CAPCHASE_INDUSTRY_EDUCATION = "capchase-industry-education",
  CAPCHASE_INDUSTRY_MUSIC = "capchase-industry-music",
  CAPCHASE_INDUSTRY_CONSUMER_FINANCE = "capchase-industry-consumer-finance",
  CAPCHASE_INDUSTRY_EVENT_TECH = "capchase-industry-event-tech",
  CAPCHASE_INDUSTRY_SECURITY = "capchase-industry-security",
  CAPCHASE_INDUSTRY_HOSTING = "capchase-industry-hosting",
  CAPCHASE_INDUSTRY_HOME_LIVING = "capchase-industry-home-living",
  CAPCHASE_INDUSTRY_TOURISM_HOSPITALITY = "capchase-industry-tourism-hospitality",
  CAPCHASE_INDUSTRY_RETAIL = "capchase-industry-retail",
  CAPCHASE_INDUSTRY_EQUIPMENT = "capchase-industry-equipment",
  CAPCHASE_INDUSTRY_GAMING = "capchase-industry-gaming",
  CAPCHASE_INDUSTRY_FOOD_BEVERAGES = "capchase-industry-food-beverages",
  CAPCHASE_INDUSTRY_ENERGY = "capchase-industry-energy",
  CAPCHASE_INDUSTRY_INSURTECH = "capchase-industry-insurtech",
  CAPCHASE_INDUSTRY_FASHION = "capchase-industry-fashion",
  CAPCHASE_INDUSTRY_HOSPITALITY = "capchase-industry-hospitality",
  CAPCHASE_INDUSTRY_KIDS = "capchase-industry-kids",
  CAPCHASE_INDUSTRY_OIL_GAS = "capchase-industry-oil-gas",
  CAPCHASE_INDUSTRY_AGRICULTURE = "capchase-industry-agriculture",
  CAPCHASE_NON_SAAS_BUSINESSES = "capchase-non-saas-businesses",
  CAPCHASE_ORIGINAL_TERM_GT_12_MONTHS = "capchase-original-term-gt-12-months",
  CAPCHASE_PRE_CAPCHASE_RUNWAY_LT_REMAINING_TERM_R40_LT_40 = "capchase-pre-capchase-runway-lt-remaining-term-r40-lt-40",
  CAPCHASE_PRE_CAPCHASE_RUNWAY_LT_9_MONTHS_R40_LT_40 = "capchase-pre-capchase-runway-lt-9-months-r40-lt-40",
  CAPCHASE_CAPSCORE_LT_50 = "capchase-capscore-lt-50",
  CAPCHASE_CAPSCORE_LT_60 = "capchase-capscore-lt-60",
  CAPCHASE_EUR_EQ_ARR_LT_2_5M = "capchase-eur-eq-arr-lt-2-5m",
  CAPCHASE_R40_LT_20 = "capchase-r40-lt-20",
  CAPCHASE_TRADING_HISTORY_LT_3_YEARS = "capchase-trading-history-lt-3-years",
  DK_DEVELOPED_NORTH_AMERICA = "dk-developed-north-america",
  DK_DEVELOPED_EUROPE = "dk-developed-europe",
  DK_DEVELOPED_ASIA_PACIFIC = "dk-developed-asia-pacific",
  DK_CIS_REGION = "dk-cis-region",
  DK_AFRICA_MIDDLE_EAST = "dk-africa-middle-east",
  DK_SOUTH_AND_CENTRAL_AMERICA = "dk-south-and-central-america",
  DK_EMERGING_ASIA_PACIFIC = "dk-emerging-asia-pacific",
  DK_EMERGING_EUROPE = "dk-emerging-europe",
  DK_AGGREGATE_EMERGING_COUNTRIES = "dk-aggregate-emerging-countries",
  DK_UNIDENTIFIED_REGION = "dk-unidentified-region",
  DK_INTEREST_COVERAGE_RATIO_PERIOD = "dk-interest-coverage-ratio-period",
  DK_INTEREST_COVERAGE_RATIO_PERIOD_CASH_TRAP = "dk-interest-coverage-ratio-period-cash-trap",
  DK_INTEREST_COVERAGE_RATIO_PERIOD_CASH_SWEEP = "dk-interest-coverage-ratio-period-cash-sweep",
  DK_INTEREST_COVERAGE_RATIO_PERIOD_DRAW_STOP = "dk-interest-coverage-ratio-period-draw-stop",
  DK_INTEREST_COVERAGE_RATIO_PERIOD_MPE = "dk-interest-coverage-ratio-period-mpe",
  DK_INTEREST_COVERAGE_RATIO_FORWARD_ONE_MONTH = "dk-interest-coverage-ratio-forward-1-month",
  DK_INTEREST_COVERAGE_RATIO_FORWARD_TWO_MONTHS = "dk-interest-coverage-ratio-forward-2-months",
  DK_INTEREST_COVERAGE_RATIO_FORWARD_THREE_MONTHS = "dk-interest-coverage-ratio-forward-3-months",
  DK_SINGLE_TIER_ONE_CREDIT_MAX = "dk-single-tier-one-credit-max",
  DK_SINGLE_TIER_TWO_CREDIT_MAX = "dk-single-tier-two-credit-max",
  DK_SINGLE_TIER_THREE_CREDIT_MAX = "dk-single-tier-three-credit-max",
  DK_TIER_ONE_CREDIT_MAX = "dk-tier-one-credit-max",
  DK_TIER_TWO_CREDIT_MAX = "dk-tier-two-credit-max",
  DK_TIER_THREE_CREDIT_MAX = "dk-tier-three-credit-max",
  DK_SINGLE_DEVELOPED_COUNTRY_MAX = "dk-single-developed-country-max",
  DK_SINGLE_EMERGING_COUNTRY_MAX = "dk-single-emerging-country-max",
  DK_NARROWBODY_MAX = "dk-narrowbody-max",
  DK_WIDEBODY_MAX = "dk-widebody-max",
  DK_FREIGHTER_MAX = "dk-freighter-max",
  DK_REGIONAL_AND_TURBOPROP_MAX = "dk-regional-and-turboprop-max",
  DK_RECOURSE_LOANS_MAX = "dk-recourse-and-single-credit-max",
  DK_NON_RECOURSE_LOANS_MAX = "dk-non-recourse-portfolio-term-equipment-loans-max",
  OFFERPAD_CITI_NUMBER_OF_BEDROOMS = "offerpad-citi-number-of-bedrooms",
  BD_CAPITAL_MINIMUM_PURCHASE_PRICE = "bd-capital-minimum-purchase-price",
  BD_CAPITAL_ASSET_FILE_RELEASED = "bd-capital-asset-file-released",
  BD_CAPITAL_ORIGINATION_DATE_PURCHASE_DATE_INTERVAL = "bd-capital-origination-date-purchase-date-interval",
  BD_CAPITAL_MARKET_VALUE = "bd-capital-market-value",
  WCP_MINIMUM_PURCHASE_PRICE = "wcp-minimum-purchase-price",
  WCP_ASSET_FILE_RELEASED = "wcp-asset-file-released",
  FUNDBOX_RAMP_UP_THRESHOLD = "fundbox-ramp-up-threshold",
  FUNDBOX_CA = "fundbox-ca",
  FUNDBOX_CA_FL = "fundbox-ca-fl",
  FUNDBOX_CA_FL_NY = "fundbox-ca-fl-ny",
  FUNDBOX_CA_FL_NY_TX = "fundbox-ca-fl-ny-tx",
  FUNDBOX_OTHER_SINGLE_STATE = "fundbox-other-single-state",
  FUNDBOX_TERRITORIES = "fundbox-territories",
  FUNDBOX_LARGEST_1_INDUSTRY = "fundbox-largest-1-industry",
  FUNDBOX_LARGEST_2_INDUSTRIES = "fundbox-largest-2-industries",
  FUNDBOX_LARGEST_3_INDUSTRIES = "fundbox-largest-3-industries",
  FUNDBOX_OTHER_SINGLE_INDUSTRY = "fundbox-other-single-industry",
  FUNDBOX_RECEIVABLES_100K_PLUS = "fundbox-receivables-100k-plus",
  FUNDBOX_RECEIVABLES_120K_PLUS = "fundbox-receivables-120k-plus",
  FUNDBOX_OBLIGOR_LT_1_YEAR = "fundbox-obligor-lt-1-year",
  FUNDBOX_OBLIGOR_LT_2_YEARS = "fundbox-obligor-lt-2-years",
  FUNDBOX_OBLIGOR_LT_4_YEARS = "fundbox-obligor-lt-4-years",
  FUNDBOX_RISK_SCORE_GTE_5 = "fundbox-risk-score-gte-5",
  FUNDBOX_RISK_SCORE_GTE_9 = "fundbox-risk-score-gt-9",
  FUNDBOX_RISK_SCORE_GTE_13 = "fundbox-risk-score-gt-13",
  FUNDBOX_RISK_SCORE_MAX_WA = "fundbox-risk-score-gt-13-max-wa",
  FUNDBOX_MAX_WA_TERM = "fundbox-max-wa-term",
  FUNDBOX_ORIGINAL_TERM_GT_24_RISK_SCORE_LTE_8 = "fundbox-original-term-gt-24-risk-score-lte-8",
  FUNDBOX_MAX_AVG_CREDIT_LIMIT = "fundbox-max-avg-credit-limit",
  FUNDBOX_MAX_AVG_RECEIVABLE_BALANCE = "fundbox-max-avg-receivable-balance",
  FUNDBOX_LARGEST_STATE = "fundbox-largest-state",
  FUNDBOX_LARGEST_STATES_2 = "fundbox-largest-states-2",
  FUNDBOX_LARGEST_STATES_3 = "fundbox-largest-states-3",
  FUNDBOX_LARGEST_STATES_4 = "fundbox-largest-states-4",
  FUNDBOX_NEW_PRODUCTS = "fundbox-new-products",
  FUNDBOX_BROKER_CHANNEL = "fundbox-broker-channel",
  FUNDBOX_ZERO_VANTAGE_SCORE = "fundbox-zero-vantage-score",
  FUNDBOX_RISK_SCORE_GTE_5_TERM_GTE_24 = "fundbox-risk-score-gte-5-term-gte-24",
  FUNDBOX_RISK_SCORE_GTE_9_TERM_GTE_24 = "fundbox-risk-score-gte-9-term-gte-24",
  FUNDBOX_RISK_SCORE_GTE_13_TERM_GTE_24 = "fundbox-risk-score-gte-13-term-gte-24",
  FUNDBOX_ORIGINAL_TERM_GT_12 = "fundbox-original-term-gt-12",
  FUNDBOX_52_WEEK_RECEIVABLES = "fundbox-52-week-receivables",
  FUNDBOX_24_WEEK_TERM_LOAN = "fundbox-24-week-term-loan",
  FUNDBOX_DELINQUENT_LOANS = "fundbox-delinquent-loans",
  FUNDBOX_MATERIAL_MODIFICATIONS = "fundbox-material-modifications",
  FUNDBOX_DUAL_PRODUCT_RECEIVABLES = "fundbox-dual-product-receivables",
  FUNDBOX_OBLIGOR_NOT_AGREED_ACH = "fundbox-obligor-not-agreed-ach",
  FUNDBOX_PAYMENT_LT_WEEKLY = "fundbox-payment-lt-weekly",
  FUNDBOX_PLUS_PRODUCT = "fundbox-plus-product",
  FUNDBOX_BREATHERS = "fundbox-breathers",
  FUNDBOX_WEIGHTED_AVG_RECEIVABLE_YIELD = "fundbox-weighted-avg-receivable-yield",
  FUNDBOX_WEIGHTED_AVG_VANTAGE_SCORE = "fundbox-weighted-avg-vantage-score",
  FUNDBOX_ELIGIBILITY_ASSIGNABLE = "fundbox-eligibility-assignable",
  FUNDBOX_ELIGIBILITY_NON_DEFAULTED = "fundbox-eligibility-non-defaulted",
  FUNDBOX_ELIGIBILITY_ELIGIBLE_OBLIGOR = "fundbox-eligibility-eligible-obligor",
  FUNDBOX_ELIGIBILITY_NOT_BK = "fundbox-eligibility-not-bk",
  FUNDBOX_ELIGIBILITY_ORIG_PRINCIPAL_OVER_150K = "fundbox-eligibility-orig-principal-over-150k",
  FUNDBOX_ELIGIBILITY_NO_LESS_THAN_WK_OR_MO_PAY = "fundbox-eligibility-no-less-than-wk-or-mo-pay",
  FUNDBOX_ELIGIBILITY_NOT_EXCEEDED_AGG_CUSTOMER_LIMIT = "fundbox-eligibility-not-exceeded-agg-customer-limit",
  FUNDBOX_ELIGIBILITY_LESS_THAN_52W_OT = "fundbox-eligibility-less-than-52w-ot",
  FUNDBOX_ELIGIBILITY_NOT_DQ_AT_TRANSFER = "fundbox-eligibility-not-dq-at-transfer",
  FUNDBOX_ELIGIBILITY_PAYMENT_AFTER_MOD = "fundbox-eligibility-payment-after-mod",
  FUNDBOX_ELIGIBILITY_NO_MORE_THAN_1_MOD = "fundbox-eligibility-no-more-than-1-mod",
  FUNDBOX_ELIGIBILITY_VERIFIED_CUSTODIED = "fundbox-eligibility-verified-custodied",
  FUNDBOX_ELIGIBILITY_PRODUCT_TENOR_TYPE = "fundbox-eligibility-product-tenor-type",
  FUNDBOX_ELIGIBILITY_MARKED_SPV = "fundbox-eligibility-marked-spv",
  FUNDBOX_ELIGIBILITY_LESS_THAN_100_APR = "fundbox-eligibility-less-than-100-apr",
  FUNDBOX_ELIGIBILITY_MPF_LESS_OR_EQUAL_3 = "fundbox-eligibility-mpf-less-or-equal-3",
  FUNDBOX_ELIGIBILITY_NOT_A_TERM_LOAN = "fundbox-eligibility-not-a-term-loan",
  FUNDBOX_ELIGIBILITY_APPROVED_ORIGINATOR = "fundbox-eligibility-approved-originator",
  FUNDRISE_PURCHASE_PRICE_LESS_THAN_150K = "fundrise-purchase-price-less-than-150k",
  FUNDRISE_PURCHASE_PRICE_MORE_THAN_550K = "fundrise-purchase-price-more-than-550k",
  FUNDRISE_INITIAL_BPO_VALUE_LESS_THAN_150K = "fundrise-initial-bpo-value-less-than-150k",
  FUNDRISE_PROPERTY_TYPE_ALLOWED = "fundrise-property-type-allowed",
  FUNDRISE_PROPERTY_TYPE_EXCLUDED = "fundrise-property-type-excluded",
  FUNDRISE_SQUARE_FOOTAGE_MORE_THAN_1000_SQ_FT = "fundrise-square-footage-more-than-1000-sq-ft",
  FUNDRISE_CONSTRUCTED_AFTER_2020 = "fundrise-constructed-after-2020",
  FUNDRISE_BEDROOM_BATHROOM_MINIMUMS = "fundrise-bedroom-bathroom-minimums",
  FUNDRISE_GROSS_INCOME_MORE_THAN_14_4k = "fundrise-gross-income-more-than-14-4k",
  FUNDRISE_SFR_APPROVED_COMMUNITY = "fundrise-sfr-approved-community",
  FUNDRISE_MSA_APPROVED_AREA = "fundrise-msa-approved-area",
  FUNDRISE_ELIGIBLE_LEASE = "fundrise-eligible-lease",
  FUNDRISE_BPO_AGE_FINANCING = "fundrise-bpo-age-financing",
  FUNDRISE_CASH_TRAP_EVENT_OF_DEFAULT = "fundrise-cash-trap-event-of-default",
  FUNDRISE_CASH_TRAP_PORTFOLIO_LOAN_TO_VALUE = "fundrise-cash-trap-portfolio-loan-to-value",
  FUNDRISE_CASH_TRAP_RENT_COVERAGE_RATIO = "fundrise-cash-trap-rent-coverage-ratio",
  FUNDRISE_CASH_TRAP_DEBT_YIELD = "fundrise-cash-trap-debt-yield",
  FUNDRISE_EVENT_OF_DEFAULT_CASH_TRAP_EVENT_OCCURED_FOR_6_MONTHS = "fundrise-event-of-default-cash-trap-event-occured-for-6-months",
  FUNDRISE_EVENT_OF_DEFAULT_DEBT_YIELD = "fundrise-event-of-default-debt-yield",
  FUNDRISE_EVENT_OF_DEFAULT_PORTFOLIO_LOAN_TO_VALUE = "fundrise-event-of-default-portfolio-loan-to-value",
  FUNDRISE_EVENT_OF_DEFAULT_RENT_COVERAGE_RATIO = "fundrise-event-of-default-rent-coverage-ratio",
  FUNDRISE_EVENT_OF_DEFAULT_NO_DEFAULTS = "fundrise-event-of-default-no-defaults",
  FUNDRISE_AMORTIZATION_EVENT_TRIGGER = "fundrise-amortization-event-trigger",
  FUNDRISE_INTEREST_RATE_CAP_NOTIONAL_GREATER_THAN_LIMIT = "fundrise-interest-rate-cap-notional-greater-than-limit",
  FUNDRISE_ALLOCATED_LOAN_AMOUNT = "fundrise-allocated-loan-amount",
  FUNDRISE_MANAGEMENT_FEES = "fundrise-management-fees",
  SVB_IMPORTER_DELINQUENT = "svb-importer-delinquent",
  SVB_IMPORTER_FRAUD = "svb-importer-fraud",
  SVB_IMPORTER_DEFAULTED = "svb-importer-defaulted",
  SVB_IMPORTER_ORIGINAL_TERM = "svb-importer-original-term",
  SVB_NOT_AADC_DEBTOR = "svb-not-aadc-debtor",
  SVB_TOP_FACTORING_COUNTERPARTY = "svb-top-factoring-counterparty",
  SVB_SECOND_FACTORING_COUNTERPARTY = "svb-second-factoring-counterparty",
  SVB_ALL_OTHER_FACTORING_COUNTERPARTIES = "svb-all-other-factoring-counterparties",
  SVB_PERISHABLES = "svb-perishables",
  SVB_UNINSURED_EXPOSURE = "svb-uninsured-exposure",
  SVB_ORIGINAL_TERM = "svb-original-term",
  SVB_MONTHLY_VINTAGE_WIND_DOWN = "svb-monthly-vintage-wind-down",
  SVB_DELINQUENT_RATIO_WIND_DOWN = "svb-delinquent-ratio-wind-down",
  SVB_MONTHLY_VINTAGE_EVENT_OF_DEFAULT = "svb-monthly-vintage-event-of-default",
  SVB_DELINQUENT_RATIO_EVENT_OF_DEFAULT = "svb-delinquent-ratio-event-of-default",
  SVB_AGGREGATE_RECEIVABLE_BALANCE_EXPORTER = "svb-aggregate-receivable-balance-exporter",
  SVB_AGGREGATE_RECEIVABLE_BALANCE_IMPORTER = "svb-aggregate-receivable-balance-importer",
  GENERIC_DELINQUENT = "generic-delinquent",
  GENERIC_BANKRUPTCY = "generic-bankruptcy",
  GENERIC_FRAUD = "generic-fraud",
  GENERIC_DEFAULTED = "generic-defaulted",
  GENERIC_VOIDED = "generic-void",
  GENERIC_REFUNDED = "generic-refunded",
  GENERIC_ORIGINAL_TERM = "generic-original-term",
  GENERIC_REGULATORY_EVENT = "generic-regulatory-event",
  GENERIC_DAYS_PAST_DUE = "generic-days-past-due",
  GENERIC_WEIGHTED_AVG_ORIGINAL_TERM = "generic-weighted-avg-original-term",
  GENERIC_WEIGHTED_AVG_MONTHLY_FEE = "generic-weighted-avg-monthly-fee",
  GENERIC_TOTAL_COMMITMENT = "generic-total-commitment",
  GENERIC_ASSET_CALCS_VERIFICATION = "generic-asset-calcs-verification",
  GENERIC_AGGREGATE_NOTATIONAL_AMOUNT = "generic-aggregate-notational-amount",
  MCH_LENDER_PORTION = "mch-lender-portion",
  MCH_LTC_RATIO_CLASS_A = "mch-ltc-ratio-class-a",
  MCH_LTV_RATIO_CLASS_A = "mch-ltv-ratio-class-a",
  MCH_LTV_RATIO_BFR_CLASS_A = "mch-ltv-ratio-bfr-class-a",
  MCH_LTC_RATIO_CLASS_A_B = "mch-ltc-ratio-class-a-b",
  MCH_LTV_RATIO_CLASS_A_B = "mch-ltv-ratio-class-a-b",
  MCH_LTV_RATIO_BFR_CLASS_A_B = "mch-ltv-ratio-bfr-class-a-b",
  MCH_NOTIONAL_AMOUNT = "mch-notional-amount",
  MCH_LARGEST_MARKET = "mch-largest-market",
  MCH_ATTACHED_RENTAL_HOMES = "mch-attached-rental-homes",
  MCH_180_DAY_AGED_PROPERTIES = "mch-180-day-aged-properties",
  MCH_270_DAY_AGED_PROPERTIES = "mch-270-day-aged-properties",
  MCH_BPO_VALUE_LOW = "mch-bpo-value-low",
  MCH_BPO_VALUE_HIGH = "mch-bpo-value-high",
  MCH_BFR_PROPERTIES = "mch-bfr-properties",
  MCH_LTV_RATIO_AMORTIZATION_EVENT = "mch-ltv-ratio-amortization-event",
  MCH_DSCR_AMORTIZATION_EVENT = "mch-dscr-amortization-event",
  MCH_DEBT_YIELD_AMORTIZATION_EVENT = "mch-debt-yield-amortization-event",
  SETPOINT_PRE_CLOSE_REQUIREMENTS_COMPLETE = "setpoint-pre-close-requirements-complete",
  SETPOINT_POST_CLOSE_REQUIREMENTS_COMPLETE = "setpoint-post-close-requirements-complete",
  SETPOINT_MIN_AMOUNT_MORTGAGE_LOAN_ELIGIBILITY = "setpoint-min-amount-mortgage-loan-eligibility",
  SETPOINT_MIN_MARKET_VALUE_ELIGIBILITY = "setpoint-min-market-value-eligibility",
  SETPOINT_MIN_MORTGAGE_LOAN_PERMITTED_INDEBTEDNESS_ELIGIBILITY = "setpoint-min-mortgage-loan-permitted-indebtedness-eligibility",
  SETPOINT_MAX_MORTGAGE_DTI_ELIGIBILITY = "setpoint-max-mortgage-dti-eligibility",
  SETPOINT_MIN_PURCHASE_PRICE_ELIGIBILITY = "setpoint-min-purchase-price-eligibility",
  SETPOINT_MAX_MARKET_VALUE_ELIGIBILITY = "setpoint-max-market-value-eligibility",
  SETPOINT_MAX_PURCHASE_PRICE_ELIGIBILITY = "setpoint-max-purchase-price-eligibility",
  SETPOINT_MSA_MAX_LIMIT_ELIGIBILITY = "setpoint-msa-max-limit-eligibility",
  SETPOINT_ELIGIBLE_PROPERTY_TYPE_ELIGIBILITY = "setpoint-eligible-property-type-eligibility",
  SETPOINT_LOAN_TO_PURCHASE_PRICE_RATIO_ELIGIBILITY = "setpoint-loan-to-purchase-price-ratio-eligibility",
  SETPOINT_MIN_FICO_ELIGIBILITY = "setpoint-min-fico-eligibility",
  SETPOINT_FIRST_LIEN_ELIGIBILITY = "setpoint-first-lien-eligibility",
  SETPOINT_APPROVED_STATE_ELIGIBILITY = "setpoint-approved-state-eligibility",
  SETPOINT_DELINQUENT_LOAN_ELIGIBILITY = "setpoint-delinquent-eligibility",
  SETPOINT_MATURITY_DATE_ELIGIBILITY = "setpoint-maturity-date-eligibility",
  SETPOINT_IN_POSSESSION_OF_CUSTODIAN_ELIGIBILITY = "setpoint-in-possession-of-custodian-eligibility",
  SETPOINT_SELF_CUSTODY_THRESHOLD_ELIGIBILITY = "setpoint-self-custody-threshold-eligibility",
  SETPOINT_LOAN_ORIGINATION_DATE_ELIGIBILITY = "setpoint-loan-origination-date-eligibility",
  SETPOINT_PURCHASE_PRICE_OVER_MARKET_VALUE_ELIGIBILITY = "setpoint-purchase-price-over-market-value-eligibility",
  SETPOINT_LTV_OVER_CLTV_ELIGIBILITY = "setpoint-ltv-over-cltv-eligibility",
  SETPOINT_MIN_MARKET_VALUE_50K_CONCENTRATION = "setpoint-min-market-value-50k-concentration",
  SETPOINT_MAX_MARKET_VALUE_1_5M_CONCENTRATION = "setpoint-max-market-value-1.5m-concentration",
  SETPOINT_MAX_MARKET_VALUE_2M_CONCENTRATION = "setpoint-max-market-value-2m-concentration",
  SETPOINT_MIN_MARKET_VALUE_85K_CONCENTRATION = "setpoint-min-market-value-85K-concentration",
  SETPOINT_MAX_PURCHASE_PRICE_1_5M_CONCENTRATION = "setpoint-max-purchase-price-1_5M-concentration",
  SETPOINT_CONDO_CONCENTRATION = "setpoint-condo-concentration",
  SETPOINT_MORTGAGE_LOAN_120_DAYS_CONCENTRATION = "setpoint-mortgage-loan-120-days-concentration",
  SETPOINT_MORTGAGE_LOAN_180_DAYS_CONCENTRATION = "setpoint-mortgage-loan-180-days-concentration",
  SETPOINT_MORTGAGE_LOAN_360_DAYS_CONCENTRATION = "setpoint-mortgage-loan-360-days-concentration",
  SETPOINT_REPURCHASE_DATE_AFTER_MATURITY_CONCENTRATION = "setpoint-repurchase-date-after-maturity-concentration",
  SETPOINT_FICO_CONCENTRATION = "setpoint-fico-concentration",
  SETPOINT_WEIGHTED_AVERAGE_FICO_CONCENTRATION = "setpoint-weighted-average-fico-concentration",
  SETPOINT_MSA_CONCENTRATION = "setpoint-msa-concentration",
  SETPOINT_ORIGINAL_UPB_CONCENTRATION = "setpoint-original-upb-concentration",
  KYTE_FORD = "kyte-ford",
  KYTE_GMC = "kyte-gmc",
  KYTE_STELLANTIS = "kyte-stellantis",
  KYTE_TOYOTA = "kyte-toyota",
  KYTE_HONDA = "kyte-honda",
  KYTE_MAZDA = "kyte-mazda",
  KYTE_NISSAN = "kyte-nissan",
  KYTE_VOLVO = "kyte-volvo",
  KYTE_JAGUAR_LR = "kyte-jaguar-lr",
  KYTE_VOLKSWAGEN = "kyte-volkswagen",
  KYTE_HYUNDAI = "kyte-hyundai",
  KYTE_MERCEDES_BENZ = "kyte-mercedes-benz",
  KYTE_BMW = "kyte-bmw",
  KYTE_OTHER_OEMS = "kyte-other-oems",
  KYTE_TESLA = "kyte-tesla",
  KYTE_LIEN_HOLIDAY = "kyte-lien-holiday",
  ROCKLYN_CONDO = "rocklyn-condo",
  ROCKLYN_COMMUNITY_ARBOR_MEADOWS = "rocklyn-community-arbor-meadows",
  ROCKLYN_COMMUNITY_BUCKINGHAM = "rocklyn-community-buckingham",
  ROCKLYN_COMMUNITY_CENTENNIAL_RIDGE = "rocklyn-community-centennial-ridge",
  ROCKLYN_COMMUNITY_EAGLE_LAKE = "rocklyn-community-eagle-lake",
  ROCKLYN_COMMUNITY_NORTH_POINTE = "rocklyn-community-north-pointe",
  ROCKLYN_COMMUNITY_OLD_SKIPPER = "rocklyn-community-old-skipper",
  ROCKLYN_COMMUNITY_PARKWAY_POINTE = "rocklyn-community-parkway-pointe",
  ROCKLYN_COMMUNITY_SEBRING = "rocklyn-community-sebring",
  ROCKLYN_COMMUNITY_SOUTHPORT = "rocklyn-community-southport",
  ROCKLYN_COMMUNITY_THE_HAMILTON = "rocklyn-community-the-hamilton",
  ROCKLYN_COMMUNITY_TRIBUTE = "rocklyn-community-tribute",
  ROCKLYN_COMMUNITY_VAUGHN_TOWNHOMES = "rocklyn-community-vaughn-townhomes",
  ROCKLYN_COMMUNITY_WESTFIELD = "rocklyn-community-westfield",
  ROCKLYN_COMMUNITY_WOODBINE = "rocklyn-community-woodbine",
  ROCKLYN_MSA_ATLANTA_SANDY_SPRINGS_ALPHARETTA = "rocklyn-msa-atlanta-sandy-springs-alpharetta",
  ROCKLYN_MSA_BRUNSWICK = "rocklyn-msa-brunswick",
  ROCKLYN_MSA_CALHOUN = "rocklyn-msa-calhoun",
  ROCKLYN_MSA_CAPE_CORAL_FORT_MYERS = "rocklyn-msa-cape-coral-fort-myers",
  ROCKLYN_MSA_JEFFERSON = "rocklyn-msa-jefferson",
  ROCKLYN_MSA_LAGRANGE = "rocklyn-msa-lagrange",
  ROCKLYN_MSA_LAKELAND_WINTER_HAVEN = "rocklyn-msa-lakeland-winter-haven",
  ROCKLYN_MSA_MACON_BIBB_COUNTY = "rocklyn-msa-macon-bibb-county",
  ROCKLYN_MSA_SEBRING_AVON_PARK = "rocklyn-msa-sebring-avon-park",
  ROCKLYN_MSA_TAMPA_ST_PETERSBURG_CLEARWATER = "rocklyn-msa-tampa-st-petersburg-clearwater",
  GREENSKY_GS_ELIG_DISPUTES = "greensky-gs-elig-disputes",
  GREENSKY_GS_ELIG_LEGAL_ACTION = "greensky-gs-elig-legal-action",
  GREENSKY_GS_ELIG_DECEASED_OR_INSOLVENT = "greensky-gs-elig-deceased-or-insolvent",
  GREENSKY_GS_ELIG_MATERIAL_MODIFICATIONS = "greensky-gs-elig-material-modifications",
  GREENSKY_GS_ELIG_FRAUD_OR_IDENTITY_THEFT = "greensky-gs-elig-fraud-or-identity-theft",
  GREENSKY_GS_ELIG_NOT_REDUCED_RATE_ASSET = "greensky-gs-elig-reduced-rate-asset",
  GREENSKY_GS_ELIG_GT_30_DPD = "greensky-gs-elig-gt-30-dpd",
  GREENSKY_GS_ELIG_CHARGED_OFF_ASSET = "greensky-gs-elig-charged-off-asset",
  GREENSKY_GS_ELIG_GTE_60_DPD = "greensky-gs-elig-gte-60-dpd",
  GREENSKY_GS_ELIG_VALID_STATE_OR_TERRITORY = "greensky-gs-elig-valid-state-or-territory",
  GREENSKY_GS_ELIG_ORIG_TERM_GT_320_MONTHS = "greensky-gs-elig-orig-term-gt-320-months",
  GREENSKY_GS_ELIG_PROMO_PERIOD_GT_25_MONTHS = "greensky-gs-elig-promo-period-gt-25-months",
  GREENSKY_GS_ELIG_CREDIT_LIMIT_GT_350K = "greensky-gs-elig-credit-limit-gt-350k",
  GREENSKY_GS_ELIG_INAPPLICABLE_DUE_TO_USURY = "greensky-gs-elig-inapplicable-due-to-usury",
  GREENSKY_GS_ELIG_BACKBOOK_LOANS_NOT_INSOLVENT_OR_BANKRUPT = "greensky-gs-elig-backbook-loans-not-insolvent-or-bankrupt",
  GREENSKY_GS_ELIG_NOT_UPLOADED_TO_CUSTODIAN = "greensky-gs-elig-not-uploaded-to-custodian",
  GREENSKY_GS_ELIG_ORIGINATED_BEFORE_8_1_2022 = "greensky-gs-elig-originated-before-8-1-2022",
  GREENSKY_GS_TOP_3_STATES = "greensky-gs-top-3-states",
  GREENSKY_GS_TOP_STATE = "greensky-gs-top-state",
  GREENSKY_GS_REDUCED_RATE_ASSETS_FICO_GT_775 = "greensky-gs-rr-assets-fico-gt-775",
  GREENSKY_GS_ZERO_INTEREST_ASSETS_FICO_GT_775 = "greensky-gs-zero-interest-assets-fico-gt-775",
  GREENSKY_GS_DEFERRED_RATE_ASSETS_FICO_GT_775 = "greensky-gs-deferred-rate-assets-fico-gt-775",
  GREENSKY_GS_REDUCED_RATE_ASSETS_FICO_LT_700 = "greensky-gs-rr-assets-fico-lt-700",
  GREENSKY_GS_ZERO_INTEREST_ASSETS_FICO_LT_700 = "greensky-gs-zero-interest-assets-fico-lt-700",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS_FICO_LT_700 = "greensky-gs-di-assets-fico-lt-700",
  GREENSKY_GS_FICO_LT_660 = "greensky-gs-fico-lt-660",
  GREENSKY_GS_TOP_3_MERCHANT_CATEGORIES = "greensky-gs-top-3-merchant-categories",
  GREENSKY_GS_TOP_MERCHANT_CATEGORY_NOT_IN_TOP_3 = "greensky-gs-top-merchant-category-not-in-top-3",
  GREENSKY_GS_TOP_MERCHANT = "greensky-gs-top-merchant",
  GREENSKY_GS_CREDIT_LIMIT_GT_75K = "greensky-gs-credit-limit-gt-75k",
  GREENSKY_GS_PRE_FUNDING_PERIOD_GT_6M = "greensky-gs-pre-funding-period-gt-6m",
  GREENSKY_GS_REDUCED_RATE_ASSETS_MINIMUM_TEST = "greensky-gs-rr-assets-minimum-test",
  GREENSKY_GS_ZERO_INTEREST_ASSETS = "greensky-gs-zero-interest-assets",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS = "greensky-gs-di-assets",
  GREENSKY_GS_DEFERRED_NO_NO_ASSETS = "greensky-gs-deferred-no-no-assets",
  GREENSKY_GS_MERCHANT_CATEGORY_SOLAR = "greensky-gs-merchant-category-solar",
  GREENSKY_GS_MERCHANT_CATEGORY_HOME_DEPOT = "greensky-gs-merchant-category-home-depot",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS_ORIG_PROMO_PERIOD_GT_18M = "greensky-gs-di-assets-orig-promo-period-gt-18m",
  GREENSKY_GS_30_DQ_30_59_DPD_AT_PRIOR_EOM = "greensky-gs-30-dq-30-59-dpd-at-prior-eom",
  GREENSKY_GS_MERCHANT_CATEGORY_WINDOWS_DOORS_HVAC_MINIMUM_TEST = "greensky-gs-merchant-multi-category-minimum-test",
  GREENSKY_GS_REDUCED_RATE_ASSETS_AND_NO_OR_LOW_INTEREST_RATE_INTRO_PERIOD = "greensky-gs-rr-assets-and-no-or-low-interest-rate-intro-period",
  GREENSKY_GS_DTI_GT_65 = "greensky-gs-dti-gt-65",
  GREENSKY_GS_DTI_GT_120 = "greensky-gs-dti-gt-120",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS_MINIMUM_TEST = "greensky-gs-di-assets-minimum-test",
  GREENSKY_GS_BACK_BOOK_MATERIAL_MODIFICATION = "greensky-gs-back-book-material-modification",
  GREENSKY_GS_THREATENING_ROR_OFFSET_COUNTERCLAIM_DISCOUNT_ADJUSTMENT_OR_DEFENSE = "greensky-gs-disputed-assets",
  GREENSKY_GS_WA_FICO_REDUCED_RATE_ASSETS = "greensky-gs-wa-fico-rr-assets",
  GREENSKY_GS_WA_FICO_ZERO_INTEREST_ASSETS = "greensky-gs-wa-fico-zero-interest-assets",
  GREENSKY_GS_WA_FICO_DEFERRED_INTEREST_ASSETS = "greensky-gs-wa-fico-di-assets",
  GREENSKY_GS_WA_DTI_REDUCED_RATE_ASSETS = "greensky-gs-wa-dti-rr-assets",
  GREENSKY_GS_WA_DTI_ZERO_INTEREST_ASSETS = "greensky-gs-wa-dti-zero-interest-assets",
  GREENSKY_GS_WA_DTI_DEFERRED_INTEREST_ASSETS = "greensky-gs-wa-dti-di-assets",
  GREENSKY_GS_WA_REMAINING_TERM_REDUCED_RATE = "greensky-gs-wa-remaining-term-reduced-rate",
  GREENSKY_GS_WA_REMAINING_TERM_DEFERRED_INTEREST = "greensky-gs-wa-remaining-term-deferred-interest",
  GREENSKY_GS_WA_REMAINING_TERM_ZERO_INTEREST = "greensky-gs-wa-remaining-term-zero-interest",
  GREENSKY_GS_WA_REMAINING_PROMO_PERIOD_ZERO_INTEREST = "greensky-gs-wa-remaining-promo-period-zero-interest",
  GREENSKY_GS_WA_REMAINING_PROMO_PERIOD_REDUCED_RATE = "greensky-gs-wa-remaining-promo-period-reduced-rate",
  GREENSKY_GS_WA_INTEREST_RATE_REDUCED_RATE_ASSETS = "greensky-gs-wa-interest-rate-rr-assets",
  GREENSKY_GS_WA_INTEREST_RATE_DEFERRED_INTEREST_ASSETS = "greensky-gs-wa-interest-rate-di-assets",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_35_40 = "greensky-gs-open-purchase-window-assets-35-40",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_40_45 = "greensky-gs-open-purchase-window-assets-40-45",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_45_50 = "greensky-gs-open-purchase-window-assets-45-50",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_50_55 = "greensky-gs-open-purchase-window-assets-50-55",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_55 = "greensky-gs-open-purchase-window-assets-55",
  OPPLOANS_ATALAYA_ELIGIBILITY_CHECKS = "opploans-atalaya-eligibility-checks",
  UPLIFT_ATALAYA_ELIG_FICO_14A = "uplift-atalaya-elig-fico-14a",
  UPLIFT_ATALAYA_ELIG_FICO_14B = "uplift-atalaya-elig-fico-14b",
  UPLIFT_ATALAYA_ELIG_RISK_TIER_15 = "uplift-atalaya-elig-risk-tier-15",
  UPLIFT_ATALAYA_ELIG_STATE_17 = "uplift-atalaya-elig-state-17",
  UPLIFT_ATALAYA_ELIG_ORIGINAL_TERM_TO_MATURITY_18 = "uplift-atalaya-elig-original-term-to-maturity-18",
  UPLIFT_ATALAYA_ELIG_INTEREST_RATE_19 = "uplift-atalaya-elig-interest-rate-19",
  UPLIFT_ATALAYA_ELIG_ORIGINATION_FEE_20 = "uplift-atalaya-elig-origination-fee-20",
  UPLIFT_ATALAYA_ELIG_NOT_DELINQUENT_21 = "uplift-atalaya-elig-not-delinquent-21",
  UPLIFT_ATALAYA_ELIG_RECEVIABLE_BALANCE_33 = "uplift-atalaya-elig-receivable-balance-33",
  UPLIFT_ATALAYA_ELIG_NON_ACCRUAL_37 = "uplift-atalaya-elig-non-accrual-37",
  UPLIFT_ATALAYA_ELIG_STATE_LICENCE_38 = "uplift-atalaya-elig-state-licence-38",
  UPLIFT_ATALAYA_ELIG_VERIFIED_39 = "uplift-atalaya-elig-verified-39",
  UPLIFT_ATALAYA_ELIG_STATE_INTEREST_RATE_40 = "uplift-atalaya-elig-state-interest-rate-40",
  UPLIFT_ATALAYA_ELIG_NOT_DEFERRAL_41 = "uplift-atalaya-elig-not-deferral-41",
  UPLIFT_ATALAYA_ELIG_FICO_AGED_44 = "uplift-atalaya-elig-fico-aged-44",
  UPLIFT_ATALAYA_ELIG_HIGH_BALANCE_GRADE_46 = "uplift-atalaya-elig-high-balance-grade-46",
  UPLIFT_ATALAYA_ELIG_NO_FICO_CLEAR_CREDIT_47 = "uplift-atalaya-elig-no-fico-clear-credit-47",
  UPLIFT_ATALAYA_ELIG_NO_FICO_LEXIS_NEXIS_48 = "uplift-atalaya-elig-no-fico-lexis-nexis-48",
  UPLIFT_ATALAYA_ELIG_UPLIFT_ELIGIBILITY = "uplift-atalaya-elig-uplift-eligibilityUPLIFT_ATALAYA_ELIG",
  UPLIFT_ATALAYA_CON_WA_TERM_TO_MATURITY_1 = "uplift-atalaya-con-wa-term-to-maturity-1",
  UPLIFT_ATALAYA_CON_WA_INTEREST_RATE_2 = "uplift-atalaya-con-wa-interest-rate-2",
  UPLIFT_ATALAYA_CON_BALANCE_AT_ORIGINATION_LOW_3 = "uplift-atalaya-con-balance-at-origination-low-3",
  UPLIFT_ATALAYA_CON_BALANCE_AT_ORIGINATION_MID_4 = "uplift-atalaya-con-balance-at-origination-mid-4",
  UPLIFT_ATALAYA_CON_BALANCE_AT_ORIGINATION_HIGH_5 = "uplift-atalaya-con-balance-at-origination-high-5",
  UPLIFT_ATALAYA_CON_WA_FICO_6 = "uplift-atalaya-con-wa-fico-6",
  UPLIFT_ATALAYA_CON_FICO_AT_ORIG_LOW_7 = "uplift-atalaya-con-fico-at-orig-low-7",
  UPLIFT_ATALAYA_CON_FICO_AT_ORIG_MID_8 = "uplift-atalaya-con-fico-at-orig-mid-8",
  UPLIFT_ATALAYA_CON_FICO_AT_ORIG_HIGH_9 = "uplift-atalaya-con-fico-at-orig-high-9",
  UPLIFT_ATALAYA_CON_NO_FICO_AT_ORIG_10 = "uplift-atalaya-con-no-fico-at-orig-10",
  UPLIFT_ATALAYA_CON_TOP_STATE_11 = "uplift-atalaya-con-top-state-11",
  UPLIFT_ATALAYA_CON_STATE_LICENCE_12 = "uplift-atalaya-con-state-licence-12",
  UPLIFT_ATALAYA_CON_SINGLE_OBLIGOR_13 = "uplift-atalaya-con-single-obligor-13",
  UPLIFT_ATALAYA_CON_SINGLE_MERCHANT_14 = "uplift-atalaya-con-single-merchant-14",
  UPLIFT_ATALAYA_CON_DEFERRAL_RECEIVABLES_15 = "uplift-atalaya-con-deferral-receivables-15",
  UPLIFT_ATALAYA_CON_18_MOS_RECEIVABLES_17 = "uplift-atalaya-con-18-mos-receivables-17",
  UPLIFT_ATALAYA_CON_18_MOS_RECEIVABLES_WA_FICO_18 = "uplift-atalaya-con-18-mos-receivables-wa-fico-18",
  UPLIFT_ATALAYA_CON_AVG_BALANCE_18_MOS_19 = "uplift-atalaya-con-avg-balance-18-mos-19",
  UPLIFT_ATALAYA_CON_ELIGIBLE_POOL_BAL_CRUISES_21 = "uplift-atalaya-con-eligible-pool-bal-cruises-21",
  UPLIFT_ATALAYA_CON_TIER_1_MERCHANT_22 = "uplift-atalaya-con-tier-1-merchant-22",
  UPLIFT_ATALAYA_CON_TIER_2_MERCHANT_23 = "uplift-atalaya-con-tier-2-merchant-23",
  UPLIFT_ATALAYA_CON_SINGLE_TRAVEL_PARTNER_24 = "uplift-atalaya-con-single-travel-partner-24",
  UPLIFT_ATALAYA_CON_LOW_FICO_26 = "uplift-atalaya-con-low-fico-26",
  UPLIFT_ATALAYA_CON_SUBVENTED_BALANCE_LOW_27 = "uplift-atalaya-con-subvented-balance-low-27",
  UPLIFT_ATALAYA_CON_SUBVENTED_BALANCE_HIGH_28 = "uplift-atalaya-con-subvented-balance-high-28",
  UPLIFT_ATALAYA_CON_WA_MERCHANT_DISCOUNT_LOW_29 = "uplift-atalaya-con-wa-merchant-discount-low-29",
  UPLIFT_ATALAYA_CON_WA_MERCHANT_DISCOUNT_HIGH_30 = "uplift-atalaya-con-wa-merchant-discount-high-30",
  UPLIFT_ATALAYA_ELIGIBILITY_CHECKS = "uplift-atalaya-eligibility-checks",
  ZIBBY_ATALAYA_LEASE_TERM_1 = "zibby-atalaya-lease-term-1",
  ZIBBY_ATALAYA_CURRENT_LEASE_BALANCE_2X = "zibby-atalaya-current-lease-balance-2-x",
  ZIBBY_ATALAYA_CURRENT_LEASE_BALANCE_AUTO_2Y = "zibby-atalaya-current-lease-balance-auto-2y",
  ZIBBY_ATALAYA_CURRENT_LEASE_BALANCE_AUTO_2Y_AGGREGATE = "zibby-atalaya-current-lease-balance-auto-2y-aggregate",
  ZIBBY_ATALAYA_PAYMENTS_DUE_4 = "zibby-atalaya-payments-due-4",
  ZIBBY_ATALAYA_NOT_DEFAULTED_7 = "zibby-atalaya-not-defaulted-7",
  ZIBBY_ATALAYA_LEASE_CONTRACT_MULTIPLE_34 = "zibby-atalaya-lease-contract-multiple-34",
  ZIBBY_ATALAYA_ORIGINATED_IN_APPROVED_STATE_42 = "zibby-atalaya-originated-in-approved-state-42",
  ZIBBY_ATALAYA_AFFILIATE_LESSEE_OR_GUARANTOR_HIGH_8 = "zibby-atalaya-affiliate-lessee-or-guarantor-high-8",
  ZIBBY_ATALAYA_SINGLE_STATE_HIGH_37A = "zibby-atalaya-single-state-high-37a",
  ZIBBY_ATALAYA_TOP_4_STATES_HIGH_37B = "zibby-atalaya-top-4-states-high-37b",
  ZIBBY_ATALAYA_WAYFAIR_PARTNERSHIP_HIGH_38I = "zibby-atalaya-wayfair-partnership-high-38i",
  ZIBBY_ATALAYA_SINGLE_RETAIL_PARTNERSHIP_HIGH_38II = "zibby-atalaya-single-retail-partnership-high-38ii",
  ZIBBY_ATALAYA_ORIGINAL_NET_LEASE_COST_HIGH_39 = "zibby-atalaya-original-net-lease-cost-high-39",
  ZIBBY_ATALAYA_UNMATURED_DEFAULTED_LEASES_HIGH_40 = "zibby-atalaya-unmatured-defaulted-leases-high-40",
  ZIBBY_ATALAYA_AVERAGE_CURRENT_LEASE_BALANCE_HIGH_41 = "zibby-atalaya-average-current-lease-balance-high-41",
  ZIBBY_ATALAYA_ELIGIBILITY_CHECKS = "zibby-atalaya-eligibility-checks",
  UPGRADE_ATALAYA_NO_DELINQUENCY = "upgrade-atalaya-no-delinquency",
  UPGRADE_ATALAYA_NO_CHARGEOFF = "upgrade-atalaya-no-chargeoff",
  UPGRADE_ATALAYA_NOT_AMENDED = "upgrade-atalaya-not-amended",
  UPGRADE_ATALAYA_ORIGINAL_TERM_TO_MATURITY = "upgrade-atalaya-original-term-to-maturity",
  UPGRADE_ATALAYA_APR = "upgrade-atalaya-apr",
  UPGRADE_ATALAYA_APR_MA = "upgrade-atalaya-apr-ma",
  UPGRADE_ATALAYA_APR_NY = "upgrade-atalaya-apr-ny",
  UPGRADE_ATALAYA_APPROVED_STATE = "upgrade-atalaya-approved-state",
  UPGRADE_ATALAYA_CREDIT_LINE = "upgrade-atalaya-credit-line",
  UPGRADE_ATALAYA_PRINCIPAL_BALANCE = "upgrade-atalaya-principal-balance",
  UPGRADE_ATALAYA_FICO_VANTAGE_SCORE_AT_ORIGINATION = "upgrade-atalaya-fico-vantage-score-at-origination",
  UPGRADE_ATALAYA_FICO_VANTAGE_CURRENT = "upgrade-atalaya-fico-vantage-current",
  UPGRADE_ATALAYA_DTI = "upgrade-atalaya-dti",
  UPGRADE_ATALAYA_I = "upgrade-atalaya-i",
  UPGRADE_ATALAYA_II = "upgrade-atalaya-ii",
  UPGRADE_ATALAYA_III = "upgrade-atalaya-iii",
  UPGRADE_ATALAYA_IV = "upgrade-atalaya-iv",
  UPGRADE_ATALAYA_V = "upgrade-atalaya-v",
  UPGRADE_ATALAYA_VI = "upgrade-atalaya-vi",
  UPGRADE_ATALAYA_VII = "upgrade-atalaya-vii",
  UPGRADE_ATALAYA_VIII = "upgrade-atalaya-viii",
  UPGRADE_ATALAYA_IX = "upgrade-atalaya-ix",
  UPGRADE_ATALAYA_X = "upgrade-atalaya-x",
  UPGRADE_ATALAYA_XI = "upgrade-atalaya-xi",
  UPGRADE_ATALAYA_XII = "upgrade-atalaya-xii",
  UPGRADE_ATALAYA_XIII = "upgrade-atalaya-xiii",
  UPGRADE_ATALAYA_XIV = "upgrade-atalaya-xiv",
  UPGRADE_ATALAYA_XV = "upgrade-atalaya-xv",
  ELIGIBILITY_1 = "eligibility-1",
  ELIGIBILITY_2 = "eligibility-2",
  ELIGIBILITY_3 = "eligibility-3",
  ELIGIBILITY_4 = "eligibility-4",
  ELIGIBILITY_5 = "eligibility-5",
  ELIGIBILITY_6 = "eligibility-6",
  CON_LIMIT_1 = "con-limit-1",
  CON_LIMIT_2 = "con-limit-2",
  CON_LIMIT_3 = "con-limit-3",
  CON_LIMIT_4 = "con-limit-4",
  CON_LIMIT_5 = "con-limit-5",
  CON_LIMIT_6 = "con-limit-6",
  TEMPLATE_LIMITS_REPLACE_ME = "template-concentration-limits",
}
export enum TableCellStatus {
  DEFAULT = "default",
  SUCCESS = "success",
  INFO = "info",
  ERROR = "error",
}
export enum TableCellStatusIcon {
  PASS = "pass",
  FAIL = "fail",
  CHECKMARK = "checkmark",
  XMARK = "xmark",
}
export enum RTLGrade {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}
export enum YesNo {
  YES = "Y",
  NO = "N",
}
export enum ClearNotClear {
  CLEAR = "clear",
  NOT_CLEAR = "not-clear",
}
export enum ReportColumnContentType {
  DILIGENCE_REQUIREMENT = "diligence-requirement",
  DOCUMENT_COUNT = "document-count",
  DUE_DATE = "due-date",
  EXTRACTED_JSON = "extracted-json",
  FAILED_DOCUMENTS = "failed-documents",
  FLAGS = "flags",
  FUNDING_REQUIREMENT = "funding-requirement",
  GUIDELINES = "guidelines",
  PROGRESS_CHART = "progress-chart",
  TEXT = "text",
  VALUATION_STATUS = "valuation-status",
  VALIDATION = "validation",
  GRADES = "grades",
  DILIGENCE_EXCEPTION_COUNT = "diligence-exception-count",
}
export enum ValuationRequirementReportStatus {
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
  NOT_REQUIRED = "not required",
}
export enum AssetTab {
  SUMMARY = "summary",
  DOCUMENTS = "documents",
  SETTLEMENT = "settlement",
  FACILITY_LIMITS = "facility-limits",
  FUNDING = "funding",
}
export enum AssetFundingEligibilityStatus {
  ELIGIBLE = "eligible",
  INELIGIBLE = "ineligible",
  AT_RISK = "at-risk",
  EXCLUDED = "excluded",
  WAIVED = "waived",
}
export enum DateFilterOption {
  ALL = "all",
  LAST_WEEK = "last-week",
  TODAY = "today",
  TOMORROW = "tomorrow",
  THIS_WEEK = "this-week",
  NEXT_WEEK = "next-week",
  CUSTOM = "custom",
}
export enum Report {
  ALL = "all",
  FLAGGED = "flagged",
  FLAGGED_DOCUMENTS = "flagged-documents",
  UPCOMING_DUE_DATES = "upcoming-due-dates",
  OVERDUE = "overdue",
  PURCHASED = "purchased",
  SOLD = "sold",
  TERMINATED = "terminated",
  SETPOINT_VERIFICATION = "setpoint-verification",
  HOMEWARD_PRE_CLOSE_PENDING_APPROVAL = "homeward-pre-close-pending-approval",
  HOMEWARD_SETTLEMENT = "homeward-settlement",
  HOMEWARD_WIRES = "homeward-wires",
  HOMEWARD_AT_CLOSE_PENDING_APPROVAL = "homeward-at-close-pending-approval",
  HOMEWARD_POST_CLOSE_PENDING_APPROVAL = "homeward-post-close-pending-approval",
  FLYHOMES_PRE_CLOSE = "flyhomes-preclose",
  FLYHOMES_LOAN_TAPE_VERIFICATION = "flyhomes-loan-tape-verification",
  FLYHOMES_FUNDING = "flyhomes-funding",
  FLYHOMES_AT_CLOSE = "flyhomes-at-close",
  FLYHOMES_PAYOFF = "flyhomes-payoff",
  FLYHOMES_CLOSED = "flyhomes-closed",
  ORCHARD_PENDING_PURCHASE = "orchard-pending-purchase",
  ORCHARD_ACTIVE_UNFUNDED = "orchard-active-unfunded",
  ORCHARD_ACTIVE_FUNDED = "orchard-active-funded",
  ORCHARD_PENDING_SALE = "orchard-pending-sale",
  ORCHARD_PENDING_SALE_FUNDED = "orchard-pending-sale-funded",
  ORCHARD_PENDING_SALE_UNFUNDED = "orchard-pending-sale-unfunded",
  ORCHARD_SOLD_ON_LINE = "orchard-sold-on-line",
  ORCHARD_SOLD_FUNDED = "orchard-sold-funded",
  ORCHARD_EXPORT_DOCUMENTS = "orchard-export-documents",
  ZAVVIE_EARNEST_MONEY = "zavvie-earnest-money",
  ZAVVIE_8Z_FUNDING = "zavvie-8z-funding",
  DEFAULT_PRE_CLOSE = "default-pre-close",
  DEFAULT_WIRE_VERIFICATION = "default-wire-verification",
  DEFAULT_FUNDING = "default-funding",
  DEFAULT_CHANGE_REQUEST_FUNDING = "default-change-request-funding",
  DEFAULT_CHANGE_REQUEST_FUNDED = "default-change-request-funded",
  DEFAULT_READY_FOR_FUNDING = "default-ready-for-funding",
  DEFAULT_AT_CLOSE = "default-at-close",
  DEFAULT_PAYOFF = "default-payoff",
  DEFAULT_CLOSED = "default-closed",
  DEFAULT_DOCUMENT_VERIFICATION = "default-document-verification",
  DEFAULT_PRE_FUNDING = "default-pre-funding",
  DEFAULT_POST_FUNDING = "default-post-funding",
  OPENDOOR_OSN_DOCUMENT_REVIEW = "opendoor-osn-document-review",
  OPENDOOR_MY_PROPERTIES = "opendoor-my-properties",
  OPENDOOR_OSN_FAILED_DOCUMENT = "opendoor-osn-failed-document",
  OPENDOOR_EXTERNAL_DOCUMENT_VERIFICATION = "opendoor-external-document-verification",
  OPENDOOR_COMPLETE = "opendoor-complete",
  OS_NATIONAL_DOCUMENT_REVIEW = "os-national-document-review",
  OS_NATIONAL_MY_PROPERTIES = "os-national-my-properties",
  OS_NATIONAL_COMPLETE = "os-national-complete",
  WANDER_COMPLETE = "wander-complete",
  SFR_DEMO_COMPLETE = "sfr-demo-complete",
  VALUATION_PENDING = "valuation-pending",
  VALUATION_COMPLETED = "valuation-completed",
  EXTERNAL_VERIFICATION_COMPLETE = "external-verification-complete",
  AMHERST_COORDINATOR_PROPERTIES = "amherst-coodinator-properties",
  AMHERST_MANAGER_PROPERTIES = "amherst-manager-properties",
  AMHERST_COMPLETE = "amherst-complete",
  FUNDRISE_DOCUMENT_VERIFICATION = "fundrise-document-verification",
  RTL_DILIGENCE = "rtl-diligence",
  RTL_DILIGENCE_COMPLETE = "rtl-diligence-complete",
  RTL_DILIGENCE_EXCEPTION = "rtl-diligence-exception",
  SFR_LEGACY_DILIGENCE = "sfr-diligence",
  SFR_LEGACY_DILIGENCE_COMPLETE = "sfr-diligence-complete",
  DAVIDSON_KEMPNER_ALL = "davidson-kempner-all",
  DAVIDSON_KEMPNER_AWARDED = "davidson-kempner-awarded",
  DAVIDSON_KEMPNER_PRICING = "davidson-kempner-pricing",
  DAVIDSON_KEMPNER_FUNDED = "davidson-kempner-funded",
  DAVIDSON_KEMPNER_PAID_OFF = "davidson-kempner-paid-off",
  EXPORT_DOCUMENTS = "export-documents",
  BASIC_EXPORT_DOCUMENTS = "basic-export-documents",
  RTL_EXPORT_DOCUMENTS = "rtl-export-documents",
  SOLD_TO_CAPITAL_PARTNER = "sold-to-capital-partner",
}
export enum ExportLoanTapeType {
  LOAN_TAPE = "loan-tape",
  REPORT_VIEW = "report-view",
}
export enum ExportLoanTapeStatus {
  PENDING = "pending",
  DONE = "done",
}
export enum PassFailReportFacility {
  HOMEWARD_BAWAG = "homeward-bawag",
  HOMEWARD_CREDIT_SUISSE = "homeward-credit-suisse",
  WANDER_CREDIT_SUISSE = "wander-credit-suisse",
}
export enum UploadLoanTapeStatus {
  PENDING = "pending",
  FAILED = "failed",
  DONE = "done",
}
export enum BankAccountTransferType {
  FUNDING = "funding",
  DISBURSEMENT = "disbursement",
  COLLECTION = "collection",
  RESERVE = "reserve",
  ADVANCE_CHANGE = "advance-change",
  INTEREST = "interest",
  PAYOFF = "payoff",
  PAYOFF_EXCESS = "payoff-excess",
  TRANSACTION_FEE = "transaction-fee",
  UNUSED_FEE = "unused-fee",
  YIELD_MAINTENANCE_FEE = "yield-maintenance-fee",
  BANK_ACTIVITY_REMITTANCE = "bank-activity-remittance",
}
export enum StripeInvoiceStatus {
  DRAFT = "draft",
  OPEN = "open",
  PAID = "paid",
  UNCOLLECTIBLE = "uncollectible",
  VOID = "void",
}
export enum PaymentDirection {
  CREDIT = "credit",
  DEBIT = "debit",
}
export enum ConcentrationLimitCategory {
  AMORTIZATION_EVENT = "amortization-event",
  AVAILABILITY = "availability",
  CASH_TRAP_EVENT = "cash-trap-event",
  CONCENTRATION_LIMIT = "concentration-limit",
  COVENANT = "covenant",
  DEFAULT_EVENT = "default-event",
  EARLY_WIND_DOWN_EVENT = "early-wind-down",
  ELIGIBILITY = "eligibility",
  GENERIC = "generic",
  INTEREST_RATE_CAP = "interest-rate-cap",
  PROPERTY_LEVEL_ALA = "property-level-ala",
  MANAGEMENT_FEES = "management-fees",
}
export enum ConcentrationLimitType {
  PERCENT = "percent",
  CONSTANT = "constant",
}
export enum FacilityStructure {
  REVOLVING = "revolving",
  TERM_LOAN = "term-loan",
}
export enum FacilityAgreementType {
  LOAN = "loan",
  REPO = "repo",
}
export enum FacilityComplianceStatus {
  COMPLIANT = "compliant",
  AT_RISK = "at-risk",
  NON_COMPLIANT = "non-compliant",
}
export enum USState {
  ALASKA = "Alaska",
  ALABAMA = "Alabama",
  ARKANSAS = "Arkansas",
  ARIZONA = "Arizona",
  CALIFORNIA = "California",
  COLORADO = "Colorado",
  CONNECTICUT = "Connecticut",
  DISTRICT_OF_COLUMBIA = "District of Columbia",
  DELAWARE = "Delaware",
  FLORIDA = "Florida",
  GEORGIA = "Georgia",
  HAWAII = "Hawaii",
  IOWA = "Iowa",
  IDAHO = "Idaho",
  ILLINOIS = "Illinois",
  INDIANA = "Indiana",
  KANSAS = "Kansas",
  KENTUCKY = "Kentucky",
  LOUISIANA = "Louisiana",
  MASSACHUSETTS = "Massachusetts",
  MARYLAND = "Maryland",
  MAINE = "Maine",
  MICHIGAN = "Michigan",
  MINNESOTA = "Minnesota",
  MISSOURI = "Missouri",
  MISSISSIPPI = "Mississippi",
  MONTANA = "Montana",
  NORTH_CAROLINA = "North Carolina",
  NORTH_DAKOTA = "North Dakota",
  NEBRASKA = "Nebraska",
  NEW_HAMPSHIRE = "New Hampshire",
  NEW_JERSEY = "New Jersey",
  NEW_MEXICO = "New Mexico",
  NEVADA = "Nevada",
  NEW_YORK = "New York",
  OHIO = "Ohio",
  OKLAHOMA = "Oklahoma",
  OREGON = "Oregon",
  PENNSYLVANIA = "Pennsylvania",
  RHODE_ISLAND = "Rhode Island",
  SOUTH_CAROLINA = "South Carolina",
  SOUTH_DAKOTA = "South Dakota",
  TENNESSEE = "Tennessee",
  TEXAS = "Texas",
  UTAH = "Utah",
  VIRGINIA = "Virginia",
  VERMONT = "Vermont",
  WASHINGTON = "Washington",
  WISCONSIN = "Wisconsin",
  WEST_VIRGINIA = "West Virginia",
  WYOMING = "Wyoming",
}
export enum FacilityInputValueType {
  TEMPLATE_DATE_KEY = "sample-date-input-key-do-not-use",
  TEMPLATE_MONEY_KEY = "sample-money-input-key-do-not-use",
  TEMPLATE_FLOAT_KEY = "sample-float-input-key-do-not-use",
  TRANSACTION_SUBI_2021_GST = "transaction-subi-2021-gst",
  CTCNA_FBO_MORTGAGE_PRODUCTS_CLEARING_ACCOUNT = "ctcna-fbo-mortgage-products-clearing-account",
  TITLING_TRUST_CONC_ACCT = "titling-trust-conc-acct",
  CASH_ACCOUNTS = "cash-accounts",
  RESERVE_ACCOUNT_BALANCE = "reserve-account-balance",
  OUT_OF_APP_RESERVE_BALANCE = "out-of-app-reserve-balance",
  CASH_IN_COLLECTION_ACCOUNT = "cash-in-collection-account",
  DRAWN_FUNDS = "drawn-funds",
  FEES_AND_EXPENSES_DUE = "fees-and-expenses-due",
  FUNDING_PROCEEDS_ACCOUNT_BALANCE = "funding-proceeds-account-balance",
  DETERMINATION_DATE = "determination-date",
  TOTAL_COMMITMENT = "total-commitment",
  LOAN_TERM_AMOUNT = "loan-term-amount",
  AGGREGATE_NOTIONAL_AMOUNT = "aggregate-notional-amount",
  ADJUSTED_TANGIBLE_NET_WORTH = "adjusted-tangible-net-worth",
  OUTSTANDING_PRINCIPAL_TRUE_UP = "outstanding-principal-true-up",
  THIRD_PARTY_FEES = "third-party-fees",
  AMHERST_GS_APPROVED_CBSAS = "amhherst-gs-approved-cbsas",
  AMHERST_GS_ANNUALIZED_ACTUAL_CASH_FROM_CAP = "amherst-gs-annualized-actual-cash-from-cap",
  AMHERST_GS_FUNDING_PROCEEDS_END_BAL = "amherst-gs-funding-proceeds-end-bal",
  AMHERST_GS_STABLE_VACANCIES = "amherst-gs-stable-vacancies",
  AMHERST_GS_STABLE_DELINQUENCIES = "amherst-gs-stable-delinquencies",
  AMHERST_GS_TWO_SEVENTY_PLUS_PRE_STABLE = "amherst-gs-270-plus-pre-stable",
  AMHERST_GS_TWO_SEVENTY_PLUS_PRE_STABLE_CURRENT = "amherst-gs-270-plus-pre-stable-current",
  AMHERST_NET_SALES_PROCEEDS = "amherst-net-sales-proceeds",
  AMHERST_GS_CAPITAL_NOTION_AMOUNT_1 = "amherst-gs-capital-notion-amount-1",
  AMHERST_GS_STRIKE_RATE_1 = "amherst-gs-strike-rate-1",
  AMHERST_GS_CAPITAL_NOTION_AMOUNT_2 = "amherst-gs-capital-notion-amount-2",
  AMHERST_GS_STRIKE_RATE_2 = "amherst-gs-strike-rate-2",
  AMHERST_GS_MARGIN = "amherst-gs-margin",
  CAPCHASE_TOP_1_ELIGIBLE_OBLIGOR = "capchase-top-1-eligible-obligor",
  CAPCHASE_TOP_2_ELIGIBLE_OBLIGORS = "capchase-top-2-eligible-obligors",
  CAPCHASE_TOP_5_ELIGIBLE_OBLIGORS = "capchase-top-5-eligible-obligors",
  CAPCHASE_INDUSTRY_MARKETING = "capchase-industry-marketing",
  CAPCHASE_INDUSTRY_PAYMENTS = "capchase-industry-payments",
  CAPCHASE_INDUSTRY_REAL_ESTATE = "capchase-industry-real-estate",
  CAPCHASE_INDUSTRY_CUSTOMER_RELATIONSHIP_MGMT = "capchase-industry-customer-relationship-mgmt",
  CAPCHASE_INDUSTRY_AI = "capchase-industry-ai",
  CAPCHASE_INDUSTRY_WELLNESS_BEAUTY = "capchase-industry-wellness-beauty",
  CAPCHASE_INDUSTRY_HUMAN_RESOURCES = "capchase-industry-human-resources",
  CAPCHASE_INDUSTRY_LEGAL = "capchase-industry-legal",
  CAPCHASE_INDUSTRY_OUTSOURCING_BPO = "capchase-industry-outsourcing-bpo",
  CAPCHASE_INDUSTRY_TRANSPORTATION_LOGISTICS = "capchase-industry-transportation-logistics",
  CAPCHASE_INDUSTRY_TELECOM_COMMUNICATIONS = "capchase-industry-telecom-communications",
  CAPCHASE_INDUSTRY_MEDIA = "capchase-industry-media",
  CAPCHASE_INDUSTRY_SUBSCRIPTION_BOX = "capchase-industry-subscription-box",
  CAPCHASE_INDUSTRY_FINANCIAL_MGMT_SOLUTIONS = "capchase-industry-financial-mgmt-solutions",
  CAPCHASE_INDUSTRY_HEALTH_LIFE_SCIENCE = "capchase-industry-health-life-science",
  CAPCHASE_INDUSTRY_EDUCATION = "capchase-industry-education",
  CAPCHASE_INDUSTRY_MUSIC = "capchase-industry-music",
  CAPCHASE_INDUSTRY_CONSUMER_FINANCE = "capchase-industry-consumer-finance",
  CAPCHASE_INDUSTRY_EVENT_TECH = "capchase-industry-event-tech",
  CAPCHASE_INDUSTRY_SECURITY = "capchase-industry-security",
  CAPCHASE_INDUSTRY_HOSTING = "capchase-industry-hosting",
  CAPCHASE_INDUSTRY_HOME_LIVING = "capchase-industry-home-living",
  CAPCHASE_INDUSTRY_TOURISM_HOSPITALITY = "capchase-industry-tourism-hospitality",
  CAPCHASE_INDUSTRY_RETAIL = "capchase-industry-retail",
  CAPCHASE_INDUSTRY_EQUIPMENT = "capchase-industry-equipment",
  CAPCHASE_INDUSTRY_GAMING = "capchase-industry-gaming",
  CAPCHASE_INDUSTRY_FOOD_BEVERAGES = "capchase-industry-food-beverages",
  CAPCHASE_INDUSTRY_ENERGY = "capchase-industry-energy",
  CAPCHASE_INDUSTRY_INSURTECH = "capchase-industry-insurtech",
  CAPCHASE_INDUSTRY_FASHION = "capchase-industry-fashion",
  CAPCHASE_INDUSTRY_HOSPITALITY = "capchase-industry-hospitality",
  CAPCHASE_INDUSTRY_KIDS = "capchase-industry-kids",
  CAPCHASE_INDUSTRY_OIL_GAS = "capchase-industry-oil-gas",
  CAPCHASE_INDUSTRY_AGRICULTURE = "capchase-industry-agriculture",
  CAPCHASE_NON_SAAS_BUSINESSES = "capchase-non-saas-businesses",
  CAPCHASE_ORIGINAL_TERM_GT_12_MONTHS = "capchase-original-term-gt-12-months",
  CAPCHASE_PRE_CAPCHASE_RUNWAY_LT_REMAINING_TERM_R40_LT_40 = "capchase-pre-capchase-runway-lt-remaining-term-r40-lt-40",
  CAPCHASE_PRE_CAPCHASE_RUNWAY_LT_9_MONTHS_R40_LT_40 = "capchase-pre-capchase-runway-lt-9-months-r40-lt-40",
  CAPCHASE_CAPSCORE_LT_50 = "capchase-capscore-lt-50",
  CAPCHASE_CAPSCORE_LT_60 = "capchase-capscore-lt-60",
  CAPCHASE_EUR_EQ_ARR_LT_2_5M = "capchase-eur-eq-arr-lt-2-5m",
  CAPCHASE_R40_LT_20 = "capchase-r40-lt-20",
  CAPCHASE_TRADING_HISTORY_LT_3_YEARS = "capchase-trading-history-lt-3-years",
  CAPCHASE_MINIMUM_WEIGHTED_AVG_YIELD_TERMINATION_EVENT_LIMIT = "capchase-minimum-weighted-avg-yield-termination-event-limit",
  CAPCHASE_PERMITTED_RESTRUCTURING_CONDITION_LIMIT = "capchase-permitted-restructuring-condition-limit",
  CAPCHASE_AVG_OBLIGOR_EXPOSURE_CP_LIMIT = "capchase-avg-obligor-exposure-cp-limit",
  CAPCHASE_AVG_OBLIGOR_EXPOSURE_BB_LIMIT = "capchase-avg-obligor-exposure-bb-limit",
  CAPCHASE_WEIGHTED_AVG_REMAINING_TERM_CP_LIMIT = "capchase-weighted-avg-remaining-term-cp-limit",
  CAPCHASE_WEIGHTED_AVG_REMAINING_TERM_BB_LIMIT = "capchase-weighted-avg-remaining-term-bb-limit",
  CAPCHASE_HIGH_EXPOSURE_OBLIGOR_LIMIT = "capchase-high-exposure-obligor-limit",
  CAPCHASE_AVG_DELINQUENCY_RATIO_LEVEL_1_LIMIT = "capchase-avg-delinquency-ratio-level-1-limit",
  CAPCHASE_AVG_DELINQUENCY_RATIO_LEVEL_2_LIMIT = "capchase-avg-delinquency-ratio-level-2-limit",
  CAPCHASE_SERVICING_FEE_RATE = "capchase-servicing-fee-rate",
  OPAD_JPM_MARKET_RAW_VOLUMES = "opad-jpm-market-raw-volumes",
  OPAD_JPM_HPI_MARKET_STEP_DOWNS = "opad-jpm-hpi-market-step-downs",
  DK_AIRLINE_CATALOG = "dk-airline-catalog",
  DK_AIRCRAFT_CATALOG = "dk-aircraft-catalog",
  DK_COUNTRY_CATALOG = "dk-country-catalog",
  DK_AGE_ADJUST_CATALOG = "dk-age-adjust-catalog",
  DK_TIER_ADJUST_CATALOG = "dk-tier-adjust-catalog",
  DK_BANK_ACCOUNT_PAYMENTS = "dk-bank-account-payments",
  CHECKPOINT_DATE = "checkpoint-date",
  DK_SENIOR_EXPENSES = "dk-senior-expenses",
  DK_SUBORDINATED_OPERATING_EXPENSES = "dk-subordinated-operating-expenses",
  DK_CS_CONSOLIDATED_IMPORT = "dk-cs-consolidated-import",
  DK_MARKED_VALUES_I_IMPORT = "dk-marked-values-1-import",
  DK_MARKED_VALUES_II_IMPORT = "dk-marked-values-2-import",
  DK_TOTAL_MV_APL_II = "dk-total-marked-value-apl-ii",
  DK_2023_ABS_STRUCTURE = "dk-2023-abs-structure",
  DK_2023_R_NOTES_MARK_VALUE = "dk-2023-r-notes-mark-value",
  DK_2023_A_NOTES_MARK_VALUE = "dk-2023-a-notes-mark-value",
  DK_2023_B_NOTES_MARK_VALUE = "dk-2023-b-notes-mark-value",
  DK_2023_C_NOTES_MARK_VALUE = "dk-2023-c-notes-mark-value",
  DK_2023_D_NOTES_MARK_VALUE = "dk-2023-d-notes-mark-value",
  DK_SANDBOX_SPOT_CURVE = "dk-sandbox-spot-curve",
  DK_SANDBOX_CLASS_SPREAD = "dk-sandbox-class-spread",
  DK_SANDBOX_BOND_SPLIT = "dk-sandbox-bond-split",
  DK_SANDBOX_BOND_COUPON = "dk-sandbox-bond-coupon",
  DK_SANDBOX_CUTOFF_DATE = "dk-sandbox-cutoff-date",
  DK_SANDBOX_FIRST_PAYMENT = "dk-sandbox-first-payment",
  DK_SANDBOX_R_NOTE_DISCOUNT = "dk-sandbox-r-note-discount",
  DK_SANDBOX_SERVICE_FEE_DISCOUNT = "dk-sandbox-service-discount",
  DK_SANDBOX_D_NOTE_HELD = "dk-sandbox-d-note-held",
  DK_COLLECTIONS_ACCOUNT_STARTING_BALANCE = "dk-collections-account-starting-balance",
  DEMO_BANK_ACCOUNT_PAYMENTS = "demo-bank-account-payments",
  DEMO_PAYMENT_DATE = "demo-payment-date",
  FUNDBOX_RAMP_UP_THRESHOLD = "fundbox-ramp-up-threshold",
  FUNDBOX_CA = "fundbox-ca",
  FUNDBOX_CA_FL = "fundbox-ca-fl",
  FUNDBOX_CA_FL_NY = "fundbox-ca-fl-ny",
  FUNDBOX_CA_FL_NY_TX = "fundbox-ca-fl-ny-tx",
  FUNDBOX_OTHER_SINGLE_STATE = "fundbox-other-single-state",
  FUNDBOX_TERRITORIES = "fundbox-territories",
  FUNDBOX_LARGEST_1_INDUSTRY = "fundbox-largest-1-industry",
  FUNDBOX_LARGEST_2_INDUSTRIES = "fundbox-largest-2-industries",
  FUNDBOX_LARGEST_3_INDUSTRIES = "fundbox-largest-3-industries",
  FUNDBOX_OTHER_SINGLE_INDUSTRY = "fundbox-other-single-industry",
  FUNDBOX_RECEIVABLES_100K_PLUS = "fundbox-receivables-100k-plus",
  FUNDBOX_RECEIVABLES_120K_PLUS = "fundbox-receivables-120k-plus",
  FUNDBOX_OBLIGOR_LT_1_YEAR = "fundbox-obligor-lt-1-year",
  FUNDBOX_OBLIGOR_LT_2_YEARS = "fundbox-obligor-lt-2-years",
  FUNDBOX_OBLIGOR_LT_4_YEARS = "fundbox-obligor-lt-4-years",
  FUNDBOX_RISK_SCORE_GTE_5 = "fundbox-risk-score-gte-5",
  FUNDBOX_RISK_SCORE_GTE_9 = "fundbox-risk-score-gt-9",
  FUNDBOX_RISK_SCORE_GTE_13 = "fundbox-risk-score-gt-13",
  FUNDBOX_RISK_SCORE_MAX_WA = "fundbox-risk-score-gt-13-max-wa",
  FUNDBOX_ORIGINAL_TERM_GT_24_RISK_SCORE_LTE_8 = "fundbox-original-term-gt-24-risk-score-lte-8",
  FUNDBOX_MAX_WA_TERM = "fundbox-max-wa-term",
  FUNDBOX_MAX_AVG_CREDIT_LIMIT = "fundbox-max-avg-credit-limit",
  FUNDBOX_MAX_AVG_RECEIVABLE_BALANCE = "fundbox-max-avg-receivable-balance",
  FUNDBOX_LARGEST_STATE = "fundbox-largest-state",
  FUNDBOX_LARGEST_STATES_2 = "fundbox-largest-states-2",
  FUNDBOX_LARGEST_STATES_3 = "fundbox-largest-states-3",
  FUNDBOX_LARGEST_STATES_4 = "fundbox-largest-states-4",
  FUNDBOX_NEW_PRODUCTS = "fundbox-new-products",
  FUNDBOX_BROKER_CHANNEL = "fundbox-broker-channel",
  FUNDBOX_ZERO_VANTAGE_SCORE = "fundbox-zero-vantage-score",
  FUNDBOX_RISK_SCORE_GTE_5_TERM_GTE_24 = "fundbox-risk-score-gte-5-term-gte-24",
  FUNDBOX_RISK_SCORE_GTE_9_TERM_GTE_24 = "fundbox-risk-score-gte-9-term-gte-24",
  FUNDBOX_RISK_SCORE_GTE_13_TERM_GTE_24 = "fundbox-risk-score-gte-13-term-gte-24",
  FUNDBOX_ORIGINAL_TERM_GT_12 = "fundbox-original-term-gt-12",
  FUNDBOX_52_WEEK_RECEIVABLES = "fundbox-52-week-receivables",
  FUNDBOX_24_WEEK_TERM_LOAN = "fundbox-24-week-term-loan",
  FUNDBOX_DELINQUENT_LOANS = "fundbox-delinquent-loans",
  FUNDBOX_MATERIAL_MODIFICATIONS = "fundbox-material-modifications",
  FUNDBOX_DUAL_PRODUCT_RECEIVABLES = "fundbox-dual-product-receivables",
  FUNDBOX_OBLIGOR_NOT_AGREED_ACH = "fundbox-obligor-not-agreed-ach",
  FUNDBOX_PAYMENT_LT_WEEKLY = "fundbox-payment-lt-weekly",
  FUNDBOX_PLUS_PRODUCT = "fundbox-plus-product",
  FUNDBOX_BREATHERS = "fundbox-breathers",
  FUNDBOX_WEIGHTED_AVG_RECEIVABLE_YIELD = "fundbox-weighted-avg-receivable-yield",
  FUNDBOX_WEIGHTED_AVG_VANTAGE_SCORE = "fundbox-weighted-avg-vantage-score",
  FUNDBOX_SERVICING_FEE_RATE = "fundbox-servicing-fee-rate",
  FUNDRISE_CA_MAXIMUM_COMMITTED_FACILITY_AMOUNT = "fundrise-maximum-committed-facility-amount",
  FUNDRISE_CA_MAXIMUM_UNCOMMITTED_FACILITY_AMOUNT = "fundrise-maximum-uncommitted-facility-amount",
  FUNDRISE_CA_CAP_INTEREST_RATE = "fundrise-ca-cap-interest-rate",
  FUNDRISE_CA_APPLICABLE_INTEREST_RATE = "fundrise-ca-applicable-interest-rate",
  FUNDRISE_CA_JPM_ACCOUNT = "fundrise-ca-jpm-account",
  FUNDRISE_CA_LOAN_ACCOUNT = "fundrise-ca-loan-account",
  FUNDRISE_CA_RESERVE_ACCOUNT = "fundrise-ca-reserve-account",
  FUNDRISE_CA_BORROWER_ACCOUNT = "fundrise-ca-borrower-account",
  FUNDRISE_CA_DILIGENCE_ACCOUNT = "fundrise-ca-diligence-account",
  FUNDRISE_CA_MONTHLY_INCOME_COLLECTED = "fundrise-ca-monthly-income-collected",
  FUNDRISE_CA_MONTHLY_INVOICE_COLLECTED = "fundrise-ca-monthly-invoice-collected",
  FUNDRISE_CA_MANAGEMENT_FEE_BY_COMMUNITY = "fundrise-ca-management-fee-by-community",
  FUNDRISE_CA_MANAGEMENT_FEE_MINIMUM_BY_COMMUNITY = "fundrise-ca-management-fee-minimum-by-community",
  FUNDRISE_CA_FACILITY_OUTSTANDING_AMOUNT_OVERRIDE = "fundrise-ca-facility-outstanding-amount-override",
  DEMO_AIRLINE_CATALOG = "demo-airline-catalog",
  DEMO_AIRCRAFT_CATALOG = "demo-aircraft-catalog",
  DEMO_COUNTRY_CATALOG = "demo-country-catalog",
  DEMO_AGE_ADJUST_CATALOG = "demo-age-adjust-catalog",
  DEMO_TIER_ADJUST_CATALOG = "demo-tier-adjust-catalog",
  DEMO_AIRCRAFT_SUMMARY = "demo-aircraft-summary",
  KYTE_INVESTMENT_GRADE_ADVANCE_RATE = "kyte-investment-grade-advance-rate",
  KYTE_NON_INVESTMENT_GRADE_ADVANCE_RATE = "kyte-non-investment-grade-advance-rate",
  KYTE_CASH_AMOUNT_ADVANCE_RATE = "kyte-cash-amount-advance-rate",
  KYTE_MEZZ_FACILITY_ADVANCE_RATE = "kyte-mezz-facility-advance-rate",
  KYTE_DRAWN_AMOUNT_RATE = "kyte-drawn-amount-rate",
  KYTE_SENIOR_FACILITY_DRAWN_SPREAD = "kyte-senior-facility-drawn-spread",
  KYTE_MEZZ_DRAWN_SPREAD = "kyte-mezz-drawn-spread",
  KYTE_SENIOR_FACILITY_COMMITMENT_AMOUNT_A1 = "kyte-senior-facility-commitment-amount-a1",
  KYTE_SENIOR_FACILITY_COMMITMENT_AMOUNT_A2 = "kyte-senior-facility-commitment-amount-a2",
  KYTE_MEZZ_FACILITY_COMMITMENT_AMOUNT = "kyte-mezz-facility-commitment-amount",
  KYTE_SENIOR_FACILITY_MAX_PRINCIPAL = "kyte-senior-facility-max-principal",
  KYTE_MEZZ_FACILITY_MAX_PRINCIPAL = "kyte-mezz-facility-max-principal",
  KYTE_CLASS_A_UNDRAWN_FEE_RATE_ANNUAL = "kyte-class-a-undrawn-fee-rate-annual",
  KYTE_CLASS_B_UNDRAWN_FEE_RATE_ANNUAL = "kyte-class-b-undrawn-fee-rate-annual",
  KYTE_MANUFACTURER_TO_RATINGS_LEVEL = "kyte-manufacturer-to-ratings-level",
  KYTE_PRE_VOLCD_PROGRAM_VEHICLE_DEPRECIATION_AMOUNT = "kyte-pre-volcd-program-vehicle-depreciation-amount",
  KYTE_PROGRAM_VEHICLE_DEPRECIATION_ASSUMPTION_TRUE_UP_AMOUNT = "kyte-program-vehicle-depreciation-assumption-true-up-amount",
  KYTE_CASUALTY_PAYMENT_AMOUNT = "kyte-casualty-payment-amount",
  KYTE_TOTAL_SPECIAL_DEFAULT_PAYMENT_AMOUNT = "kyte-total-special-default-payment-amount",
  KYTE_TOTAL_REDESIGNATION_AMOUNTS = "kyte-total-redesignation-amounts",
  KYTE_TOTAL_EARLY_TERMINATION_PAYMENT_AMOUNT = "kyte-total-early-termination-payment-amount",
  MUNDI_SVB_DPD_LIMIT = "mundi-svb-dpd-limit",
  MUNDI_SVB_NOT_AADC_LIMIT = "mundi-svb-not-aadc-limit",
  MUNDI_SVB_TOP_FACTORING_LIMIT = "mundi-svb-top-factoring-limit",
  MUNDI_SVB_SECOND_FACTORING_LIMIT = "mundi-svb-second-factoring-limit",
  MUNDI_SVB_THIRD_FACTORING_LIMIT = "mundi-svb-third-factoring-limit",
  MUNDI_SVB_PRODUCE_LIMIT = "mundi-svb-produce-limit",
  MUNDI_SVB_UNINSURED_EXPOSURE_LIMIT = "mundi-svb-uninsured-exposure-limit",
  MUNDI_SVB_ORIGINAL_TERM_LIMIT = "mundi-svb-original-term-limit",
  MUNDI_SVB_WA_ORIGINAL_TERM_LIMIT = "mundi-svb-wa-original-term-limit",
  MUNDI_SVB_WA_MONTHLY_FEE_LIMIT = "mundi-svb-wa-monthly-fee-limit",
  MUNDI_SVB_WIND_DOWN_MONTHLY_VINTAGE_DEFAULTED_LIMIT = "mundi-svb-wind-down-monthly-vintage-defaulted-limit",
  MUNDI_SVB_WIND_DOWN_DELINQUENT_RATIO_LIMIT = "mundi-svb-wind-down-delinquent-ratio-limit",
  MUNDI_SVB_DEFAULT_EVENT_MONTHLY_VINTAGE_DEFAULTED_LIMIT = "mundi-svb-default-event-monthly-vintage-defaulted-limit",
  MUNDI_SVB_DEFAULT_EVENT_DELINQUENT_RATIO_LIMIT = "mundi-svb-default-event-delinquent-ratio-limit",
  MUNDI_SVB_INSURANCE_COVERAGE_AMOUNT = "mundi-svb-insurance-coverage-amount",
  FF_ATALAYA_AVG_ORIGINAL_BALANCE_LIMIT = "ff-atalaya-avg-original-balance-limit",
  FF_ATALAYA_WA_FICO_LIMIT = "ff-atalaya-wa-fico-limit",
  FF_ATALAYA_FICO_HIGH_LIMIT = "ff-atalaya-fico-high-limit",
  FF_ATALAYA_WA_PAYMENT_TO_INCOME_LIMIT = "ff-atalaya-wa-payment-to-income-limit",
  FF_ATALAYA_WA_ANNUAL_INCOME_LIMIT = "ff-atalaya-wa-annual-income-limit",
  FF_ATALAYA_FICO_LOW_LIMIT = "ff-atalaya-fico-low-limit",
  FF_ATALAYA_WA_ORIGINAL_TERM_LIMIT = "ff-atalaya-wa-original-term-limit",
  FF_ATALAYA_ORIGINAL_TERM_LIMIT = "ff-atalaya-original-term-limit",
  FF_ATALAYA_CA_MID_BALANCE_LIMIT = "ff-atalaya-ca-mid-balance-limit",
  FF_ATALAYA_CA_HIGH_BALANCE_LIMIT = "ff-atalaya-ca-high-balance-limit",
  FF_ATALAYA_TOP2_STATES_MID_BALANCE_LIMIT = "ff-atalaya-top2-states-mid-balance-limit",
  FF_ATALAYA_TOP2_STATES_HIGH_BALANCE_LIMIT = "ff-atalaya-top2-states-high-balance-limit",
  FF_ATALAYA_TOP3_STATES_MID_BALANCE_LIMIT = "ff-atalaya-top3-states-mid-balance-limit",
  FF_ATALAYA_TOP3_STATES_HIGH_BALANCE_LIMIT = "ff-atalaya-top3-states-high-balance-limit",
  FF_ATALAYA_WA_YIELD_LIMIT = "ff-atalaya-wa-yield-limit",
  FF_ATALAYA_ZERO_APR_LIMIT = "ff-atalaya-zero-apr-limit",
  FF_ATALAYA_MODIFIED_LIMIT = "ff-atalaya-modified-limit",
  FF_ATALAYA_MODIFIED_LEGACY_LIMIT = "ff-atalaya-modified-legacy-limit",
  FF_ATALAYA_LEGACY_RECEIVABLES_ADVANCE_LIMIT = "ff-atalaya-legacy-receivables-advance-limit",
  FF_ATALAYA_LESS_680_FICO_RECEIVABLES_ADVANCE_LIMIT = "ff-atalaya-less-680-fico-receivables-advance-limit",
  FF_ATALAYA_SHORT_RECEIVABLES_ADVANCE_LIMIT = "ff-atalaya-short-receivables-advance-limit",
  FF_ATALAYA_ZERO_APR_RECEIVABLES_ADVANCE_LIMIT = "ff-atalaya-zero-apr-receivables-advance-limit",
  FF_ATALAYA_OTHER_RECEIVABLES_ADVANCE_LIMIT = "ff-atalaya-other-receivables-advance-limit",
  FF_ATALAYA_DELINQUENCY_RATIO_LIMIT = "ff-atalaya-deliquency-ratio-limit",
  SILO_SVB_TOTAL_COMMITMENT_AMOUNT = "silo-svb-total-commitment-amount",
  SILO_SVB_MINIMUM_OVERCOLLATERALIZATION = "silo-svb-minimum-overcollateralization",
  SILO_SVB_ADVANCE_RATE = "silo-svb-advance-rate",
  MCH_LTC_RATIO_CLASS_A_LIMIT = "mch-ltc-ratio-class-a-limit",
  MCH_LTV_RATIO_CLASS_A_LIMIT = "mch-ltv-ratio-class-a-limit",
  MCH_LTV_RATIO_BFR_CLASS_A_LIMIT = "mch-ltv-ratio-bfr-class-a",
  MCH_LTC_RATIO_CLASS_A_B_LIMIT = "mch-ltc-ratio-class-a-b",
  MCH_LTV_RATIO_CLASS_A_B_LIMIT = "mch-ltv-ratio-class-a-b",
  MCH_LTV_RATIO_BFR_CLASS_A_B_LIMIT = "mch-ltv-ratio-bfr-class-a-b",
  MCH_LARGEST_MARKET_LIMIT = "mch-largest-market",
  MCH_ATTACHED_RENTAL_HOMES_LIMIT = "mch-attached-rental-homes",
  MCH_180_DAY_AGED_PROPERTIES_LIMIT = "mch-180-day-aged-properties",
  MCH_270_DAY_AGED_PROPERTIES_LIMIT = "mch-270-day-aged-properties",
  MCH_BPO_VALUE_LOW_LIMIT = "mch-bpo-value-low",
  MCH_BPO_VALUE_HIGH_LIMIT = "mch-bpo-value-high",
  MCH_BFR_PROPERTIES_LIMIT = "mch-bfr-properties",
  MCH_LTV_RATIO_AMORTIZATION_EVENT_LIMIT = "mch-ltv-ratio-amortization-event",
  MCH_DSCR_AMORTIZATION_EVENT_LIMIT = "mch-dscr-amortization-event",
  MCH_DEBT_YIELD_AMORTIZATION_EVENT_LIMIT = "mch-debt-yield-amortization-event",
  MCH_AMOUNT_DUE_TO_THE_BORROWER = "mch-amount-due-to-the-borrower",
  MCH_AMOUNT_DUE_TO_A_BORROWER_OPERATING_ACCOUNT = "mch-amount-due-to-a-borrower-operating-account",
  MCH_AMOUNT_DUE_TO_EACH_LENDER = "mch-amount-due-to-each-lender",
  MCH_AMOUNT_DUE_TO_EACH_SWAP_COUNTERPARTY = "mch-amount-due-to-each-swap-counterparty",
  MCH_EARLY_AMORTIZATION_EVENT_PAYDOWN = "mch-early-amortization-event-paydown",
  MCH_ADMINISTRATIVE_AGENT_FEE = "mch-administrative-agent-fee",
  MCH_PAYING_AGENT_FEE = "mch-paying-agent-fee",
  MCH_CALCULATION_AGENT_FEE = "mch-calculation-agent-fee",
  MCH_DILIGENCE_AGENT_FEE = "mch-diligence-agent-fee",
  MCH_LENDERS_FEE = "mch-lenders-fee",
  MCH_EXIT_FEE = "mch-exit-fee",
  MCH_OTHER_OBLIGATIONS = "mch-other-obligations",
  MCH_NOTIONAL_AMOUNT = "mch-notional-amount",
  MCH_TOTAL_COMMITMENT = "mch-total-commitment",
  PSF_FACILITY_SERVICER_PAYMENTS = "psf-facility-servicer-payments",
  ROCKLYN_ELIGIBLE_POST_STABILIZED_VACANT_6_9MO = "rocklyn-eligible-post-stabilized-vacant-6-9mo",
  ROCKLYN_ELIGIBLE_POST_STABILIZED_DELINQUENT_6_9MO = "rocklyn-eligible-post-stabilized-delinquent-6-9mo",
  ROCKLYN_ELIGIBLE_POST_STABILIZED_VACANT_9MO = "rocklyn-eligible-post-stabilized-vacant-9mo",
  ROCKLYN_ELIGIBLE_POST_STABILIZED_DELINQUENT_9MO = "rocklyn-eligible-post-stabilized-delinquent-9mo",
  ROCKLYN_ELIGIBLE_PRE_STABILIZED_6_9MO = "rocklyn-eligible-pre-stabilized-6-9mo",
  ROCKLYN_ELIGIBLE_PRE_STABILIZED_9MO = "rocklyn-eligible-pre-stabilized-9mo",
  ROCKLYN_ELIGIBLE_ALL_OTHER = "rocklyn-eligible-all-other",
  ROCKLYN_ACQUISITION_COST_MULTIPLIER_SELF_BUILT = "rocklyn-acquisition-cost-multiplier-self-built",
  ROCKLYN_AGGREGATE_COMMITMENT_AMOUNT = "rocklyn-aggregate-commitment-amount",
  ROCKLYN_REQUEST_ADVANCE_THRESHOLD = "rocklyn-request-advance-threshold",
  ROCKLYN_FLOOR = "rocklyn-floor",
  ROCKLYN_APPLICABLE_MARGIN_BEFORE_EXTENSION = "rocklyn-applicable-margin-before-extension",
  ROCKLYN_APPLICABLE_MARGIN_ON_OR_AFTER_EXTENSION = "rocklyn-applicable-margin-on-or-after-extension",
  ROCKLYN_CASH_TRAP_INTEREST_RATE_SPREAD = "rocklyn-cash-trap-interest-rate-spread",
  ROCKLYN_DEFAULT_INTEREST_RATE_SPREAD = "rocklyn-default-interest-rate-spread",
  ROCKLYN_NOTIONAL_AMOUNT_OUTSTANDING = "rocklyn-notional-amount-outstanding",
  ROCKLYN_STRIKE_PRICE = "rocklyn-strike-price",
  ROCKLYN_STRIKE_RATE = "rocklyn-strike-rate",
  ROCKLYN_LIMIT_PERCENT_BY_COMMUNITY = "rocklyn-limit-percent-by-community",
  ROCKLYN_LIMIT_BY_COMMUNITY = "rocklyn-limit-by-community",
  ROCKLYN_LIMIT_PERCENT_BY_METRO_AREA = "rocklyn-limit-percent-by-metro-area",
  ROCKLYN_LIMIT_PERCENT_LOW_BY_METRO_AREA = "rocklyn-limit-percent-low-by-metro-area",
  ROCKLYN_LIMIT_PERCENT_HIGH_BY_METRO_AREA = "rocklyn-limit-percent-high-by-metro-area",
  ROCKLYN_LIMIT_LESS_BY_METRO_AREA = "rocklyn-limit-less-by-metro-area",
  ROCKLYN_LIMIT_GREATER_BY_METRO_AREA = "rocklyn-limit-greater-by-metro-area",
  ROCKLYN_LIMIT_PERCENT_CONDOMINIUM_UNITS = "rocklyn-limit-percent-condominium-units",
  PATHWAY_QUALIFYING_REFINANCING_DATE = "pathway-qualifying-refinancing-date",
  PATHWAY_APPLICABLE_MARGIN = "pathway-applicable-margin",
  PATHWAY_MAXIMUM_LEGAL_RATE = "pathway-maximum-legal-rate",
  PATHWAY_AGGREGATE_COMMITMENT = "pathway-aggregate-commitment",
  PATHWAY_UNUSED_FEE_RATE = "pathway-unused-fee-rate",
  PATHWAY_ADMINISTRATIVE_FEE = "pathway-administrative-fee",
  PATHWAY_LENDER = "pathway-lender",
  PATHWAY_LENDER_COMMITMENT = "pathway-lender-commitment",
  PATHWAY_STRIKE_RATE = "pathway-strike-rate",
  PATHWAY_AGG_NOTIONAL_PRINCIPAL_AMOUNT = "pathway-agg-notional-principal-amount",
  PATHWAY_CASH_COLLATERAL_RESERVE = "pathway-cash-collateral-reserve",
  PATHWAY_DEBT_SERVICE_RESERVE = "pathway-debt-service-reserve",
  PATHWAY_ELIGIBILITY_RESERVES = "pathway-eligibility-reserves",
  PATHWAY_INSURANCE_RESERVES = "pathway-insurance-reserves",
  PATHWAY_NON_CONFORMING_PROPERTY_RESERVES = "pathway-non-conforming-property-reserves",
  PATHWAY_SPECIAL_INSURANCE_RESERVES = "pathway-special-insurance-reserves",
  PATHWAY_SPECIAL_RESERVES = "pathway-special-reserves",
  PATHWAY_STANDING_RESERVES = "pathway-standing-reserves",
  PATHWAY_TAX_RESERVE = "pathway-tax-reserve",
  SOFR_CURVE = "sofr-curve",
  GREENSKY_GS_TOP_3_STATES = "greensky-gs-top-3-states",
  GREENSKY_GS_TOP_STATE = "greensky-gs-top-state",
  GREENSKY_GS_REDUCED_RATE_ASSETS_FICO_GT_775 = "greensky-gs-rr-assets-fico-gt-775",
  GREENSKY_GS_ZERO_INTEREST_ASSETS_FICO_GT_775 = "greensky-gs-zero-interest-assets-fico-gt-775",
  GREENSKY_GS_DEFERRED_RATE_ASSETS_FICO_GT_775 = "greensky-gs-deferred-rate-assets-fico-gt-775",
  GREENSKY_GS_REDUCED_RATE_ASSETS_FICO_LT_700 = "greensky-gs-rr-assets-fico-lt-700",
  GREENSKY_GS_ZERO_INTEREST_ASSETS_FICO_LT_700 = "greensky-gs-zero-interest-assets-fico-lt-700",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS_FICO_LT_700 = "greensky-gs-di-assets-fico-lt-700",
  GREENSKY_GS_FICO_LT_660 = "greensky-gs-fico-lt-660",
  GREENSKY_GS_TOP_3_MERCHANT_CATEGORIES = "greensky-gs-top-3-merchant-categories",
  GREENSKY_GS_TOP_MERCHANT_CATEGORY_NOT_IN_TOP_3 = "greensky-gs-top-merchant-category-not-in-top-3",
  GREENSKY_GS_TOP_MERCHANT = "greensky-gs-top-merchant",
  GREENSKY_GS_CREDIT_LIMIT_GT_75K = "greensky-gs-credit-limit-gt-75k",
  GREENSKY_GS_PRE_FUNDING_PERIOD_GT_6M = "greensky-gs-pre-funding-period-gt-6m",
  GREENSKY_GS_REDUCED_RATE_ASSETS_MINIMUM_TEST = "greensky-gs-rr-assets-minimum-test",
  GREENSKY_GS_ZERO_INTEREST_ASSETS = "greensky-gs-zero-interest-assets",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS = "greensky-gs-di-assets",
  GREENSKY_GS_DEFERRED_NO_NO_ASSETS = "greensky-gs-deferred-no-no-assets",
  GREENSKY_GS_MERCHANT_CATEGORY_SOLAR = "greensky-gs-merchant-category-solar",
  GREENSKY_GS_MERCHANT_CATEGORY_HOME_DEPOT = "greensky-gs-merchant-category-home-depot",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS_ORIG_PROMO_PERIOD_GT_18M = "greensky-gs-di-assets-orig-promo-period-gt-18m",
  GREENSKY_GS_30_DQ_30_59_DPD_AT_PRIOR_EOM = "greensky-gs-30-dq-30-59-dpd-at-prior-eom",
  GREENSKY_GS_MERCHANT_CATEGORY_WINDOWS_DOORS_HVAC_MINIMUM_TEST = "greensky-gs-merchant-multi-category-minimum-test",
  GREENSKY_GS_REDUCED_RATE_ASSETS_AND_NO_OR_LOW_INTEREST_RATE_INTRO_PERIOD = "greensky-gs-rr-assets-and-no-or-low-interest-rate-intro-period",
  GREENSKY_GS_DTI_GT_65 = "greensky-gs-dti-gt-65",
  GREENSKY_GS_DTI_GT_120 = "greensky-gs-dti-gt-120",
  GREENSKY_GS_DEFERRED_INTEREST_ASSETS_MINIMUM_TEST = "greensky-gs-di-assets-minimum-test",
  GREENSKY_GS_BACK_BOOK_MATERIAL_MODIFICATION = "greensky-gs-back-book-material-modification",
  GREENSKY_GS_THREATENING_ROR_OFFSET_COUNTERCLAIM_DISCOUNT_ADJUSTMENT_OR_DEFENSE = "greensky-gs-disputed-assets",
  GREENSKY_GS_WA_FICO_REDUCED_RATE_ASSETS = "greensky-gs-wa-fico-rr-assets",
  GREENSKY_GS_WA_FICO_ZERO_INTEREST_ASSETS = "greensky-gs-wa-fico-zero-interest-assets",
  GREENSKY_GS_WA_FICO_DEFERRED_INTEREST_ASSETS = "greensky-gs-wa-fico-di-assets",
  GREENSKY_GS_WA_DTI_REDUCED_RATE_ASSETS = "greensky-gs-wa-dti-rr-assets",
  GREENSKY_GS_WA_DTI_ZERO_INTEREST_ASSETS = "greensky-gs-wa-dti-zero-interest-assets",
  GREENSKY_GS_WA_DTI_DEFERRED_INTEREST_ASSETS = "greensky-gs-wa-dti-di-assets",
  GREENSKY_GS_WA_REMAINING_TERM_REDUCED_RATE = "greensky-gs-wa-remaining-term-reduced-rate",
  GREENSKY_GS_WA_REMAINING_TERM_DEFERRED_INTEREST = "greensky-gs-wa-remaining-term-deferred-interest",
  GREENSKY_GS_WA_REMAINING_TERM_ZERO_INTEREST = "greensky-gs-wa-remaining-term-zero-interest",
  GREENSKY_GS_WA_REMAINING_PROMO_PERIOD_ZERO_INTEREST = "greensky-gs-wa-remaining-promo-period-zero-interest",
  GREENSKY_GS_WA_REMAINING_PROMO_PERIOD_REDUCED_RATE = "greensky-gs-wa-remaining-promo-period-reduced-rate",
  GREENSKY_GS_WA_INTEREST_RATE_REDUCED_RATE_ASSETS = "greensky-gs-wa-interest-rate-rr-assets",
  GREENSKY_GS_WA_INTEREST_RATE_DEFERRED_INTEREST_ASSETS = "greensky-gs-wa-interest-rate-di-assets",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_35_40 = "greensky-gs-open-purchase-window-assets-35-40",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_40_45 = "greensky-gs-open-purchase-window-assets-40-45",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_45_50 = "greensky-gs-open-purchase-window-assets-45-50",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_50_55 = "greensky-gs-open-purchase-window-assets-50-55",
  GREENSKY_GS_OPEN_PURCHASE_WINDOW_ASSETS_55 = "greensky-gs-open-purchase-window-assets-55",
  GREENSKY_GS_REVOLVING_PERIOD_END_DATE = "greensky-revolving-period-end-date",
  GREENSKY_GS_WAREHOUSE_2Y_ALLOCATION = "greensky-gs-warehouse-2y-allocation",
  GREENSKY_GS_COLLATERAL_ACCOUNT_BALANCE = "greensky-gs-collateral-account-balance",
  GREENSKY_GS_DESIRE_TO_KEEP_AMOUNT = "greensky-gs-desire-to-keep-amount",
  UPLIFT_ATALAYA_CONCENTRATION_1 = "uplift-atalaya-concentration-1",
  UPLIFT_ATALAYA_CONCENTRATION_2 = "uplift-atalaya-concentration-2",
  UPLIFT_ATALAYA_CONCENTRATION_3 = "uplift-atalaya-concentration-3",
  UPLIFT_ATALAYA_CONCENTRATION_4 = "uplift-atalaya-concentration-4",
  UPLIFT_ATALAYA_CONCENTRATION_5 = "uplift-atalaya-concentration-5",
  UPLIFT_ATALAYA_CONCENTRATION_6 = "uplift-atalaya-concentration-6",
  UPLIFT_ATALAYA_CONCENTRATION_7 = "uplift-atalaya-concentration-7",
  UPLIFT_ATALAYA_CONCENTRATION_8 = "uplift-atalaya-concentration-8",
  UPLIFT_ATALAYA_CONCENTRATION_9 = "uplift-atalaya-concentration-9",
  UPLIFT_ATALAYA_CONCENTRATION_10 = "uplift-atalaya-concentration-10",
  UPLIFT_ATALAYA_CONCENTRATION_11 = "uplift-atalaya-concentration-11",
  UPLIFT_ATALAYA_CONCENTRATION_12 = "uplift-atalaya-concentration-12",
  UPLIFT_ATALAYA_CONCENTRATION_13 = "uplift-atalaya-concentration-13",
  UPLIFT_ATALAYA_CONCENTRATION_14 = "uplift-atalaya-concentration-14",
  UPLIFT_ATALAYA_CONCENTRATION_15 = "uplift-atalaya-concentration-15",
  UPLIFT_ATALAYA_CONCENTRATION_17 = "uplift-atalaya-concentration-17",
  UPLIFT_ATALAYA_CONCENTRATION_18 = "uplift-atalaya-concentration-18",
  UPLIFT_ATALAYA_CONCENTRATION_19 = "uplift-atalaya-concentration-19",
  UPLIFT_ATALAYA_CONCENTRATION_21 = "uplift-atalaya-concentration-21",
  UPLIFT_ATALAYA_CONCENTRATION_22 = "uplift-atalaya-concentration-22",
  UPLIFT_ATALAYA_CONCENTRATION_23 = "uplift-atalaya-concentration-23",
  UPLIFT_ATALAYA_CONCENTRATION_24 = "uplift-atalaya-concentration-24",
  UPLIFT_ATALAYA_CONCENTRATION_26 = "uplift-atalaya-concentration-26",
  UPLIFT_ATALAYA_CONCENTRATION_27 = "uplift-atalaya-concentration-27",
  UPLIFT_ATALAYA_CONCENTRATION_28 = "uplift-atalaya-concentration-28",
  UPLIFT_ATALAYA_CONCENTRATION_29 = "uplift-atalaya-concentration-29",
  UPLIFT_ATALAYA_CONCENTRATION_30 = "uplift-atalaya-concentration-30",
  ZIBBY_ATALAYA_CONCENTRATION_1 = "zibby-atalaya-concentration-1",
  ZIBBY_ATALAYA_CONCENTRATION_2 = "zibby-atalaya-concentration-2",
  ZIBBY_ATALAYA_CONCENTRATION_3 = "zibby-atalaya-concentration-3",
  ZIBBY_ATALAYA_CONCENTRATION_4 = "zibby-atalaya-concentration-4",
  ZIBBY_ATALAYA_CONCENTRATION_5 = "zibby-atalaya-concentration-5",
  ZIBBY_ATALAYA_CONCENTRATION_6 = "zibby-atalaya-concentration-6",
  ZIBBY_ATALAYA_CONCENTRATION_7 = "zibby-atalaya-concentration-7",
  ZIBBY_ATALAYA_CONCENTRATION_8 = "zibby-atalaya-concentration-8",
  UPGRADE_ATALAYA_CONCENTRATION_1 = "upgrade-atalaya-concentration-1",
  UPGRADE_ATALAYA_CONCENTRATION_2 = "upgrade-atalaya-concentration-2",
  UPGRADE_ATALAYA_CONCENTRATION_3 = "upgrade-atalaya-concentration-3",
  UPGRADE_ATALAYA_CONCENTRATION_4 = "upgrade-atalaya-concentration-4",
  UPGRADE_ATALAYA_CONCENTRATION_5 = "upgrade-atalaya-concentration-5",
  UPGRADE_ATALAYA_CONCENTRATION_6 = "upgrade-atalaya-concentration-6",
  UPGRADE_ATALAYA_CONCENTRATION_7 = "upgrade-atalaya-concentration-7",
  UPGRADE_ATALAYA_CONCENTRATION_8 = "upgrade-atalaya-concentration-8",
  UPGRADE_ATALAYA_CONCENTRATION_9 = "upgrade-atalaya-concentration-9",
  UPGRADE_ATALAYA_CONCENTRATION_10 = "upgrade-atalaya-concentration-10",
  UPGRADE_ATALAYA_CONCENTRATION_11 = "upgrade-atalaya-concentration-11",
  UPGRADE_ATALAYA_CONCENTRATION_12 = "upgrade-atalaya-concentration-12",
  UPGRADE_ATALAYA_CONCENTRATION_13 = "upgrade-atalaya-concentration-13",
  UPGRADE_ATALAYA_CONCENTRATION_14 = "upgrade-atalaya-concentration-14",
  UPGRADE_ATALAYA_CONCENTRATION_15 = "upgrade-atalaya-concentration-15",
  GENESIS_ATALAYA_CON_1 = "genesis-atalaya-con-1",
  GENESIS_ATALAYA_CON_2 = "genesis-atalaya-con-2",
  GENESIS_ATALAYA_CON_3 = "genesis-atalaya-con-3",
  GENESIS_ATALAYA_CON_4 = "genesis-atalaya-con-4",
  GENESIS_ATALAYA_CON_5 = "genesis-atalaya-con-5",
  GENESIS_ATALAYA_CON_6 = "genesis-atalaya-con-6",
}
export enum ExportReportStatus {
  PENDING = "pending",
  DONE = "done",
}
export enum BorrowingBaseEntryType {
  ENTER = "enter",
  EXIT = "exit",
  ADVANCE_STEP_UP = "advance-step-up",
  ADVANCE_STEP_DOWN = "advance-step-down",
  ADVANCE_RATE_MANUAL_ADJUSTMENT = "advance-rate-override",
  ADVANCE_AMOUNT_OVERRIDE = "advance-amount-override",
}
export enum BuyerGroupStatus {
  PRE_PROPERTY = "pre-property",
  ACTIVE = "active",
  UNDER_CONTRACT = "under-contract",
  FUNDING = "funding",
  TRADITIONAL_FUNDING = "traditional-funding",
  TERMINATED = "terminated",
  DELETED = "deleted",
}
export enum BuyerGroupPersonnelType {
  AGENT = "agent",
  LOAN_OFFICER = "loan-officer",
  OTHER = "other",
}
export enum ActivityFeedType {
  CLOSE_DATE_CHANGE = "close-date-change",
  DOCUMENT_FAIL = "document-fail",
  DOCUMENT_FAIL_DISPUTE = "document-fail-dispute",
  FUNDING_REQUIREMENT_COMPLETE = "funding-requirement-complete",
  FUNDING_REQUIREMENT_INCOMPLETE = "funding-requirement-incomplete",
  NOTE = "note",
  NOTE_EDIT = "note-edit",
  CATEGORY_COMPLETE = "category-complete",
  DILIGENCE_FAIL = "diligence-fail",
  DILIGENCE_PASS = "diligence-pass",
  BUYER_GROUP_PROPERTY_TO_ASSET_PROPERTY = "bg-prop-to-asset-prop",
  ASSET_PROPERTY_FROM_BUYER_GROUP_PROPERTY = "asset-prop-from-bg-prop",
  PROPERTY_FUNDING_REQUESTED = "property-funding-requested",
  PROPERTY_FUNDED = "property-funded",
  OPENDOOR_CLOSING_REPORT_UPDATED = "od-closing-report-updated",
  FACILITY_REASSIGNED = "facility-reassigned",
  DILIGENCE_FACILITY_ASSIGNED = "diligence-facility-assigned",
  MARKET_VALUE_OVERRIDE = "market-value-updated",
  PROPERTY_PAID_OFF = "property-paid-off",
  AT_CLOSE_COMPLETE = "at-close-complete",
  POST_CLOSE_COMPLETE = "post-close-complete",
  PRE_CLOSE_COMPLETE = "pre-close-complete",
  DILIGENCE_FACILITY_REASSIGNED = "diligence-facility-reassigned",
}
export enum BuyersTab {
  ALL = "all",
  PRE_PROPERTY = "program-introduction",
  ACTIVE = "house-hunting",
  UNDER_CONTRACT = "under-contract",
  FUNDING = "funding",
  TRADITIONAL = "traditional",
  TERMINATED = "terminated",
  UPCOMING = "upcoming",
  OVERDUE = "overdue",
}
export enum CalcAgentInputFileType {
  ANCILLARY_FILE = "ancillary-file",
  ASSET_OS = "asset-os",
  CERT = "cert",
  DATA_TAPE = "data-tape",
  OUTPUT_ATTACHMENT = "output-attachment",
  SIGNED_BORROWING_BASE = "signed-borrowing-base",
  VERIFICATION_FILE = "verification-file",
}
export enum CalcAgentInputModalType {
  DATE = "date",
  FLOAT = "float",
  PERCENT = "percent",
  MONEY = "money",
  MONTH = "month",
  REPORT = "report",
  SNOWFLAKE = "snowflake",
  TEXT = "text",
  YEAR = "year",
  YES_OR_NO = "yes-or-no",
}
export enum CalcAgentInputModelField {
  EFFECTIVE_DATE = "effective-date",
  MONTH = "month",
  YEAR = "year",
}
export enum CalcAgentReportType {
  BORROWER_REPORT_ASSET_VERIFICATION = "borrower-report-asset-verification",
  BORROWER_REPORT_FUNDING_REQUEST = "borrower-report-funding-request",
  BORROWER_REPORT_INITIAL_SALE = "borrower-report-initial-sale",
  BORROWER_REPORT_INITIAL_PURCHASE = "borrower-report-initial-purchase",
  BORROWER_REPORT_MONTHLY_FUNDING_REPORT = "borrower-report-monthly-funding",
  BORROWER_REPORT_MONTHLY_REMITTANCE = "borrower-report-monthly-remittance",
  BORROWER_REPORT_WEEKLY_BORROWING_BASE_SUBMISSION = "borrower-report-weekly-borrowing-base-submission",
  BORROWER_REPORT_BORROWING_BASE_SUBMISSION = "borrower-report-borrowing-base-submission",
  BORROWER_REPORT_MONTHLY_SERVICER_REPORT = "borrower-report-monthly-servicer-report",
  BORROWER_REPORT_MONTHLY_NOTEHOLDERS_STATEMENT = "borrower-report-monthly-noteholders-statement",
  CALC_AGENT_BORROWING_REQUEST = "borrowing-request",
  CALC_AGENT_MONTHLY_REMITTANCE = "monthly-remittance",
  CALC_AGENT_RESERVE_RELEASE_REQUEST = "reserve-release-request",
  CALC_AGENT_RELEASE_REQUEST = "release-request",
  CALC_AGENT_MONTHLY_PAYMENT = "monthly-payment",
}
export enum CalcAgentStatus {
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
  CLOSED = "closed",
}
export enum CalcAgentReviewStatus {
  PENDING = "pending",
  READY_FOR_SETPOINT_REVIEW = "ready-for-setpoint-review",
  APPROVED = "approved",
  CHANGES_REQUESTED = "changes-requested",
  SUBMITTED_TO_LENDER = "submitted",
  READY_FOR_BORROWING_REQUEST = "ready-for-borrowing-request",
  BORROWING_REQUEST_ADDED = "borrowing-request-added",
  FUNDED = "funded",
}
export enum CalcAgentProductCategory {
  BORROWER_REPORTS = "borrower-reports",
  CALCULATION_AGENT = "calculation-agent",
}
export enum CalcAgentTimelineType {
  APPROVAL = "approval",
  COMMENT = "comment",
  EMAIL_COMMENT = "email-comment",
  CREATE_REPORT = "create",
  INITIAL_REPORT_READY = "initial-report-ready",
  FULL_REPORT_READY = "full-report-ready",
  READY_FOR_REVIEW = "review-ready",
  REJECTION = "rejection",
  SUBMITTED_TO_LENDER = "submitted",
  UNDO_APPROVAL = "undo-approval",
  READY_FOR_BORROWING_REQUEST = "ready-for-borrowing-request",
  BORROWING_REQUEST_ADDED = "borrowing-request-added",
  FUNDING = "funding",
}
export enum CustomWaiverType {
  ATALAYA_ALMOST_GLOBAL_WAIVER = "atalaya-almost-global-waiver",
  ATALAYA_OVER_ADVANCE = "atalaya-over-advance",
}
export enum Action {
  DELETED = "Deleted",
  CREATED = "Created",
  UPDATED = "Updated",
}
export enum TrackedChanges {
  SETTING = "Setting",
  WAIVER = "Waiver",
}
export enum CalcAgentBorrowingRequestModalType {
  DATE = "date",
  MONEY = "money",
  MONTH = "month",
  REPORT = "report",
  TEXT = "text",
  YEAR = "year",
}
export enum CalcAgentCurrencyType {
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR",
  JPY = "JPY",
  AUD = "AUD",
  CAD = "CAD",
  CHF = "CHF",
  CNY = "CNY",
  SEK = "SEK",
  NZD = "NZD",
  MXN = "MXN",
  SGD = "SGD",
  HKD = "HKD",
  NOK = "NOK",
  KRW = "KRW",
  TRY = "TRY",
  RUB = "RUB",
  INR = "INR",
  BRL = "BRL",
}
export enum FacilityComplianceRequirementCadence {
  ANNUAL = "annual",
  QUARTERLY = "quarterly",
  MONTHLY = "monthly",
}
export enum FacilityComplianceRequirementStatus {
  PENDING = "pending",
  DUE = "due",
  PAST_DUE = "past-due",
  FLAGGED = "flagged",
  COMPLETED = "completed",
  ARCHIVED = "archived",
}
export enum ExemptionStatus {
  REQUESTED = "requested",
  APPROVED = "approved",
  DENIED = "denied",
}
export enum CustomerBankTransactionState {
  UNRECONCILED = "unreconciled",
  RECONCILED = "reconciled",
}
export enum CustomerInvoiceState {
  UNRECONCILED = "unreconciled",
  RECONCILED = "reconciled",
}
export enum DocumentAssetQueueFilterType {
  ALL = "All",
  CLEARED = "Cleared",
  IN_PROGRESS = "In Progress",
  PRE_CLOSE_IN_PROGRESS = "Pre-Close In Progress",
  PRE_CLOSE_UPDATED = "Pre-Close Updated",
  POST_CLOSE_IN_PROGRESS = "Post-Close In Progress",
  POST_CLOSE_UPDATED = "Post-Close Updated",
  NOT_STARTED = "Not Started",
  HIDDEN = "Hidden",
  TERMINATED = "Terminated",
  EXCEPTION = "Exception",
}
export enum DocumentAssetQueueType {
  RTL = "rtl",
  SFR = "sfr",
  SPC_RTL = "spc-rtl",
}
export enum DocumentReviewStatus {
  PASS = "pass",
  FAIL = "fail",
}
export enum DocumentQueueFilterType {
  IN_PROGRESS = "In Progress",
  PRE_CLOSE_IN_PROGRESS = "Pre-Close In Progress",
  ALL = "All",
}
export enum ConrexBuyingEntity {
  AGREGICH = "Argegich 2022-1 LLC",
  BNTR = "BNTR DFR Owner LLC",
  BPREP = "BPREP SFR Owner LLC",
}
export enum RTLBorrowerExperienceBucket {
  INSTITUTIONAL = "Institutional",
  EXPERIENCED = "Experienced",
  LIMITED = "Limited",
  NO_EXPERIENCE = "No Experience",
  UNABLE_TO_VERIFY = "Unable To Verify",
}
export enum HUDFlag {
  PURCHASE = "Purchase",
  REFINANCE = "Refinance",
}
export enum TitleType {
  COMMITMENT = "Commitment",
  TITLE_POLICY = "Title Policy",
}
export enum LoanTypeCategory {
  PURCHASE = "Purchase",
  REFINANCE = "Refinance",
  CASH_OUT_REFINANCE = "Cash Out Refinance",
  R_T_REFINANCE = "R/T Refinance",
  NO_FLAG = "No Flag",
  DELAYED_FINANCE = "Delayed Finance",
}
export enum DeedType {
  RECORDED = "Recorded",
  UNRECORDED = "Unrecorded",
}
export enum RateType {
  FIXED = "Fixed",
  VARIABLE = "Variable",
}
export enum AccrualType {
  _30_360 = "30/360",
  ACTUAL_360 = "Actual/360",
  ACTUAL_365 = "Actual/365",
}
export enum Dutch {
  DUTCH = "Dutch",
  NON_DUTCH = "Non-Dutch",
}
export enum USForeign {
  US = "US",
  FOREIGN = "Foreign",
}
export enum GuarantyType {
  AMOUNT = "$ Amount",
  UNLIMITED = "Unlimited",
}
export enum HazardPolicyType {
  HAZARD = "Hazard",
  BUILDERS_RISK = "Builders Risk",
  LIABILITY = "Liability",
  CONDO = "Condo",
  CONDO_WALLS_IN_MASTER = "Condo Walls In Master",
}
export enum RTLValuationType {
  TEN_04 = "1004",
  TEN_05 = "1005",
  TEN_25 = "1025",
  TEN_73 = "1073",
  TWENTY_55 = "2055",
  HYBRID_APPRAISAL = "Hybrid Appraisal",
  BPO = "BPO",
  DESKTOP_APPRAISAL = "Desktop Appraisal",
  COMMERCIAL = "Commercial",
}
export enum OccupiedType {
  TENANT = "Tenant",
  OWNER = "Owner",
  VACANT = "Vacant",
}
export enum LocationType {
  URBAN = "Urban",
  SUBURBAN = "Suburban",
  RURAL = "Rural",
  NOT_PROVIDED = "Not Provided",
}
export enum MarketCondition {
  INCREASING = "Increasing",
  STABLE = "Stable",
  DECLINING = "Declining",
  UNKNOWN = "Unknown",
}
export enum ZoningCompliance {
  LEGAL = "Legal",
  LEGAL_NONCONFORMING = "Legal Nonconforming",
  ILLEGAL = "Illegal",
  NO_ZONING = "No Zoning",
  NOT_PROVIDED = "Not Provided",
}
export enum RTLPropertyType {
  SFR = "SFR",
  CONDO = "Condo",
  TOWNHOME = "Townhome",
  TWO_FOUR_UNIT = "2-4 Unit",
  MULTIFAMILY = "Multifamily (5+ Units)",
  MIXED_USE = "Mixed Use",
  COOP = "Coop",
  COMMERCIAL = "Commercial",
  LAND = "Land",
  FINISHED_LOTS = "Finished Lots",
  DEVELOPMENT = "Development",
  OTHER = "Other",
  NOT_PROVIDED = "Not Provided",
}
/**
 * NYMT has custom validation where a "condo" value in the data tape can be matched
 * to either Condo - High Rise or Condo - Low Rise in the document review. This enum
 * has both CONDO and CONDO_HIGH_RISE and CONDO_LOW_RISE. CONDO is used only for loan
 * tape parsing and the others are only used for the document review dropdown. The
 * custom validation is implemented in the front end and back end
 */
export enum RTLNYMTPropertyType {
  SFR = "SFR",
  CONDO = "Condo",
  CONDO_HIGH_RISE = "Condo - High Rise",
  CONDO_LOW_RISE = "Condo - Low Rise",
  TOWNHOME = "Townhome",
  TWO_FOUR_UNIT = "2-4 Unit",
  MULTIFAMILY = "Multifamily (5+ Units)",
  MIXED_USE = "Mixed Use",
  COOP = "Coop",
  COMMERCIAL = "Commercial",
  LAND = "Land",
  FINISHED_LOTS = "Finished Lots",
  DEVELOPMENT = "Development",
  OTHER = "Other",
  NOT_PROVIDED = "Not Provided",
}
export enum ConditionRating {
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  C6 = "C6",
  POOR = "Poor",
  FAIR = "Fair",
  AVERAGE = "Average",
  GOOD = "Good",
  NOT_PROVIDED = "Not Provided",
}
export enum YesNoNA {
  YES = "Y",
  NO = "N",
  NA = "N/A",
}
export enum PurchaseAgreementType {
  FINAL_HUD = "Final HUD1/Settlement Statement",
  PRELIM_HID = "Prelim HUD1/Settlement Statement",
  PURCHASE_CONTRACT = "Purchase Contract",
  BID_RECEIPT = "Bid Receipt",
}
export enum SFRTitleType {
  INSURANCE_COMMITMENT = "Title Insurance Commitment",
  OWNERS_POLICY = "Owners Policy",
  LENDERS_POLICY = "Lenders Policy",
  TITLE_REPORT = "Title Report",
  PROFORMA = "Proforma",
}
export enum SFRTitleIssues {
  DEED_OF_TRUST = "Deed of Trust",
  HOMESTEAD_DECLARATION = "Homestead Declaration",
  JUDGEMENT = "Judgment",
  HOA_LIEN = "HOA Lien",
  TAX_LIEN = "Tax Lien",
  SPOUSAL_INTEREST = "Spousal Interest",
  IRS_LIEN = "IRS Lien",
  OTHER = "Other",
}
export enum SFRTitleHOAOptions {
  CCR_ON_TITLE = "CCR's showing on title",
  TYPE_HOA_NAME = "Type HOA Name",
}
export enum ScenarioMetricType {
  AMOUNT_BORROWED = "amount-borrowed",
  INTEREST_COSTS = "interest-costs",
  TOTAL_COST = "total-cost",
  TOTAL_VALUATION = "total-valuation",
  LOAN_TO_COST = "loan-to-cost",
  LOAN_TO_VALUE = "loan-to-value",
  BORROWED_AMOUNT_BY_PROPERTY_TYPE = "borrowed-amount-by-property-type",
  DAYS_ON_BASE = "days-on-base",
  ANNUAL_NET_INCOME = "annual-net-income",
  ANNUAL_INTEREST = "annual-interest",
  DEBT_SERVICE_COVERAGE_RATIO = "debt-service-coverage-ratio",
  DEBT_YIELD = "debt-yield",
  FACILITY_SIZE = "facility-size",
  CASH_REQUIRED = "cash-required",
  POOL_BALANCE = "pool-balance",
  UNIT_COUNT = "unit-count",
  DELINQUENT_AMOUNT = "delinquent-amount",
  DELINQUENCY_RATIO = "delinquency-ratio",
  NET_LOSS_AMOUNT = "net-loss-amount",
  NET_CHARGED_OFF_LOSSES = "net-charged-off-losses",
  NET_LOSS_RATIO = "net-loss-ratio",
  EXCESS_SPREAD_RATIO = "excess-spread-ratio",
  EXTENDED_RECEIVABLES = "extended-receivables",
  EXTENSION_RATIO = "extension-ratio",
  BPO_ADJUSTED_BORROWING_BASE_VALUE = "bpo-adjusted-borrowing-base-value",
  BPO_ADJUSTED_LOAN_TO_COST = "bpo-adjusted-loan-to-cost",
  BPO_ADJUSTED_LOAN_TO_VALUE = "bpo-adjusted-loan-to-value",
  ALLOCATED_LOAN_AMOUNT = "allocated-loan-amount",
  THIRTY_DAY_BPO_ADJUSTED_VALUE = "thirty-day-bpo-adjusted-value",
  THIRTY_DAY_BPO_ADJUSTED_DELTA = "thirty-day-bpo-adjusted-delta",
  BPO_ADJUSTED_BORROWING_BASE_VALUE_BY_TYPE = "bpo-adjusted_borrowing_base_value_by_type",
  PRE_STABILIZED_BPO_ADJUSTED_VALUE = "pre-stabilized-bpo-adjusted-value",
  POST_STABILIZED_BPO_ADJUSTED_VALUE = "post-stabilized-bpo-adjusted-value",
  BUNGALO_BPO_ADJUSTED_VALUE = "bungalo-stabilized-bpo-adjusted-value",
  STUDIO_BUILD_BPO_ADJUSTED_VALUE = "studio-build-stabilized-bpo-adjusted-value",
}
export enum FacilityMetricCardType {
  SIMPLE = "simple",
  MULTI = "multi",
  RANKED_BAR = "ranked-bar",
  PERCENT_OF_TOTAL_BAR = "percent-of-total-bar",
  AREA_CHART = "area-chart",
  SCENARIO_METRIC_DIFF = "scenario-metric-diff",
  LINE_CHART = "line-chart",
}
export enum ActivityFeedExportTimeframe {
  PAST_DAY = "past-day",
  PAST_WEEK = "past-week",
  PAST_MONTH = "past-month",
}
export enum ValuationOrderStatus {
  CREATED = "created",
  PENDING = "pending",
  COMPLETE = "complete",
  FAILED = "failed",
  CANCELLED = "cancelled",
}
export enum ParserType {
  LOAN_PARSER = "loan-parser",
  PROPERTY_PARSER = "property-parser",
  ANCILLARY_PARSER = "ancillary-parser",
}
export enum ScenarioOperationType {
  REMOVE_ASSET = "remove-asset",
  ADD_ASSET = "add-asset",
  REASSIGN_ASSET = "reassign-asset",
}
export enum OptimizationAssetPopulation {
  ALL_ASSETS = "all-assets",
  COE_LT_90_DAYS = "coe-lt-90-days",
}
export enum OptimizationMethod {
  MAXIMIZE_LEVERAGE = "maximize-leverage",
  MINIMIZE_COST_OF_CAPITAL = "minimize-cost-of-capital",
}
export enum ScenarioStatus {
  PROCESSING = "processing",
  POPULATED = "populated",
}
export enum NotificationType {
  ASSET = "asset",
  DOCUMENT_FAIL = "document-fail",
  BUYER = "buyer",
  NEW_ASSETS = "new-assets",
  TRANSFER_CUSTOMER_APPROVAL = "transfer-customer-approval",
  CONCENTRATION_LIMIT_STATUS_CHANGE = "concentration-limit-status-change",
  FACILITY_COMPLIANCE = "facility-compliance",
  DAILY_OPTIMIZATION_REPORT = "daily-optimization-report",
  DILIGENCE_REPORT_PUBLISHED = "diligence-report-published",
  ASSET_PAYOFF_PROCESSED = "asset-payoff-processed",
  CAPITAL_OS_REPORT = "capital-os-report",
}
export enum CustomerRole {
  ACCOUNTING = "accounting",
  ADMIN = "admin",
  BILLING = "billing",
  CLOSING_COORDINATOR = "closing-coordinator",
  FINANCE = "finance",
}
export enum SetpointRole {
  CAPITAL_SOLUTIONS = "capital-solutions",
  DOCUMENT_VERIFICATION = "document-verification",
  DOCUMENT_VERIFICATION_LEAD = "document-verification-lead",
  ENGINEER = "engineer",
  RTL_DOCUMENT_VERIFICATION = "rtl-document-verification",
  RTL_DOCUMENT_VERIFICATION_LEAD = "rtl-document-verification-lead",
  SALES = "sales",
  SETPOINT_CAPITAL = "setpoint-capital",
  SFR_DOCUMENT_VERIFICATION = "sfr-document-verification",
  SUPER_ADMIN = "super-admin",
}
export enum NotificationMethod {
  EMAIL = "email",
}
export enum FundingRequestsStatus {
  PENDING = "pending-verification",
  SCHEDULED = "scheduled",
  FAILED = "failed",
  EXECUTED = "executed",
}
export enum NetTransferCategory {
  ADVANCE_CHANGE = "advance-change",
  INTEREST = "interest",
  PAYOFF = "payoff",
  PAYOFF_EXCESS = "payoff-excess",
  TRANSACTION_FEE = "transaction-fee",
  UNUSED_FEE = "unused-fee",
  YIELD_MAINTENANCE_FEE = "yield-maintenance-fee",
  BANK_ACTIVITY_REMITTANCE = "bank-activity-remittance",
}
export enum PaymentType {
  MANUAL = "manual",
  ACH = "ach",
  WIRE = "wire",
}
export enum PaymentStatus {
  NEEDS_APPROVAL = "needs-approval",
  APPROVED = "approved",
  PROCESSING = "processing",
  SENT = "sent",
  COMPLETED = "completed",
  RETURNED = "returned",
  REVERSED = "reversed",
  FAILED = "failed",
  DENIED = "denied",
  CANCELLED = "cancelled",
}
export enum TransferDirection {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}
export enum NetTransferStatus {
  STAGED = "staged",
  REQUESTED = "requested",
  SETTLED = "settled",
  IGNORED = "ignored",
}
export enum PayoffStatus {
  PENDING = "pending",
  SETTLED = "settled",
}
export enum ParticipationPaymentStatus {
  SETTLED = "settled",
  PENDING = "pending",
}
