import { useRouter } from "next/router"
import toast from "react-hot-toast"
import { useMutation, useQuery } from "react-query"
import type {
  EmailLoginForm,
  PostEmailReportsSendBody,
  ProfileResponse,
  RedirectResponse,
} from "types/server/auth"
import { get, post } from "utility/fetcher"

export function useAuth0LoginMutation({ callBackUrl = null }) {
  const router = useRouter()
  const login = useMutation({
    mutationFn: () => {
      return post<RedirectResponse>("/auth/login/auth0")
    },
    onSuccess: (data) => {
      if (data.location) {
        window.location.assign(data.location)
      }
    },
    onError: () => {
      router.push({
        pathname: "/login",
        query: {
          next: callBackUrl ? callBackUrl : window.location.href,
        },
      })
    },
  })
  return login
}

export function useInitCsrf() {
  const query = useQuery("csrf-init", async () => await get("/auth/login/csrf"))
  return query.isSuccess
}

export function useEmailLoginMutation() {
  const router = useRouter()
  const login = useMutation({
    mutationFn: (email: string) => {
      return post<RedirectResponse, EmailLoginForm>("/auth/login/email", {
        email: email,
      })
    },
    onSuccess: (data) => {
      if (data.location) {
        window.location.assign(data.location)
      }
    },
    onError: () => {
      router.push({
        pathname: "/login",
      })
    },
  })
  return login
}

export function useProfileQuery() {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["current-user-profile"],
    () => get<ProfileResponse>("/auth/user"),
  )
  return { data, isLoading, isError, isSuccess }
}

export function useEmailReports() {
  const { data, isLoading, isError } = useQuery(["auth-email-reports"], () =>
    get("/auth/email-reports"),
  )
  return { data, isLoading, isError }
}

export function useSendEmailReport() {
  return useMutation({
    mutationFn: (body: PostEmailReportsSendBody) => {
      return post("/auth/email-reports/send", body)
    },
    onSuccess: () => {
      toast.success("Report sent")
    },
  })
}
