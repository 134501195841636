import { datadogRum } from "@datadog/browser-rum"

import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  gitHash,
  isDataDogEnabled,
  setpointEnv,
} from "config"
import Script from "next/script"

export const DatadogScript = () => {
  if (!isDataDogEnabled) {
    return null
  }

  const existingInitConfiguration = datadogRum.getInitConfiguration()

  if (typeof existingInitConfiguration !== "undefined") {
    return null
  }

  datadogRum.init({
    clientToken: DATADOG_CLIENT_TOKEN as string,
    applicationId: DATADOG_APPLICATION_ID as string,
    site: "datadoghq.com",
    service: "setpoint",
    env: setpointEnv,
    version: gitHash,
    sessionSampleRate: 30,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  })

  return <Script id="datadog-script" />
}
