import { isDemo } from "config"
import * as process from "process"
import { debounce, isClient } from "utility/utils"

const APP_ORIGIN = isClient()
  ? window.location.origin
  : process.env.NEXT_PUBLIC_APP_ORIGIN

const PROXY_PATH = "/proxy-api"

const ABSOLUTE_PROXY_URL = new URL(PROXY_PATH, APP_ORIGIN)
const ERROR_TOAST_MSG =
  "An error occurred while processing this action. Please try again after refreshing your page."

const absoluteUrlRegex = new RegExp("^(?:[a-z+]+:)?//", "i")

const safeToastError = debounce(async () => {
  if (isClient() && !isDemo) {
    const toast = (await import("react-hot-toast")).default
    toast.error(ERROR_TOAST_MSG)
  }
}, 1000)

export const getAbsoluteEndpoint = (endpoint: string) => {
  if (absoluteUrlRegex.test(endpoint)) {
    return endpoint
  }
  const absolute = new URL(`${PROXY_PATH}${endpoint}`, ABSOLUTE_PROXY_URL)
  return absolute.toString()
}

export const getAbsoluteEndpointWithParams = (endpoint: string, params = {}) => {
  const absolute = new URL(`${PROXY_PATH}${endpoint}`, ABSOLUTE_PROXY_URL)
  const searchParams = new URLSearchParams(params)
  absolute.search = searchParams.toString()
  return absolute.toString()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const get = async <RespT = any>(
  endpoint: string,
  params: Record<string, unknown | undefined> = {},
  additionalHeaders = {},
): Promise<RespT> => {
  const absoluteEndpoint = getAbsoluteEndpoint(endpoint)

  const absoluteEndpointUrl = new URL(absoluteEndpoint)
  for (const [key, value] of Object.entries(params))
    if (typeof value !== "undefined" && value !== null)
      absoluteEndpointUrl.searchParams.append(key, String(value))

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  if (additionalHeaders) Object.assign(headers, additionalHeaders)

  const response = await fetch(absoluteEndpointUrl, {
    method: "GET",
    headers,
  })

  if (!response.ok) {
    await safeToastError()
    throw Error(response.statusText)
  }

  return response.json()
}

const getCookie = (name: string) => {
  let cookieValue = null
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";")
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export const getCSRFToken = () => {
  return getCookie("_setpoint_csrf")
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const post = async <RespT = any, BodyT = any>(
  endpoint: string,
  data: BodyT | undefined = undefined,
  showError = true,
  additionalHeaders = {},
): Promise<RespT> => {
  const absoluteEndpoint = getAbsoluteEndpoint(endpoint)
  const headers: {
    Accept: string
    "Content-Type": string
    "X-CSRFToken"?: string
  } = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  const csrfToken = getCSRFToken()
  if (csrfToken !== null) {
    headers["X-CSRFToken"] = csrfToken
  }

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders)
  }

  const postData = typeof data === "undefined" ? {} : data

  const response: Response = await fetch(absoluteEndpoint, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(postData),
  })
  if (!response.ok) {
    if (showError) await safeToastError()
    throw Error(response.statusText)
  }
  const respData: RespT = await response.json()
  return respData
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patch = async <RespT = any, BodyT = any>(
  endpoint: string,
  data: BodyT | undefined = undefined,
  showError = true,
  additionalHeaders = {},
): Promise<RespT> => {
  const absoluteEndpoint = getAbsoluteEndpoint(endpoint)
  const headers: {
    Accept: string
    "Content-Type": string
    "X-CSRFToken"?: string
  } = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  const csrfToken = getCSRFToken()
  if (csrfToken !== null) {
    headers["X-CSRFToken"] = csrfToken
  }

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders)
  }

  const postData = typeof data === "undefined" ? {} : data

  const response: Response = await fetch(absoluteEndpoint, {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(postData),
  })
  if (!response.ok) {
    if (showError) await safeToastError()
    throw Error(response.statusText)
  }
  const respData: RespT = await response.json()
  return respData
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const delete_ = async <RespT = any>(
  endpoint: string,
  showError = true,
  additionalHeaders = {},
): Promise<RespT> => {
  const absoluteEndpoint = getAbsoluteEndpoint(endpoint)
  const headers: {
    Accept: string
    "Content-Type": string
    "X-CSRFToken"?: string
  } = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  const csrfToken = getCSRFToken()
  if (csrfToken !== null) {
    headers["X-CSRFToken"] = csrfToken
  }

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders)
  }

  const response: Response = await fetch(absoluteEndpoint, {
    method: "DELETE",
    headers: headers,
  })

  if (!response.ok) {
    if (showError) await safeToastError()
    throw Error(response.statusText)
  }
  const respData: RespT = await response.json()
  return respData
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const upload = async <RespT = any>(
  endpoint: string,
  files: File[],
  data: object,
): Promise<RespT> => {
  const absoluteEndpoint = getAbsoluteEndpoint(endpoint)

  const formData = new FormData()
  for (const file of files) formData.append("file", file)
  for (const key in data) {
    formData.append(key, data[key as keyof typeof data])
  }

  const headers: { "X-CSRFToken"?: string } = {}
  const csrfToken = getCSRFToken()
  if (csrfToken !== null) {
    headers["X-CSRFToken"] = csrfToken
  }

  const response: Response = await fetch(absoluteEndpoint, {
    method: "POST",
    body: formData,
    headers: headers,
  })
  if (!response.ok) {
    await safeToastError()
    throw Error(response.statusText)
  }
  return await response.json()
}

export const boxUpload = async (
  accessToken: string,
  files: File[],
  data: { name: string; parent: { id: string } },
) => {
  const absoluteEndpoint = "https://upload.box.com/api/2.0/files/content"
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  }

  const formData = new FormData()
  formData.append("attributes", JSON.stringify(data))
  for (const file of files) formData.append("file", file)

  const response: Response = await fetch(absoluteEndpoint, {
    method: "POST",
    body: formData,
    headers: headers,
  })

  if (!response.ok) {
    await safeToastError()
    throw Error(response.statusText)
  }

  return await response.json()
}
