import { type GetServerSidePropsContext } from "next"
import { Permission } from "types/server/auth"
import { checkAuth, getSession, redirect } from "utility/auth"

export const CALC_AGENT_PAGE_PATHNAME =
  "/calculation-agent/report/[calcAgentReportGlobalId]/[tab]"

export const CALC_AGENT_TABS = [
  {
    label: "Reports",
    value: "reports",
    href: "/calculation-agent/summary",
  },
  {
    label: "Waivers",
    value: "waivers",
    href: "/calculation-agent/waivers",
  },
  {
    label: "Settings",
    value: "settings",
    href: "/calculation-agent/settings",
  },
  {
    label: "Audit Log",
    value: "audit",
    href: "/calculation-agent/audit",
  },
]
export const CALC_AGENT_SUMMARY_PATHNAME = CALC_AGENT_TABS[0].href

/** Requires the `Permission.CALCULATION_AGENT_PAGE` to be set. */
export async function redirectUnauthorizedUsers(context: GetServerSidePropsContext) {
  const session = await getSession(context)
  if (!session) return redirect("/")
  return checkAuth(context, Permission.CALCULATION_AGENT_PAGE)
}
