import { MONTH_OPTIONS, YEAR_OPTIONS } from "const"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"
import millify from "millify"
import { isNil } from "ramda"
import { useEffect, useState } from "react"
import resolveConfig from "tailwindcss/resolveConfig"
import {
  BrowserFormatter,
  DiligenceCategory,
  DocumentReviewField,
  FundingRequirementModel,
  Option,
  RTLDocumentAssetQueueItem,
  SFRDocumentAssetQueueItem,
} from "types/server/main"
import type { SnakeToCamelCase } from "utility/types"
import { replaceAll, titleCase } from "voca"
// eslint-disable-next-line absolute-only/imports
import tailwindConfig from "../../tailwind.config"

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U

export const isText = (data: unknown): data is string => {
  return typeof data === "string"
}

TimeAgo.addDefaultLocale(en)

const timeAgoFormatter = new TimeAgo("en-US")

export const EPOCH = new Date(-1)

const removeTime = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate())

const utcDatetime = (date: Date) =>
  new Date(date.getTime() + date.getTimezoneOffset() * 60_000)

export function today() {
  const date = new Date()
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60_000)
  utcDate.setHours(0, 0, 0, 0)
  return removeTime(utcDate)
}

export function startOfMonth() {
  const date = today()
  date.setDate(1)
  return date
}

export function startOfLastMonth() {
  const date = startOfMonth()
  date.setMonth(date.getMonth() - 1)
  return date
}

export function timeAgo(dateStr: string): string {
  if (!dateStr) return ""
  const date = new Date(dateStr)
  return timeAgoFormatter.format(date)
}

export function getUTC<T extends string | undefined>(
  dateStr?: T,
): T extends string ? Date : undefined {
  if (dateStr)
    return utcDatetime(new Date(dateStr)) as T extends string ? Date : undefined
  return undefined as T extends string ? Date : undefined
}

export function dateStringFromDatetimeString(datetimeStr?: string): string {
  if (!datetimeStr) return ""

  return datetimeStr.split("T")[0]
}

export function convertDateToUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

export function monthDiff(startDate: Date, endDate: Date) {
  let months
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12
  months -= startDate.getMonth()
  months += endDate.getMonth()

  if (endDate.getDate() < startDate.getDate()) {
    months--
  }

  return months
}

export function dateDiff(startDate: Date, endDate: Date) {
  const diff = endDate.getTime() - startDate.getTime()
  return Math.round(diff / (1000 * 60 * 60 * 24))
}

export function shortDate(dateStr: string | undefined): string | undefined {
  if (dateStr) return getUTC(dateStr)?.toLocaleDateString()
}

export function monthYearDate(dateStr: string | undefined): string | undefined {
  if (dateStr)
    return getUTC(dateStr)?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
    })
}

export const shortDateForDateInput = (dateStr: string) => {
  const isoDatetime = getUTC(dateStr)?.toISOString() ?? ""
  const [date] = isoDatetime.split("T")
  return date
}

export function mediumDate(dateStr: string): string | undefined {
  return getUTC(dateStr)?.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

export function dateString<T extends Date | undefined>(
  date: T,
): T extends Date ? string : undefined {
  if (date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}` as T extends Date ? string : undefined
  }
  return undefined as T extends Date ? string : undefined
}

export function dateStringPadded(date: Date | undefined): string | undefined {
  if (date)
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
}

export function dateStringPaddedMDY(
  date: Date | string | undefined,
): string | undefined {
  if (date) {
    const formatted = new Date(date)
    return `${pad(formatted.getUTCMonth() + 1)}/${pad(
      formatted.getUTCDate(),
    )}/${formatted.getUTCFullYear()}`
  }
}

export function pad(num: number): string {
  return (num < 10 ? "0" : "") + num
}

export function shortDatetime(dateStr?: string): string | undefined {
  if (!dateStr) return
  const date = new Date(dateStr)
  const options: Record<string, "2-digit"> = {
    hour: "2-digit",
    minute: "2-digit",
  }
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString("en-US", options)}`
}

export function longDatetime(date: Date): string {
  return date.toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

export function getCurrentMonthAndYearOptions(): {
  currentMonthOption: Option
  currentYearOption: Option
} {
  let currentMonth = new Date().getMonth() - 1
  if (currentMonth < 0) currentMonth = 11
  const currentMonthOption = MONTH_OPTIONS[currentMonth]
  const currentYearOption = YEAR_OPTIONS[currentMonth === 11 ? 0 : 1]
  return { currentMonthOption, currentYearOption }
}

export function positiveFullMoney(
  dollarAmt?: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string | undefined {
  if (typeof dollarAmt === "undefined" || dollarAmt == null) return
  return fullMoney(Math.abs(dollarAmt), minimumFractionDigits, maximumFractionDigits)
}

export function fullMoney(
  dollarAmt?: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string | undefined {
  if (typeof dollarAmt === "undefined" || dollarAmt == null) return
  return dollarAmt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits,
  })
}

export type AccountingMoneyParams = {
  dollarAmt?: number | null
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  useDashForZero?: boolean
  currency?: string
}

export function prettyNumeric(number: number, locale = "en-US") {
  const formatter = new Intl.NumberFormat(locale)
  return formatter.format(number)
}

export function getCurrencySymbol(currencyAbbreviation: string): string {
  const parts = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyAbbreviation,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).formatToParts(1)

  const currencyPart = parts.find((part) => part.type === "currency")

  return currencyPart ? currencyPart.value : "$"
}

export function accountingMoney(
  dollarAmt?: number | null | undefined,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  useDashForZero = false,
  currency = "USD",
): string | undefined {
  if (typeof dollarAmt === "undefined" || dollarAmt == null) return

  if (dollarAmt == 0 && useDashForZero) return "-"

  const formattedMoney = Math.abs(dollarAmt).toLocaleString("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  })

  if (dollarAmt < 0) return `(${formattedMoney})`

  return formattedMoney
}

export function accountingMoneyMultiCurrency({
  dollarAmt,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  useDashForZero = false,
  currency = "USD",
}: AccountingMoneyParams): string | undefined {
  // NOTE: For now this duplicates accountingMoney, but it's intended to be used for multi-currency
  // support without having to pass in all the parameters every time.
  return accountingMoney(
    dollarAmt,
    minimumFractionDigits,
    maximumFractionDigits,
    useDashForZero,
    currency,
  )
}

export function shortMoney(
  dollarAmt?: number,
  spaceAfterDollarSymbol = false,
): string | undefined {
  if (typeof dollarAmt === "undefined" || dollarAmt == null) return
  const formattedMoney = dollarAmt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formattedMoney.replace("$", spaceAfterDollarSymbol ? "$ " : "$")
}

export function moneyAbbreviation(
  dollarAmount: number | undefined | null,
  precision = 2,
): string | undefined {
  if (typeof dollarAmount === "undefined" || dollarAmount == null) return
  return `$${millify(dollarAmount, { precision })}`
}

export const equalToTheCent = (left: number, right: number): boolean =>
  Number(left.toFixed(2)) === Number(right.toFixed(2))

export function percentage(
  fraction: number | undefined,
  precision: number,
  includeSign = false,
): string {
  if (typeof fraction === "undefined" || fraction == null) return ""
  const sign = includeSign && fraction > 0 ? "+" : ""
  return `${sign}${(fraction * 100).toFixed(precision)}%`
}

// Whether the value can safely be converted to a number or money
export function isNumeric(value: unknown): value is number {
  switch (typeof value) {
    case "number":
      return true
    case "string":
      // Ensures that string is a number and not empty
      return !isNaN(Number(value)) && !isNaN(parseFloat(value))
    default:
      return false
  }
}

export function parsePathnameRoot(pathname: string): string {
  if (!pathname.includes("/")) return pathname
  if (pathname.startsWith("/")) return pathname.split("/")[1]
  return pathname.split("/")[0]
}

export function parseFirstname(name: string): string | undefined {
  if (name) return name.split(" ")[0]
}

export function parseLastname(name: string): string | undefined {
  if (!name) return
  const split = name.split(" ")
  if (split.length < 2) return
  return name.replace(`${parseFirstname(name)} `, "")
}

export function limitTextLength(text: string, len: number): string {
  if (text.length <= len) return text
  const mid = Math.ceil(text.length / 2)
  const overflow = text.length - len
  const low = mid - overflow / 2
  const high = mid + overflow / 2
  return `${text.slice(0, low)}...${text.slice(high)}`
}

export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const toTitleCase = (text: string): string =>
  text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

export function valueToOption(value: string, options: Option[]) {
  return options.find((option) => option.value === value)
}

export function debounce<T extends (...args: unknown[]) => void>(
  func: T,
  timeout = 300,
): (...args: Parameters<T>) => void {
  let timer: NodeJS.Timeout | undefined
  return (...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export function debounceLeading<T extends (...args: unknown[]) => void>(
  func: T,
  timeout = 300,
): (...args: Parameters<T>) => void {
  let timer: NodeJS.Timeout | undefined
  return (...args: Parameters<T>) => {
    if (!timer) {
      func(...args)
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = undefined
    }, timeout)
  }
}

export function popupWindow(
  url: string,
  windowName: string,
  win: Window,
  w: number,
  h: number,
) {
  if (!win || !win?.top) return
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2
  return win.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
  )
}

export function isServer(): boolean {
  return typeof window === "undefined"
}

export function isClient(): boolean {
  return !isServer()
}

export function kebabToTitleCase(value: string) {
  if (typeof value !== "string") {
    return ""
  }

  return titleCase(replaceAll(replaceAll(value, "-", " "), "_", " "))
}

export function titleToKebabCase(value: string) {
  if (typeof value !== "string") {
    return ""
  }

  return value.toLowerCase().replaceAll(" ", "-")
}

export const snakeToCamelCase = <S extends string>(value: S): SnakeToCamelCase<S> =>
  value
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()) as SnakeToCamelCase<S>

export const snakeToPascalCase = (value: string): string => {
  const valueAsCamelCase = snakeToCamelCase(value)
  return valueAsCamelCase[0].toUpperCase() + valueAsCamelCase.substring(1)
}

export const uniqueFilter = (
  value: unknown,
  index: number,
  self: unknown[],
): boolean => {
  return self.indexOf(value) === index
}

export const cleanedObject = (obj: Record<string, unknown>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_key, value]) => value !== undefined),
  )
}

export const resolveTailwindConfig = () => {
  return resolveConfig(tailwindConfig)
}

export const nextMonth = (date: Date): Date => {
  const currentMonth = date.getMonth() + 1
  const yearOffset = currentMonth >= 12 ? 1 : 0
  date.setMonth(currentMonth % 12)
  date.setFullYear(date.getFullYear() + yearOffset)
  return date
}

export const isFalseyDict = (dict: Record<string, unknown> | null): boolean =>
  !dict || Object.keys(dict).length === 0

export const isNullUndefinedOrNaN = (value?: string | null): boolean => {
  if (typeof value === "string" && value === "$NaN") return true
  return (
    value === null || value === "null" || value === undefined || value === "undefined"
  )
}

export const isNullOrEmpty = (value: string | null | unknown[]) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "string" ? value.trim().length === 0 : value.length === 0)
  )
}

export const randomHash = (length = 6) => {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function isRTLDocumentAssetQueueItem(
  queueItem: RTLDocumentAssetQueueItem | SFRDocumentAssetQueueItem | undefined,
): queueItem is RTLDocumentAssetQueueItem {
  return !isNil(queueItem) && "grades" in queueItem
}

export function isSFRDocumentAssetQueueItem(
  queueItem?: RTLDocumentAssetQueueItem | SFRDocumentAssetQueueItem,
): queueItem is SFRDocumentAssetQueueItem {
  return queueItem?.category == DiligenceCategory.SFR
}

export const hasDefaults = (
  tasks: FundingRequirementModel[],
  form?: {
    [k: string]: DocumentReviewField
  },
): boolean => {
  // check funding reqs
  if (
    tasks.some(
      (task) =>
        (task.checkOptionConfig ?? [])?.some((check) => check?.default === true),
    )
  ) {
    return true
  }
  // check document reviews
  const formData = form ?? {}
  return Object.values(formData).some((reviewField) => reviewField?.defaultValue)
}

export const tabKeys = ["Enter", " "]

/**
 * Polyfill for the `useId` hook in React v18
 * @see https://react.dev/reference/react/useId
 */
export function useId() {
  const [id, setId] = useState("")
  useEffect(() => setId(`id-${Math.trunc(Math.random() * 1e6)}`), [])
  return id
}

export const isNumericBrowserFormatter = (formatter: BrowserFormatter) =>
  [
    BrowserFormatter.ABBREVIATED_MONEY,
    BrowserFormatter.ACCOUNTING_MONEY,
    BrowserFormatter.CREDIT_SCORE,
    BrowserFormatter.FULL_MONEY,
    BrowserFormatter.NUMERIC,
    BrowserFormatter.NUMERIC_PRECISION_ZERO,
    BrowserFormatter.NUMERIC_PRECISION_ONE,
    BrowserFormatter.NUMERIC_PRECISION_TWO,
    BrowserFormatter.NUMERIC_PRECISION_TWO_X,
    BrowserFormatter.PERCENTAGE,
    BrowserFormatter.PERCENTAGE_PRECISION_ONE,
    BrowserFormatter.PERCENTAGE_PRECISION_TWO,
    BrowserFormatter.PERCENTAGE_PRECISION_THREE,
    BrowserFormatter.PERCENTAGE_PRECISION_FOUR,
    BrowserFormatter.SHORT_MONEY,
  ].includes(formatter)

export const isBooleanBrowserFormatter = (formatter: BrowserFormatter) =>
  [
    BrowserFormatter.BOOL,
    BrowserFormatter.BOOL_ONE_ZERO,
    BrowserFormatter.BOOL_PASS_FAIL,
    BrowserFormatter.BOOL_YES_NO_FULL,
    BrowserFormatter.BOOL_YES_NO_SHORT,
  ].includes(formatter)

export const getSubdomain = () => (isClient() ? window.location.host.split(".")[0] : "")

export const buildUniqueGlobalIdSet = <T extends { globalId: string }>(data: T[]) => {
  const uniqueCollection: T[] = []
  const globalIdSet = new Set()

  data?.forEach((requirement) => {
    if (!globalIdSet.has(requirement.globalId)) {
      globalIdSet.add(requirement.globalId)
      uniqueCollection.push(requirement)
    }
  })

  return uniqueCollection
}

export const pluralize = (
  count: number = 0,
  singular: string,
  plural: string,
  hideCount = false,
) => {
  const result = count === 1 ? singular : plural
  return hideCount ? result : `${count} ${result}`
}

export const isMoneyFormatter = (formatter: BrowserFormatter) =>
  [
    BrowserFormatter.ABBREVIATED_MONEY,
    BrowserFormatter.ACCOUNTING_MONEY,
    BrowserFormatter.ACCOUNTING_MONEY_EUR,
    BrowserFormatter.ACCOUNTING_MONEY_GBP,
    BrowserFormatter.FULL_MONEY,
    BrowserFormatter.SHORT_MONEY,
  ].includes(formatter)

const userAgentHasKeyword = (keyword: string): boolean => {
  if (isNil(navigator)) {
    return false
  }

  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1
}

export const isFirefoxBrowser = (): boolean => {
  if (isNil(navigator)) {
    return false
  }

  return (
    userAgentHasKeyword("Firefox") ||
    userAgentHasKeyword("FxiOS") ||
    userAgentHasKeyword("Focus")
  )
}

export const getOldestDate = (...dates: (string | undefined)[]): string | undefined => {
  const validDates = dates
    .map((dateString) => (dateString ? new Date(dateString) : undefined))
    .filter((date): date is Date => date !== undefined && !isNaN(date.getTime()))
    .sort((date1, date2) => date1.getTime() - date2.getTime())

  return validDates.length > 0 ? validDates[0].toISOString() : undefined
}
