/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running setpoint_util.gen_typescript.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export type AccountAVMProvider = "setpoint"

export interface AccountOption {
  globalId: string
  name: string
}
export interface EmailLoginForm {
  email: string
}
export interface EmailVerifyForm {
  email: string
  code: string
  redirect?: string
}
export interface LoginQuery {
  next?: string
}
export interface LoginUser {
  accountName?: string
  email: string
  isSetpointEmail: boolean
  name?: string
  image?: string
}
export interface ProfileResponse {
  accountSettings?: AccountSettings
  accountType?: AccountType
  isSetpoint?: boolean
  isDemo?: boolean
  permissions: Permission[]
  user?: LoginUser
  accountMemberGlobalId?: string
  account?: AccountOption
  accounts?: AccountOption[]
}
export interface AccountSettings {
  avmProvider?: AccountAVMProvider
  fundingTeamEmails?: string[]
  fundingAccountNumber?: string
  fundingRoutingNumber?: string
  fundingProducts?: ProductType[]
  fundingWireInstructionsS3Key?: string
  propertySource?: AccountPropertySource
  addAssetModalSchema?: AddAssetModalSchema
  addAssetModalToBorrowingBase?: boolean
  fundingDestination?: FundingDestination & string
  conrexAddPropertyModal?: boolean
  generateDefaultScenario?: boolean
  createBoxFolderOnAssetIngestion?: boolean
  forwardLoanTapeAddresses?: string[]
  isDemo?: boolean
  whitelabelSubdomain?: string
  disableIntercom?: boolean
  permissionConfigType?: AccountPermissionConfigType
  borrowerReportsProductName?: string
  enableIpWhitelist?: boolean
  ipWhitelist?: string[]
  dataRetentionPolicy?: AccountDataRetentionPolicy
}
export interface AccountDataRetentionPolicy {
  deleteCapOsApprovedReportAssetResultsAfterDays?: number
  deleteCapOsNotApprovedReportAssetResultsAfterDays?: number
  deleteCapOsApprovedReportReprocessJsonAfterDays?: number
  deleteCapOsNotApprovedReportReprocessJsonAfterDays?: number
}
export interface RedirectResponse {
  location: string
}
export interface CommandBarCommand {
  label: string
  value: CommandbarDevCommand
}
export interface EmailReport {
  label: string
  value: CommandbarEmailReport
  parameterOptions: EmailReportParameterOptions[]
}
export interface GetAccountsResponse {
  accounts: AccountOption[]
}
export interface GetCommandBarDevCommandsResponse {
  commands: CommandBarCommand[]
}
export interface GetEmailReportsResponse {
  emailReports: EmailReport[]
}
export interface GlobalIdBody {
  global_id: string
}
export interface PostCommandBarDevCommandBody {
  assetGlobalId?: string
  command: CommandbarDevCommand
}
export interface PostCommandBarDevCommandResp {
  url?: string
}
export interface PostEmailReportsSendBody {
  emailReport: CommandbarEmailReport
  params: EmailReportsSendParameters
}
export interface EmailReportsSendParameters {
  facilityGlobalId?: string
  startDate?: string
  endDate?: string
}

export enum ProductType {
  BBYS = "bbys",
  CASH_OFFER = "cash-offer",
  IBUYER = "ibuyer",
  FIX_AND_FLIP = "fix-flip",
  INSURANCE = "insurance",
  SFR = "sfr",
  RENT_TO_OWN = "rto",
  SBYL = "sbyl",
  EQUITY_ADVANCE = "equity-advance",
  TRADE_UP = "trade-up",
  BRIDGE_LOAN = "bridge-loan",
  COMMERCIAL = "commercial",
  ORCHARD_AGED_GOLDMAN_SACHS = "orchard-gs",
  ORCHARD_OFFERBOOST = "orchard-ob",
  HW_BBYS_AGED = "hw-bbys-aged",
  HW_CASH_OFFER_AGED = "hw-cash-offer-aged",
  HW_FLOOR_PRICE_ACTIVATION = "hw-floor-price-activation",
  HW_BACKED_BY_CASH = "hw-backed-by-cash",
  IVF = "ivf",
  EGG_FREEZING = "egg-freezing",
  AUTO = "auto",
  AIRPLANE_LEASE = "airplane-lease",
  METRO_TRAILING = "metro-trailing",
  RTL = "rtl",
  FUND_TO_BUILD = "fund-to-build",
  RECEIVABLE = "receivable",
}
export enum AccountPropertySource {
  MODAL = "modal",
}
export enum AddAssetModalSchema {
  CONREX = "conrex",
  DAVIDSON_KEMPNER = "davidson-kempner",
  PSF = "psf",
  STANDARD = "standard",
}
export enum FundingDestination {
  ESCROW = "escrow",
  ORIGINATOR = "originator",
}
export enum AccountPermissionConfigType {
  ANALYTICS_ONLY = "analytics-only",
  ASSET_OS = "asset_os",
  BORROWER_REPORTS_ANALYTICS_LENDER = "borrower_reports_analytics_lender",
  BORROWER_REPORTS_LENDER = "borrower_reports_lender",
  BORROWER_REPORTS_ORIGINATOR = "borrower_reports",
  BORROWER_REPORTS_VERIFICATION_ORIGINATOR = "borrower_reports_verification",
  CALC_AGENT_ORIGINATOR = "calc_agent_originator",
  RTL_ORIGINATOR_ASSET_OS = "rtl_originator_collateral_manager",
  SETPOINT_AND_RTL_ORIGINATOR_WITH_ASSET_OS_AND_CHANGE_REQUEST = "setpoint_and_rtl_originator_with_collateral_manager_and_change_request",
  SETPOINT_ORIGINATOR_LEGACY = "setpoint_originator_legacy",
  SETPOINT_ORIGINATOR = "setpoint_originator",
}
export enum AccountType {
  LENDER = "lender",
  ORIGINATOR = "originator",
  AGGREGATOR = "aggregator",
}
export enum Permission {
  SETPOINT_ANALYTICS = "setpoint-analytics",
  ASSET_DOCUMENTS_TAB = "asset-documents-tab",
  ASSET_FUNDING = "asset-funding",
  ASSET_PAGE = "asset-page",
  ASSETS_PAGE = "assets-page",
  BANK_ACCOUNT_PAGE = "bank-account-page",
  BANK_ACTIVITY = "bank-activity",
  BILLING_PAGE = "billing-page",
  BORROWER_REPORTS_PAGE = "borrower-reports-page",
  BUYER_PAGE = "buyer-page",
  CALCULATION_AGENT_PAGE = "calculation-agent-page",
  CALCULATION_AGENT_ROUTES = "calculation-agent-routes",
  CHANGE_REQUEST = "change-request",
  CREATE_FACILITY_SCENARIOS = "create-facility-scenarios",
  DATA_TAPES_PAGE = "data-tapes-page",
  DOCUMENTS_ESCALATED_QUEUE_PAGE = "escalated-documents-queue-page",
  DOCUMENTS_QUEUE_PAGE = "documents-queue-page",
  DILIGENCE_REPORT_PAGE = "diligence-report-page",
  FACILITIES_PAGE = "facilities-page",
  FACILITY_COMPLIANCE_PAGE = "facility-compliance-page",
  FACILITY_MONITORING_PAGE = "facility-monitoring-page",
  FACILITY_NET_CASH_FLOW_BUILD = "facility-net-cash-flow-build",
  FACILITY_SCENARIOS = "facility-scenarios",
  FACILITY_TERMS = "facility-terms",
  FUNDING_FEED_PAGE = "funding-feed-page",
  FUNDING_PAGE = "funding-page",
  HW_ASSET_SETTLEMENT_TAB = "hw-asset-settlement-tab",
  RTL_DOCUMENTS_QUEUE_PAGE = "rtl-documents-queue-page",
  RTL_DOCUMENTS_QUEUE_SUMMARY_PAGE = "rtl-documents-queue-summary-page",
  RTL_GUIDELINE_CHECKS_PAGE = "rtl-guideline-checks-page",
  SETTINGS_PAGE = "settings-page",
  SFR_DOCUMENTS_QUEUE_PAGE = "sfr-documents-queue-page",
  SUMMARY_SHEET_DEBUG_PAGE = "summary-sheet-debug-page",
  TRANSFERS_PAGE = "transfers-page",
  UPCOMING_ADVANCE_PAGE = "upcoming-advance-page",
  VALUATIONS_PAGE = "valuations-page",
  SEE_SPC_RTL_QUEUE = "see-spc-rtl-queue",
  RECONCILIATION_PAGE = "reconciliation-page",
  IN_APP_ONBOARDING = "in-app-onboarding",
  MANAGE_DATA_PARSERS = "manage-data-parsers",
  LOCAL_ACCOUNT_ID_OVERRIDE = "local-account-id-override",
  MANAGE_ADVANCED_ACCOUNT_SETTINGS = "manage-advanced-account-settings",
  ADD_BUYER_GROUP = "add-buyer-group",
  ADD_CALC_AGENT_REPORT = "add-calc-agent-report",
  ADD_EDIT_CALC_AGENT_WAIVER = "add-edit-calc-agent-waiver",
  ADD_DILIGENCE_DUE_DATE = "diligence-due-date",
  ADD_SETPOINT_ROLES = "add-setpoint-roles",
  APPROVE_BUYER_DOCUMENTS = "approve-buyer-documents",
  APPROVE_TRANSACTION = "approve-transaction",
  APPROVE_WIRE = "approve-wire",
  ASSET_BULK_MARK_AS_SOLD = "asset-bulk-mark-as-sold",
  ASSET_BULK_TERMINATE = "asset-bulk-terminate",
  ASSET_BULK_REINSTATE = "asset-bulk-reinstate",
  ASSET_EDIT_DUE_DATES = "asset-edit-due-dates",
  AUTO_EXPAND_NAV_TAB = "auto-expand-nav-tab",
  BORROWING_BASE_EXPORT = "borrowing-base-export",
  CHANGE_REQUEST_MARK_FUNDED = "change-request-mark-funded",
  COMMANDBAR_DEV_COMMANDS = "commandbar-dev-commands",
  CONCENTRATION_LIMIT_EXEMPTION_REVIEW = "concentration-limit-exemption-review",
  CREATE_APPRAISALS = "create-appraisals",
  SOFT_DELETE_ENTITIES = "soft-delete-entities",
  ADD_ASSET = "add-asset",
  EDIT_CALC_AGENT_FACILITY_SETTINGS = "edit-calc-agent-facility-settings",
  EDIT_CLOSING_DATE = "edit-closing-date",
  EDIT_FACILITIES = "edit-facilities",
  EDIT_FUNDING_CALCULATIONS = "edit-funding-calculations",
  EDIT_INVOICE = "edit-invoice",
  EDIT_ROLES = "edit-roles",
  EDIT_TRANSACTION_DETAILS = "edit-transaction-details",
  ENRICHED_BUYER_CONVERSION_MODAL = "enriched-buyer-conversion-modal",
  EXPORT_ASSET_REPORT = "export-asset-report",
  EXPORT_DILIGENCE_REPORT_ON_ASSET_PAGE = "export-diligence-report-on-asset-page",
  EXPORT_FUNDING_FEED = "export-funding-feed",
  FLAG_FACILITY_COMPLIANCE_REQUIREMENTS = "flag-facility-compliance-requirements",
  FUNDING_REQUIREMENT_ACTIONS = "funding-requirement-actions",
  HIDE_ASSET_FROM_REVIEW = "hide-asset-from-review",
  INITIATE_WIRE = "initiate-wire",
  INVITE_USER = "invite-user",
  MANAGE_DATA_TAPES = "manage-data-tapes",
  MANUALLY_MARK_DOC_UPLOADED = "manually-mark-doc-uploaded",
  METRO_TRAILING_PROPERTY = "metro-trailing-property",
  OPENDOOR_SKIP_VESTING_DEED = "opendoor-skip-vesting-deed",
  POST_FUNDING_FACILITY_REASSIGNMENT = "post-funding-facility-reassignment",
  PUBLISH_DILIGENCE_REPORT = "publish-diligence-report",
  REASSIGN_ASSET_FACILITY = "reassign-asset-facility",
  RESTRICT_ALL_NEW_FUNDING_REQUESTS = "restrict-all-new-funding-requests",
  SEE_FUNDING_REQUIREMENT_DEBUG_IDS = "see-funding-requirement-debug-ids",
  SEE_FUNDING_REQUIREMENT_REVIEW_DOC_MARK_MISSING = "see-funding-requirement-review-doc-mark-missing",
  SEE_FUNDING_REQUIREMENT_REVIEW_SKIP = "see-funding-requirement-review-skip",
  SEE_LEVERAGE_FACILITY = "see-leverage-facility",
  SEE_PURCHASE_POWER = "see-purchase-power",
  SEE_WIRE_TRANSFER_INSTRUCTIONS = "see-wire-transfer-instructions",
  SEE_FUNDING_VEHICLES = "see-funding-vehicles",
  SEE_ENVIRONMENT_INDICATORS = "see-environment-indicators",
  SEND_REPORTING_EMAILS = "send-reporting-emails",
  SET_BUYER_FACILITY = "set-buyer-facility",
  SETPOINT_FACILITY_SETTINGS = "setpoint-facility-settings",
  SETPOINT_OPENDOOR_DOC_REVIEW = "setpoint-opendoor-doc-review",
  SUPER_ADMIN_APPROVE_CALC_AGENT_REPORT = "super-admin-approve-calc-agent-report",
  REPROCESS_CALC_AGENT_REPORT = "reprocess-calc-agent-report",
  SWITCH_ACCOUNT_SUPER_ADMIN = "switch-account-super-admin",
  SWITCH_ACCOUNT_CAN_ACCESS_SETPOINT_FACILITIES = "switch-account-can-access-setpoint-facilities",
  SWITCH_ACCOUNT_CAN_ACCESS_TECH_ONLY_ACCOUNTS = "switch-account-can-access-tech-only-accounts",
  TERMINATE_ASSET = "terminate-asset",
  TOGGLE_CHECKLIST_FUNDING_REQUIREMENT = "toggle-checklist-funding-requirement",
  TRADITIONAL_FUNDING = "traditional-funding-status",
  UPDATE_ADVANCE_RATE = "update-advance-rate",
  UPDATE_CUSTOMER_ASSET_ID = "update-customer-asset-id",
  UPLOAD_ASSET_DATA = "upload-asset-data",
  VIEW_ALL_ASSET_PAGES = "view-all-asset-pages",
  VIEW_ASSET_LIMIT_CHANGES = "view-asset-limit-changes",
  VIEW_APPRAISALS = "view-appraisals",
  VIEW_INVISIBLE_FACILITIES = "view-invisible-facilities",
  VIEW_MAX_CASH_OFFER = "view-max-cash-offer",
  REMOVE_ITEMS_FROM_FUNDING_REQUEST = "remove-items-from-funding-request",
  ASSET_BORROWING_BASE_TRANSFER = "asset-borrowing-base-transfer",
  DELETE_CALC_AGENT_REPORT = "delete-calc-agent-report",
  HOMEWARD_TRIGGER_DOCUMENT_VERIFICATION_REPORTS = "homeward-trigger-document-verification-reports",
  WANDER_TRIGGER_DOCUMENT_VERIFICATION_REPORTS = "wander-trigger-document-verification-reports",
}
export enum CommandbarDevCommand {
  COMPLETE_PRE_CLOSE = "complete-pre-close",
  RTL_UPLOAD_DOCS = "rtl-upload-docs",
  ADD_DEBUG_PROPERTY = "add-debug-property",
  ADD_DEBUG_PROPERTIES = "add-debug-properties",
  ALMOST_COMPLETE_PRE_CLOSE = "almost-complete-pre-close",
  COMPLETE_AT_CLOSE = "complete-at-close",
  RESET_DEMO_PROPERTIES = "reset-demo-properties",
  SARAH_SIGN_IN = "sarah-sign-in",
  SIMULATE_DILIGENCE_REPORT = "simulate-diligence-report",
}
export enum CommandbarEmailReport {
  ADVANCE_CHANGE_REPORT = "advance-change-report",
  COLLECTIONS_REPORT = "collections-report",
  DAY_BY_DAY_REPORTS = "day-by-day-reports",
  FUND_ACTIVITY_RECONCILIATION_REPORT = "fund-activity-reconciliation-report",
  FUNDING_REPORT = "funding-report",
  SECURITIZATION_ACTIVITY_RECONCILIATION_REPORT = "securitization-activity-reconciliation-report",
  SETPOINT_LOAN_TAPE_REPORT = "setpoint-loan-tape-report",
  WSFS_PRE_FUNDING_REPORT = "wsfs-pre-funding-report",
  CHURCHILL_REPORT = "churchill-report",
  BAWAG_REPO_REPORT = "bawag-repo-report",
  REO_REPORT = "reo-report",
  VALUATION_REPORT = "valuation-report",
  AMHERST_BORROWING_BASE_REPORT = "amherst-borrowing-base-report",
  BACKFLIP_EXCESS_DAYS_ON_BASE_REPORT = "backflip-excess-days-on-base-report",
  FLYHOMES_BAWAG_BORROWING_BASE = "flyhomes-bawag-borrowing-base",
  FLYHOMES_BAWAG_PRE_CLOSE = "flyhomes-bawag-pre-close",
  FLYHOMES_BAWAG_AT_CLOSE = "flyhomes-bawag-at-close",
  FLYHOMES_SETPOINT_2_BORROWING_BASE = "flyhomes-securitization-borrowing-base",
  FLYHOMES_SETPOINT_2_PRE_CLOSE = "flyhomes-securitization-pre-close",
  FLYHOMES_SETPOINT_2_AT_CLOSE = "flyhomes-securitization-at-close",
  DAVIDSON_KEMPNER_VALUATION_REPORT = "davidson-kempner-valuation-report",
  UE_FUNDED_ASSET_REPORT = "ue-funded-asset-report",
}
export enum EmailReportParameterOptions {
  DATE_RANGE = "date-range",
  FACILITY = "facility",
}
