import { BORROWER_REPORTS_SUMMARY_PATHNAME } from "lib/borrower-reports"
import { CALC_AGENT_SUMMARY_PATHNAME } from "lib/calculation-agent"
import { GetServerSidePropsContext } from "next"
import { Permission, ProfileResponse } from "types/server/auth"
import { get, getAbsoluteEndpoint } from "utility/fetcher"

export const redirect = (destination: string) => ({
  redirect: { destination, permanent: false },
})

export const getSession = async ({
  req,
}: GetServerSidePropsContext): Promise<ProfileResponse | null | undefined> => {
  try {
    return await get<ProfileResponse>(
      "/auth/user",
      {},
      {
        Cookie: req.headers.cookie,
      },
    )
  } catch (error) {
    return
  }
}

export const checkAuth = async (
  context: GetServerSidePropsContext,
  permission?: Permission | null | undefined,
  otherProps: Record<string, unknown> = {},
) => {
  const { resolvedUrl } = context
  const isRootPath = resolvedUrl === "/"
  const isLoginPage = resolvedUrl.startsWith("/login")

  const encodedUrl = isRootPath
    ? "/login"
    : `/login?next=${encodeURIComponent(resolvedUrl)}`
  const loginUrl = isLoginPage ? resolvedUrl : encodedUrl

  const session = await getSession(context)

  if (!session?.user) {
    if (isLoginPage) return { props: {} }
    return redirect(loginUrl)
  }

  const permissions = (session.permissions || []) as Permission[]

  if (permissions.length === 0) return redirect("/unauthorized")

  if (permission && !permissions.includes(permission)) {
    let next = "/"
    if (permissions.includes(Permission.FACILITIES_PAGE)) {
      next = "/portfolio/summary"
    } else if (permissions.includes(Permission.CALCULATION_AGENT_PAGE)) {
      next = CALC_AGENT_SUMMARY_PATHNAME
    } else if (permissions.includes(Permission.BORROWER_REPORTS_PAGE)) {
      next = BORROWER_REPORTS_SUMMARY_PATHNAME
    } else if (permissions.includes(Permission.DOCUMENTS_QUEUE_PAGE)) {
      next = "/diligence"
    } else if (permissions.includes(Permission.ASSETS_PAGE)) {
      next = "/assets"
    } else if (permissions.includes(Permission.SETPOINT_ANALYTICS)) {
      next = "/analytics"
    }
    return redirect(next)
  }

  if (isLoginPage) {
    const next = "/"
    return redirect(next as string)
  }

  return { props: { ...otherProps, session } }
}

export const logOut = async () => {
  const logoutUrl = getAbsoluteEndpoint("/auth/logout")
  window.location.assign(logoutUrl)
}
