import * as snippet from "@segment/snippet"
import { isNonProd, isProd, isSegmentEnabled, SEGMENT_WRITE_KEY } from "config"
import Script from "next/script"
import { ParsedUrlQuery } from "querystring"

const renderSegmentSnippet = () => {
  const options = {
    apiKey: SEGMENT_WRITE_KEY,
    page: true,
  }

  if (isNonProd) {
    return snippet.max(options)
  }

  if (isProd) {
    return snippet.min(options)
  }
}

export const SegmentScript = () => {
  if (!isSegmentEnabled) {
    return null
  }

  return (
    <Script
      id="segment-script"
      dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() || "" }}
    />
  )
}

type PotentialWindowObject = Window | Record<string, unknown>

export const trackPageView = (
  renderedPath: string,
  pathName: string,
  routerQueryObject: ParsedUrlQuery,
) => {
  const potentialWindowObject: PotentialWindowObject = window ?? {}
  const segmentAnalytics =
    potentialWindowObject["analytics" as keyof PotentialWindowObject]
  if (typeof segmentAnalytics === "undefined") {
    return
  }

  segmentAnalytics.page("Viewed Page", {
    path: pathName,
    query: routerQueryObject,
  })
}

export const identifyUser = (
  userIdentifier?: string,
  traits?: Record<string, unknown>,
) => {
  const potentialWindowObject: PotentialWindowObject = window ?? {}
  const segmentAnalytics =
    potentialWindowObject["analytics" as keyof PotentialWindowObject]
  if (typeof segmentAnalytics === "undefined") {
    return
  }
  segmentAnalytics.identify(userIdentifier, traits)
}
